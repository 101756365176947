<template>
  <div>
    <b-row aria-hidden="true">
      <b-col cols="6" lg="8">
        <b-row class="mb-2">
          <b-col cols="1" class="pl-0">
            <div class="circle ml-sm-2 ml-lg-4 ml-1">
              1
            </div>
          </b-col>
          <b-col class="ml-2">
            <div class="line-item" v-html="Footer1" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="1" class="pl-0">
            <div class="circle ml-sm-2 ml-lg-4 ml-1">
              2
            </div>
          </b-col>
          <b-col class="ml-2">
            <div class="line-item" v-html="Footer2" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="1" class="pl-0">
            <div class="circle ml-sm-2 ml-lg-4 ml-1">
              3
            </div>
          </b-col>
          <b-col class="ml-2">
            <div class="line-item" v-html="Footer3" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" lg="4" style="border-left: 1px solid #426580;">
        <b-row class="py-3">
          <b-col cols="12" md="2" class="m-auto">
            <font-awesome-icon :icon="['fa', 'globe']" size="2x" class="icon-shade" />
          </b-col>
          <b-col cols="12" md="10">
            <b-row>
              <b-col>
                <a
                  href="http://www.state.nj.us/education"
                  target="_blank"
                  rel="noopener noreferrer">
                  www.state.nj.us/education
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <a
                  href="http://www.njschooldata.org"
                  target="_blank"
                  rel="noopener noreferrer">
                  www.njschooldata.org
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" class="m-auto">
            <font-awesome-icon :icon="['fa', 'at']" size="2x" class="icon-shade" />
          </b-col>
          <b-col cols="12" md="10">
            <a
              href="mailto:reportcard@doe.nj.gov"
              target="_blank"
              rel="noopener noreferrer">
              reportcard@doe.nj.gov
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="sr-only">
      <b-col>
        <span>1;</span><span v-html="Footer1" />
        <span>2;</span><span v-html="Footer2" />
        <span>3;</span><span v-html="Footer3" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TranslationSvc from '../services/translation.service';

export default {
  computed: {
    currentReportType() {
      return this.$route.params.reportType.toLowerCase();
    },
    FooterTitle() {
      if (this.$route.name === 'ReportSummary') {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolSummary.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictSummary.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      } else {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolDetail.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictDetail.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
        if (this.currentReportType === 'state') {
          return TranslationSvc.State.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      }
      return '';
    },
    Footer1() {
      if (this.$route.name === 'ReportSummary') {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolSummary.getFooterTranslation(
            'Footer-1',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictSummary.getFooterTranslation(
            'Footer-1',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      } else {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolDetail.getFooterTranslation(
            'Footer-1',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictDetail.getFooterTranslation(
            'Footer-1',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
        if (this.currentReportType === 'state') {
          return TranslationSvc.State.getFooterTranslation(
            'Footer-1',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      }
      return '';
    },
    Footer2() {
      if (this.$route.name === 'ReportSummary') {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolSummary.getFooterTranslation(
            'Footer-2',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictSummary.getFooterTranslation(
            'Footer-2',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      } else {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolDetail.getFooterTranslation(
            'Footer-2',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictDetail.getFooterTranslation(
            'Footer-2',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
        if (this.currentReportType === 'state') {
          return TranslationSvc.State.getFooterTranslation(
            'Footer-2',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      }
      return '';
    },
    Footer3() {
      if (this.$route.name === 'ReportSummary') {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolSummary.getFooterTranslation(
            'Footer-3',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictSummary.getFooterTranslation(
            'Footer-3',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      } else {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolDetail.getFooterTranslation(
            'Footer-3',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictDetail.getFooterTranslation(
            'Footer-3',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
        if (this.currentReportType === 'state') {
          return TranslationSvc.State.getFooterTranslation(
            'Footer-3',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      }
      return '';
    },
  },
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 2px;
  background: #426580;
  border: 3px solid #426580;
  color: #fff;
  text-align: center;
}
.line-item {
  font-size: 1em;
}
@media print {
  .circle {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 1px;
    background: #426580;
    border: 3px solid #426580;
    color: #fff;
    text-align: center;
  }
  .line-item {
    font-size: 8pt;
  }
}
.icon-shade {
  color: #426580;
}
</style>
