<template>
  <div>
    <b-row
      v-if="HaveData || (ChartType == 'None' && VisualNoDataText && VisualNoDataText !== '-')"
      :class="{ 'pt-4': VisualTitle != '' }"
    >
      <b-col>
        <b-card
          :id="$props.VisualTitle"
          ref="VisualContainer"
          :class="borderClass"
          no-body
          class="mt-md-4 mt-print-0"
        >
          <b-card-header v-if="isSinglePrint">
            <span>{{ printDisplay }}</span>
          </b-card-header>
          <b-card-body class="py-2">
            <b-row v-if="VisualTitle != ''">
              <b-col>
                <h3>
                  {{ VisualTitle }} <i
                    v-if="$route.name === 'Summary'
                      && VisualTitle != ''"
                    class="fas fa-info-circle"
                  />
                </h3>
              </b-col>
              <b-col
                v-if="$route.name != 'Summary'
                  && VisualTitle != '' && !SuppressPrintButton"
                cols="1"
                class="d-none d-lg-block"
              >
                <a
                  :id="$props.VisualTitle + 'no-print'"
                  ref="printIcon"
                  class="small-icon"
                  :title="VisualTitle + `Download Chart Icon`"
                  @click="print"
                  @keydown="print"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    :alt="VisualTitle + ` Download Chart Icon`"
                  >
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <p v-html="visualDescription" />
            <div class="chartRender">
              <div class="ChartSubRender">
                <span class="sub-heading" v-html="ChartSubTitle" />
              </div>
              <div v-if="ShowChartTitle" class="subTitle">
                <span class="subTitle-Text">{{ ChartTitle }}</span><br />
                <span class="">{{ ChartDescription }}</span>
              </div>
              <b-row v-show="isPrint && ChartType != ChartTypes.GaugeChart">
                <b-col>
                  <span v-html="chartImage" />
                </b-col>
              </b-row>
              <!-- Here are all the Chart Types; controlled by v-if -->
              <div :class="{ chartWithSubTitle: ChartSubTitle.length > 0 }">
                <gauge-chart
                  v-if="ChartType === ChartTypes.GaugeChart"
                  aria-hidden="true"
                  :ref="ChartTypes.GaugeChart"
                  :legend-labels="LegendLabels"
                  :chart-value-text="ChartValueText"
                  :chart-value="ChartValue"
                  :chart-value-string="ChartValueString"
                  :chart-value-label="ChartValueLabel"
                  :scale-data-points="ScaleDataPoints"
                  :scale-data-points-label="ScaleDataPointsLabel"
                  :data-decoration="DataDecoration"
                  :suppress-legend="$props.SuppressLegend"
                />

                <bar-chart
                  v-if="ChartType === ChartTypes.BarChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.BarChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                  :suppress-legend="$props.SuppressLegend"
                />
                <line-chart
                  v-if="ChartType === ChartTypes.LineChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.LineChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                />
                <horizontal-bar-chart
                  v-if="ChartType === ChartTypes.HorizontalBarChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.HorizontalBarChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                  :show-x-axis-labels="$props.ShowXAxisLabels"
                  :height="150"
                />
                <stacked-bar-chart
                  v-if="ChartType === ChartTypes.StackedBarChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.StackedBarChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                  class="stacked-bar-parent"
                  @animationCompleted="animationCompleted"
                />
                <triple-bar-chart
                  v-if="ChartType === ChartTypes.TripleBarChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.TripleBarChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                  class="tripple-bar-parent"
                />
                <double-donut-chart
                  v-if="ChartType === ChartTypes.DoubleDonutChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.DoubleDonutChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                />
                <single-donut-chart
                  v-if="ChartType === ChartTypes.DonutChart"
                  v-show="!isPrint"
                  :ref="ChartTypes.DonutChart"
                  :data-labels="Labels"
                  :data-sets="Data"
                  :chart-label="ChartTitle"
                />
                <!-- Table Rendering -->
                <div v-if="ChartType === 'table-grid'" class="table">
                  <b-row :class="TableParentScrollClass">
                    <b-table
                      striped
                      :items="TableItems"
                      :fields="TableFields"
                      :tbody-tr-class="setTbodyTrClass"
                      thead-tr-class="hideDefaultHeader"
                      class="align-middle"
                      :class="tableClass"
                    >
                      <template #cell()="data">
                        <span v-if="hasLink(data)" v-html="data.value" />
                        <span
                          v-else-if="data.value === ''"
                          class="sr-only">Empty</span>
                        <span v-else>{{ data.value }}</span>
                      </template>
                      <template #thead-top="data">
                        <b-tr>
                          <b-th
                            v-for="(col, index) in data.fields"
                            :key="index"
                            :class="setHeaderStyle(col)"
                          >
                            {{ col.label }}
                          </b-th>
                        </b-tr>
                      </template>
                      <template #head()="data">
                        <span v-if="data === data" />
                      </template>
                    </b-table>
                  </b-row>
                </div>
                <div v-if="ChartType === 'None'" v-html="VisualNoDataText" />
                <!-- <div v-if="ChartType !== 'table-grid'">
                  <div v-for="(element, index) in Data"
                  :key="index" v-html="Labels + ` ` + Data[index].dataAsString"></div>
                </div> -->
                <div v-if="ChartType !== 'table-grid'" class="sr-only" v-html="ScreenReaderData" />
              </div>
              <div class="chart-xaxis-title">
                <span class="x-axis-title" v-html="ChartXAxisTitle" />
              </div>
            </div>
            <slot />
            <p
              v-if="visualFooter && visualFooter.length > 0"
              class="p-1"
              v-html="visualFooter"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BarChart from './charts/BarChart';
import LineChart from './charts/LineChart';
import HorizontalBarChart from './charts/DoubleHBarChart';
import StackedBarChart from './charts/StackedBarChart';
import TripleBarChart from './charts/TripleBarChart';
import DoubleDonutChart from './charts/DoubleDoughnutChart';
import SingleDonutChart from './charts/SingleDonutChart';
import GaugeChart from './charts/GaugeChart.vue';
import ChartTypes from './ChartTypes';

export default {
  components: {
    BarChart,
    LineChart,
    HorizontalBarChart,
    StackedBarChart,
    TripleBarChart,
    DoubleDonutChart,
    SingleDonutChart,
    GaugeChart,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    ChartType: {
      type: String,
      default: 'table-grid',
      required: true,
    },
    PrintContainerId: { // custom individual print - start from this id
      type: String,
      default: '',
      required: false,
    },
    VisualTitle: {
      type: String,
      default: '',
      required: false,
    },
    visualDescription: {
      type: String,
      default: '',
      required: false,
    },
    visualFooter: {
      type: String,
      default: '',
      required: false,
    },
    VisualNoDataText: {
      type: String,
      default: '',
      required: false,
    },
    DataSortOnTables: {
      type: Boolean,
      default: false,
      required: false,
    },
    ChartSubTitle: {
      type: String,
      default: '',
      required: false,
    },
    ChartXAxisTitle: {
      type: String,
      default: '',
      required: false,
    },
    suppressBorder: {
      type: Boolean,
      default: false,
      required: false,
    },
    EqualWidthDataColumns: {
      type: Boolean,
      default: false,
      required: false,
    },
    TableShading: {
      type: Array,
      default() {
        return [];
      },
      // Example
      // [
      //   {
      //     cellValues: ['NA', 'N/A'],
      //     cellVariant: 'primary',
      //   },
      // ]
    },
    HeaderShading: {
      type: Boolean,
      // Example
      // [
      //   {
      //     cellValues: ['NA', 'N/A'],
      //     cellVariant: 'primary',
      //   },
      // ]
    },
    HorizontalLines: {
      type: Array,
      default() {
        return [];
      },
      // Example
      // ['Total'] <-- Draws line over "Total"
      // ['Total', 'KG - Half Day'] <-- Draws line over "Total" and "KG - Half Day"
    },
    VerticalLines: {
      type: Array,
      default: () => ['2019-20 ELA'],
      // Example
      // ['2019-20 ELA'] <-- Draws a line right of column header "2019-20 ELA"
      // ['2019-20 ELA', 'Performance Measure'] <-- Draws a line right of columns with
      //                                    headers '"2019-20 ELA" and "Performance Measure"
    },
    ScaleDataPoints: {
      type: Array,
      default: () => [0, 90.9, 94.9, 100],
    },
    ScaleDataPointsLabel: {
      type: Array,
      default: () => [],
      required: false,
    },
    ShowXAxisLabels: {
      type: Boolean,
      default: true,
    },
    SuppressPrintButton: {
      type: Boolean,
      default: false,
    },
    SuppressLegend: {
      type: Boolean,
      default: false,
      required: false,
    },
    DataDecoration: {
      type: String,
      default: '%',
      required: false,
    },
  },
  data() {
    return {
      ChartTypes,
      chartImage: '',
      isPrint: false,
      isSinglePrint: false,
    };
  },
  computed: {
    LegendLabels() {
      return this.$props.chartData.Labels;
    },
    ChartValueText() {
      return `${this.$props.chartData.Values[0].dataAsString}`;
    },
    ChartValue() {
      return this.$props.chartData.Values[0].value;
    },
    ChartValueString() {
      return `${this.$props.chartData.Values[0].value}%`;
    },
    ChartValueLabel() {
      return this.$props.chartData.Values[0].valueLabel;
    },
    printDisplay() {
      switch (this.$route.params.reportType.toLowerCase()) {
        case 'state':
          return `${this.$store.getters.SelectedSchoolYear.text} New Jersey State Performance Reports`;
        case 'district':
          return `${this.$store.getters.SelectedSchoolYear.text} ${this.$store.getters.CurrentDistrictInfo.districtName} (${this.$store.getters.CurrentDistrictInfo.countyCode}-${this.$store.getters.CurrentDistrictInfo.districtCode})`;
        case 'school':
          return `${this.$store.getters.SelectedSchoolYear.text} ${this.$store.getters.CurrentSchoolInfo.schoolName} : ${this.$store.getters.CurrentSchoolInfo.districtName} (${this.$store.getters.CurrentSchoolInfo.countyCode}-${this.$store.getters.CurrentSchoolInfo.districtCode}-${this.$store.getters.CurrentSchoolInfo.schoolCode})`;
        default:
          return 'Error in Routing Information';
      }
    },
    borderClass() {
      return {
        'outside-border': this.ChartType !== ChartTypes.DoubleDonutChart,
        'no-border': this.ChartType === ChartTypes.DoubleDonutChart || this.$props.suppressBorder,
      };
    },
    TableParentScrollClass() {
      // add horizontal scroll when there are more than 5 columns and overflow
      return {
        'p-4 table-parent table-parent-scroll': this.$props.chartData.ColumnHeaders && this.$props.chartData.ColumnHeaders.length > 5,
        'p-4 table-parent': !this.$props.chartData.ColumnHeaders || this.$props.chartData.ColumnHeaders.length <= 5,
      };
    },
    tableClass() {
      return this.ChartType === ChartTypes.TableGrid && this.$props.EqualWidthDataColumns ? 'eq-width-table table-grid' : 'table-grid';
    },
    Labels() {
      // store object : Labels
      return this.$props.chartData.Labels;
    },
    Data() {
      // store object : values - Translated to DataSet object for chart
      return this.$props.chartData.Values;
    },
    ScreenReaderData() {
      let j;
      let i;
      let labelValuePair;
      let ChartKey;
      if (this.$props.ChartType === 'None') {
        return 'There is no chart or table data for this topic.';
      }
      if (this.$props.ChartType !== 'horizontal-bar-chart'
        && this.$props.ChartType !== 'donut-chart'
        && this.$props.ChartType !== 'double-donut-chart'
        && this.$props.ChartType !== 'gauge-chart'
        && this.$props.ChartType !== 'line-chart') {
        for (j = 0; j < this.$props.chartData.Labels.length; j += 1) {
          for (i = 0; i < this.$props.chartData.Values.length; i += 1) {
            // eslint-disable-next-line
            labelValuePair += '<div>' + this.$props.chartData.Values[i].label + ' ' + this.$props.chartData.Labels[j]
            + ' ' + this.$props.chartData.Values[i].dataAsString[j].replace('<br/>', ' ') + '</div>';
          }
        }
      } else if (this.$props.ChartType === 'double-donut-chart') {
        for (j = 0; j < this.$props.chartData.Labels.length; j += 1) {
          // eslint-disable-next-line
          ChartKey += this.$props.chartData.Labels[j] + ' '; // Chart_Key
        }
        // eslint-disable-next-line
        labelValuePair = 'Legend: ' + ChartKey + ' Value: ' + this.$props.chartData.Values[0].label + ' ' + this.$props.chartData.Values[0].dataAsString + ' ';
      } else if (this.$props.ChartType === 'horizontal-bar-chart' || this.$props.ChartType === 'line-chart') {
        for (j = 0; j < this.$props.chartData.Labels.length; j += 1) {
          for (i = 0; i < this.$props.chartData.Values.length; i += 1) {
            // eslint-disable-next-line
            labelValuePair += this.$props.chartData.Labels[j] + ' ' + this.$props.chartData.Values[i].dataAsString[j] + ' ';
          }
        }
      } else if (this.$props.ChartType === 'gauge-chart') {
        labelValuePair = 'Legend: ';
        for (i = 0; i < this.$props.chartData.Labels.length; i += 1) {
          labelValuePair += `${this.$props.chartData.Labels[i]}; `;
        }
        labelValuePair = labelValuePair.replace(/-/g, 'to');
        labelValuePair += `Chart Value: ${this.$props.chartData.Values[0].value}% - ${this.$props.chartData.Values[0].label}`;
      } else {
        // eslint-disable-next-line
        labelValuePair = this.$props.chartData.Labels + ' ' + this.$props.chartData.Values[0].dataAsString[0] + ' ';
      }
      return labelValuePair.replace(/undefined/g, '');
    },
    ShowChartTitle() {
      return this.$props.chartData.ChartSubTitle
         && this.$props.chartData.ChartSubTitle.length > 0;
    },
    ShowXAxisTitle() {
      return this.$props.chartData.ChartXAxisTitle
          && this.$props.chartData.ChartXAxisTitle.length > 0;
    },
    ChartTitle() {
      return this.$props.chartData.ChartSubTitle;
    },
    ChartDescription() {
      return this.$props.chartData.description;
    },
    ColumnHeaders() {
      return this.$props.chartData.ColumnHeaders; // store object : column headers
    },
    TableItems() {
      try {
        if (!this.$props.chartData
          || !this.$props.chartData.ColumnHeaders
          || !this.$props.chartData.Labels
          || !this.$props.chartData.Values) {
          return [];
        }

        let columnIndex;
        let rowIndex;
        let tmp = {};
        const dataValue = [];

        const colHeaders = this.$props.chartData.ColumnHeaders;
        const dataLabels = this.$props.chartData.Labels;
        const dataValues = this.$props.chartData.Values;

        for (rowIndex = 0; rowIndex < dataLabels.length; rowIndex += 1) {
          tmp = {};
          tmp[colHeaders[0]] = dataLabels[rowIndex];
          dataValue.push(tmp);
        }
        for (columnIndex = 0; columnIndex < colHeaders.length - 1; columnIndex += 1) {
          for (rowIndex = 0; rowIndex < dataLabels.length; rowIndex += 1) {
            tmp = dataValue[rowIndex];
            tmp[colHeaders[columnIndex + 1]] = dataValues[columnIndex].dataAsString[rowIndex];
            this.applyTableShading(
              dataValues[columnIndex].dataAsString[rowIndex],
              tmp,
              colHeaders[columnIndex + 1],
            );
          }
        }
        return dataValue;
      } catch (e) {
        console.error(e);
      }
      return [];
    },
    TableFields() {
      try {
        if (this.$props.DataSortOnTables) {
          return this.$props.chartData.ColumnHeaders.map((o) => ({
            key: o,
            sortable: true,
          }));
        }
        if (!this.$props.chartData.ColumnHeaders || this.$props.chartData.ColumnHeaders.length === 0
          || this.$props.chartData.ColumnHeaders.some((o) => !o)) {
          return [];
        }
        const fields = this.$props.chartData.ColumnHeaders.map((o) => ({
          key: o,
          tdClass: this.$props.VerticalLines.some((i) => i.toLowerCase() === o.toLowerCase()) ? 'td-right-border' : '',
          label: o, // this is the column header. use column headers as it is. do not capitalize
        }));

        return fields;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    HaveData() {
      try {
        return this.$props.chartData
        && (this.$props.ChartType === 'horizontal-bar-chart' || this.$props.chartData.ColumnHeaders)
        && this.$props.chartData.Labels
        && this.$props.chartData.Values;
      } catch (e) {
        console.error(e);
      }
      return false;
    },
  },
  methods: {
    animationCompleted() {
      if (this.$store.getters.IsPrintView) {
        this.enablePrintView();
      }
    },
    hasLink(data) {
      return data.value.indexOf('<a') >= 0;
    },
    setTbodyTrClass(item) {
      const classes = [];
      if (this.HorizontalLines
        && this.HorizontalLines.some(
          (o) => o.toLowerCase() === item[this.ColumnHeaders[0]].toLowerCase(),
        )) {
        classes.push('custom-border');
      }
      // if (item[this.ColumnHeaders[0]] === 'Total'
      // || item[this.ColumnHeaders[0]] === 'Total Unique Incidents') classes.push('custom-border');
      return classes;
    },
    setHeaderStyle(col) {
      const classes = [];
      // eslint-disable-next-line
      // alert(col.label);
      if (this.HeaderShading) {
        if (col.label === '% Level 1: Did not yet meet expectations'
        || col.label === '% Nivel 1: Todavía no cumplió las expectativas') {
          classes.push('custom-headerlightpurple');
        } else if (col.label === '% Level 2: Partially met expectations'
        || col.label === '% Nivel 2: Cumplió parcialmente las expectativas') {
          classes.push('custom-headermidpurple');
        } else if (col.label === '% Level 3: Approached expectations'
        || col.label === '% Nivel 3: Se acercó a las expectativas') {
          classes.push('custom-headerpurple');
        } else if (col.label === '% Level 4: Met expectations'
        || col.label === '% Nivel 4: Cumplió las expectativas') {
          classes.push('custom-headermiddarkpurple');
        } else if (col.label === '% Level 5: Exceeded expectations'
          || col.label === '% Nivel 5: Superó las expectativas') {
          classes.push('custom-headerdarkpurple');
        }
      }
      return classes;
    },
    enablePrintView() {
      this.isPrint = true;
      if (this.$props.ChartType !== ChartTypes.TableGrid
        && this.$props.ChartType !== 'None') {
        const ctx = this.$refs[this.$props.ChartType].getContext();
        this.chartImage = `<img src="${ctx.canvas.toDataURL()}" alt="Print view of charts"/>`;
      }
      if (this.$refs.printIcon) {
        this.$refs.printIcon.hidden = true;
      }
    },
    disablePrintView() {
      this.isPrint = false;
      this.chartImage = '';
      if (this.$refs.printIcon) {
        this.$refs.printIcon.hidden = false;
      }
    },
    print() {
      this.isSinglePrint = true;
      this.$refs.VisualContainer.style.width = '1008px';
      setTimeout(() => {
        this.enablePrintView();

        this.$nextTick(() => {
          // eslint-disable-next-line
          if(this.$props.PrintContainerId && this.$props.PrintContainerId !== '') {
            // custom individual print - start from this id
            this.$htmlToPaper(this.$props.PrintContainerId);
          } else {
            // by default print div starting at the visualtitle
            this.$htmlToPaper(this.$props.VisualTitle);
          }
          this.disablePrintView();
          this.isSinglePrint = false;
          this.$refs.VisualContainer.style.width = '';
        });
      }, 100);
    },
    applyTableShading(cellValue, outputObject, columnName) {
      if (!this.$props.TableShading) return; // No shading applied
      this.$props.TableShading.forEach((ts) => {
        if (ts.cellValues.some((o) => o === cellValue)) {
          // eslint-disable-next-line
          if (!outputObject._cellVariants) outputObject._cellVariants = {};
          // eslint-disable-next-line
          outputObject._cellVariants[columnName] = ts.cellVariant;
          if (ts.suppressText) {
            // eslint-disable-next-line
            outputObject[columnName] = '';
          }
        }
      });
    },
  },
};
</script>

<style>
th {
  background-color: #287AB8;
  font-weight: bold;
  color: #fff;
  font-size: 10.5pt;
}
.table td,
.table th {
  padding: .25rem !important;
}
.table td,
.table th {
    text-align: left;
    vertical-align: middle !important;
}
.table td {
    vertical-align: middle !important;;
}
.table td + td,
.table th + th {
    text-align: center;
    vertical-align: middle;
}
tr.custom-border {
  border-top: 2px solid #000 !important;
}
tr.hideDefaultHeader {
  display: none;
}
th.custom-headerlightpurple {
  background-color: #9257E0 !important;
}
th.custom-headermidpurple {
  background-color: #8237EB !important;
}
th.custom-headerpurple {
  background-color: #7717DE !important;
}
th.custom-headermiddarkpurple {
  background-color: #451c86 !important;
}
th.custom-headerdarkpurple {
  background-color: #270e45 !important;
}
.td-right-border {
  border-right: 2px solid #000 !important;
}
</style>

<style scoped>
.chartRender {
  display: block;
  position: relative;
}
.ChartSubRender {
  min-height: 92px;
  position: absolute;
  bottom: -70px;
  left: 0;

  transform: rotate(270deg);
  transform-origin: 0 0;
}
.ChartSubRender H3 {
  font-size: 1.3rem;
}
.subTitle-Text{
  font-weight: bold;
  font-size: 1.3em;
}
.chartWithSubTitle {
  margin-left: 60px;
}
.outside-border{
    border: 2px solid #287AB8;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
.no-border{
  border: 0px;
  padding-top: 0;
}
.no-border .card-body{
  padding-top: 0;
  padding-bottom: 0;
}
.info-circle{
  font-size: 20px;
}
span.cellData {
  width: 100%;
  height: 100%;
}
a {
  color: #0071EB;
}
</style>
<style scoped>
.table-parent-scroll {
      overflow-x: auto;
    }
  .eq-width-table {
      table-layout: auto;
    }
    .eq-width-table :deep(th:nth-child(1)) {
      width: auto;
    }
  @media (min-width: 768px) {
    .eq-width-table {
      table-layout: fixed;
    }
    .eq-width-table :deep(th:nth-child(1)) {
      width: 25%!important;
    }
  }
  @media only screen and (max-width: 767px) {
    /* show horizontal scroll for table on small screens */
    .table-parent {
        overflow-x: scroll;
        overflow-y: hidden;
      }
      .table-grid :deep(th){
      min-width: 80px!important;
    }
    .tripple-bar-parent {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .tripple-bar-parent :deep(canvas){
        min-width: 680px;
        max-height: 350px;
    }
    .stacked-bar-parent {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .stacked-bar-parent :deep(canvas){
        min-width: 380px;
        max-height: 350px;
    }
  }
</style>
