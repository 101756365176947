import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const summaryUrl = `${rootUrl}api/schoolsummary`;
const detailAcademicUrl = `${rootUrl}api/schooldetailed/AcademicAchievement`;
const detailedAccountabilityUrl = `${rootUrl}api/schooldetailed/Accountability`;
const detailedClimateUrl = `${rootUrl}api/schooldetailed/Climate`;
const detailedCollegeUrl = `${rootUrl}api/schooldetailed/CollegeAndCareerReadiness`;
const detailedDemographicsUrl = `${rootUrl}api/schooldetailed/Demographics`;
const detailGradUrl = `${rootUrl}api/schooldetailed/Grad`;
const detailStaffUrl = `${rootUrl}api/schooldetailed/Staff`;
const detailedStudentGrowthUrl = `${rootUrl}api/schooldetailed/StudentGrowth`;
const detailedHeaderUrl = `${rootUrl}api/schooldetailed/header`;
const detailedOverviewUrl = `${rootUrl}api/schooldetailed/overview`;
const detailNarrativeUrl = `${rootUrl}api/schooldetailed/Narrative`;
const detailPerPupilUrl = `${rootUrl}api/schooldetailed/PerPupil`;

export default {
  Summary: {
    async getHeader(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
      const response = await axios.get(
        `${summaryUrl}/header/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getSchoolHighlights(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
      const response = await axios.get(
        `${summaryUrl}/SchoolHighlights/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getStudentGrowthCompareELA(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/StudentGrowthComparisonELA/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentGrowthCompareMath(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/StudentGrowthComparisonMath/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsGraduatingFourYear(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/AreStudentsGraduatingFourYear/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsGraduatingFiveYear(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/AreStudentsGraduatingFiveYear/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentsOnAssessmentsELA(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/StudentPerformanceELA/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentsOnAssessmentsMath(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/StudentPerformanceMaths/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsAtRisk(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/AreStudentsAtRisk/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getAreStudentsCollegeAndCareerReady(
      schoolYear,
      countyCode,
      districtCode,
      schoolCode,
      languageCode,
    ) {
      const response = await axios.get(
        `${summaryUrl}/AreStudentsCollegeAndCareerReady/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
      );
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
  },
  Detail: {
    Header: {
      async getHeader(schoolYear, countyCode, districtCode, schoolCd, languageCode) {
        const response = await axios.get(`${detailedHeaderUrl}/${schoolYear}/${countyCode}/${districtCode}/${schoolCd}?lang=${languageCode}`);
        return response.data;
      },
    },
    Overview: {
      async getOverviewTypeAndContactInformation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedOverviewUrl}/ContactInformation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getOverview(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(
          `${detailedOverviewUrl}/Overview/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );

        return response.data;
      },
    },
    Academic: {
      // eslint-disable-next-line max-len
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        try {
          const response = await axios.get(
            `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsELA/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
          );
          return {
            labels: [
              response.data.columns.column2Name,
              response.data.columns.column3Name,
              response.data.columns.column4Name,
            ],
            values: [
              [response.data.data.map((o) => o.column2Data)],
              [response.data.data.map((o) => o.column3Data)],
              [response.data.data.map((o) => o.column4Data)],
            ],
            columnHeaders: [
              [response.data.data.map((o) => o.column1Data)],
            ],
          };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
      // eslint-disable-next-line max-len
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsMath/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            [response.data.data.map((o) => o.column1Data)],
          ],
        };
      },
      async getEnglishLanguageArtsandMathematicsPerformanceTrend(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getELAParticipationPerformance(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentParticipationAndPerformance/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getEnglishLanguageArtsAssessmentPerformanceTrends(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsAssessmentPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getELAPerformanceByGradeX(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentPerformanceByGradeX/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathParticipationPerformance(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentParticipationAndPerformance/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getMathematicsAssessmentPerformanceTrends(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathematicsAssessmentPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getMathPerformanceByGradeX(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByGradeX/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathPerformanceByTestX(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByTestX/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];
        apiData.forEach((test) => {
          returnData.push({
            test: test.data[0].column1Data,
            labels: test.data.map((o) => o.column2Data),
            values: [
              [test.data.map((o) => o.column3Data)],
              [test.data.map((o) => o.column4Data)],
              [test.data.map((o) => o.column5Data)],
              [test.data.map((o) => o.column6Data)],
              [test.data.map((o) => o.column7Data)],
              [test.data.map((o) => o.column8Data)],
              [test.data.map((o) => o.column9Data)],
              [test.data.map((o) => o.column10Data)],
              [test.data.map((o) => o.column11Data)],
              [test.data.map((o) => o.column12Data)],
              [test.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              test.columns.column2Name,
              test.columns.column3Name,
              test.columns.column4Name,
              test.columns.column5Name,
              test.columns.column6Name,
              test.columns.column7Name,
              test.columns.column8Name,
              test.columns.column9Name,
              test.columns.column10Name,
              test.columns.column11Name,
              test.columns.column12Name,
              test.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAScienceAssessmentGradeXSummary(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentGradeXSummary/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAPerformanceByGradeXTable(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentTableByGradeX/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNationalAssessmentEducationalProgressNAEP(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NationalAssessmentEducationalProgressNAEP/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getDLMAssessment(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/DLMAlternateAssessmentParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getEnglishProficiencyTest(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishProgressToProf(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELLProgressToProficiency/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishParticipationPerf(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getNJGPA11(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJGPAGrade11/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
    },
    Accountability: {
      async getESSAAccountabilityStatus(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedAccountabilityUrl}/ESSAAccountabilityStatus/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getESSAAccountabilityProgress(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedAccountabilityUrl}/ESSAAccountabilityProgress/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getESSAAccountabilitySummative(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedAccountabilityUrl}/IndicatorScoresAndSummaryRatings/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getESSASummativeFooter(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(
          `${detailedAccountabilityUrl}/AlternateMethodNote/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return response.data;
      },
      async getESSAAccountabilitySummary(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedAccountabilityUrl}/SummaryByStudentGroup/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
            [response.data.data.map((o) => o.column10Data)],
            [response.data.data.map((o) => o.column11Data)],
            [response.data.data.map((o) => o.column12Data)],
            [response.data.data.map((o) => o.column13Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
            response.data.columns.column10Name,
            response.data.columns.column11Name,
            response.data.columns.column12Name,
            response.data.columns.column13Name,
          ],
        };
      },
      /* async getComprehensiveStatus(schoolYear, countyCode,
        districtCode, schoolCode, languageCode) {
        const response = await axios.get(`${detailedAccountabilityUrl}/
        ComprehensiveStatus/${schoolYear}/
        ${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${await tokenService.getToken()}`,
          },
        });
        // Translate Response to DataSet objects for VisualComponent
        return {

        };
      }, */
    },
    Climate: {
      async getAbsentChronic(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/ChronicAbsenteeism/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getDaysAbsent(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/DaysAbsent/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getAbsentChronicGrade(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/ChronicAbsenteeismByGrade/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getViolenceVandalismHIBandSubstanceOffenses(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/ViolenceVandalismHIBandSubstanceOffenses/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getPoliceNotifications(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/PoliceNotifications/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getHarassmentIntimidationandBullyingHIBInvestigations(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/HarassmentIntimidationandBullyingHIBInvestigations/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovals(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/StudentDisciplinaryRemovals/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovalsSchoolDaysMissed(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/StudentDisciplinaryRemovalsSchoolDaysMissed/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
      /* WHERE DOES THIS GO? */
      async getSchoolDay(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/SchoolDay/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [response.data.data.map((o) => o.column2Data)],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getDeviceRatios(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedClimateUrl}/DeviceRatios/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [response.data.data.map((o) => o.column2Data),
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    College: {
      async getPSATSATACTParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/PSATSATACTParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getPSATSATACTPerformance(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/PSATSATACTPerformance/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
          ],
        };
      },
      async getAPIBCourseworkParticipationandPerformance(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/APIBCourseworkParticipationandPerformance/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getAPIBCoursesOffered(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/APIBCoursesOffered/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDualEnrollmentCourseworkParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/DualEnrollmentCourseworkParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getDualEnrollStdtGrp(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ApIbDualEnrlPartByStudGroup/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getStructuredLearningExpParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/StructuredLearningExpParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/CareerandTechnicalEducationParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipationbyStudentGroup(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/CareerandTechnicalEducationParticipationbyStudentGroup/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getWorkBasedLearning(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/WorkBasedParticipationByCareerCluster/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getIndustryValuedCredentials(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/IndustryValuedCredentials/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationValuedCareer(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/IndustryValuedCredentialsbyCareerCluster/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getVisualandPerformingArtsCourseParticipation6to8(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation6to8Donut(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtSchool/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`);
        if (!response.data
          || !response.data.data
          || response.data.data.length === 0) {
          return {};
        }
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      } return {};
      },
      async getVisualandPerformingArtsCourseParticipation6to8DonutState(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtState/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`);
        if (!response.data
          || !response.data.data
          || response.data.data.length === 0) {
          return {};
        }
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      } return {};
      },
      async getVisualandPerformingArtsCourseParticipation9to12(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation9to12Donut(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtSchool/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        if (!response.data
          || !response.data.data
          || response.data.data.length === 0) {
          return {};
        }
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, undefined],
        };
      },
      async getVisualandPerformingArtsCourseParticipation9to12DonutState(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtState/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        if (!response.data
          || !response.data.data
          || response.data.data.length === 0) {
          return {};
        }
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      },
      async getMathematicsCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/MathematicsCourseParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        if (response.data.data.length <= 0 || response.data.columns.length <= 0) {
          return {};
        }
        // eslint-disable-next-line no-unused-vars
        const retLabels = response.data.data.map((o) => o.column1Data);
        const retvalues = [];
        for (let i = 2; i <= 8; i += 1) {
          if (response.data.data[0][`column${i}Data`]) {
            retvalues.push(response.data.data.map((o) => o[`column${i}Data`]));
          }
        }
        const retHeaders = [];
        for (let j = 1; j <= 8; j += 1) {
          if (response.data.columns[`column${j}Name`]) {
            retHeaders.push(response.data.columns[`column${j}Name`]);
          }
        }
        return {
          labels: retLabels,
          values: retvalues,
          columnHeaders: retHeaders,
        };
      },
      async getScienceCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ScienceCourseParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getSocialStudiesandHistoryCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/SocialStudiesandHistoryCourseParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getWorldLanguagesCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/WorldLanguagesCourseParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getComputerScienceandInformationTechnologyCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ComputerScienceandInformationTechnologyCourseParticipation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getSealofBiliteracy(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/SealofBiliteracy/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        // alert(
        //  response.data.data.map((o) => o.column2Data),
        //  response.data.data.map((o) => o.column3Data),
        // );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      /* async getSealofBiliteracy(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/SealofBiliteracy/${schoolYear}/
        ${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${await tokenService.getToken()}`,
          },
        });
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.Column1Data),
          values: [
            [response.data.data.map((o) => o.Column2Data)],
          ],
          columnHeaders: [
            response.data.columnHeaders.column1Name,
          ],
        };
      }, */
    },
    Staff: {
      async getTeachersExperience(schoolYear, countyCode, districtCode, schoolCode, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersExperience/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getAdministratorsExperienceDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/AdministratorsExperienceDistrictLevel/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStaffCountsSchoolLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/StaffCounts/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentandStaffRatios(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/StudentandStaffRatios/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getTeachersandAdministratorsDemographics(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsDemographics/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getTeachersandAdministratorsLevelofEducation(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsLevelofEducation/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getTeachersandAdministratorsOneYearRetentionDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsOneYearRetentionDistrictLevel/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getTeacherSubjectAreaSchoolLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersBySubjectArea/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
            [response.data.data.map((o) => o.column10Data)],
            [response.data.data.map((o) => o.column11Data)],
            [response.data.data.map((o) => o.column12Data)],
            [response.data.data.map((o) => o.column13Data)],
            [response.data.data.map((o) => o.column14Data)],
            [response.data.data.map((o) => o.column15Data)],
            [response.data.data.map((o) => o.column16Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
            response.data.columns.column10Name,
            response.data.columns.column11Name,
            response.data.columns.column12Name,
            response.data.columns.column13Name,
            response.data.columns.column14Name,
            response.data.columns.column15Name,
            response.data.columns.column16Name,
          ],
        };
      },
      // async getFacultyAttendance(schoolYear,
      //   countyCode, districtCode, schoolCode, languageCode) {
      //   const response = await axios.get(
      // eslint-disable-next-line max-len
      //     `${detailStaffUrl}/FacultyAttendance/${schoolYear}/${countyCode}/
      // ${districtCode}/${schoolCode}?lang=${languageCode}`,
      //     {
      //       headers: {
      //         'content-type': 'application/json',
      //         Authorization: `Bearer ${await tokenService.getToken()}`,
      //       },
      //     },
      //   );
      //   return {
      //     labels: response.data.data.map((o) => o.column1Data),
      //     values: [
      //       [response.data.data.map((o) => o.column2Data)],
      //       // [response.data.data.map((o) => o.column3Data)],
      //     ],
      //     columnHeaders: [
      //       response.data.columns.column1Name,
      //       response.data.columns.column2Name,
      //       // response.data.columns.column3Name,
      //     ],
      //   };
      // },
    },
    Demographics: {
      async getEnrollmentTrendsByGrade(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByGrade/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByStudentGroup(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByStudentGroup/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByRacialAndEthnicGroup(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByRacialAndEthnicGroup/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullHalfDayPkAndKg(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByFullHalfDayPkAndKg/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent

        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullAndSharedTimeStatus(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByFullAndSharedTimeStatus/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentByHomeLanguage(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentByHomeLanguage/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Grad: {
      async getGraduationRateTrendsVisual4Year(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrendsVisual4Year/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrendsVisual5Year(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrendsVisual5Year/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrends(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrends/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort4YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort4YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort5YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort5YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort6YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort6YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getFederalGraduationRates(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/FederalGraduationRate/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getAccountabilityGraduationRates(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/AccountabilityGraduationRates/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getGraduationPathways(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationPathways/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDropoutRateTrends(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/DropoutRateTrends/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryVisual(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        if (!response.data
          || !response.data.columns
          || !response.data.data
          || response.data.data.length === 0) {
          return {};
        }
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4.
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        // IMPORTANT NOTE: For the visual, 4 year should be plotted in the lower coordinate
        // so we have to switch rows 0 and 1

        if (!response.data.columns.column5Name) {
          return {
            labels: [response.data.columns.column3Name,
              response.data.columns.column4Name],
            values: [
              [response.data.data[1].column3Data, response.data.data[1].column4Data],
              [response.data.data[0].column3Data, response.data.data[0].column4Data],
              [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
                `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`],
            ],
            columnHeaders: [
              response.data.columns.column2Name,
              response.data.data[1].column2Data,
              response.data.data[0].column2Data,
              response.data.data[2].column2Data,
            ],
          };
        }
        return {
          labels: [response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name],
          values: [
            [response.data.data[1].column3Data, response.data.data[1].column4Data,
              response.data.data[1].column5Data],
            [response.data.data[0].column3Data, response.data.data[0].column4Data,
              response.data.data[0].column5Data],
            [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column5Data}`],
          ],
          columnHeaders: [
            response.data.columns.column2Name,
            response.data.data[1].column2Data,
            response.data.data[0].column2Data,
            response.data.data[2].column2Data,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryTable(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRatesFall(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRatesFall/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRates16month(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRates16month/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getApprenticeship(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Apprenticeship/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Narrative: {
      // async getNarrativeByCategory(schoolYear, countyCode, districtCode,
      //   schoolCode, category, languageCode) {
      //   const response = await axios.get(
      // eslint-disable-next-line max-len
      //     `${detailNarrativeUrl}/NarrativeByCategory/${schoolYear}/
      // ${countyCode}/${districtCode}/${schoolCode}/${category}?lang=${languageCode}`,
      //   );
      //   return response.data;
      // },
      async getNarratives(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailNarrativeUrl}/Narrative/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return response.data;
      },
    },
    StudentGrowth: {
      async getStudentGrowthELATrendsMedian(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgressELAMedian/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthMathTrendsMedian(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgressMathMedian/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthTrendsMedian(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgress/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getStudentGrowthTableData(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowth/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getStudentGrowthELAPerfLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByPerfLevelELA/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        if (response.data.columns.column5Name === undefined) {
          return {
            labels: response.data.data.map((o) => o.column1Data),
            values: [
              [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
            columnHeaders: [
              response.data.columns.column1Name,
              response.data.columns.column3Name,
            ],
          };
        }
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthMathPerfLevel(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByPerfLevelMath/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthELAGrade(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByGradeELA/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        const s = {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
        return s;
      },
      async getStudentGrowthMathGrade(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByGradeMath/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      mergeColumnData(column2Data, column3Data) {
        const isNumber = column2Data && (!Number.isNaN(parseFloat(column2Data))
          || Number.isFinite(column2Data));
        if (isNumber) {
          if (column3Data) {
          return `${column3Data}<br/>${column2Data}`;
          }
        }
        return `${column2Data}`;
      },
    },
    PerPupil: {
      async getPerPupilExpenditure(
        schoolYear,
        countyCode,
        districtCode,
        schoolCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailPerPupilUrl}/PerPupilExpenditures/${schoolYear}/${countyCode}/${districtCode}/${schoolCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
    },
  },
};
