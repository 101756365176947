import districtService from '../services/district.service';
import schoolService from '../services/school.service';

export default {
  state: {
    summaryReportData: [],
  },
  getters: {
    SummaryReportData: (state) => state.summaryReportData,
  },
  mutations: {
    SET_SUMMARY_REPORT_DATA(state, payload) {
      const component = state.summaryReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.summaryReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getHighlights({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_Highlights`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getSchoolHighlights(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getDistrictHighlights(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_Highlights`, data: response });
      }
    },
    async getStudentGrowthCompareELA({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthELA`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getStudentGrowthCompareELA(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getStudentGrowthCompareELA(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthELA`, data: response });
      }
    },
    async getStudentGrowthCompareMath({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthMath`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getStudentGrowthCompareMath(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getStudentGrowthCompareMath(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthMath`, data: response });
      }
    },
    async getStudentGrad4Year({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentsGrad4Year`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getAreStudentsGraduatingFourYear(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getAreStudentsGraduatingFourYear(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentsGrad4Year`, data: response });
      }
    },
    async getStudentGrad5Year({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentsGrad5Year`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getAreStudentsGraduatingFiveYear(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getAreStudentsGraduatingFiveYear(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentsGrad5Year`, data: response });
      }
    },
    async getHowAssessELA({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_HowAssessELA`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getStudentsOnAssessmentsELA(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getStudentsOnAssessmentsELA(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_HowAssessELA`, data: response });
      }
    },
    async getHowAssessMath({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_HowAssessMath`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getStudentsOnAssessmentsMath(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getStudentsOnAssessmentsMath(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_HowAssessMath`, data: response });
      }
    },
    async getStudentsAtRisk({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentsAtRisk`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getAreStudentsAtRisk(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getAreStudentsAtRisk(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentsAtRisk`, data: response });
      }
    },
    async getStudentCCR({ commit, state }, payload) {
      if (!state.summaryReportData.some((o) => o.Component === `${payload.reportType}_StudentsCCR`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Summary.getAreStudentsCollegeAndCareerReady(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          case 'district':
            response = await districtService.Summary.getAreStudentsCollegeAndCareerReady(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            response = {};
            break;
        }
        commit('SET_SUMMARY_REPORT_DATA', { Component: `${payload.reportType}_StudentsCCR`, data: response });
      }
    },
  },
};
