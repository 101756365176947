import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const schoolYearUrl = `${rootUrl}api/cds/schoolyears`;

function mapResponse(sy) {
  return {
    text: sy.schoolYear,
    value: sy.yearCode,
    embargo: sy.embargo,
    historical: sy.historicalOnly,
  };
}

export default {
  async getCurrentSchoolYears() {
    const response = await axios.get(schoolYearUrl);

    return {
      data: Array.from(response.data, (sy) => mapResponse(sy)),
    };
  },
};
