import StateTranslations from '../data/nj-perf-rep-lang-st';
import SchoolSummaryTranslations from '../data/nj-perf-rep-lang-sch-sum';
import SchoolDetailTranslations from '../data/nj-perf-rep-lang-sch-det';
import DistrictSummaryTranslations from '../data/nj-perf-rep-lang-dist-sum';
import DistrictDetailTranslations from '../data/nj-perf-rep-lang-dist-det';
import TranslationConstants from '../data/translation.constants';

function parseLanguage(languageObject, selectedLanguage) {
  if (!languageObject
    || (!languageObject.English
      && selectedLanguage === TranslationConstants.LanguageOptions.English)
    || (!languageObject.Spanish
      && selectedLanguage === TranslationConstants.LanguageOptions.Spanish)) {
    return '-';
  }
  if (selectedLanguage === TranslationConstants.LanguageOptions.Spanish) {
    // return Spanish
    if (languageObject.Spanish && languageObject.Spanish.length > 0) {
      return languageObject.Spanish.replaceAll('\\"', '"');
    }
    return languageObject.English.replaceAll('\\"', '"');
  }
  // Return English
  return languageObject.English.replaceAll('\\"', '"');
}

export default {
  Constants: TranslationConstants,
  State: {
    getNavigationTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Navigation
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getReportKeyTranslation(itemKey, lang, year) {
      const translations = StateTranslations.ReportKey
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getOverviewTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Overview
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getDemographicTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Demographic
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStudentGrowthTranslation(itemKey, lang, year) {
      const translations = StateTranslations.StudentGrowth
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAcademicAchievementTranslation(itemKey, lang, year) {
      const translations = StateTranslations.AcademicAchievement
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getCollegeReadinessTranslation(itemKey, lang, year) {
      const translations = StateTranslations.CollegeReadiness
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getGradTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Grad
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getClimateEnvTranslation(itemKey, lang, year) {
      const translations = StateTranslations.ClimateEnv
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStaffTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Staff
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getPerPupilExpTranslation(itemKey, lang, year) {
      const translations = StateTranslations.PerPupilExp
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAccountabilityTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Accountability
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getFooterTranslation(itemKey, lang, year) {
      const translations = StateTranslations.Footer
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
  },
  SchoolSummary: {
    getReportKeyTranslation(itemKey, lang, year) {
      const translations = SchoolSummaryTranslations.ReportKey
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);
      if (lang === TranslationConstants.LanguageOptions.Spanish) {
        // return Spanish
        if (translations[0].Spanish && translations[0].Spanish.length > 0) {
          return translations[0].Spanish;
        }
        return translations[0].English;
      }
      // Return English
      return translations[0].English;
    },
    getHeaderTranslation(itemKey, lang, year) {
      const translations = SchoolSummaryTranslations.Header
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);
      if (lang === TranslationConstants.LanguageOptions.Spanish) {
        // return Spanish
        if (translations[0].Spanish && translations[0].Spanish.length > 0) {
          return translations[0].Spanish;
        }
        return translations[0].English;
      }
      // Return English
      return translations[0].English;
    },
    getBodyTranslation(itemKey, lang, year) {
      const translations = SchoolSummaryTranslations.Body
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);
      if (lang === TranslationConstants.LanguageOptions.Spanish) {
        // return Spanish
        if (translations[0].Spanish && translations[0].Spanish.length > 0) {
          return translations[0].Spanish;
        }
        return translations[0].English;
      }
      // Return English
      return translations[0].English;
    },
    getFooterTranslation(itemKey, lang, year) {
      const translations = SchoolSummaryTranslations.Footer
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
  },
  SchoolDetail: {
    getNavigationTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Navigation
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getReportKeyTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.ReportKey
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getOverviewTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Overview
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getDemographicTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Demographic
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStudentGrowthTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.StudentGrowth
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAcademicAchievementTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.AcademicAchievement
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getCollegeReadinessTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.CollegeReadiness
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getGradTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Grad
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getClimateEnvTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.ClimateEnv
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStaffTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Staff
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getPerPupilExpTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.PerPupilExp
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAccountabilityTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Accountability
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getNarrativeTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Narrative
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getFooterTranslation(itemKey, lang, year) {
      const translations = SchoolDetailTranslations.Footer
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
  },
  DistrictSummary: {
    getReportKeyTranslation(itemKey, lang, year) {
      const translations = DistrictSummaryTranslations.ReportKey
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getHeaderTranslation(itemKey, lang, year) {
      const translations = DistrictSummaryTranslations.Header
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getBodyTranslation(itemKey, lang, year) {
      const translations = DistrictSummaryTranslations.Body
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getFooterTranslation(itemKey, lang, year) {
      const translations = DistrictSummaryTranslations.Footer
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
  },
  DistrictDetail: {
    getNavigationTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Navigation
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getReportKeyTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.ReportKey
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getOverviewTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Overview
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getDemographicTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Demographic
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStudentGrowthTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.StudentGrowth
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAcademicAchievementTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.AcademicAchievement
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getCollegeReadinessTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.CollegeReadiness
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getGradTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Grad
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getClimateEnvTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.ClimateEnv
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getStaffTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Staff
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getPerPupilExpTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.PerPupilExp
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getAccountabilityTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Accountability
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getNarrativeTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Narrative
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
    getFooterTranslation(itemKey, lang, year) {
      const translations = DistrictDetailTranslations.Footer
        .filter((o) => o.Component === itemKey && o.Year <= year)
        .sort((objA, objB) => objB.Year - objA.Year);

      return parseLanguage(translations[0], lang);
    },
  },
};
