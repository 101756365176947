import pattern from 'patternomaly';

export default {
  None: 'None',
  BarChart: 'bar-chart',
  LineChart: 'line-chart',
  HorizontalBarChart: 'horizontal-bar-chart',
  TableGrid: 'table-grid',
  DonutChart: 'donut-chart',
  DoubleDonutChart: 'double-donut-chart',
  StackedBarChart: 'stacked-bar-chart',
  TripleBarChart: 'triple-bar-chart',
  GaugeChart: 'gauge-chart',
  // TODO : Pick better colors
  BarChartColors: ['#c5dcf1', '#327ec4', '#1d4b75', '#5997e3'],
  // TODO : Fill out all patterns and implement
  BarChartPatterns: [
    pattern.draw('diagonal', '#c5dcf1'),
  ],
  HorizontalBarChartColors: [
    ['#5b9bd5', '#44546a'],
    ['#green', '#lightGreen'],
  ],
};
