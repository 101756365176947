<template>
  <div>
    <b-container fluid>
      <b-row class="pt-4 d-print-none">
        <b-col>
          <section-header :id="ExpenditureId" :header-text="PerPupilSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="pb-4">
        <b-col cols="12">
          <visual-component
            v-if="schoolYear <= 1920 || reportType === 'state'"
            :visual-title="ExpenditureTitle"
            :visual-description="ExpenditureDescription"
            :visual-footer="ExpenditureFooter"
            chart-type="None"
            :chart-data="{ Values: [], Labels: [], ColumnHeaders: [] }"
          >
            <span v-if="ExpenditureUrl && ExpenditureUrl !== '-'" v-html="ExpenditureUrl" />
          </visual-component>
          <visual-component
            v-else
            :visual-title="ExpenditureTitle"
            :visual-description="ExpenditureDescription"
            :chart-type="ChartTypes.Table"
            :chart-data="ExpenditureData"
            :horizontal-lines="HorizontalLineRows"
          >
            <span v-if="ExpenditureUrl && ExpenditureUrl !== '-'" v-html="ExpenditureUrl" />
          </visual-component>
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import translation from '../../mixins/translation-service.mixin';
import ChartTypes from '../../components/ChartTypes';
import perPupil from '../../mixins/detailComponents/perpupil.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';
import VisualComponent from '../../components/VisualComponent.vue';
import SectionHeader from '../../components/SectionHeader.vue';

export default {
  name: 'SchoolPerPupil',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [perPupil, translation, inPageNav],
  data() {
    return {
      ChartTypes,
    };
  },
  computed: {
    iframeResizeOptions() {
      return {
        log: true,
        autoSize: true,
        heightCalculationMethod: 'lowestElement',
        scrolling: true,
      };
    },
  },
};
</script>

<style scoped>
.outside-border{
  border: 2px solid #287AB8;
}
</style>
