<template>
  <div class="visual-wrapper">
    <div v-if="ShowLegend" class="legend-holder">
      <div v-for="(item, index) in legendLabelOrder" :key="index" class="legend-item">
        <span class="legend-color-block" :style="legendColorBlockStyle(index)" />
        <span class="legend-text-block">{{ item }}</span>
      </div>
    </div>
    <div class="canvas-holder">
      <canvas ref="canvas" />
    </div>
    <div class="chart-subtext">
      <span v-if="ChartValueText != ''" :class="chartValueClass">{{ ChartValueText }}</span><br v-if="ChartValueText != ''" class="keep-me" />
      <span v-if="ChartValueLabel != ''" class="value-text">{{ ChartValueLabel }}</span>
    </div>
  </div>
</template>

<script>
import Chart from 'chartjs-gauge';

export default {
  props: {
    ScaleDataPoints: {
      default: () => [0, 90.9, 94.9, 100],
    },
    ScaleDataPointsLabel: {
      default: () => [],
      required: false,
    },
    LegendLabels: {
      default: () => [
        'Below Standard: 0-90.9',
        'Met Standard: 91-94.9',
        'Exceeds Standard: 95-100',
      ],
    },
    LegendReverseOrder: {
      default: false,
    },
    BackgroundColors: {
      default: () => ['#ae322a', '#ae322a', '#9ccb3e', '#327fc2'],
    },
    ChartValueText: {
      default: '',
    },
    ChartValue: {
      default: 92,
    },
    ChartValueString: {
      default: '92%',
    },
    ChartValueLabel: {
      default: 'Met Standard',
    },
    ShowLegend: {
      default: true,
    },
    DataLabels: {
      default: () => ['Below Standard, Met Standard, Exceeds Standard'],
    },
    DataDecoration: {
      default: '%',
    },
    DataSet: {
      default: () => [
        {
          data: [0, 90.9, 94.9, 100],
          // Repeat the first color for the 0 value
          backgroundColor: ['#ae322a', '#ae322a', '#9ccb3e', '#327fc2'],
          borderWidth: 1,
          legendDataLabels: [
            'Below Standard: 0-90.9',
            'Met Standard: 91-94.9',
            'Exceeds Standard: 95-100'],
        },
      ],
    },
  },
  data() {
    return {
      datacollection: {
        legendLabels: [
          'Below Standard: 0-90.9',
          'Met Standard: 91-94.9',
          'Exceeds Standard: 95-100',
        ],
        labels: this.$props.DataLabels,
        datasets: this.$props.DataSet,
        scaleDataPointLabels: this.$props.ScaleDataPointsLabel,
      },
    };
  },
  computed: {
    isPrintView() {
      return this.$store.getters.isPrintView;
    },
    chartValueClass() {
      if (this.ChartValueLabel && this.ChartValueLabel !== '') {
        return 'dial-value';
      }
      return 'value-text';
    },
    options() {
      return {
        responsive: true,
        layout: {
          padding: {
            bottom: 30,
            top: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as percentage of interval between inner radius (0) & outer radius (100)
          lengthPercentage: 30,
          // The color of the needle
          color: 'rgba(0, 0, 0, 1)',
        },
        valueLabel: {
          display: false,
        },
        animation: {
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            const { chartArea } = chartInstance;
            const dataLabels = this.data.datasets[0].data;
            const scalePointLabels = this.data.datasets[0].scaleDataLabel;
            const dataDecor = this.data.datasets[0].dataDecoration;
            // const dataValue = this.data.datasets[0].value;
            // const dataValueLabel = this.data.datasets[0].valueLabel;

            // Capture the chart origin; AKA position of needle origin.
            const chartOrigin = {
              x: chartArea.left + ((chartArea.right - chartArea.left) / 2),
              y: chartArea.bottom - chartInstance.config.options.layout.padding.bottom,
            };

            // Show bounding labels
            ctx.textAlign = 'left';
            ctx.textBaseline = 'bottom';
            ctx.font = 'bold 12px Roboto';
            ctx.fillStyle = '#000';

            let i; // Iterator variable
            let multiplier = 1; // Determines Left or Right movement of label
            let offset = 15; // Label offset depending on side of chart
            const yOffset = -30;
            for (i = 0; i < dataLabels.length; i += 1) {
              multiplier = 1;
              let angle = 180 * (dataLabels[i] / 100);
              if (angle > 90) {
                // Obtuse angle offsets and multiplier
                angle = 180 - angle;
                multiplier = -1;
                offset = 5;
              } else {
                // Acute angle offset
                offset = -27;
              }

              // Using trig functions to determin label location around the arc
              const a = Math.round(
                chartInstance.outerRadius * Math.sin((90 - angle) * (Math.PI / 180)),
              );
              const b = Math.round(
                chartInstance.outerRadius * Math.cos((90 - angle) * (Math.PI / 180)),
              );

              // Print the bounding label at the point calculated
              ctx.fillText(`${scalePointLabels[i]}${dataDecor}`, chartOrigin.x - (a * multiplier) + offset, chartOrigin.y - yOffset - b);
            }

            // Show Value Text
            // ctx.textAlign = 'center';
            // ctx.textBaseline = 'top';
            // ctx.font = '14px Roboto';
            // ctx.fillStyle = '#000';

            // // Print the Value below the needle origin
            // ctx.fillText(`${dataValue}%`, chartOrigin.x, chartOrigin.y + 10);

            // // Show Value Label
            // ctx.font = '22px Roboto';
            // ctx.fillText(`${dataValueLabel}`, chartOrigin.x, chartOrigin.y + 43);
          },
        },
      };
    },
    legendLabelOrder() {
      if (this.$props.LegendReverseOrder) {
        return this.$props.LegendLabels.slice().reverse();
      }

      return this.$props.LegendLabels;
    },
  },
  mounted() {
    // renderChart function renders the chart with the datacollection and options object.
    const ctx = this.getContext();
    this.$props.ChartValue = this.isNumber(this.$props.ChartValue) ? this.$props.ChartValue : 1;
    if (this.$props.ScaleDataPointsLabel === null) {
      this.$props.ScaleDataPointsLabel = this.$props.ScaleDataPoints;
    }
    window.myGuage = new Chart(ctx, {
      data: {
        labels: [this.$props.ScaleDataPointsLabel],
        datasets: [{
          data: this.$props.ScaleDataPoints,
          scaleDataLabel: this.$props.ScaleDataPointsLabel,
          value: this.$props.ChartValue,
          valueLabel: this.$props.ChartValueLabel,
          backgroundColor: this.$props.BackgroundColors,
          borderWidth: 2,
          dataDecoration: this.$props.DataDecoration,
        }],
        legendLabels: this.$props.LegendLabels,
      },
      options: this.options,
    });
  },
  methods: {
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
    legendColorBlockStyle(index) {
      if (this.$props.LegendReverseOrder) {
        return `background-color: ${this.$props.BackgroundColors.slice().reverse()[index]}`;
      }
      return `background-color: ${this.$props.BackgroundColors[index + 1]}`;
    },
    isNumber(str) {
      if (typeof str !== 'string') return false; // we only process strings!
      // could also coerce to string: str = ""+str
      return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
    },
  },
};
</script>

<style scoped>
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#canvas-holder {
  width: 100%;
  height: 100%;
}
.legend-holder {
  text-align: center;
  display: inline-block;
}
.legend-item {
  text-align: left;
}
.legend-color-block {
  width: 25px;
  height: 12px;
  display: inline-block;
  border: 1px solid black;
  margin-right: 5px;
}
.dial-value {
  font: 2rem Roboto black;
}
.value-text {
  font: 1.5rem Roboto black;
}
</style>
