/* eslint no-underscore-dangle: 0 */
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    DataLabels: {
      default: () => ['Bengali', 'English', 'Spanish', 'Urdu'],
    },
    SuppressLegend: {
      default: () => false,
    },
    DataSets: {
      default: () => [{
        fill: false,
        label: 'Total Students',
        data: [8.0, 53.9, 30, 1.6],
        backgroundColor: '#5997e3',
        borderColor: '#287AB8',
        borderWidth: 0.5,
      },
      {
        fill: false,
        label: 'White Students',
        data: [8.0, 53.9, 30, 1.6],
        backgroundColor: '#5997e3',
        borderColor: '#287AB8',
        borderWidth: 0.5,
      },
      {
        fill: false,
        label: 'Hispanic Students',
        data: [8.0, 53.9, 30, 1.6],
        backgroundColor: '#5997e3',
        borderColor: '#287AB8',
        borderWidth: 0.5,
      }],
    },
  },
  data() {
    return {
      datacollection: {
        labels: this.$props.DataLabels,
        datasets: this.$props.DataSets,
      },
    };
  },
  computed: {
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
        },
        layout: {
          padding: {
            top: 20,
          },
        },
        legend: {
          display: !this.$props.SuppressLegend,
          onClick: (e) => e.stopPropagation(),
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet?.label} - ${dataSet.dataAsString[tooltipItem.index].toString().replace('<br/>', ' ')}`;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: this.isPrintView ? 0 : 1,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = '12px Roboto';
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                // const data = dataset.data[index];
                const dataStr = `${dataset.dataAsString[index]}`;
                const lines = dataStr.split('<br/>');
                // if there are html <br/> tags, split the string into multiple lines
                let { y } = bar._model;
                if (lines.length > 1) {
                  y -= 20;
                }
                for (let k = 0; k < lines.length; k += 1) {
                  ctx.fillText(lines[k], bar._model.x, y + (k * 15));
                }
                // ctx.fillText(dataStr, bar._model.x, bar._model.y);
              });
            });
          },
        },
      };
    },
  },
  mounted() {
    // renderChart function renders the chart with the datacollection and options object.
    // NJS-19821 replace all non-numeric values wtih zero so that the bar can be rendered
    this.datacollection.datasets[0].data = this.datacollection.datasets[0].data
      .map((val) => this.replaceNonNumbers(val));
    this.renderChart(this.datacollection, this.options);
  },
  methods: {
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
    replaceNonNumbers(n) {
      const isNumber = n && (!Number.isNaN(n) || Number.isFinite(n));
      if (isNumber) return n;
      return 0;
    },
  },
};
