import DistrictDetailTranslations from '../data/nj-perf-rep-lang-dist-det';
import SchoolDetailTranslations from '../data/nj-perf-rep-lang-sch-det';
import StateDetailTranslations from '../data/nj-perf-rep-lang-st';

export default {
  computed: {
    CurrentInstitution() {
      switch (this.ReportType) {
        case 'school':
          return this.$store.getters.CurrentSchoolInfo;
        case 'district':
          return this.$store.getters.CurrentDistrictInfo;
        default:
          return {};
      }
    },
    CurrentTranslation() {
      switch (this.ReportType) {
        case 'school':
          return SchoolDetailTranslations;
        case 'district':
          return DistrictDetailTranslations;
        default:
          return StateDetailTranslations;
      }
    },
    GradesOffered() {
      let grades;
      switch (this.ReportType) {
        case 'school':
          if (!this.CurrentInstitution.schoolKeyData
            || this.CurrentInstitution.schoolKeyData.length === 0) return {};
          grades = this.CurrentInstitution.schoolKeyData
            .filter((kd) => kd.key === 'Grades Offered');
          break;
        case 'district':
          if (!this.CurrentInstitution.districtKeyData
            || this.CurrentInstitution.districtKeyData.length === 0) return {};
          grades = this.CurrentInstitution.districtKeyData
            .filter((kd) => kd.key === 'Grades Offered');
          break;
        default:
          return {};
      }

      if (!grades || grades.length === 0) return {};

      const gradeArray = grades[0].value.split('-');

      return {
        lowerGrade: Number.isNaN(+gradeArray[0]) ? 0 : +gradeArray[0],
        upperGrade: Number.isNaN(+gradeArray[1]) ? 0 : +gradeArray[1],
      };
    },
  },
  methods: {
    institutionOffersGrade(grade) {
      const numGrade = +grade;
      const gradesOffered = this.GradesOffered;
      if (gradesOffered.upperGrade >= numGrade
        && gradesOffered.lowerGrade <= numGrade) return true;

      return false;
    },
    visualIsAvailableForInstitution(section, component) {
      const navItem = this.CurrentTranslation[section]
        .filter((o) => o.Component === component
          && o.Year <= this.$store.getters.SelectedSchoolYear.value)
        .sort((objA, objB) => objB.Year - objA.Year);
      if (!navItem || !navItem.length || navItem.length === 0) {
        // If we don't have a NavItem then don't show Visual.
        return false;
      }

      if (this.ReportType === 'state') {
        // If we have a NavItem, but we are looking at State report it should be shown
        return true;
      }

      if (!navItem[0].VisualId) {
        // Template is not associated with a VisualId, suppress visual
        return false;
      }
      if (!navItem[0].Template) {
        // Template is not defined, suppress visual
        return false;
      }

      if (navItem[0].Template.toLowerCase() !== 'all'
        && navItem[0].Template.indexOf(`[${this.CurrentInstitution.template}]`) < 0) {
        // Not valid for the given template
        return false;
      }

      if (navItem[0].GradesOffered.toString().toLowerCase() === 'all') {
        // Valid for all grades
        return true;
      }

      if (navItem[0].GradesOffered.toString().toLowerCase() === 'none') {
        // Not visible
        return false;
      }

      const validGrades = navItem[0].GradesOffered.toString().split(',');
      let isValid;
      if (navItem[0].FilterType.toLowerCase() === 'or') {
        // Using 'OR' means we assume false and are proven wrong.
        isValid = false;
        validGrades.forEach((grade) => {
          isValid = isValid
            || this.institutionOffersGrade(Number.isNaN(+grade) ? 0 : grade);
        });
      } else {
        // Using 'AND' means we assume true and are proven correct.
        isValid = true;
        validGrades.forEach((grade) => {
          isValid = isValid
            && this.institutionOffersGrade(Number.isNaN(+grade) ? 0 : grade);
        });
      }

      return isValid;
    },
  },
};
