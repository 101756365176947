import { Doughnut } from 'vue-chartjs';
import pattern from 'patternomaly';

const COLORS = [
  // light red green blue
  '#70ad47',
  '#a1b8e1',
  '#df7f7f',
  // dark red green blue
  '#ad312c',
  '#008000',
  '#2c7fc2',
];
export default {
  extends: Doughnut,
  props: {
    DataLabels: {
      default: () => [
        'Below Standard: 0 - 57.8',
        'Met Standard: 57.9 - 80',
        'Exceeds Standard: 80 - 100',
      ],
    },
    DataSets: {
      default: () => [
        {
          data: [
            57.8,
            22.2,
            20,
          ],
          backgroundColor: [
            pattern.draw('diamond-box', COLORS[2]),
            pattern.draw('dot', COLORS[1]),
            pattern.draw('dash', COLORS[0]),
          ],
          label: 'Dataset 1',
        },
        {
          data: [
            47.5,
            42.5,
          ],
          backgroundColor: [
            COLORS[3], // dark red
            // COLORS[4],//dark green
            // COLORS[5],//dark blue
          ],
          borderWidth: 1,
          label: 'Dataset 2',
        },
      ],
    },
  },
  computed: {
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        cutoutPercentage: 50,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          onClick: (e) => e.stopPropagation(),
          labels: {
            fontSize: this.isPrintView ? 14 : 16,
            color: '#000',
            generateLabels(chart) {
              const legendDataSet = chart.data.datasets.filter((o) => o.label === 'CHART_KEY')[0];
              const { labels } = chart.data;
              const legend = [];

              let i;
              for (i = 0; i < labels.length; i += 1) {
                legend.push({
                  text: labels[i],
                  fillStyle: legendDataSet.backgroundColor[i],
                  hidden: false,
                });
              }

              return legend;
            },
          },
          position: 'top',
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet.label} - ${dataSet.dataAsString[0]}`;
            },
          },
        },
        title: {
          display: false,
          text: 'Chart.js Doughnut Chart',
        },
        animation: {
          animateScale: true,
          animateRotate: true,
          duration: this.isPrintView ? 0 : 1000,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            const datasets = this.data.datasets.filter((o) => o.label !== 'CHART_KEY');
            const dataset = datasets[0];
            const canvasBounds = chartInstance.canvas.getBoundingClientRect();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = 'bold 18px Roboto';
            ctx.fillText(`${dataset.dataAsString[0]}`, (canvasBounds.width / 2), (canvasBounds.height / 2) + 40);
            ctx.fillText(`${dataset.label}`, (canvasBounds.width / 2), (canvasBounds.height / 2) + 60);
            // ctx.fillText(`${dataset.dataAsString[0]}`, x, 245);
            // ctx.fillText(`${dataset.label}`, x, 270);
          },
        },
        plugins: {
          datalabels: {
            color: 'black',
            backgroundColor: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
            formatter(value) {
              return `${Math.round(value)}%`;
            },
          },
        },
      };
    },
  },
  data() {
    return {
      datacollection: {
        labels: this.$props.DataLabels,
        datasets: this.$props.DataSets,
      },

    };
  },
  mounted() {
    // renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options);
  },
  methods: {
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
  },
};
