import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const searchUrl = `${rootUrl}api/cds/`;

export default {
  async performSearch(schoolYear, searchString) {
    const response = await axios.get(`${searchUrl}${schoolYear}/Search/${searchString}`);

    return response.data;
  },
};
