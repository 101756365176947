<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col class="left-column">
          <b-row class="header-logo">
            <b-col>
              <!-- Replace this with actual logo and text -->
              <img src="..//assets/prlogo.png" alt="NJ School Performance Reports Logo">
            </b-col>
          </b-row>
          <b-row class="intro-paragraph">
            <b-col>
              <p class="intro-text">
                The School Performance Reports reflect the New Jersey Department
                of Education's commitment to providing parents, students and school communities with
                a large variety of information about each school and district. These reports can be
                used as a tool to help evaluate whether all students have equitable access to high
                quality education. We encourage you to use these reports to learn more, start
                conversations, and engage.
              </p>
            </b-col>
          </b-row>
          <b-row class="whats-new">
            <b-col>
              <p>
                <strong>What's New:</strong>
                <br />
                <ul>
                  <li>2022-2023 reports released April 2024.</li>
                </ul>
              </p>
            </b-col>
          </b-row>
          <b-row class="questions">
            <b-col>
              <strong>Questions</strong> about reports? Contact
              <a href="mailto:reportcard@doe.nj.gov">reportcard@doe.nj.gov</a>
            </b-col>
          </b-row>
          <b-row class="search-box">
            <b-col>
              <omni-search search-type="long" />
            </b-col>
          </b-row>
          <b-row class="button-row text-center">
            <b-col cols="12" md="4" class="pb-2">
              <b-button class="nav-button" @click="goToSchoolList">
                Schools A-Z
              </b-button>
            </b-col>
            <b-col cols="12" md="4" class="pb-2">
              <b-button
                v-if="isReportAvailable"
                class="nav-button"
                @click="goToDistrictList"
              >
                Districts A-Z
              </b-button>
            </b-col>
            <b-col cols="12" md="4" class="pb-2">
              <b-button
                v-if="canSee && isReportAvailable"
                class="nav-button"
                @click="goToStateReport"
              >
                State Report
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="right-column d-none d-lg-block">
          <b-row class="nj-map">
            <img src="../assets/nj-county-map.png" alt="New Jersey Map by County">
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import accessHandler from '../mixins/access-denied.mixin';
import OmniSearch from '../components/OmniSearch.vue';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    OmniSearch,
  },
  mixins: [accessHandler],
  computed: {
    isReportAvailable() {
      if (this.$store.getters.SelectedSchoolYear.value >= 1617) {
        return true;
      }
      return false;
    },
  },
  methods: {
    goToDistrictList() {
      this.$router.push({ name: 'List', params: { type: 'district' } });
    },
    goToSchoolList() {
      this.$router.push({ name: 'List', params: { type: 'school' } });
    },
    goToStateReport() {
      if (this.$store.getters.SelectedSchoolYear.value >= '1920') {
        this.$store.dispatch('flushReportData');
        this.$router.push({ name: 'ReportSelect' });
      } else {
        this.$store.dispatch('flushReportData');
        this.$router.push({
          name: 'PriorYearReport',
          params: {
            schoolYear: this.$store.getters.SelectedSchoolYear.text,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.home {
  padding: 0 7%;
}
.left-column {
  text-align: left;
  min-width: 65%;
}
.right-column {
  text-align: center;
  min-width: 35%;
}
.nj-map {
  padding-top: 19px;
}
.intro-text {
  padding-top: 16px;
  text-align: justify;
  font-size: 18px;
  font-family: Roboto;
  line-height: 21px;
}
.whats-new {
  padding-top: 27px;
}
.questions {
  padding-top: 35px;
}
.search-box {
  padding-top: 59px;
}
.button-row {
  padding-top: 87px;
}
.header-logo {
  padding-top: 25px;
}
.nav-button {
  width: 164px;
  background-color: #008000;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 21px;
  height: 50px;
}
.nav-button:hover {
  background-color: #A9D08E;
  color: #000;
}
a {
  color: #0071EB;
}
</style>
