import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    demographicsReportData: [],
  },
  getters: {
    DemographicsReportData: (state) => state.demographicsReportData,
  },
  mutations: {
    SET_DEMOGRAPHIC_REPORT_DATA(state, payload) {
      const component = state.demographicsReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.demographicsReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    // #region Demographics
    async getDistrictEnrollmentByGrade(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Demographics.getEnrollmentTrendsByGrade(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Demographics.getEnrollmentTrendsByGrade(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Demographics.getEnrollmentTrendsByGrade(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByGrade`, data: response });
      }
    },
    async getDistrictEnrollmentByStudentGroup(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByStudentGroup`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Demographics.getEnrollmentTrendsByStudentGroup(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Demographics.getEnrollmentTrendsByStudentGroup(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Demographics.getEnrollmentTrendsByStudentGroup(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByStudentGroup`, data: response });
      }
    },
    async getDistrictEnrollmentByRace(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByRace`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService
              .Detail.Demographics.getEnrollmentTrendsByRacialAndEthnicGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService
              .Detail.Demographics.getEnrollmentTrendsByRacialAndEthnicGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Demographics.getEnrollmentTrendsByRacialAndEthnicGroup(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByRace`, data: response });
      }
    },
    async getDistrictEnrollmentByDay(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByDay`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService
              .Detail.Demographics.getEnrollmentTrendsByFullHalfDayPkAndKg(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService
              .Detail.Demographics.getEnrollmentTrendsByFullHalfDayPkAndKg(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Demographics.getEnrollmentTrendsByFullHalfDayPkAndKg(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByDay`, data: response });
      }
    },
    async getDistrictEnrollmentByTime(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByTime`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService
              .Detail.Demographics.getEnrollmentTrendsByFullAndSharedTimeStatus(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService
              .Detail.Demographics.getEnrollmentTrendsByFullAndSharedTimeStatus(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Demographics.getEnrollmentTrendsByFullAndSharedTimeStatus(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByTime`, data: response });
      }
    },
    async getDistrictEnrollmentByHomeLanguage(
      { commit, state },
      payload,
    ) {
      if (!state.demographicsReportData.some((o) => o.Component === `${payload.reportType}_EnrollmentByHomeLanguage`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService
              .Detail.Demographics.getEnrollmentByHomeLanguage(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService
              .Detail.Demographics.getEnrollmentByHomeLanguage(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Demographics.getEnrollmentByHomeLanguage(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_DEMOGRAPHIC_REPORT_DATA', { Component: `${payload.reportType}_EnrollmentByHomeLanguage`, data: response });
      }
    },
    // #endregion
  },
};
