import tokenService from './token.service';
import loginService from './login.service';

export default {
  getAuthService() {
    const type = localStorage.getItem('authOverride');
    if (type && type === 'handoff') {
      return tokenService;
    }
    if (process.env.VUE_APP_USE_AUTH === 'Yes') {
      return loginService;
    }
    return tokenService;
  },
};
