import DistrictDetailTranslations from '../data/nj-perf-rep-lang-dist-det';
import SchoolDetailTranslations from '../data/nj-perf-rep-lang-sch-det';
import StateDetailTranslations from '../data/nj-perf-rep-lang-st';

export default {
  data() {
    return {
      navItem: {},
    };
  },
  mounted() {
    let curRouteName = this.$route.name;
    if (curRouteName === 'Default'
      || curRouteName === 'DetailReport'
      || curRouteName === 'PrintDetail') {
      curRouteName = 'Overview';
    }

    this.navItem = this.getNavItem(
      curRouteName,
      this.$store.getters.SelectedSchoolYear.value,
    );
  },
  computed: {
    Translation() {
      if (!this.$route
        || !this.$route.params
        || !this.$route.params.reportType) {
        return {};
      }
      switch (this.$route.params.reportType.toLowerCase()) {
        case 'district':
          return DistrictDetailTranslations;
        case 'school':
          return SchoolDetailTranslations;
        case 'state':
          return StateDetailTranslations;
        default:
          return {};
      }
    },
  },
  methods: {
    getNavItem(routeName, schoolYear) {
      const navItems = this.Translation.Navigation
        .filter((o) => o.Component === routeName && o.Year <= schoolYear)
        .sort((objA, objB) => objB - objA);

      return navItems[0];
    },
    getSubNavigationId(section, component, schoolYear) {
      if (!section) return '';
      const navItems = this.Translation[section]
        .filter((o) => o.Component === component && o.Year <= schoolYear && o.VisualId !== '')
        .sort((objA, objB) => objB.Year - objA.Year);

      if (navItems && navItems.length > 0) {
        return navItems[0].VisualId;
      }
      return '';
    },
    GetElementIdByTitle(elementTitle) {
      if (!this.navItem) return '';
      return this.getSubNavigationId(
        this.navItem.NavSection,
        elementTitle,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
  },
};
