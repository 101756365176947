import TranslationSvc from '../services/translation.service';

export default {
  data() {
    return {
      translationService: {},
    };
  },
  computed: {
    ReportType() {
      return this.$route.params.reportType.toLowerCase();
    },
  },
  created() {
    if (this.$route.params.reportType === 'state') {
      this.translationService = TranslationSvc.StateSummary;
    } else if (this.$route.params.reportType === 'school') {
      this.translationService = TranslationSvc.SchoolSummary;
    } else {
      this.translationService = TranslationSvc.DistrictSummary;
    }
  },
};
