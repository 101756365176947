import TranslationSvc from '../services/translation.service';

export default {
  data() {
    return {
      translationService: {},
    };
  },
  computed: {
    ReportType() {
      if (!this.$route.params.reportType) { return ''; }
      return this.$route.params.reportType.toLowerCase();
    },
  },
  created() {
    if (this.ReportType === 'state') {
      this.translationService = TranslationSvc.State;
    } else if (this.ReportType === 'school') {
      this.translationService = TranslationSvc.SchoolDetail;
    } else {
      this.translationService = TranslationSvc.DistrictDetail;
    }
  },
};
