<template>
  <div>
    <b-container class="pt-5">
      <b-card>
        <b-card-header>
          <h5>Report is Currently Closed</h5>
        </b-card-header>
        <b-card-body>
          <p>
            Due to current data embargo rules, this report is currently unavailable.
            Please check back later to view the official report.
          </p>
        </b-card-body>
        <b-card-footer>
          <b-link :to="{ name: 'Home' }">
            Return to Search Page
          </b-link>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>

</style>
