import TranslationSvc from '../../services/translation.service';
import ChartTypes from '../../components/ChartTypes';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [mapping, navigation],
  data() {
    return {
      ChartTypes,
    };
  },
  computed: {
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    borderClass() {
      return {
        'outside-border': this.ChartType !== ChartTypes.DoubleDonutChart,
        'no-border': this.ChartType === ChartTypes.DoubleDonutChart || this.$props.suppressBorder,
      };
    },
    Labels() {
      return this.$props.chartData.Labels;
    },
    Data() {
      return this.$props.chartData.Values;
    },
    ChartLabel() {
      return this.$props.chartData.ChartLabel;
    },
    CourseParticipationArtsId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationArtsTitle,
      );
    },
    Grade68Title() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.VisPerfArts68Title,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Grade912Title() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.VisPerfArts912Title,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    VisArtsDesc() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.VisArtsDesc,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    VisualPerformingArts6to8() {
      const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts6to8`);
      let tmp;
      if (!storeObjects || storeObjects.length === 0
        || !storeObjects[0].data
        || storeObjects[0].data.length === 0) {
        return {};
      }
      const dataArray = [];
      const storeObject = storeObjects[0].data;
      if (this.ReportType !== 'state') {
        for (let i = 0; i <= storeObject.length - 1; i += 1) {
          tmp = {
            Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
            Values: [{
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject[i].data.data1.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', ''),
              +storeObject[i].data.data2.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
              label: storeObject[i].name,
            }],
            ChartSubTitle: storeObject[i].name,
          };
          dataArray.push(tmp);
        }
      } else {
        for (let i = 0; i <= storeObject.length - 1; i += 1) {
          tmp = {
            Labels: [storeObject[i].data.header1],
            Values: [{
              backgroundColor: ['#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject[i].data.data1.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject[i].data.data1],
              label: storeObject[i].name,
            }],
            ChartSubTitle: storeObject[i].name,
          };
          dataArray.push(tmp);
        }
      }
      return dataArray;
    },
    HideVisualPerformingArts6to8() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts6to8`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data
          || storeObjects[0].data.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    VisualPerformingArts9to12() {
      const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts9to12`);
      let tmp;
      if (!storeObjects || storeObjects.length === 0
        || !storeObjects[0].data
        || storeObjects[0].data.length === 0) {
        return {};
      }
      const dataArray = [];
      const storeObject = storeObjects[0].data;
      if (this.ReportType !== 'state') {
        for (let i = 0; i <= storeObject.length - 1; i += 1) {
          tmp = {
            Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
            Values: [{
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject[i].data.data1.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', ''),
              +storeObject[i].data.data2.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
              label: storeObject[i].name,
            }],
            ChartSubTitle: storeObject[i].name,
          };
          dataArray.push(tmp);
        }
      } else {
        for (let i = 0; i <= storeObject.length - 1; i += 1) {
          tmp = {
            Labels: [storeObject[i].data.header1],
            Values: [{
              backgroundColor: ['#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject[i].data.data1.replace('%', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject[i].data.data1],
              label: storeObject[i].name,
            }],
            ChartSubTitle: storeObject[i].name,
          };
          dataArray.push(tmp);
        }
      }
      return dataArray;
    },
    HideVisualPerformingArts9to12() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts9to12`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data
          || storeObjects[0].data.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    CourseParticipationArtsTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationArtsTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationArtsDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationArtsText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    VisualPerformingDonut6to8() {
      const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts6to8Donut`);
      if (!storeData || storeData.length === 0
        || !storeData[0].data
        || !storeData[0].data.labels
        || storeData[0].data.labels.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);
      // responseObject.Values[0].data = [responseObject.Values[0].data,
      //   100 - responseObject.Values[0].data];
      if (this.ReportType === 'state') {
        responseObject.Values[0].backgroundColor = ['#44546a'];
        responseObject.Values[0].borderColor = ['#44546a'];
      } else {
        responseObject.Values[0].backgroundColor = ['#5997e3'];
        responseObject.Values[0].borderColor = ['#5997e3'];
      }
      responseObject.Values[0].borderWidth = 1;
      responseObject.Values[0].dataAsString[1] = '';
      return responseObject;
    },
    VisualPerformingDonut6to8State() {
      const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts6to8DonutState`);
      if (!storeData || storeData.length === 0
        || !storeData[0].data
        || !storeData[0].data.labels
        || storeData[0].data.labels.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);
      // responseObject.Values[0].data = [responseObject.Values[0].data,
      //   100 - responseObject.Values[0].data];
      responseObject.Values[0].backgroundColor = ['#44546a'];
      responseObject.Values[0].borderWidth = 1;
      responseObject.Values[0].borderColor = ['#44546a'];
      responseObject.Values[0].dataAsString[1] = '';
      return responseObject;
    },
    VisualPerformingDonut9to12() {
      const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts9to12Donut`);
      if (!storeData || storeData.length === 0
        || !storeData[0].data
        || storeData[0].data.length === 0) {
        return {};
      }

      const storeObject = storeData[0].data;

      const responseObject = this.MapData(storeObject, this.ChartTypes);
      // responseObject.Values[0].data = [responseObject.Values[0].data,
      //   100 - responseObject.Values[0].data];
      if (this.ReportType === 'state') {
        responseObject.Values[0].backgroundColor = ['#44546a'];
        responseObject.Values[0].borderColor = ['#44546a'];
      } else {
        responseObject.Values[0].backgroundColor = ['#5997e3'];
        responseObject.Values[0].borderColor = ['#5997e3'];
      }
      responseObject.Values[0].borderWidth = 1;
      responseObject.Values[0].dataAsString[1] = '';
      return responseObject;
    },
    VisualPerformingDonut9to12State() {
      const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationArts9to12DonutState`);
      if (!storeData || storeData.length === 0
        || !storeData[0].data
        || storeData[0].data.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);
      // responseObject.Values[0].data = [responseObject.Values[0].data,
      // 100 - responseObject.Values[0].data];
      responseObject.Values[0].backgroundColor = ['#44546a'];
      responseObject.Values[0].borderWidth = 1;
      responseObject.Values[0].borderColor = ['#44546a'];
      responseObject.Values[0].dataAsString[1] = '';
      return responseObject;
    },
  },
  methods: {
    // fetchData() {
    //   // const params = {
    //   // reportType: this.REEEEE,
    //   // };
    //   // this.$store.dispatch('getXYZ', params);
    //   // this.$store.dispatch('getABCD', params);
    // },
    print() {
      this.$refs.VisualPerformingArts.style.width = '1008px';
      setTimeout(() => {
        if (this.$refs.VisualPerformingDonut6to8) {
          this.$refs.VisualPerformingDonut6to8.enablePrintView();
          this.PrintDisplay = this.$refs.VisualPerformingDonut6to8.printDisplay;
        }
        if (this.$refs.VisualPerformingDonut6to8State) {
          this.$refs.VisualPerformingDonut6to8State.enablePrintView();
          this.PrintDisplay = this.$refs.VisualPerformingDonut6to8State.printDisplay;
        }
        if (this.$refs.VisualPerformingDonut9to12) {
          this.$refs.VisualPerformingDonut9to12.enablePrintView();
          this.PrintDisplay = this.$refs.VisualPerformingDonut9to12.printDisplay;
        }
        if (this.$refs.VisualPerformingDonut9to12State) {
          this.$refs.VisualPerformingDonut9to12State.enablePrintView();
          this.PrintDisplay = this.$refs.VisualPerformingDonut9to12State.printDisplay;
        }

        let i;
        if (this.$refs.VPA68Container) {
          for (i = 0; i < this.$refs.VPA68Container.length; i += 1) {
            this.$refs[`VPA68-Chart-${i}`][0].enablePrintView();
          }
        }

        if (this.$refs.VPA912Container) {
          for (i = 0; i < this.$refs.VPA912Container.length; i += 1) {
            this.$refs[`VPA912-Chart-${i}`][0].enablePrintView();
          }
        }

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.CourseParticipationArtsId}`);

          if (this.$refs.VisualPerformingDonut6to8) {
            this.$refs.VisualPerformingDonut6to8.disablePrintView();
          }
          if (this.$refs.VisualPerformingDonut6to8State) {
            this.$refs.VisualPerformingDonut6to8State.disablePrintView();
          }
          if (this.$refs.VisualPerformingDonut9to12) {
            this.$refs.VisualPerformingDonut9to12.disablePrintView();
          }
          if (this.$refs.VisualPerformingDonut9to12State) {
            this.$refs.VisualPerformingDonut9to12State.disablePrintView();
          }

          if (this.$refs.VPA68Container) {
            for (i = 0; i < this.$refs.VPA68Container.length; i += 1) {
              this.$refs[`VPA68-Chart-${i}`][0].disablePrintView();
            }
          }

          if (this.$refs.VPA912Container) {
            for (i = 0; i < this.$refs.VPA912Container.length; i += 1) {
              this.$refs[`VPA912-Chart-${i}`][0].disablePrintView();
            }
          }

          this.$refs.VisualPerformingArts.style.width = '';
        });
      }, 100);
    },
  },
  // watch: {
  //   Language() {
  //     this.$store.dispatch('flushReportData');
  //     this.fetchData();
  //   },
  // },
  // mounted() {
  //   this.fetchData();
  // },
};
