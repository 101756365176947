<template>
  <div>
    <b-row>
      <b-col cols="4">
        <img
          class="report-logo"
          src="../assets/prlogo.png"
          alt="NJ School Performance Reports Logo"
        >
      </b-col>
      <b-col cols="4" style="text-align: center;">
        <b>{{ InstitutionName }}</b><br class="keep-me">
        <span>{{ InstitutionCDSCode }}</span><br class="keep-me">
        <span>{{ ReportYear }}</span>
      </b-col>
      <b-col cols="4">
        <report-key />
      </b-col>
    </b-row>
    <div class="section-header">
      <slot />
    </div>
  </div>
</template>

<script>
import ReportKey from './ReportKey.vue';

export default {
  components: {
    ReportKey,
  },
  computed: {
    InstitutionName() {
      switch (this.$route.params.reportType.toLowerCase()) {
        case 'state':
          return 'New Jersey';
        case 'district':
          return this.$store.getters.CurrentDistrictInfo.districtName;
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.schoolName;
        default:
          return 'ERROR';
      }
    },
    InstitutionCDSCode() {
      switch (this.$route.params.reportType.toLowerCase()) {
        case 'state':
          return '';
        case 'district':
          return `(${this.$store.getters.CurrentDistrictInfo.countyCode}-${this.$store.getters.CurrentDistrictInfo.districtCode})`;
        case 'school':
          return `(${this.$store.getters.CurrentSchoolInfo.countyCode}-${this.$store.getters.CurrentSchoolInfo.districtCode}-${this.$store.getters.CurrentSchoolInfo.schoolCode})`;
        default:
          return 'ERROR';
      }
    },
    ReportYear() {
      return this.$store.getters.SelectedSchoolYear.text;
    },
  },
};
</script>

<style scoped>

</style>
