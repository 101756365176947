import districtService from '../services/district.service';
import schoolService from '../services/school.service';
// import stateService from '..///services/state.service';

export default {
  state: {
    perPupilExpenditureData: [],
  },
  getters: {
    PerPupilData: (state) => state.perPupilExpenditureData,
  },
  mutations: {
    SET_PER_PUPIL_DATA(state, payload) {
      const component = state.perPupilExpenditureData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.perPupilExpenditureData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getPerPupilExpenditure(
      { commit, state },
      payload,
    ) {
      if (!state.perPupilExpenditureData.some((o) => o.Component === `${payload.reportType}_PerPupilExpenditure`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.PerPupil.getPerPupilExpenditure(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.PerPupil.getPerPupilExpenditure(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = {};
            break;
        }

        commit('SET_PER_PUPIL_DATA', { Component: `${payload.reportType}_PerPupilExpenditure`, data: response });
      }
    },
  },
};
