import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    DataLabels: {
      default: () => ['Bengali', 'English', 'Spanish'],
    },
    DataSets: {
      default: () => [
        {
          data: [8.0, 64.0, 32.3],
          backgroundColor: ['#FF0000', '#CCC'],
          borderWidth: 1,
          label: '% of Students',
        },
      ],
    },
  },
  data() {
    return {
      datacollection: {
        labels: this.$props.DataLabels,
        datasets: this.$props.DataSets,
      },
    };
  },
  computed: {
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        cutoutPercentage: 50,
        responsive: true,
        maintainAspectRatio: false,
        width: 10,
        legend: {
          onClick: (e) => e.stopPropagation(), // prevent clicking legend
          labels: {
            fontSize: 16,
            color: '#000',
            filter(item) {
              // Logic to remove a particular legend item goes here
              // if the item is undefined, dont show the label
              if (!item.text || item.text === '') {
                return false;
              }
              return true;
            },
          },
          position: 'top',
        },
        tooltips: {
          filter(tooltipItem, data) {
            // Logic to remove a particular tooltip item goes here
            // if the item is undefined, dont show the tooltip
            const label = data.labels[tooltipItem.index];
            if (!label || label === '') {
              return false;
            }
            return true;
          },
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              if (!label || label === '') {
                return '';
              }
              let tooltipText = '';
              if (label !== dataSet.label) {
                tooltipText = `${label} - ${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
              } else {
                tooltipText = `${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
              }
              // do some stuff
              return tooltipText;
            },
          },
        },
        title: {
          display: false,
          text: 'Chart.js Doughnut Chart',
        },
        animation: {
          animateScale: true,
          animateRotate: true,
          duration: this.isPrintView ? 0 : 0.5,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = '12px Roboto';
            if (this.data.datasets[0].dataAsString[0] === 'N') {
              // show N if no data
              // eslint-disable-next-line no-underscore-dangle
              const model = this.data.datasets[0]._meta[
                // eslint-disable-next-line no-underscore-dangle
                Object.keys(this.data.datasets[0]._meta)[0]].data[0]._model;
              ctx.fillText(this.data.datasets[0].dataAsString[0], model.x, model.y);
            }
            let evenOdd = 0;
            let lastValueSmall = false;
            this.data.datasets.forEach((dataset) => {
              for (let i = 0; i < dataset.data.length; i += 1) {
                // eslint-disable-next-line no-underscore-dangle
                const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                // eslint-disable-next-line max-len
                const midRadius1 = model.innerRadius + (model.outerRadius - model.innerRadius) / 2;
                const startAngle1 = model.startAngle;
                const endAngle1 = model.endAngle;
                const midAngle1 = startAngle1 + (endAngle1 - startAngle1) / 2;
                const x = midRadius1 * Math.cos(midAngle1);
                const y = midRadius1 * Math.sin(midAngle1);
                ctx.fillStyle = '#fff';
                ctx.font = '13px bold';
                if (!dataset.dataAsString[i] || dataset.dataAsString[i] === ''
                ) {
                  ctx.fillText('', model.x + x, model.y + y);
                } else {
                  // const evenOdd = i % 2 ? 10 : 10;
                  if (dataset.data[i] < 6) {
                    lastValueSmall = true;
                    evenOdd += 20;
                  }
                  if (dataset.data[i] > 0) {
                    ctx.fillStyle = 'rgba(255, 255, 255, 0.6)';
                    if (dataset.backgroundColor !== '#c5dcf1') {
                      if (!lastValueSmall) {
                        // draw at the center
                        ctx.fillRect(
                          model.x + x - 20,
                          model.y + y - 20,
                          41,
                          23,
                        );
                      } else {
                        // draw away from center becoz pies are closer in this case
                        ctx.fillRect(
                          model.x + x - 16,
                          model.y + y - (evenOdd) + 30,
                          35,
                          23,
                        );
                      }
                    }
                    // ctx.strokeText(dataStr, bar._model.x,
                    ctx.fillStyle = '#000';
                    ctx.font = 'bold 10pt arial';
                  }
                  if (!lastValueSmall) {
                    // draw at the center
                    ctx.fillText(dataset.dataAsString[i], model.x + x, model.y + y);
                  } else {
                    // draw away from center becoz pies are closer in this case
                    ctx.fillText(
                      dataset.dataAsString[i],
                      model.x + x + 6,
                      model.y + y - (evenOdd) + 50,
                    );
                  }
                }
              }
            });
          },
        },
        plugins: {
          datalabels: {
            color: 'black',
            backgroundColor: '#000',
            font: {
              weight: 'bold',
              size: 14,
            },
            formatter(value) {
              return `${Math.round(value)}%`;
            },
          },
        },
      };
    },
  },
  methods: {
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
  },
  mounted() {
    // renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options);
  },
};
