import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    staffReportData: [],
  },
  getters: {
    StaffReportData: (state) => state.staffReportData,
  },
  mutations: {
    SET_STAFF_REPORT_DATA(state, payload) {
      const component = state.staffReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.staffReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getDistrictTeacherExp(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_TeacherExp`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Staff.getTeachersExperience(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Staff.getTeachersExperience(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getTeachersExperience(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_TeacherExp`, data: response });
      }
    },
    async getDistrictAdmExp(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_AdmExp`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Staff.getAdministratorsExperienceDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Staff.getAdministratorsExperienceDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getAdministratorsExperienceDistrictLevel(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_AdmExp`, data: response });
      }
    },
    async getDistrictStaffCounts(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_StaffCounts`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Staff.getStaffCountsDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Staff.getStaffCountsSchoolLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getStaffCountsStateLevel(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_StaffCounts`, data: response });
      }
    },
    async getDistrictStdStaffRatios(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_StdStaffRatios`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Staff.getStudentandStaffRatios(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Staff.getStudentandStaffRatios(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getStudentandStaffRatios(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_StdStaffRatios`, data: response });
      }
    },
    async getDistrictTeacherDemog(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_TeacherDemog`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Staff.getTeachersandAdministratorsDemographics(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Staff.getTeachersandAdministratorsDemographics(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getTeachersandAdministratorsDemographics(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_TeacherDemog`, data: response });
      }
    },
    async getDistrictTeacherLevelEdu(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_TeacherLevelEdu`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Staff.getTeachersandAdministratorsLevelofEducation(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await
            schoolService.Detail.Staff.getTeachersandAdministratorsLevelofEducation(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Staff.getTeachersandAdministratorsLevelofEducation(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_TeacherLevelEdu`, data: response });
      }
    },
    async getDistrictTeacher1YearRetention(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_Teacher1YearRetention`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Staff.getTeachersandAdministratorsOneYearRetentionDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await
            schoolService.Detail.Staff.getTeachersandAdministratorsOneYearRetentionDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await
            stateService.Detail.Staff.getTeachersandAdministratorsOneYearRetentionStateLevel(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_Teacher1YearRetention`, data: response });
      }
    },
    async getDistrictTeacherSubjectArea(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_TeacherSubjectArea`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Staff.getTeacherSubjectAreaDistrictLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Staff.getTeacherSubjectAreaSchoolLevel(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Staff.getTeacherSubjectAreaStateLevel(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_TeacherSubjectArea`, data: response });
      }
    },
    // async getDistrictFacultyAttendance(
    //   { commit, state },
    //   payload,
    // ) {
    // eslint-disable-next-line max-len
    //   if (!state.staffReportData.some((o) => o.Component
    // === `${payload.reportType}_FacultyAttendance`)) {
    //     let response;
    //     switch (payload.reportType) {
    //       case 'district':
    //         // eslint-disable-next-line max-len
    //         response = await districtService.Detail.Staff.getFacultyAttendance(
    //           payload.schoolYear, payload.countyCd, payload.districtCd, payload.langCd,
    //         );
    //         break;
    //       case 'school':
    //         // eslint-disable-next-line max-len
    //         response = await schoolService.Detail.Staff.getFacultyAttendance(
    //           payload.schoolYear,
    //           payload.countyCd, payload.districtCd, payload.schoolCd,
    //           payload.langCd,
    //         );
    //         break;
    //       default: // state
    //         // eslint-disable-next-line max-len
    //         response = await stateService.Detail.Staff.getFacultyAttendance(
    //           payload.schoolYear, payload.langCd,
    //         );
    //         break;
    //     }
    // eslint-disable-next-line max-len
    //     commit('SET_STAFF_REPORT_DATA', { Component:
    // `${payload.reportType}_FacultyAttendance`, data: response });
    //   }
    // },
    async getStatewideEducatorEquityData(
      { commit, state },
      payload,
    ) {
      if (!state.staffReportData.some((o) => o.Component === `${payload.reportType}_StatewideEducatorEquityData`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = {};
            // this report is not avaialble for district
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = {};
            // this report is not avaialble for school
            break;
          default: // ONLY state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Staff.getStatewideEducatorEquityData(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_STAFF_REPORT_DATA', { Component: `${payload.reportType}_StatewideEducatorEquityData`, data: response });
      }
    },
  },
};
