<template>
  <div>
    <div class="sectionHeader">
      {{ HeaderText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    HeaderText: {
      type: String,
      default: 'Section',
    },
  },
};
</script>

<style scoped>
.sectionHeader {
  background-color: #426580;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  padding: 0.5em 1em;
  text-align: left;
}
</style>
