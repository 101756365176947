<template>
  <div>
    <b-row v-if="!HideAbsentChronicData" class="d-print-none">
      <b-col>
        <section-header :id="AbsentHeaderId" :header-text="AbsentSectionHeader" />
      </b-col>
    </b-row>
    <b-row v-if="!HideAbsentChronicData" class="pt-4">
      <b-col cols="12">
        <div class="intro" v-html="AbsentIntro" />
      </b-col>
    </b-row>
    <b-row v-if="!HideAbsentChronicData">
      <b-col>
        <visual-component
          v-if="$store.getters.SelectedSchoolYear.value > 1920"
          :id="AbsentChronicId"
          class="h-100"
          :visual-title="AbsentChronicTitle"
          :visual-description="AbsentChronicDescription"
          :chart-type="ChartTypes.TableGrid"
          :chart-data="AbsentChronicData"
          :table-shading="AbsentChronicTableShading"
          :horizontal-lines="['White', 'Blanco',
                              'Female', 'Femenino', 'Economically Disadvantaged Students',
                              'Estudiantes Económicamente en Desventaja']"
          :vertical-lines="AbsentChronicVerticalLines"
        />
      </b-col>
    </b-row>
    <b-row v-if="!HideAbsentChronicData">
      <b-col>
        <visual-component
          v-if="$store.getters.SelectedSchoolYear.value > 1920"
          :id="AbsentDaysId"
          :visual-title="AbsentDaysTitle"
          :visual-description="AbsentDaysDescription"
          :chart-type="ChartTypes.BarChart"
          :chart-data="AbsentDaysData"
          :chart-sub-title="AbsentDaysChartSubTitle"
          :chart-x-axis-title="AbsentDaysChartXAxisTitle"
          :suppress-legend="true"
          :v-html="NumberOfAbsences"
        />
      </b-col>
    </b-row>
    <b-row v-if="!HideAbsentChronicData">
      <b-col>
        <visual-component
          v-if="$store.getters.SelectedSchoolYear.value > 1920"
          :id="AbsentGradeId"
          :visual-title="AbsentGradeTitle"
          :visual-description="AbsentGradeDescription"
          :chart-type="ChartTypes.BarChart"
          :chart-data="AbsentGradeData"
          :chart-sub-title="AbsentGradeChartSubTitle"
          :chart-x-axis-title="AbsentGradeChartXAxisTitle"
          :suppress-legend="false"
          :v-html="GradeLevel"
        />
      </b-col>
    </b-row>
    <br>
    <b-row class="pt-4 pb-4">
      <b-col cols="12">
        <b-row class="d-print-none">
          <b-col>
            <section-header :id="DisciplineId" :header-text="DisciplineSectionHeader" />
          </b-col>
        </b-row>
        <div class="intro" v-html="Violenceintro" />
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideViolenceOffensesData"
              :id="ViolenceOffensesId"
              class="h-100"
              :visual-title="ViolenceOffensesTitle"
              :visual-description="ViolenceOffensesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="ViolenceOffensesData"
              :horizontal-lines="['Total Unique Incidents', 'Total de incidentes únicos']"
            />
          </b-col>
          <b-col>
            <visual-component
              v-if="!HidePoliceNotifData"
              :id="PoliceNotifId"
              class="h-100"
              :visual-title="PoliceNotifTitle"
              :visual-description="PoliceNotifDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="PoliceNotifData"
              :horizontal-lines="['Other Incidents Leading to Removal',
                                  'Otros Incidentes que Conducen a la Eliminación']"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideHarrassInvestData"
              :id="HarrassInvestId"
              class="h-100"
              :visual-title="HarrassInvestTitle"
              :visual-description="HarrassInvestDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="HarrassInvestData"
              :table-shading="HarrassInvestTableShading"
            />
          </b-col>
          <b-col class="h-100">
            <b-card
              v-if="!HideStdDisciplinData"
              :id="`${StdDisciplinId}-print`"
              class="outside-border mt-5"
            >
              <b-card-header class="d-none d-print-block">
                {{ StdDisciplinPrintDisplay }}
              </b-card-header>
              <b-row>
                <b-col :id="StdDisciplinId" cols="11">
                  <h3 class="text-center">
                    {{ StdDisciplinTitle }}
                  </h3>
                </b-col>
                <b-col cols="1" class="pr-4 d-none d-lg-block">
                  <a
                    class="small-icon d-print-none"
                    :title="StdDisciplinTitle"
                    @click="printStdDispiplin"
                    @keydown="printStdDispiplin"
                  >
                    <font-awesome-icon :icon="['fas', 'download']">
                      <span class="sr-only">Download Icon</span>
                    </font-awesome-icon>
                  </a>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="pb-0 mb-0" v-html="StdDisciplinDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    :id="StdDisciplinId"
                    ref="StdDiscipline"
                    class="embedded-visual"
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdDisciplinData"
                    :suppress-border="true"
                  />
                  <visual-component
                    ref="SchoolDaysMissed"
                    class="embedded-visual"
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="SchoolDaysMissedData"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="!HideSchoolDayData" class="pt-4 pb-4">
      <b-col cols="12">
        <b-row class="d-print-none">
          <b-col>
            <section-header :id="SchoolDayHeaderId" :header-text="SchoolDaySectionHeader" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideSchoolDayData"
              :id="SchoolDayId"
              :visual-title="SchoolDayTitle"
              :visual-description="SchoolDayDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="SchoolDayData"
              :horizontal-lines="['Full Time - Instructional Time',
                                  'Tiempo Completo - Tiempo de Instrucción']"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      v-if="!HideDeviceRatiosData || !HideTechnologyInternetAccessTitle"
      class="pt-4 d-print-none"
    >
      <b-col>
        <section-header :id="DeviceRatiosHeaderId" :header-text="DeviceRatioSectionHeader" />
      </b-col>
    </b-row>
    <b-row class="pb-4">
      <b-col cols="12">
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideDeviceRatiosData"
              :id="DeviceRatiosId"
              :visual-title="DeviceRatiosTitle"
              :visual-description="DeviceRatiosDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="DeviceRatiosData"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-container
      v-if="!HideTechnologyInternetAccessTitle"
      :id="TechnologyInternetAccessId"
      fluid
    >
      <b-row class="pt-2 pb-4">
        <b-col cols="12" class="outside-border pt-4">
          <h3 v-html="TechnologyInternetAccessTitle" />
          <p class="p-1" v-html="TechnologyInternetAccessText" />
        </b-col>
      </b-row>
      <br />
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
// eslint-disable-next-line import/no-cycle
import Climate from '../../mixins/detailComponents/climate.mixin';
import translation from '../../mixins/translation-service.mixin';
import hidden from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

// import SchoolSvc from '../services/school.service';
// import DistrictSvc from '../services/district.service';
// import StateSvc from '../services/state.service';

export default {
  name: 'SchoolClimate',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [Climate, translation, hidden, inPageNav],
  data() {
    return {
      ChartTypes,
      StdDisciplinPrintDisplay: '',
    };
  },
  methods: {
    printStdDispiplin() {
      this.$refs.StdDiscipline.enablePrintView();
      this.$refs.SchoolDaysMissed.enablePrintView();
      this.StdDisciplinPrintDisplay = this.$refs.SchoolDaysMissed.printDisplay;

      this.$nextTick(() => {
        this.$htmlToPaper(`${this.StdDisciplinId}-print`);

        this.$refs.StdDiscipline.disablePrintView();
        this.$refs.SchoolDaysMissed.disablePrintView();
      });
    },
  },
};
</script>

<style>
.h-100 .row.pt-4,
.h-100 .row.pt-4 .col .card.outside-border {
  height: 100% !important;
}
</style>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
  border-radius: 4px;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
.embedded-visual :deep(.card-body.py-2) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.embedded-visual :deep(div.row.p-4) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.embedded-visual :deep(.table .row.row.p-4) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
