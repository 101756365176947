<template>
  <div>
    <b-container v-if="ShowAs === 'List'" fluid class="p-0">
      <b-row class="reportKey text-left px-0 pt-4 pt-md-2">
        <b-col class="p-0">
          <strong v-html="RKTitle" />
          <ul>
            <li><strong>* </strong><span v-html="ItemStar" /></li>
            <li><strong>** </strong><span v-html="ItemStarStar" /></li>
            <li><strong>N </strong><span v-html="ItemN" /></li>
            <li><strong>† </strong><span v-html="ItemPlus" /></li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else fluid class="reportKey-bg pb-1">
      <b-row class="reportKey w-100 text-left pt-0">
        <b-col cols="12" md="auto" class="pr-0">
          <strong v-html="RKTitle" />
        </b-col>
        <b-col cols="12" md="auto" class="pr-0">
          <strong>* </strong>
          <span v-html="ItemStar" />
        </b-col>
        <b-col cols="12" md="auto" class="pr-0">
          <strong>** </strong>
          <span v-html="ItemStarStar" />
        </b-col>
        <b-col cols="12" md="auto" class="pr-0">
          <strong>N </strong>
          <span v-html="ItemN" />
        </b-col>
        <b-col cols="12" md="auto" class="pr-0">
          <strong>† </strong>
          <span v-html="ItemPlus" />
        </b-col>
        <b-col
          cols="12"
          class="d-block d-lg-none"
          style="border-bottom: 1px solid rgb(0 128 0 / 16%)"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TranslationSvc from '../services/translation.service';
import translation from '../mixins/translation-service.mixin';

export default {
  mixins: [translation],
  props: {
    ShowAs: {
      type: String,
      default: 'List',
    },
  },
  computed: {
    RKTitle() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.Title,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemStar() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemStar,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemStarStar() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemStarStar,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemN() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemN,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemPlus() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemPlus,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
  },
};
</script>

<style scoped>
.reportKey ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
@media print {
  .reportKey {
    display: block;
  }
  .col {
    font-size: 7.5pt;
  }
}

@media screen {
  .reportKey-bg {
    background-color: white;
  }
  .reportKey {
    display: inline-flex;
    font-size: .90em;
    text-align: left;
    margin: 0;
    padding: 0;
  }
}
</style>
