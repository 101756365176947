import pattern from 'patternomaly';
import TranslationSvc from '../../services/translation.service';
import ChartTypes from '../../components/ChartTypes';
import templateCode from '../template-code.mixin';
import translation from '../translation-summary-service.mixin';
import mapping from '../mapping.mixin';

export default {
  data() {
    return {
      ChartTypes,
    };
  },
  mixins: [templateCode, mapping, translation],
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$route.params.schoolYear,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };
      this.$store.dispatch('getHighlights', params);
      this.$store.dispatch('getStudentsAtRisk', params);
      this.$store.dispatch('getStudentGrad4Year', params);
      this.$store.dispatch('getStudentGrad5Year', params);
      this.$store.dispatch('getStudentGrowthCompareELA', params);
      this.$store.dispatch('getStudentGrowthCompareMath', params);
      this.$store.dispatch('getHowAssessELA', params);
      this.$store.dispatch('getHowAssessMath', params);
      this.$store.dispatch('getStudentCCR', params);
    },
    ShowSubVisual(data) {
      // TODO: We need to come up with a 'Key' to use instead of the lTitle field
      switch (data.lTitle) {
        case 'Universitaria Inscripción':
        case 'College Enrollment:':
          return this.institutionOffersGrade('12');
        case 'AP/IB Cursos:':
        case 'AP/IB Courses:':
          return this.institutionOffersGrade('12')
            || this.institutionOffersGrade('11')
            || this.institutionOffersGrade('10');
        case 'Inscripción Dual:':
        case 'Dual Enrollment:':
          return this.institutionOffersGrade('12')
            || this.institutionOffersGrade('11')
            || this.institutionOffersGrade('10');
        case 'Credenciales valoradas en la industria':
        case 'Industry-Valued Credentials:':
          return this.institutionOffersGrade('12')
            || this.institutionOffersGrade('11')
            || this.institutionOffersGrade('10')
            || this.institutionOffersGrade('9');
        default:
          return false;
      }
    },
    ShowCollegeReady() {
      const gradeOffered = this.institutionOffersGrade('12')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('9');

      const visualData = this.StdCollegeData;
      let hasData = false;

      if (!visualData.length) return false;

      visualData.forEach((d) => {
        hasData = hasData || this.ShowSubVisual(d);
      });

      return gradeOffered && hasData;
    },
    ShowGradRates() {
      return this.institutionOffersGrade('12');
    },
    ShowPerformAssessments() {
      const gradeOffered = this.institutionOffersGrade('03')
        || this.institutionOffersGrade('04')
        || this.institutionOffersGrade('05')
        || this.institutionOffersGrade('06')
        || this.institutionOffersGrade('07')
        || this.institutionOffersGrade('08')
        || this.institutionOffersGrade('09')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('12');

      return gradeOffered;
    },
    ShowGrowthComparison() {
      const gradeOffered = this.institutionOffersGrade('04')
        || this.institutionOffersGrade('05')
        || this.institutionOffersGrade('06')
        || this.institutionOffersGrade('07')
        || this.institutionOffersGrade('08');

      return gradeOffered;
    },
    setStdRiskScroll() {
      this.$store.dispatch('setScrollToId', 'ASAR');
    },
    setCollegeReadyScroll() {
      this.$store.dispatch('setScrollToId', 'APIBCPP');
    },
  },
  computed: {
    SelectedSchoolYear() {
      const year = this.$store.getters
        .CurrentSchoolYears.filter((sy) => sy.text === this.$route.params.schoolYear);
      return year[0];
    },
    postSecondaryLink() {
      return {
        name: 'PostSecondary',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    climateLink() {
      return {
        name: 'Climate',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    accountabilityLink() {
      return {
        name: 'Accountability',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    collegeReadyLink() {
      return {
        name: 'CollegeCareer',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    academicAchievementLink() {
      return {
        name: 'AcademicAchievement',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    studentGrowthLink() {
      return {
        name: 'StudentGrowth',
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      };
    },
    currentReportType() {
      return this.$route.params.reportType.toLowerCase();
    },
    FooterTitle() {
      if (this.currentReportType === 'school') {
        return TranslationSvc.SchoolSummary.getFooterTranslation(
          'Footer-Title',
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
      }
      if (this.currentReportType === 'district') {
        return TranslationSvc.DistrictSummary.getFooterTranslation(
          'Footer-Title',
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
      }
      return '';
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    SummaryIntro() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.SummaryIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ShowHighlights() {
      const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_Highlights`);
      if (!storeData || storeData.length === 0 || !storeData[0].data
        || storeData[0].data.length === 0 || !storeData[0].data[0].rowHead) {
        return false;
      }
      return true;
    },
    HighlightsTitle() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_Highlights`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || storeData[0].data.length === 0 || !storeData[0].data[0].rowHead) {
          return {};
        }
        const storeObject = storeData[0].data;
        return storeObject[0].rowHead;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HighlightsData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_Highlights`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || storeData[0].data.length === 0 || !storeData[0].data[0].rowHead) {
          return {};
        }
        const storeObject = storeData[0].data;
        return storeObject[0].bulletPoints;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    isTarget() {
      if (this.ReportType === 'school') {
        return this.$store.getters.CurrentSchoolInfo.requireComprehensiveSupport
          || this.$store.getters.CurrentSchoolInfo.requireTargetedSupport;
      }
      return this.$store.getters.CurrentDistrictInfo.requireComprehensiveSupport
        || this.$store.getters.CurrentDistrictInfo.requireTargetedSupport;
    },
    TargetText() {
      if (this.ReportType === 'school') {
        return this.$store.getters.CurrentSchoolInfo.comprehensiveOrTargetedSupport
          .replace('additional', '<strong>additional</strong>')
          .replace('adicionales', '<strong>adicionales</strong>')
          .replace('targeted', '<strong>targeted</strong>')
          .replace('específico', '<strong>específico</strong>')
          .replace('específicas', '<strong>específicas</strong>');
      }
      return this.$store.getters.CurrentDistrictInfo.comprehensiveOrTargetedSupport;
    },
    hiddenForSchoolYear() {
      return this.$store.getters.SelectedSchoolYear.value < '1920';
    },
    StudentGrowthCompareTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthCompareTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StudentGrowthCompareDescription() {
      const gradText = this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthCompareText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return gradText;
    },
    StdGrowthELAData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthELA`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};
        // HowAssess Subtitle is same for StdGrowth
        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.HowAssessELADataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.ExceedsStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        retObject.Labels = [`${L1}: 1 - 39.5`, `${L2}: 40 - 59.5`, `${L3}: 60 - 99`];

        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [39.5, 20, 39];
        scaleDataSet.dataAsString = ['39.5', '59.5', '99'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 39.5) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 59.5) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];
        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdGrowthMathData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthMath`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};
        // HowAssess Subtitle is same for StdGrowth
        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.HowAssessMathDataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.ExceedsStandard,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        // eslint-disable-next-line max-len
        // retObject.Labels = ['Below State: 0 - 92.1%', 'Met State: 92.2 - 95.9%',
        // 'Met Goal: 96 - 100%'];
        retObject.Labels = [`${L1}: 1 - 39.5`, `${L2}: 40 - 59.5`, `${L3}: 60 - 99`];
        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [39.5, 20, 39];
        scaleDataSet.dataAsString = ['39.5', '59.5', '99'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 39.5) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 59.5) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];

        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HowAssessTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.HowAssessTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    HowAssessDescription() {
      const gradText = this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.HowAssessText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return gradText;
    },
    StdRiskTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdRiskTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdRiskDescription() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdRiskText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdRiskData() {
      try {
        const storeObjects = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentsAtRisk`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        //  return this.MapData(storeObject, this.ChartTypes);
        const tmp = {
          // id: `key${i}`,
          lDescription: storeObject.description,
          Labels: [storeObject.data.header1, storeObject.data.header2],
          Values: [{
            backgroundColor: ['#5b9bd5', '#44546a'],
            pointBorderColor: '#249EBF',
            data: [+storeObject.data.data1.replace('%', '').replace('*', '')
              .replace('N/A', '').replace('NA', '')
              .replace('N', ''),
            +storeObject.data.data2.replace('%', '').replace('*', '')
              .replace('N/A', '').replace('NA', '')
              .replace('N', '')],
            dataAsString: [storeObject.data.data1, storeObject.data.data2],
            label: storeObject.name,
          }],
          lTitle: storeObject.name,
        };
        return tmp;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StdRiskXAxisTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdRiskXAxisTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ShowStdRisk() {
      const storeObjects = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentsAtRisk`);

      if (!storeObjects || storeObjects.length === 0) {
        return false;
      }
      // eslint-disable-next-line
      alert('here');      
      return true;
    },
    StdGrowthTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthDescription() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGradTitle() {
      return this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGradTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGradDescription() {
      const gradText = this.translationService.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdGradText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return gradText;
    },
    StdGrad4YearData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentsGrad4Year`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};
        // retObject.ChartSubTitle = 'Four-Year Graduation Rate';
        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.StdGrad4YearDataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetGoal,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        retObject.Labels = [`${L1}: 0 - 91.0%`, `${L2}: 91.1 - 94.9%`, `${L3}: 95 - 100%`];

        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [91.0, 3.9, 5.1];
        scaleDataSet.dataAsString = ['91.0%', '94.9%', '100%'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 91.0) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 94.9) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];

        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdGrad5YearData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentsGrad5Year`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};

        // retObject.ChartSubTitle = 'Five-Year Graduation Rate';
        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.StdGrad5YearDataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetGoal,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        // eslint-disable-next-line max-len
        // retObject.Labels = ['Below State: 0 - 92.1%', 'Met State: 92.2 - 95.9%',
        // 'Met Goal: 96 - 100%'];
        retObject.Labels = [`${L1}: 0 - 92.6%`, `${L2}: 92.7 - 95.9%`, `${L3}: 96 - 100%`];
        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [92.6, 3.3, 5.1];
        scaleDataSet.dataAsString = ['92.6%', '95.9%', '100%'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 92.6) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 96) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];

        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HowAssessELAData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_HowAssessELA`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};

        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.HowAssessELADataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetGoal,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        retObject.Labels = [`${L1}: 0 - 51.2%`, `${L2}: 51.3 - 79.9%`, `${L3}: 80 - 100%`];

        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [51.2, 28.7, 20];
        scaleDataSet.dataAsString = ['51.3%', '79.9%', '100%'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 51.2) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 79.9) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];

        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HowAssessMathData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_HowAssessMath`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const retObject = {
          Values: [],
          Labels: [],
          ColumnHeaders: [],
        };
        const scaleDataSet = {};
        const valueDataSet = {};

        // retObject.ChartSubTitle = 'Five-Year Graduation Rate';
        retObject.ChartSubTitle = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.HowAssessMathDataChartSubTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L1 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.BelowState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L2 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetState,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
        const L3 = this.translationService.getBodyTranslation(
          TranslationSvc.Constants.ComponentNames.MetGoal,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );

        // Fill Scale Labels and Values
        // eslint-disable-next-line max-len
        // retObject.Labels = ['Below State: 0 - 92.1%', 'Met State: 92.2 - 95.9%',
        // 'Met Goal: 96 - 100%'];
        retObject.Labels = [`${L1}: 0 - 38.1%`, `${L2}: 38.2 - 79.9%`, `${L3}: 80 - 100%`];
        scaleDataSet.fill = false;
        scaleDataSet.label = 'CHART_KEY';
        scaleDataSet.data = [38.1, 41.8, 20];
        scaleDataSet.dataAsString = ['38.2%', '79.9%', '100%'];
        scaleDataSet.backgroundColor = [
          pattern.draw('dash', 'rgb(220, 53, 69, 0.65)'),
          pattern.draw('dot', 'rgb(40, 167, 69, 0.65)'),
          pattern.draw('diagonal', 'rgb(23, 162, 184, 0.65)'),
        ];

        // Build DataSet
        const backGround = [];
        const valArray = storeObject.values.flat().flat();

        valueDataSet.fill = false;
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.label = storeObject.labels[0];
        valueDataSet.data = valArray.map((o) => (o || '')
          .replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', ''));
        valueDataSet.dataAsString = valArray;
        valueDataSet.backgroundColor = [];

        // Set background color based on value
        if (valueDataSet.data[0] <= 38.1) {
          backGround.push('#DC3545');
        } else if (valueDataSet.data[0] <= 79.9) {
          backGround.push('#28A745');
        } else {
          backGround.push('#17A2B8');
        }

        backGround.push('#E5E5E5');

        valueDataSet.backgroundColor = backGround;

        // Balance out the values count
        valueDataSet.data.push(100 - valueDataSet.data[0]);
        valueDataSet.data.push(0);

        // eslint-disable-next-line prefer-destructuring
        valueDataSet.value = valArray[0].replace('%', '')
          .replace(',', '')
          .replace('<', '')
          .replace('>', '')
          .replace('*', '')
          .replace('NA', '')
          .replace('N/A', '')
          .replace('N', '');
        // eslint-disable-next-line prefer-destructuring
        valueDataSet.valueLabel = storeObject.labels[0];

        retObject.Values.push(valueDataSet);
        retObject.Values.push(scaleDataSet);

        return retObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdCollegeTitle() {
      return TranslationSvc.SchoolSummary.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdCollegeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdCollegeDescription() {
      return TranslationSvc.SchoolSummary.getBodyTranslation(
        TranslationSvc.Constants.ComponentNames.StdCollegeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdCollegeData() {
      try {
        const storeData = this.$store.getters.SummaryReportData.filter((o) => o.Component === `${this.ReportType}_StudentsCCR`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        const returnObj = [];
        let tmp;
        for (let i = 0; i <= storeObject.length - 1; i += 1) {
          tmp = {
            id: `key${i}`,
            lDescription: storeObject[i].description,
            Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
            Values: [{
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject[i].data.data1.replace('%', '').replace('*', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', ''),
              +storeObject[i].data.data2.replace('%', '').replace('*', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
              label: storeObject[i].name,
            }],
            lTitle: storeObject[i].name,
          };
          returnObj.push(tmp);
        }
        return returnObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
  },
  watch: {
    ReportType() {
      this.fetchData();
    },
    Language() {
      console.log(`Language Changed to ${this.$store.getters.CurrentLanguage}`);
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
    SelectedSchoolYear() {
      this.$store.dispatch('clearSchoolDistrictinfo');
      if (this.ReportType === 'school') {
        this.$store.dispatch('getSchoolSummaryHeader', {
          schoolYear: this.$store.getters.SelectedSchoolYear.text,
          countyCd: this.$route.params.countyCd,
          districtCd: this.$route.params.districtCd,
          schoolCd: this.$route.params.schoolCd,
          langCd: this.$store.getters.CurrentLanguage,
        });
      } else {
        this.$store.dispatch('getDistrictSummaryHeader', {
          schoolYear: this.$store.getters.SelectedSchoolYear.text,
          countyCd: this.$route.params.countyCd,
          districtCd: this.$route.params.districtCd,
          langCd: this.$store.getters.CurrentLanguage,
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
