<template>
  <div>
    <b-container>
      <b-row class="pt-4 d-print-none">
        <b-col>
          <section-header :id="StaffHeaderId" :header-text="StaffSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="">
        <b-col :id="StaffHeaderId" cols="12" class="intro">
          <span v-html="StaffIntro" />
        </b-col>
      </b-row>
      <b-row class="pb-4">
        <b-col cols="12">
          <visual-component
            v-if="!HideTeacherExpVisual"
            :id="TeacherExpId"
            :visual-title="TeacherExpTitle"
            :visual-description="TeacherExpDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="TeacherExpData"
          />
          <visual-component
            v-if="!HideAdmExpVisual"
            :id="AdmExpId"
            :visual-title="AdmExpTitle"
            :visual-description="AdmExpDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AdmExpData"
          />
          <!-- :HorizontalLines="['Librarians/Media Specialists',
                'Bibliotecarios/especialistas en medios']" -->
          <visual-component
            :id="StaffCountsId"
            :visual-title="StaffCountsTitle"
            :visual-description="StaffCountsDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="StaffCountsData"
          />
          <visual-component
            :id="StdStaffRatiosId"
            :visual-title="StdStaffRatiosTitle"
            :visual-description="StdStaffRatiosDescription"
            :visual-footer="StdStaffRatiosPlusFooter"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="StdStaffRatiosData"
            :table-shading="StdStaffRatiosTableShading"
            :horizontal-lines="['Students to Librarians/Media Specialists †',
                                'Estudiantes a Bibliotecarios/Especialistas en medios †',
                                'Students to Librarians/Media Specialists',
                                'Estudiantes a bibliotecarios/Especialistas en medios']"
          />
          <visual-component
            v-if="!HideTeacherDemogVisual"
            :id="TeacherDemogId"
            :visual-title="TeacherDemogTitle"
            :visual-description="TeacherDemogDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="TeacherDemogData"
            :horizontal-lines="['White', 'Blanco']"
            :vertical-lines="['Administrators in School',
                              'Administradores en la escuela', 'Administrators in District',
                              'Administradores en la distrito', 'Administradores en el distrito']"
          />
          <b-row class="pt-4">
            <b-col>
              <b-card :id="`${TeacherLevelEducId}-print`" class="outside-border mt-4">
                <b-row :id="TeacherLevelEducId">
                  <b-col>
                    <h3>{{ TeacherLevelEducTitle }}</h3>
                  </b-col>
                  <b-col cols="1" class="d-none d-lg-block">
                    <a
                      class="small-icon"
                      :title="TeacherLevelEducTitle"
                      @click="printTeacherLevelEduc"
                      @keydown="printTeacherLevelEduc"
                    >
                      <font-awesome-icon :icon="['fas', 'download']">
                        <span class="sr-only">Download Icon</span>
                      </font-awesome-icon>
                    </a>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p v-html="TeacherLevelEducDescription" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <visual-component
                      v-if="!HideTeacherLevelEducVisual"
                      ref="TeacherLevelEduc1"
                      :visual-title="''"
                      :visual-description="''"
                      :suppress-border="true"
                      :suppress-print-button="true"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="TeacherLevelEducData()[0]"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <visual-component
                      v-if="!HideTeacherLevelEducVisual"
                      ref="TeacherLevelEduc2"
                      :visual-title="''"
                      :suppress-border="true"
                      :suppress-print-button="true"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="TeacherLevelEducData()[1]"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <visual-component
                      v-if="!HideTeacherLevelEducVisual"
                      ref="TeacherLevelEduc3"
                      :visual-title="''"
                      :suppress-border="true"
                      :suppress-print-button="true"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="TeacherLevelEducData()[2]"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <!-- <visual-component v-for="fullDataSet in TeacherLevelEducData"
                :key="fullDataSet"
                :VisualTitle="TeacherLevelEducTitle"
                :visualDescription="TeacherLevelEducDescription"
                :ChartType="ChartTypes.HorizontalBarChart"
                :chartData="fullDataSet"
                :suppressBorder="true"/> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <visual-component
                v-if="!HideTeacher1YearRetentionVisual"
                :id="Teacher1YearRetentionId"
                :visual-title="Teacher1YearRetentionTitle"
                :visual-description="Teacher1YearRetentionDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="Teacher1YearRetentionData"
              />
            </b-col>
            <!-- <b-col>
              <visual-component
                :VisualTitle="FacultyAttendaceTitle"
                :visualDescription="FacultyAttendaceDescription"
                :ChartType="ChartTypes.TableGrid"
                :chartData="FacultyAttendaceData"
                v-if="!HideFacultyAttendaceVisual" />
            </b-col> -->
          </b-row>
          <b-row>
            <b-col>
              <visual-component
                v-if="!HideTeacherSubjectAreaVisual"
                :id="TeacherSubjectAreaId"
                :visual-title="TeacherSubjectAreaTitle"
                :visual-description="TeacherSubjectAreaDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="TeacherSubjectAreaData"
                :vertical-lines="['% Non-binary or Undesignated Gender',
                                  '% % Género no binario o no identificado',
                                  '% Two or More Races',
                                  '% Dos o Más Razas',
                                  '% 4 or more years experience in the district',
                                  '% 4 o más años de experiencia en el Distrito']"
                :horizontal-lines="['Special Education',
                                    'Educación Especial']"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="keep-break">
              <visual-component
                v-if="!HideStatewideEducatorEquityVisual"
                :id="StatewideEducatorEquityId"
                :visual-title="StatewideEducatorEquityTitle"
                :visual-description="StatewideEducatorEquityDescription"
                :table-shading="StatewideEducatorEquityTableShading"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="StatewideEducatorEquityData"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
// eslint-disable-next-line import/no-cycle
import SectionHeader from '../../components/SectionHeader.vue';
import staff from '../../mixins/detailComponents/staff.mixin';
import translation from '../../mixins/translation-service.mixin';
import hiddenLogic from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolStaff',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [staff, translation, hiddenLogic, inPageNav],
  data() {
    return {
      ChartTypes,
    };
  },
  methods: {
    printTeacherLevelEduc() {
      this.$refs.TeacherLevelEduc1.enablePrintView();
      this.$refs.TeacherLevelEduc2.enablePrintView();
      this.$refs.TeacherLevelEduc3.enablePrintView();

      this.$nextTick(() => {
        this.$htmlToPaper(`${this.TeacherLevelEducId}-print`);

        this.$refs.TeacherLevelEduc1.disablePrintView();
        this.$refs.TeacherLevelEduc2.disablePrintView();
        this.$refs.TeacherLevelEduc3.disablePrintView();
      });
    },
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
</style>
