/* eslint no-underscore-dangle: 0 */
import { Bar } from 'vue-chartjs';
import pattern from 'patternomaly';

export default {
  extends: Bar,
  props: {
    DataLabels: {
      default: () => ['2019-2020'],
    },
    DataSets: {
      default: () => [{
        label: 'Level 1',
        data: [70],
        backgroundColor: [
          pattern.draw('dot', '#1f4e7a'),
        ],
      },
      {
        label: 'Level 2',
        data: [21],
        backgroundColor: '#2a68a2',
      },
      {
        label: 'Level 3',
        data: [8],
        backgroundColor: [
          pattern.draw('diagonal', '#4c8ac4'),
        ],
      },
      {
        label: 'Level 4',
        data: [1],
        backgroundColor: '#5997e3',
      },
      ],
    },
  },
  computed: {
    legendPosition() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        /* The viewport is less than, or equal to, 700 pixels wide */
        return 'top';
      }
      /* The viewport is greater than 700 pixels wide */
      return 'left';
    },
    legendAlign() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        /* The viewport is less than, or equal to, 700 pixels wide */
        return 'center';
      }
      /* The viewport is greater than 700 pixels wide */
      return 'center';
    },
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
            },
          },
        },
        barThickness: 'flex',
        categoryPercentage: 0.5,
        maintainAspectRatio: false,
        responsive: () => {
          if (this.IsPrintView) { return false; }
          if (window.matchMedia('(max-width: 767px)').matches) {
            /* The viewport is less than, or equal to, 700 pixels wide */
            return false;
          }
          /* The viewport is greater than 700 pixels wide */
          return true;
        },
        legend: {
          onClick: (e) => e.stopPropagation(), // prevent clicking legend
          position: this.legendPosition,
          align: this.legendAlign,
          fullWidth: false,
          boxWidth: 30,
          reverse: true,
          fontSize: '11',
        },
        layout: {
          padding: {
            top: 20,
            right: 40, // prevent clipping on small screens
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              color: 'black',
            },
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              color: 'black',
              min: 0,
              max: 100,
            },
          }],
        },
        animation: {
          duration: this.isPrintView ? 0 : 0.5,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = '12px Roboto';
            ctx.fillStyle = '#000';
            ctx.textAlign = 'center';
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              const evenOdd = i % 2 ? -20 : 15;
              let lastValueSmall = false;
              meta.data.forEach((bar, index) => {
                if (dataset.dataAsString[index] === 'N') {
                  ctx.textBaseline = 'bottom';
                  ctx.fillText('N', bar._model.x, bar._model.y);
                } else if (dataset.dataAsString[index]
                  && dataset.dataAsString[index].startsWith('txt::')) {
                  // this is  text to dispaly at the top of stacked bar
                  // post sec -> enrollment rate summary
                  if (ctx.canvas.width < 768) {
                    ctx.fillText(dataset.dataAsString[index].replace('txt::', ''), bar._model.x, bar._model.y - 15, 60);
                  } else {
                    ctx.fillText(dataset.dataAsString[index].replace('txt::', ''), bar._model.x, bar._model.y - 15);
                  }
                } else {
                  const data = dataset.data[index];
                  const dataStr = dataset.dataAsString[index];
                  if (data > 0) {
                    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
                    const ypos = bar._model.base - (bar._model.base - bar._model.y) / 2 + 0;
                    if (dataset.backgroundColor !== '#c5dcf1') {
                      // add background rectangle to the label
                      if (lastValueSmall) {
                        ctx.fillRect(
                          bar._model.x - 20 + evenOdd,
                          bar._model.base - (bar._model.base - bar._model.y) / 2 - 5,
                          38,
                          21,
                        );
                      } else {
                        ctx.fillRect(
                          bar._model.x - 20,
                          bar._model.base - (bar._model.base - bar._model.y) / 2 - 10,
                          37,
                          21,
                        );
                      }
                    }
                    // ctx.strokeText(dataStr, bar._model.x,
                    ctx.fillStyle = '#000';
                    ctx.font = 'bold 10px arial';
                    if (lastValueSmall) {
                      ctx.fillText(
                        dataStr,
                        bar._model.x + evenOdd,
                        ypos + 1,
                      );
                    } else {
                      ctx.fillText(
                        dataStr,
                        bar._model.x,
                        ypos + 1,
                      );
                    }
                    if (data < 6) {
                      lastValueSmall = true;
                    }
                  }
                  // if (data > 5) {
                  //   ctx.textBaseline = 'hanging';
                  // } else {
                  //   ctx.textBaseline = 'bottom';
                  // }
                  // if (data === 0) {
                  //   ctx.fillText('', bar._model.x, bar._model.y);
                  // } else {
                  //   ctx.fillText(dataStr, bar._model.x, bar._model.y + 5);
                  // }
                }
              });
            });
          },
        },
      };
    },
    labels() {
      return this.$props.DataLabels;
    },
    datasets() {
      return this.$props.DataSets;
    },
  },
  methods: {
    render() {
      const dataCollection = {
        labels: this.labels,
        datasets: this.datasets,
      };
      this.renderChart(dataCollection, this.options, this.onResize);
    },
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
    fillRoundRect(ctx, l, t, w, h, r) {
      const pi = Math.PI;
      ctx.beginPath();
      ctx.arc(l + r, t + r, r, -pi, -0.5 * pi, false);
      ctx.arc(l + w - r, t + r, r, -0.5 * pi, 0, false);
      ctx.arc(l + w - r, t + h - r, r, 0, 0.5 * pi, false);
      ctx.arc(l + r, t + h - r, r, 0.5 * pi, pi, false);
      ctx.closePath();
      ctx.fill();
    },
  },
  data() {
    return {
    };
  },
  watch: {
    labels() {
      this.render();
    },
    datasets() {
      this.render();
    },
  },
  mounted() {
    if (this.$store.getters.IsPrintView) {
      this.$refs.canvas.height = 250;
    }
    // renderChart function renders the chart with the datacollection and options object.
    this.render();
  },
};
