export default {
  computed: {
    hasAccess() {
      if (this.$store.getters.SelectedSchoolYear.embargo) {
        if (this.$route.params.reportType
          && this.$route.params.reportType.toLowerCase() === 'state') {
          return false;
        }
        if (!this.$route.params.districtCd) {
          // Not a district specific page
          return true;
        }
        if (!this.$store.getters.UserInfo
          || (this.$store.getters.UserInfo.institutionCode !== ''
          && this.$store.getters.UserInfo.institutionCode !== this.$route.params.districtCd)) {
          return false;
        }
      }
      return true;
    },
    canSee() {
      return !this.$store.getters.SelectedSchoolYear.embargo;
    },
  },
};
