<template>
  <div>
    <b-container class="alignment" fluid style="font-size: 10.5pt;">
      <div v-if="false">
        <b-row class="pt-4">
          <b-col>
            <span v-html="HowToTitle" />
            <br />
            <ul>
              <li v-html="HowToItem1" />
              <li v-html="HowToItem2" />
              <li v-html="HowToItem3" />
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-html="ImportantTitle" /><br />
            <span v-html="ImportantText" />
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <span v-html="NavTitle" />
            <br />
            <ul>
              <li v-html="NavItem1" />
              <li v-html="NavItem2" />
              <li v-html="NavItem3" />
              <li v-html="NavItem4" />
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-html="ImpactCovid" />
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <strong v-html="OtherTitle" />
            <br />
            <span v-html="OtherText" />
            <ul>
              <li v-html="OtherItem1" />
              <li v-html="OtherItem2" />
              <li v-html="OtherItem3" />
              <li v-html="OtherItem4" />
              <li v-html="OtherItem5" />
              <li v-html="OtherItem6" />
            </ul>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span
              v-if="ReportType === 'school'"
              v-html="SchoolContact"
            />
            <span
              v-if="ReportType === 'district'"
              v-html="DistrictContact"
            />
            <span
              v-if="ReportType === 'state'"
              v-html="StateContact"
            />
          </b-col>
        </b-row>
      </div>
      <b-row class="d-print-none">
        <b-col>
          <section-header :id="OverviewId" :header-text="OverviewSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="pt-4 print-section">
        <b-col>
          <span v-html="OverviewIntro" />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="alignment" fluid style="font-size: 10.5pt;">
      <div v-if="ReportType !== 'state'">
        <!-- This is specific for print -->
        <div class="page-break" />
        <print-header class="d-none d-print-block">
          {{ overviewHeaderText }}
        </print-header>
      </div>
      <b-row v-if="ReportType !== 'state'" class="text-center">
        <b-col>
          <visual-component
            v-if="HideTypeAndContactData"
            :id="TypeAndContactId"
            :visual-title="TypeAndContactTitle"
            :visual-description="TypeAndContactDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="TypeAndContactData"
          />
        </b-col>
      </b-row>
      <div v-if="ReportType === 'district'">
        <!-- This is specific for print -->
        <div class="page-break" />
        <print-header class="d-none d-print-block">
          {{ overviewHeaderText }}
        </print-header>
      </div>
      <b-row v-if="ReportType === 'district'" class="text-center">
        <b-col>
          <visual-component
            :id="SchoolsInDistrictId"
            :visual-title="SchoolsInDistrictTitle"
            :visual-description="SchoolsInDistrictDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="SchoolsInDistrictData"
          />
        </b-col>
      </b-row>
      <!-- <b-row class="pb-4">
          <b-col>
            <strong v-html="NotesTitle"
            v-if="this.ReportType !== 'state'">
            </strong>
          </b-col>
      </b-row> -->
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ChartTypes from '../../components/ChartTypes';
import mapping from '../../mixins/mapping.mixin';
import translation from '../../mixins/translation-service.mixin';
import VisualComponent from '../../components/VisualComponent.vue';
import PrintHeader from '../../components/PrintHeader.vue';
import SectionHeader from '../../components/SectionHeader.vue';
import overview from '../../mixins/detailComponents/overview.mixin';
// eslint-disable-next-line import/no-cycle
import navigation from '../../mixins/navigation.mixin';

export default {
  name: 'OverviewView',
  components: {
    PrintHeader,
    VisualComponent,
    SectionHeader,
  },
  mixins: [translation, mapping, navigation, overview],
  props: {
    printMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ChartTypes,
    };
  },
  computed: {
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };

      this.$store.dispatch('getTypeAndContactInformation', params);
      this.$store.dispatch('getSchoolsInDistrict', params);

      this.$emit('componentLoaded');
    },
    enableprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      visualComponents.forEach((comp) => {
        console.log(`Enabling Print View on ${comp}`);
        console.log(comp);
        comp.enablePrintView();
      });
    },
    disableprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      visualComponents.forEach((comp) => {
        comp.disablePrintView();
      });
    },
  },
};
</script>

<style scoped>
.alignment{
    text-align: left;
}
ul {
  list-style-type: disc;
}
a {
  color: #0071EB;
}
</style>
