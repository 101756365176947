<template>
  <b-row :id="IconData.key" class="pb-3">
    <b-col col md="4" lg="2" sm="12" class="px-0 narrative-icon col-print-4">
      <div class="small-blue">
        <span v-show="false">{{ IconData.key }}</span>
        <font-awesome-icon :icon="IconClass" class="fa-3x" /><br />
        <!-- <i v-bind:class="`fas ${IconClass} fa-3x`"></i><br /> -->
        <!-- <span v-html="IconData.rowHead" /> -->
        <span>{{ IconData.rowHead }}:</span>
      </div>
    </b-col>
    <b-col md="8" lg="8" sm="12" class="category-text-container col-print-8">
      <div v-if="ShowBulletPoints">
        <ul class="category-text px-3">
          <li v-for="bullet in IconData.bulletPoints" :key="bullet" class="py-1">
            {{ bullet }}
          </li>
        </ul>
      </div>
      <div v-else-if="IconData.bulletPoints.length">
        <div class="category-text">
          <div v-for="bullet in IconData.bulletPoints" :key="bullet" class="py-1">
            {{ bullet }}
          </div>
        </div>
      </div>
      <div v-else class="category-text">
        {{ IconData.bulletPoints[0] }}
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    IconTarget: {
      type: String,
      default: '#highlightssssssss',
      required: false,
    },
    IconClass: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    ShowBulletPoints: {
      type: Boolean,
      default: false,
      required: false,
    },
    IconData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.narrative-icon{
  min-width: 215px!important;
  align-self: center;
}
.small-blue{
  color: #287AB8;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 18pt;
}
.category-text-container{
  list-style-type: disc;
}
.category-text{
  text-align: justify;
}
ul.category-text{
  display: block;
  align-items:left;
}
.category{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 0px;
  box-sizing: border-box;
  border: 2px solid #287AB8;
  width: 100%;
}
.outside-border {
  box-sizing: border-box;
  border: 2px solid #287AB8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

</style>
