import authService from '../services/auth.service';

const auth = authService.getAuthService();

export default {
  mounted() {
    if (process.env.VUE_APP_USE_AUTH !== 'Yes'
      || auth.isAuthenticated()) {
      this.$store.dispatch('getCurrentSchoolYears');
      this.$store.dispatch('getUserInfo');
    }
  },
};
