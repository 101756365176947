<template>
  <div>
    <b-container fluid>
      <b-row class="d-print-none">
        <b-col>
          <section-header :id="EnrollmentId" :header-text="EnrollmentSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="py-4 py-print-2">
        <b-col cols="12" class="intro">
          <span v-html="PageHeader" />
        </b-col>
      </b-row>
      <b-row class="pb-4 pb-print-2">
        <b-col cols="12" class="col-print-4">
          <visual-component
            :id="EnrollmentByGradeId"
            :visual-title="EnrollmentByGradeTitle"
            :visual-description="EnrollmentByGradeDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnrollmentByGradeData"
            :horizontal-lines="['Total']"
          />
        </b-col>
        <b-col cols="12" class="col-print-8">
          <visual-component
            :id="EnrollmentByStudentGroupId"
            :visual-title="EnrollmentByStudentGroupTitle"
            :visual-description="EnrollmentByStudentGroupDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnrollmentByStudentGroupData"
            :horizontal-lines="['Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :table-shading="EnrollmentByStudentGroupTableShading"
          />
        </b-col>
        <b-col cols="12" class="col-print-8">
          <visual-component
            :id="EnrollmentByRaceId"
            :visual-title="EnrollmentByRaceTitle"
            :visual-description="EnrollmentByRaceDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnrollmentByRaceData"
          />
          <visual-component
            v-if="!HideEnrollmentByDayVisual"
            :id="EnrollmentByDayId"
            :visual-title="EnrollmentByDayTitle"
            :visual-description="EnrollmentByDayDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnrollmentByDayData"
            :horizontal-lines="['KG - Half Day', 'KG - Medio Día']"
          />
          <visual-component
            v-if="!HideEnrollmentByTimeVisual"
            :id="EnrollmentByTimeId"
            :visual-title="EnrollmentByTimeTitle"
            :visual-description="EnrollmentByTimeDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnrollmentByTimeData"
            :horizontal-lines="['Full Time Equivalent', 'Equivalente a tiempo completo']"
          />
          <visual-component
            :id="EnrollmentByLanguageId"
            :visual-title="EnrollmentByLanguageTitle"
            :visual-description="EnrollmentByLanguageDescription"
            :chart-type="EnrollmentByLanguageTableToggle"
            :suppress-legend="true"
            :chart-data="EnrollmentByLanguageData"
          >
            <b-form-checkbox
              v-model="EnrollmentByLanguageTableToggle"
              class="d-print-none"
              :value="ChartTypes.TableGrid"
              :unchecked-value="ChartTypes.BarChart"
            >
              {{ EnrollmentByLanguageToggleText }}
            </b-form-checkbox>
          </visual-component>
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import translation from '../../mixins/translation-service.mixin';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
import hidden from '../../mixins/hidden.mixin';
// eslint-disable-next-line import/no-cycle
import demographics from '../../mixins/detailComponents/demographics.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'DemographicsView',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [translation, hidden, demographics, inPageNav],
  data() {
    return {
      ChartTypes,
      EnrollmentByLanguageTableToggle: ChartTypes.BarChart,
    };
  },
  methods: {
    enableprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      visualComponents.forEach((comp) => {
        comp.enablePrintView();
      });
    },
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
</style>
