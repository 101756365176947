import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    growthReportData: [],
  },
  getters: {
    GrowthReportData: (state) => state.growthReportData,
  },
  mutations: {
    SET_GROWTH_REPORT_DATA(state, payload) {
      const component = state.growthReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.growthReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getStudentGrowthTrendsELAMedian(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthTrendsELAMedian`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthELATrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthELATrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthELATrendsMedian(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthTrendsELAMedian`, data: response });
      }
    },
    async getStudentGrowthTrendsMathMedian(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthTrendsMathMedian`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthMathTrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthMathTrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthMathTrendsMedian(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthTrendsMathMedian`, data: response });
      }
    },
    async getStudentGrowthTrendsMedian(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthTrendsMedian`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthTrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthTrendsMedian(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthTrendsMedian(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthTrendsMedian`, data: response });
      }
    },
    async getStudentGrowthTableData(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthTableData`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthTableData(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthTableData(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthTableData(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthTableData`, data: response });
      }
    },
    async getStudentGrowthByELAPerfLevelData(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthELAPerfLevel`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthELAPerfLevel(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthELAPerfLevel(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthELAPerfLevel(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthELAPerfLevel`, data: response });
      }
    },
    async getStudentGrowthByMathPerfLevelData(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthMathPerfLevel`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthMathPerfLevel(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthMathPerfLevel(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthMathPerfLevel(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthMathPerfLevel`, data: response });
      }
    },
    async getStudentGrowthByELAGradeData(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthELAGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthELAGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthELAGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthELAGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthELAGrade`, data: response });
      }
    },
    async getStudentGrowthByMathGradeData(
      { commit, state },
      payload,
    ) {
      if (!state.growthReportData.some((o) => o.Component === `${payload.reportType}_StudentGrowthMathGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.StudentGrowth
              .getStudentGrowthMathGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.StudentGrowth
              .getStudentGrowthMathGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.StudentGrowth
              .getStudentGrowthMathGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_GROWTH_REPORT_DATA', { Component: `${payload.reportType}_StudentGrowthMathGrade`, data: response });
      }
    },
  },
};
