import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [mapping, navigation],
  computed: {
    NarrativeSectionHeader() {
      return this.translationService.getNarrativeTranslation(
        TranslationSvc.Constants.ComponentNames.NarrativeHeader,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    narrativePages() {
      return Math.ceil(this.narrativeItems.length);
    },
    narrativeItems() {
      const storeData = this.$store.getters.NarrativeReportData.filter((o) => o.Component === `${this.ReportType}_Narrative`);
      if (!storeData || storeData.length === 0 || !storeData[0].data) {
        return [];
      }
      return storeData[0].data;
    },
    AnyNarrativeData() {
      try {
        // const storeObject = this.$store.getters.NarrativeReportData.filter
        // ((o) => o.Component.startsWith(`${this.ReportType}_Narrative`));
        const storeObject = this.$store.getters.NarrativeReportData.filter((o) => o.Component === `${this.ReportType}_Narrative`);
        if (!storeObject || storeObject.length === 0) {
          return true;
        }
        for (let i = 0; i < storeObject.length; i += 1) {
          if (storeObject[i] && storeObject[i].data
          && storeObject[i].data.bulletPoints && storeObject[i].data.bulletPoints.length > 0) {
            return true;
          }
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    Narrativetext() {
      return this.translationService.getNarrativeTranslation(
        TranslationSvc.Constants.ComponentNames.Narrativetext,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NarrativeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NarrativeHeader,
      );
    },
  },
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        // schoolYear: this.$store.getters.SelectedSchoolYear.text,
        schoolYear: this.$route.params.schoolYear,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };

      if (this.$route.name === 'PrintDetail') {
        // this.$store.dispatch('getAllNarrativeData', params);
        return;
      }
      this.$store.dispatch('getAllNarrativeData', params);
    },
    narrativeIcon(narrativeItem) {
      switch (narrativeItem.key) {
        case 'highlights':
          return ['fas', 'bullhorn'];
        case 'mission':
          return ['fas', 'lightbulb'];
        case 'awards':
          return ['fas', 'award'];
        case 'courses':
          return ['fas', 'book'];
        case 'sports':
          return ['fas', 'running'];
        case 'clubs':
          return ['fas', 'icons'];
        case 'programs':
          return ['fas', 'theater-masks'];
        case 'staff':
          return ['fas', 'users'];
        case 'postsecondary':
          return ['fas', 'university'];
        case 'supports':
          return ['fas', 'hands-helping'];
        case 'health':
          return ['fas', 'user-md'];
        case 'parent':
          return ['fas', 'handshake'];
        case 'climatesurveyused':
          return ['fas', 'poll'];
        case 'facilities':
          return ['fas', 'hotel'];
        case 'schoolsafety':
          return ['fas', 'shield-alt'];
        case 'technology':
          return ['fas', 'laptop'];
        case 'earlychildhood':
          return ['fas', 'font'];
        case 'learningduringcovid':
          return ['fas', 'people-arrows'];
        case 'essr':
          return ['fas', 'dollar-sign'];
        case 'otherinformation':
          return ['fas', 'info-circle'];
        default:
          return ['fas', 'bug'];
      }
    },
    narrativeIconSize(narrativeItem) {
      switch (narrativeItem.key) {
        case 'mission':
          return 'fa-4x pr-4';
        case 'awards':
          return 'fa-4x pr-4';
        case 'sports':
          return 'fa-4x pr-4';
        case 'essr':
          return 'fa-4x pr-4';
        default:
          return 'fa-3x pr-4';
      }
    },
    narrativeShowBullet(narrativeItem) {
      if (!narrativeItem || !narrativeItem.key) {
        return false;
      }
      switch (narrativeItem.key) {
        case 'highlights':
          return true;
        default:
          return false;
      }
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
