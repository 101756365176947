<template>
  <div>
    <b-container v-if="hasAccess" fluid>
      <b-row style="padding: 0px;">
        <b-col>
          <school-district-information />
        </b-col>
      </b-row>
      <b-row ref="navRow" class="sticky">
        <b-col>
          <!-- <report-nav-collapse /> -->
          <!-- <navigation /> -->
          <navigation-dropdown @inPageNav="handleInPageNav" />
          <report-key show-as="Line" />
        </b-col>
      </b-row>
      <b-row style="padding: 0px;">
        <b-col cols="12" md="10" offset-md="1">
          <router-view @componentLoaded="processPageNav" />
        </b-col>
      </b-row>
    </b-container>
    <access-denied v-else />
  </div>
</template>

<script>
// import ReportNavCollapse from '../components/ReportNavBarCollapse.vue';
import SchoolDistrictInformation from '../components/SchoolDistrictInformation.vue';
// import Navigation from '../components/Navigation.vue';
import NavigationDropdown from '../components/NavigationDropdown.vue';
import AccessDenied from '../components/AccessDenied.vue';
import accessHandler from '../mixins/access-denied.mixin';
import ReportKey from '../components/ReportKey.vue';

export default {
  components: {
    // ReportNavCollapse,
    // Navigation,
    NavigationDropdown,
    SchoolDistrictInformation,
    AccessDenied,
    ReportKey,
  },
  mixins: [accessHandler],
  created() {
    this.$store.dispatch('setPrintView', false);
  },
  methods: {
    handleInPageNav(visualId) {
      this.$scrollTo(`#${visualId}`, {
        offset: -1 * this.$refs.navRow.offsetHeight,
      });
    },
    processPageNav() {
      const scrollToId = this.$store.getters.ScrollToId;
      this.$nextTick(() => {
        if (scrollToId && scrollToId.length > 0) {
          this.$scrollTo(`#${scrollToId}`, {
            offset: -1 * this.$refs.navRow.offsetHeight,
          });
          this.$store.dispatch('clearScrollToId');
        }
      });
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
