<template>
  <div>
    <b-container fluid>
      <b-row class="d-print-none">
        <b-col>
          <section-header :id="StdGrowthId" :header-text="StudentGrowthSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="py-4">
        <b-col cols="12">
          <div class="intro keep-break" v-html="StdGrowthIntro" /><br />
          <b-card
            v-if="$store.getters.SelectedSchoolYear.value != 2122"
            :id="`${StdGrowthTrendId}-print`"
            ref="StudentGrowthPrintBox"
            class="outside-border"
          >
            <b-card-header class="d-none d-print-block">
              {{ printDisplay }}
            </b-card-header>
            <b-row>
              <b-col :id="StdGrowthTrendId">
                <h3>{{ StdGrowthTrendTitle }}</h3>
              </b-col>
              <b-col cols="1" class="d-none d-lg-block">
                <a
                  ref="printIcon"
                  class="small-icon d-print-none"
                  :title="StdGrowthTrendTitle"
                  @click="printELAMathTrends"
                  @keydown="printELAMathTrends"
                >
                  <font-awesome-icon :icon="['fas', 'download']">
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="StdGrowthTrendDescription" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1" class="d-print-none" />
              <b-col col md="5" sm="12" class="border-right-only mx-md-2">
                <visual-component
                  ref="ELATrends"
                  :visual-title="StdGrowthELATrendTitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="StdGrowthELATrendData"
                />
              </b-col>
              <b-col cols="1" class="d-none d-print-block" />
              <b-col col md="5" sm="12" class="mx-md-2">
                <visual-component
                  ref="MathTrends"
                  :visual-title="StdGrowthMathTrendTitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="StdGrowthMathTrendData"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <visual-component
                  ref="ELAMathTable"
                  :visual-title="''"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="StdGrowthTrendData"
                  :table-shading="StdGrowthTrendTableShading"
                  :vertical-lines="['2022-23 ELA']"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col>
              <visual-component
                v-if="$store.getters.SelectedSchoolYear.value >= 2223"
                :id="StudentGrowthId"
                :visual-title="StdGrowthTitle"
                :visual-description="StdGrowthDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="StdGrowthData"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                ]"
                :vertical-lines="['ELA: Met Standard (40-59.5)',
                                  'ELA: Cumplió con el estándar (40-59.5)']"
                :table-shading="StdGrowthTableShading"
              />
            </b-col>
          </b-row>
          <!-- Student Growth by Performance Level & Grade - 2 bar charts spacer above-->
          <b-row class="pt-4 pb-4">
            <!-- Student Growth by Performance Level - 2 bar charts-->
            <b-col md="6" sm="6">
              <b-card
                v-if="$store.getters.SelectedSchoolYear.value >= 2223"
                :id="`${StdGrowthPerfId}-print`"
                ref="StudentGrowthPerfLevelPrintBox"
                class="outside-border"
              >
                <b-card-header class="d-none d-print-block">
                  {{ printDisplay }}
                </b-card-header>
                <b-row>
                  <b-col :id="StdGrowthPerfId">
                    <b-row>
                      <b-col cols="11" center>
                        <h3>{{ StdGrowthPerfTitle }}</h3>
                      </b-col>
                      <b-col cols="1">
                        <a
                          ref="printIcon"
                          class="small-icon d-print-none"
                          :title="StdGrowthPerfTitle"
                          @click="printELAMathPerfLevel"
                          @keydown="printELAMathPerfLevel"
                        >
                          <font-awesome-icon :icon="['fas', 'download']">
                            <span class="sr-only">Download Icon</span>
                          </font-awesome-icon>
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p v-html="StdGrowthPerfDescription" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col md="12" sm="12" class="mx-md-2">
                    <b-row>
                      <b-col>
                        <span style="color: #317bb4; text-align: center; font-size: 22px;">
                          <strong v-html="StdGrowthPerfChartTitleEla" />
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <visual-component
                          ref="ELAPerfLevel"
                          :suppress-border="true"
                          :suppress-print-button="true"
                          :visual-description="''"
                          :chart-type="ChartTypes.BarChart"
                          :chart-data="StdGrowthELAPerfLevelData"
                          :chart-sub-title="StdGrowthMedianSubTitle"
                          :SuppressLegend="true"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col col md="12" sm="12" class="mx-md-2">
                    <b-row>
                      <b-col>
                        <span style="color: #317bb4; text-align: center; font-size: 22px;">
                          <strong v-html="StdGrowthPerfChartTitleMath" />
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <visual-component
                          ref="MathPerfLevel"
                          :suppress-border="true"
                          :suppress-print-button="true"
                          :visual-description="''"
                          :chart-type="ChartTypes.BarChart"
                          :chart-data="StdGrowthMathPerfLevelData"
                          :chart-sub-title="StdGrowthMedianSubTitle"
                          :SuppressLegend="true"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <!-- Student Growth by Grade - 2 bar charts-->
            <b-col md="6" sm="6">
              <b-card
                v-if="$store.getters.SelectedSchoolYear.value >= 2223"
                :id="`${StdGrowthgradeId}-print`"
                ref="StudentGrowthGradePrintBox"
                class="outside-border"
              >
                <b-card-header class="d-none d-print-block">
                  {{ printDisplay }}
                </b-card-header>
                <b-row>
                  <b-col :id="StdGrowthgradeId">
                    <b-row>
                      <b-col cols="11" center>
                        <h3>{{ StdGrowthgradeTitle }}</h3>
                      </b-col>
                      <b-col cols="1">
                        <a
                          ref="printIcon"
                          class="small-icon d-print-none"
                          :title="StdGrowthTrendTitle"
                          @click="printELAMathGrade"
                          @keydown="printELAMathGrade"
                        >
                          <font-awesome-icon :icon="['fas', 'download']">
                            <span class="sr-only">Download Icon</span>
                          </font-awesome-icon>
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <p v-html="StdGrowthgradeDescription" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col md="12" sm="12" class="mx-md-2">
                    <b-row>
                      <b-col>
                        <span style="color: #317bb4; text-align: center; font-size: 22px;">
                          <strong v-html="StdGrowthgradeChartTitleEla" />
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <visual-component
                          ref="ELAGrade"
                          :suppress-border="true"
                          :suppress-print-button="true"
                          :visual-description="''"
                          :chart-type="ChartTypes.BarChart"
                          :chart-data="StdGrowthELAGradeData"
                          :chart-sub-title="StdGrowthMedianSubTitle"
                          :SuppressLegend="true"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col col md="12" sm="12" class="mx-md-2">
                    <b-row>
                      <b-col>
                        <span style="color: #317bb4; text-align: center; font-size: 22px;">
                          <strong v-html="StdGrowthgradeChartTitleMath" />
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <visual-component
                          ref="MathGrade"
                          :suppress-border="true"
                          :suppress-print-button="true"
                          :visual-description="''"
                          :chart-type="ChartTypes.BarChart"
                          :chart-data="StdGrowthMathGradeData"
                          :chart-sub-title="StdGrowthMedianSubTitle"
                          :SuppressLegend="true"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <!-- End Student Growth by Performance Level -->
          <!-- <b-container fluid>
          <b-row class="pt-4 pb-4">
            <b-col cols="12" class="outside-border p-3">
              <h3 v-html="StdGrowthTitle"></h3>
              <p class="p-1" v-html="StdGrowthDescription" />
            </b-col>
          </b-row>
          </b-container> -->
          <!-- <visual-component
                    :VisualTitle="StdGrowthgradeTitle"
                    :visualDescription="StdGrowthgradeDescription"
                    :ChartType="ChartTypes.BarChart"
                    :chartData="StdGrowthgradeData" /> -->
          <!-- hidden for 2019-2020 -->
          <!-- <double-stacked-chart
            :VisualTitle="StdGrowthPerfTitle"
            :visualDescription="StdGrowthPerfDescription"
            />
            <double-bar-chart
            :VisualTitle="StdGrowthgradeTitle"
            :visualDescription="StdGrowthgradeDescription"
            /> -->
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
import translation from '../../mixins/translation-service.mixin';
// eslint-disable-next-line import/no-cycle
import growth from '../../mixins/detailComponents/growth.mixin';
import hidden from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolGrowth',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [translation, growth, hidden, inPageNav],
  data() {
    return {
      ChartTypes,
      printDisplay: '',
    };
  },
  methods: {
    printELAMathTrends() {
      this.$refs.StudentGrowthPrintBox.style.width = '1008px';
      setTimeout(() => {
        this.$refs.ELATrends.enablePrintView();
        this.$refs.MathTrends.enablePrintView();
        this.$refs.ELAMathTable.enablePrintView();

        this.printDisplay = this.$refs.ELATrends.printDisplay;

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.StdGrowthTrendId}-print`);

          this.$refs.ELATrends.disablePrintView();
          this.$refs.MathTrends.disablePrintView();
          this.$refs.ELAMathTable.disablePrintView();
          this.$refs.StudentGrowthPrintBox.style.width = '';
        });
      }, 100);
    },
    printELAMathPerfLevel() {
      this.$refs.StudentGrowthPerfLevelPrintBox.style.width = '1008px';
      setTimeout(() => {
        this.$refs.ELAPerfLevel.enablePrintView();
        this.$refs.MathPerfLevel.enablePrintView();

        this.printDisplay = this.$refs.ELAPerfLevel.printDisplay;

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.StdGrowthPerfId}-print`);

          this.$refs.ELAPerfLevel.disablePrintView();
          this.$refs.MathPerfLevel.disablePrintView();
          this.$refs.StudentGrowthPerfLevelPrintBox.style.width = '';
        });
      }, 100);
    },
    printELAMathGrade() {
      this.$refs.StudentGrowthGradePrintBox.style.width = '1008px';
      setTimeout(() => {
        this.$refs.ELAGrade.enablePrintView();
        this.$refs.MathGrade.enablePrintView();

        this.printDisplay = this.$refs.ELAGrade.printDisplay;

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.StdGrowthgradeId}-print`);

          this.$refs.ELAGrade.disablePrintView();
          this.$refs.MathGrade.disablePrintView();
          this.$refs.StudentGrowthGradePrintBox.style.width = '';
        });
      }, 100);
    },
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
}
.border-right-only{
  border-right: 2px solid #287AB8;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
a {
  color: #0071EB;
}
</style>
