import districtService from '../services/district.service';
import schoolService from '../services/school.service';

export default {
  state: {
    overviewReportData: [],
  },
  getters: {
    OverviewReportData: (state) => state.overviewReportData,
  },
  mutations: {
    SET_OVERVIEW_REPORT_DATA(state, payload) {
      const component = state.overviewReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.overviewReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getTypeAndContactInformation(
      { commit, state },
      payload,
    ) {
      if (!state.overviewReportData.some((o) => o.Component === `${payload.reportType}_TypeAndContactInformation`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Overview.getOverviewTypeAndContactInformation(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Overview.getOverviewTypeAndContactInformation(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default:
            break;
        }
        commit('SET_OVERVIEW_REPORT_DATA', { Component: `${payload.reportType}_TypeAndContactInformation`, data: response });
      }
    },
    async getSchoolsInDistrict(
      { commit, state },
      payload,
    ) {
      if (!state.overviewReportData.some((o) => o.Component === `${payload.reportType}_SchoolsInDistrict`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Overview.getSchoolsInDistrict(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          default:
            break;
        }
        commit('SET_OVERVIEW_REPORT_DATA', { Component: `${payload.reportType}_SchoolsInDistrict`, data: response });
      }
    },
    async getOverview({ commit, state }, payload) {
      if (!state.overviewReportData.some((o) => o.Component === `${payload.reportType}_OverviewData`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Overview.getOverview(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Overview.getOverview(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default:
            break;
        }
        commit('SET_OVERVIEW_REPORT_DATA', { Component: `${payload.reportType}_OverviewData`, data: response });
      }
    },
  },
};
