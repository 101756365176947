<template>
  <div>
    <b-container fluid>
      <b-row class="pt-4 d-print-none">
        <b-col>
          <section-header :id="AcadAchievementId" :header-text="ELASectionHeader" />
        </b-col>
      </b-row>
      <b-row class="pt-4 pb-4">
        <b-col cols="12" class="intro">
          <span v-html="AcadAchievementIntro" />
        </b-col>
      </b-row>
      <b-row class="pb-4">
        <b-col>
          <b-card
            v-if="!HideEnglishMathTrend"
            :id="`${EnglishMathTrendId}-print`"
            ref="EnglishMathTrend"
            :class="'outside-border'"
          >
            <b-card-header class="d-none d-print-block">
              {{ printDisplay }}
            </b-card-header>
            <b-row :id="EnglishMathTrendId">
              <b-col>
                <h3>{{ EnglishMathTrendTitle }}</h3>
              </b-col>
              <b-col cols="1" class="d-none d-lg-block">
                <a
                  class="small-icon d-print-none"
                  :title="EnglishMathTrendTitle"
                  @click="printELAMathTrends"
                  @keydown="printELAMathTrends"
                >
                  <font-awesome-icon :icon="['fas', 'download']">
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="EnglishMathTrendDescription" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1" class="d-print-none" />
              <b-col col md="5" sm="12" class="border-right-only mx-md-2">
                <visual-component
                  ref="ELATrends"
                  :visual-title="EnglishMathTrendELATitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="EnglishMathTrendELAData"
                />
              </b-col>
              <b-col cols="1" class="d-none d-print-block" />
              <b-col col md="5" sm="12" class="mx-md-2">
                <visual-component
                  ref="MathTrends"
                  :visual-title="EnglishMathTrendMathTitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="EnglishMathTrendMathData"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2021"
                  ref="ELAMathTable"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-footer="EnglishMathTrendPlusFooter"
                  :table-shading="EnglishMathTrendShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="EnglishMathTrendData"
                  :vertical-lines="['2020-21 ELA']"
                />
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2122"
                  ref="ELAMathTable"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-footer="EnglishMathTrendPlusFooter"
                  :table-shading="EnglishMathTrendShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="EnglishMathTrendData"
                  :vertical-lines="['2021-22 ELA']"
                />
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2223"
                  ref="ELAMathTable"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-footer="EnglishMathTrendPlusFooter"
                  :table-shading="EnglishMathTrendShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="EnglishMathTrendData"
                  :vertical-lines="['2022-23 ELA']"
                />
              </b-col>
            </b-row>
          </b-card>
          <visual-component
            v-if="$store.getters.SelectedSchoolYear.value >= 2122
              && !HideELAParticipationPerformance"
            :id="ELAParticipationPerformanceId"
            :visual-title="ELAParticipationPerformanceTitle"
            :visual-description="ELAParticipationPerformanceDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="ELAParticipationPerformanceData"
            :visual-footer="ELAParticipationPerformancePlusFooter"
            :horizontal-lines="['White', 'Blanco',
                                'Female', 'Femenino', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja',
                                'Students with Disabilities', 'Estudiantes con Discapacidades',
                                'English Learners', 'Estudiantes de inglés',
                                'Homeless Students', 'Estudiantes sin hogar']"
            :table-shading="ELAParticipationPerformanceTableShading"
          />
          <br />
          <!-- <visual-component
            :VisualTitle="EnglishMathTrendTitle"
            :visualDescription="EnglishMathTrendDescription"
            :ChartType="ChartTypes.TableGrid"
            :chartData="EnglishMathTrendData"
            v-if="HideEnglishMathTrend" /> -->
          <visual-component
            v-if="!HideEnglishPerformance"
            :id="EnglishPerformanceId"
            :visual-title="EnglishPerformanceTitle"
            :visual-description="EnglishPerformanceDescription"
            :chart-type="ChartTypes.TripleBarChart"
            :chart-data="EnglishPerformanceData"
            :chart-sub-title="EnglishPerformanceChartSubTitle"
          />
          <!-- ELA by Grade -->
          <b-row
            v-if="!HideELAPerfByGrade"
          >
            <b-col
              v-for="(grade, index) in ELAPerfByGradeData"
              :key="`${ELAPerfByGradeId}_${index}`"
              cols="12"
            >
              <visual-component
                :id="ELAPerfByGradeId"
                :visual-title="ELAPerfByGradeTitle(index)"
                :visual-description="ELAPerfByGradeDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
          <visual-component
            v-if="$store.getters.SelectedSchoolYear.value >= 2122
              && !HideMathPartPerf"
            :id="MathParticipationPerformanceId"
            :visual-title="MathParticipationPerformanceTitle"
            :visual-description="MathParticipationPerformanceDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="MathParticipationPerformanceData"
            :visual-footer="MathParticipationPerformancePlusFooter"
            :horizontal-lines="['White', 'Blanco',
                                'Female', 'Femenino', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja',
                                'Students with Disabilities', 'Estudiantes con Discapacidades',
                                'English Learners', 'Estudiantes de inglés',
                                'Homeless Students', 'Estudiantes sin hogar']"
            :table-shading="MathParticipationPerformanceTableShading"
          />
          <br />
          <!-- Percentage of Students Meeting/<br/>Exceeding Expectations -->
          <visual-component
            v-if="!HideMathPerformance"
            :id="MathPerformanceId"
            :visual-title="MathPerformanceTitle"
            :visual-description="MathPerformanceDescription"
            :chart-type="ChartTypes.TripleBarChart"
            :chart-data="MathPerformanceData"
            :chart-sub-title="MathPerfChartSubTitle"
            :visual-footer="MathPerformancePlusFooter"
          />
          <!-- Math by Grade
            v-if="!HideMathPerfByGrade"-->
          <b-row>
            <b-col
              v-for="(grade, index) in MathPerfByGradeData"
              :key="`${MathPerfByGradeId}_${index}`"
              cols="12"
            >
              <visual-component
                :id="MathPerfByGradeId(index)"
                :visual-title="MathPerfByGradeTitle(index)"
                :visual-description="MathPerfByGradeDescription(index)"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
          <!-- v-if="!HideMathPerfByTest" -->
          <b-row>
            <b-col
              v-for="(test, index) in MathPerfByTestData"
              :key="`${MathPerfByTestId}_${index}`"
              cols="12"
            >
              <visual-component
                :id="MathPerfByTestId"
                :visual-title="MathPerfByTestTitle(index)"
                :visual-description="MathPerfByTestDescription(index)"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="test"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with disabilities', 'Estudiantes con Discapacidades',
                                    'English learners', 'Estudiantes de inglés',
                                    'Homeless students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
          <b-container
            v-if="!HideDLMAssessmentParticipation
              && $store.getters.SelectedSchoolYear.value <= 1920"
            :id="DLMAssessmentParticipationId"
            fluid
          >
            <b-row class="pt-2">
              <b-col cols="12" class="outside-border pt-4">
                <h3 v-html="DLMAssessmentParticipationTitle" />
                <p class="p-1" v-html="DLMAssessmentParticipationDescription" />
              </b-col>
            </b-row>
          </b-container>
          <visual-component
            v-else-if="!HideDLMAssessmentParticipation
              && $store.getters.SelectedSchoolYear.value > 1920"
            :id="DLMAssessmentParticipationId"
            :visual-title="DLMAssessmentParticipationTitle"
            :visual-description="DLMAssessmentParticipationDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="DLMAssessmentParticipationData"
          />
          <br />
          <!-- v-if="!HideEnglishProficiency" -->
          <visual-component
            v-if="$store.getters.SelectedSchoolYear.value <= 2021"
            :id="EnglishProgressToProficiencyId"
            :visual-title="EnglishProgressToProficiencyTitle"
            :visual-description="EnglishProgressToProficiencyDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnglishProgressToProficiencyData"
          />
          <br />
          <visual-component
            v-if="!HideEnglishProficiencyTest
              && $store.getters.SelectedSchoolYear.value <= 2021"
            :id="EnglishProgressToProficiencyTestId"
            :visual-title="EnglishProgressToProficiencyTestTitle"
            :visual-description="EnglishProgressToProficiencyTestDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnglishProgressToProficiencyTestData"
          />
          <visual-component
            v-if="!HideEnglishProficiencyTest
              && $store.getters.SelectedSchoolYear.value > 2021"
            :id="EnglishProgressToProficiencyTestId"
            :visual-title="EnglishProgressToProficiencyTestTitle"
            :visual-description="EnglishProgressToProficiencyTestDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnglishProgressToProficiencyTestData"
          />
          <visual-component
            v-if="$store.getters.SelectedSchoolYear.value > 2021"
            :id="EnglishProgressToProficiencyId"
            :visual-title="EnglishProgressToProficiencyTitle"
            :visual-description="EnglishProgressToProficiencyDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="EnglishProgressToProficiencyData"
            :visual-footer="EnglishProfPlusFooter"
          />
          <br />
          <b-row>
            <b-col>
              <b-container
                v-if="!HideEnglishParticipationPerf
                  && $store.getters.SelectedSchoolYear.value <= 1920"
                :id="EnglishParticipationPerfId"
                fluid
              >
                <b-row class="pt-4">
                  <b-col cols="12" class="outside-border pt-4">
                    <h3 v-html="EnglishParticipationPerfTitle" />
                    <p class="p-1" v-html="EnglishParticipationPerfDescription" />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <br />
          <b-row v-if="!HideNJSLAScienceGradeSummary" class="pt-4 d-print-none">
            <b-col>
              <section-header
                :id="NJSLAScienceGradeSummaryId"
                :header-text="ScienceSectionHeader"
              />
            </b-col>
          </b-row>
          <div
            v-if="!HideNJSLAScienceGradeSummary"
            class="pt-4 intro"
            v-html="NJSLAScienceGradeIntro"
          />
          <b-row
            v-if="!HideNJSLAScienceGradeSummary"
          >
            <b-col
              v-for="(grade, index) in NJSLAScienceGradeSummaryData"
              :key="index"
              :lg="(12 / NJSLAScienceGradeSummaryData.length)"
              cols="12"
            >
              <visual-component
                :visual-title="NJSLAScienceGradeSummaryTitle(index)"
                :visual-description="NJSLAScienceGradeSummaryDescription"
                :chart-type="ChartTypes.StackedBarChart"
                :chart-data="grade"
              />
            </b-col>
            <!-- hidden for 2019-2020 -->
            <!-- <b-col>
              <visual-component
              :VisualTitle="NJSLAScienceGradeTitle"
              :visualDescription="NJSLAScienceGradeDescription"
              :ChartType="ChartTypes.TableGrid"
              :chartData="NJSLAScienceGradeData" />
            </b-col> -->
          </b-row>
          <!-- NJSLA by Grade Table -->
          <b-row
            v-if="!HideNJSLAPerfByGradeTable
              && $store.getters.SelectedSchoolYear.value >= 2122"
          >
            <b-col
              v-for="(grade, index) in NJSLAPerfByGradeTableData"
              :key="`${NJSLAPerfByGradeTableId}_${index}`"
              cols="12"
            >
              <visual-component
                :id="NJSLAPerfByGradeTableId"
                :visual-title="NJSLAPerfByGradeTableTitle(index)"
                :visual-description="NJSLAPerfByGradeTableDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
          <visual-component
            :id="NJGPA11Id"
            v-if="!HideNJGPA11"
            :visual-title="NJGPA11Title"
            :visual-description="NJGPA11Description"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="NJGPA11Data"
            :horizontal-lines="['White', 'Blanco',
                                'Female', 'Femenino', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja',
                                'Students with disabilities', 'Estudiantes con Discapacidades',
                                'English learners', 'Estudiantes de inglés',
                                'Homeless students', 'Estudiantes sin hogar']"
            :vertical-lines="['State % Graduation Ready: ELA',
                              'Estado % Listos para Graduación: ELA']"
          />
          <visual-component
            v-if="!HideNAEP"
            :id="NAEPId"
            :visual-title="NAEPTitle"
            :visual-description="NAEPDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="NAEPData"
          />
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
// eslint-disable-next-line import/no-cycle
import academic from '../../mixins/detailComponents/academic.mixin';
import translation from '../../mixins/translation-service.mixin';
import hidden from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolAcademic',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [translation, academic, hidden, inPageNav],
  data() {
    return {
      ChartTypes,
      printDisplay: '',
    };
  },
  methods: {
    printELAMathTrends() {
      this.$refs.EnglishMathTrend.style.width = '1008px';
      setTimeout(() => {
        this.$refs.ELATrends.enablePrintView();
        this.$refs.MathTrends.enablePrintView();
        this.$refs.ELAMathTable.enablePrintView();

        this.printDisplay = this.$refs.ELATrends.printDisplay;

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.EnglishMathTrendId}-print`);

          this.$refs.ELATrends.disablePrintView();
          this.$refs.MathTrends.disablePrintView();
          this.$refs.ELAMathTable.disablePrintView();
          this.$refs.EnglishMathTrend.style.width = '';
        });
      }, 100);
    },
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
}
@media (min-width: 768px) {
  .border-right-only{
    border-right: 2px solid #287AB8;
  }
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
</style>
