import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [navigation, mapping],
  computed: {
    ExpenditureData() {
      try {
        const storeData = this.$store.getters.PerPupilData.filter((o) => o.Component === `${this.ReportType}_PerPupilExpenditure`);
        if (!storeData
          || storeData.length === 0
          || !storeData[0].data
          || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return {
            Values: [],
            Labels: [],
            ColumnHeaders: [],
          };
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {
        Values: [],
        Labels: [],
        ColumnHeaders: [],
      };
    },
    PerPupilSectionHeader() {
      return this.translationService.getPerPupilExpTranslation(
        TranslationSvc.Constants.ComponentNames.ExpenditureTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ExpenditureId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.ExpenditureTitle,
      );
    },
    ExpenditureTitle() {
      return this.translationService.getPerPupilExpTranslation(
        TranslationSvc.Constants.ComponentNames.ExpenditureTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ExpenditureDescription() {
      return this.translationService.getPerPupilExpTranslation(
        TranslationSvc.Constants.ComponentNames.ExpenditureText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      ).replace('XXXX', this.$route.params.districtCd);
    },
    ExpenditureFooter() {
      return this.translationService.getPerPupilExpTranslation(
        TranslationSvc.Constants.ComponentNames.ExpenditureFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ExpenditureUrl() {
      return this.translationService.getPerPupilExpTranslation(
        TranslationSvc.Constants.ComponentNames.ExpenditureUrl,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      ).replace('XXXX', this.$route.params.districtCd);
    },
    schoolYear() {
      return this.$store.getters.SelectedSchoolYear.value;
    },
    reportType() {
      return this.$route.params.reportType;
    },
    HorizontalLineRows() {
      if (!this.ExpenditureData || !this.ExpenditureData.Labels
      || this.ExpenditureData.Labels.length === 0) {
        return [];
      }
      for (let index = 0; index < this.ExpenditureData.Labels.length; index += 1) {
        if (this.ExpenditureData.Labels[index] === 'District Level Central Expenditures') {
          if (index < this.ExpenditureData.Labels.length - 1) {
            return [this.ExpenditureData.Labels[index + 1]];
          }
          return [];
        }
      }
      return [];
    },
  },
  mounted() {
    // Dispatch methods to the store HERE
    this.fetchData();
  },
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };

      this.$store.dispatch('getPerPupilExpenditure', params);
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
};
