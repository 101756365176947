import vm from '../main';
import DistrictDetailTranslations from '../data/nj-perf-rep-lang-dist-det';
import SchoolDetailTranslations from '../data/nj-perf-rep-lang-sch-det';
import StateDetailTranslations from '../data/nj-perf-rep-lang-st';

function filterNav(value, index, self) {
  return self.map((o) => o.Component).indexOf(value) === index;
}
function getTranslation() {
  // This throws a NRE the first time through, not sure how to fix
  // as the reference to 'vm' is undefined and errors in an if
  // statement like if (!vm)
  if (!vm) { return {}; }
  switch (vm.$route.params.reportType.toLowerCase()) {
    case 'district':
      return DistrictDetailTranslations;
    case 'school':
      return SchoolDetailTranslations;
    case 'state':
      return StateDetailTranslations;
    default:
      return {};
  }
}
export default {
  District: {
    getNavigationItems(schoolYear) {
      const navItems = getTranslation().Navigation;
      if (!navItems) { return []; }

      navItems.filter((o) => o.schoolYear <= schoolYear)
        .sort((objA, objB) => objB.Year - objA.Year);

      navItems.filter(filterNav)
        .sort((objA, objB) => objA.SortOrder - objB.SortOrder);

      return navItems;
    },
    getSubNavigationItems(section, schoolYear, institutionInfo) {
      const allNavItems = getTranslation()[section]
        .filter((o) => o.Year <= schoolYear)
        .sort((objA, objB) => objB.Year - objA.Year);

      const navItems = [];
      allNavItems.forEach((item) => {
        if (!navItems.some((o) => o.Component === item.Component)) {
          navItems.push(item);
        }
      });
      const finalList = navItems
        .filter((obj) => obj.VisualId && obj.VisualId.length > 0)
        .sort((objA, objB) => objA.SortOrder - objB.SortOrder);

        // If ShowIfField AND ShowIfValue columns are present in the excel,
        // those conditions should be satisfied for the submenu items to show.
        // If the condtion is not satisfied, remove the submenu item from the list
        if (institutionInfo) {
          let i = finalList.length;
          // eslint-disable-next-line no-plusplus
          while (i--) {
            if (finalList[i].ShowIfField && finalList[i].ShowIfValue
              && institutionInfo[finalList[i].ShowIfField]) {
              if (institutionInfo[finalList[i].ShowIfField] !== finalList[i].ShowIfValue) {
                finalList.splice(i, 1);
              }
            }
          }
        }
      return finalList;
    },
  },
  getNavItem(routeName, schoolYear) {
    const navItems = getTranslation().Navigation
      .filter((o) => o.Component === routeName && o.Year <= schoolYear)
      .sort((objA, objB) => objB - objA);

    return navItems[0];
  },
};
