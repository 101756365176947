import TranslationSvc from '../../services/translation.service';
import navigation from '../navigation.mixin';
import mapping from '../mapping.mixin';

export default {
  mixins: [navigation, mapping],
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };
      this.$store.dispatch('getStudentGrowthTrendsELAMedian', params);
      this.$store.dispatch('getStudentGrowthTrendsMathMedian', params);
      this.$store.dispatch('getStudentGrowthTrendsMedian', params);
      this.$store.dispatch('getStudentGrowthTableData', params);
      this.$store.dispatch('getStudentGrowthByELAPerfLevelData', params);
      this.$store.dispatch('getStudentGrowthByMathPerfLevelData', params);
      this.$store.dispatch('getStudentGrowthByELAGradeData', params);
      this.$store.dispatch('getStudentGrowthByMathGradeData', params);
    },
  },
  computed: {
    StudentGrowthSectionHeader() {
      return this.translationService.getStudentGrowthTranslation(
        'Std-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    StdGrowthId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdHeader,
      );
    },
    StdGrowthIntro() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthTrendId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.StdGrowthTrendTitle);
    },
    StdGrowthTrendTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthTrendTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthTrendDescription() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthTrendText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthELATrendDescription() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthTrendText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthELATrendId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdHeader,
      );
    },
    StdGrowthELATrendTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.ELAMedianStudentGrowthChartTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthELATrendData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthTrendsELAMedian`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const mappedObj = {
        Labels: [],
        Values: [],
        ColumnHeaders: [],
      };

      mappedObj.Labels = storeObject.columnHeaders
        .filter((o) => o.includes('ELA'))
        .map((o) => o.replace(' ELA', ''));

      let i;
      const dataValues = [];
      const dataStrings = [];
      for (i = 0; i < mappedObj.Labels.length; i += 1) {
        dataValues.push(+storeObject.values[i][0][0]);
        dataStrings.push(storeObject.values[i][0][0]);
      }
      mappedObj.Values.push({
        fill: false,
        data: dataValues,
        dataAsString: dataStrings,
        label: 'ELA',
        backgroundColor: '#317bb4',
        borderColor: '#426580',
        lineTension: 0,
        spanGaps: false,
      });

      return mappedObj;
    },
    StdGrowthMathTrendTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.MathMedianStudentGrowthChartTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthMathTrendData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthTrendsMathMedian`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const mappedObj = {
        Labels: [],
        Values: [],
        ColumnHeaders: [],
      };

      mappedObj.Labels = storeObject.columnHeaders
        .filter((o) => o.includes('Math') || o.includes('Matemáticas'))
        .map((o) => o.replace(' Math', '').replace(' Matemáticas', ''));

      let i;
      const dataValues = [];
      const dataStrings = [];
      for (i = 0; i < mappedObj.Labels.length; i += 1) {
        dataValues.push(+storeObject.values[i][0][0]);
        dataStrings.push(storeObject.values[i][0][0]);
      }
      mappedObj.Values.push({
        fill: false,
        data: dataValues,
        dataAsString: dataStrings,
        label: 'Math',
        backgroundColor: '#317bb4',
        borderColor: '#426580',
        lineTension: 0,
        spanGaps: false,
      });

      return mappedObj;
    },
    StdGrowthTrendData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthTrendsMedian`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    StdGrowthTrendTableShading() {
      return [
        {
          cellValues: ['Met Standard', 'Cumplió el Estándar', 'Cumplió el Estándar',
            'Met Goal', 'Objetivo Cumplido', 'Cumplir Estándar', 'Met Target†', 'Objetivo cumplido†',
            'Met Target', 'Objetivo cumplido'],
          cellVariant: 'success',
        },
        {
          cellValues: ['Not Met', 'Objetivo no Cumplido', 'No se cumplió', 'No Cumplido'],
          cellVariant: 'danger',
        },
        {
          cellValues: ['Exceeds Standard', 'Exceder el Estándar', 'Excede el Estándar'],
          cellVariant: 'info',
        },
        {
          cellValues: ['NA', 'N/A', 'N', '*'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    StudentGrowthId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.StdGrowthTitle);
    },
    StdGrowthTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthDescription() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthTableData`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }
      const storeObject = storeObjects[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    StdGrowthTableShading() {
      return [
        {
          cellValues: ['Met Standard', 'Cumplió el Estándar', 'Cumplió el Estándar',
            'Met Goal', 'Objetivo Cumplido', 'Cumplir Estándar', 'Met Target†', 'Objetivo cumplido†',
            'Met Target', 'Objetivo cumplido'],
          cellVariant: 'success',
        },
        {
          cellValues: ['Not Met', 'Objetivo no Cumplido', 'No se cumplió', 'No Cumplido'],
          cellVariant: 'danger',
        },
        {
          cellValues: ['Exceeds Standard', 'Exceder el Estándar', 'Excede el Estándar'],
          cellVariant: 'info',
        },
        {
          cellValues: ['NA', 'N/A'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    StdGrowthPerfId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.StdGrowthPerfTitle);
    },
    StdGrowthPerfTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthPerfTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthPerfDescription() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthPerfText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthPerfChartTitleEla() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthPerfChartTitleEla,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthPerfChartTitleMath() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthPerfChartTitleMath,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthELAPerfLevelData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthELAPerfLevel`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);

      let i;
      let j;
      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Values[j].data.length; i++) {
          if (responseObject.Values[j].dataAsString[i].toString().indexOf('Low') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Bajo') >= 0) {
              responseObject.Values[j].backgroundColor.push('#c6dcf1');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('Typical') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Típico') >= 0) {
              responseObject.Values[j].backgroundColor.push('#5a9bd5');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('High') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Alto') >= 0) {
            responseObject.Values[j].backgroundColor.push('#1e4b75');
          } else {
            // when no sgp level -> it is either N or *- > treat like 0
            responseObject.Values[j].backgroundColor.push('#c6dcf1');
          }
        }
      }

      // mappedObj.Values.push({
      //   fill: false,
      //   data: dataValues,
      //   dataAsString: dataStrings,
      //   // dataAsString: dataStrings,
      //   backgroundColor: bgColors, // '#317bb4',
      //   borderColor: '#426580',
      //   lineTension: 0,
      //   spanGaps: false,
      // });

      return responseObject;
    },
    StdGrowthMathPerfLevelData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthMathPerfLevel`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);

      let i;
      let j;
      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Values[j].data.length; i++) {
          if (responseObject.Values[j].dataAsString[i].toString().indexOf('Low') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Bajo') >= 0) {
              responseObject.Values[j].backgroundColor.push('#c6dcf1');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('Typical') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Típico') >= 0) {
              responseObject.Values[j].backgroundColor.push('#5a9bd5');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('High') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Alto') >= 0) {
            responseObject.Values[j].backgroundColor.push('#1e4b75');
          } else {
            // when no sgp level -> it is either N or *- > treat like 0
            responseObject.Values[j].backgroundColor.push('#c6dcf1');
          }
        }
      }
      // mappedObj.Values.push({
      //   fill: false,
      //   data: dataValues,
      //   dataAsString: dataStrings,
      //   backgroundColor: bgColors, // '#317bb4',
      //   borderColor: '#426580',
      //   lineTension: 0,
      //   spanGaps: false,
      // });
      return responseObject;
    },
    StdGrowthMedianSubTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.MedianSubTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthgradeId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.StdGrowthgradeTitle);
    },
    StdGrowthgradeTitle() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthgradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthgradeDescription() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthgradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthgradeChartTitleEla() {
      // elaa
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthgradeChartTitleEla,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthgradeChartTitleMath() {
      return this.translationService.getStudentGrowthTranslation(
        TranslationSvc.Constants.ComponentNames.StdGrowthgradeChartTitleMath,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdGrowthELAGradeData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthELAGrade`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);

      let i;
      let j;

      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Values[j].data.length; i++) {
          if (responseObject.Values[j].dataAsString[i].toString().indexOf('Low') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Bajo') >= 0) {
              responseObject.Values[j].backgroundColor.push('#c6dcf1');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('Typical') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Típico') >= 0) {
              responseObject.Values[j].backgroundColor.push('#5a9bd5');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('High') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Alto') >= 0) {
            responseObject.Values[j].backgroundColor.push('#1e4b75');
          } else {
            // when no sgp level -> it is either N or *- > treat like 0
            responseObject.Values[j].backgroundColor.push('#c6dcf1');
          }
        }
      }

      // mappedObj.Values.push({
      //   fill: false,
      //   data: dataValues,
      //   dataAsString: dataStrings,
      //   backgroundColor: bgColors, // '#317bb4',
      //   borderColor: '#426580',
      //   lineTension: 0,
      //   spanGaps: false,
      // });

      // return mappedObj;
      return responseObject;
    },
    StdGrowthMathGradeData() {
      const storeObjects = this.$store.getters.GrowthReportData.filter((o) => o.Component === `${this.ReportType}_StudentGrowthMathGrade`);
      if (!storeObjects || storeObjects.length === 0) {
        return {};
      }

      const storeObject = storeObjects[0].data;
      const responseObject = this.MapData(storeObject, this.ChartTypes);

      let i;
      let j;

      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Values[j].data.length; i++) {
          if (responseObject.Values[j].dataAsString[i].toString().indexOf('Low') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Bajo') >= 0) {
              responseObject.Values[j].backgroundColor.push('#c6dcf1');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('Typical') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Típico') >= 0) {
              responseObject.Values[j].backgroundColor.push('#5a9bd5');
          } else if (responseObject.Values[j].dataAsString[i].toString().indexOf('High') >= 0
          || responseObject.Values[j].dataAsString[i].toString().indexOf('Alto') >= 0) {
            responseObject.Values[j].backgroundColor.push('#1e4b75');
          } else {
            // when no sgp level -> it is either N or *- > treat like 0
            responseObject.Values[j].backgroundColor.push('#c6dcf1');
          }
        }
      }

      // mappedObj.Values.push({
      //   fill: false,
      //   data: dataValues,
      //   dataAsString: dataStrings,
      //   backgroundColor: bgColors, // '#317bb4',
      //   borderColor: '#426580',
      //   lineTension: 0,
      //   spanGaps: false,
      // });

      // return mappedObj;
      return responseObject;
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
