import axios from 'axios';

const LOCAL_STORAGE_USER_TOKEN = 'user-token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh-token';
const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_TOKEN_EXPIRES = 'token_expire';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const loginUrl = `${rootUrl}api/auth/login`;
const refreshUrl = `${rootUrl}api/auth/refreshtoken`;

export default {
  refreshUrl() {
    return refreshUrl;
  },
  authUrl() {
    return loginUrl;
  },
  loginUser(userName, password) {
    const data = {
      username: userName,
      password,
    };

    return axios({
      method: 'post',
      url: loginUrl,
      data,
      headers: {
        'content-type': 'application/json',
      },
    }).then((response) => {
      localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, response.data.jwtToken);
      localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, response.data.refreshToken);
      localStorage.setItem(LOCAL_STORAGE_TOKEN, response.data.jwtToken);
      const dt = new Date();
      const expireDt = new Date(
        dt.setMinutes(dt.getMinutes() + (response.data.expires_In / 60) - 5),
      );
      localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRES, expireDt);
      return true;
    }).catch((error) => {
      console.log(error);
      return false;
    });
  },
  refreshToken(originalRequest) {
    const data = {
      jwtToken: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN),
      refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
    };

    return axios({
      method: 'post',
      url: refreshUrl,
      data,
      headers: {
        'content-type': 'application/json',
      },
    }).then((response) => {
      localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, response.data.refreshToken);
      localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, response.data.jwtToken);
      localStorage.setItem(LOCAL_STORAGE_TOKEN, response.data.jwtToken);
      const dt = new Date();
      const expireDt = new Date(
        dt.setMinutes(dt.getMinutes() + (response.data.expires_In / 60) - 5),
      );
      localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRES, expireDt);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.jwtToken}`;
      return axios(originalRequest);
    });
  },
  getToken() {
    return localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);
  },
  isAuthenticated() {
    if (localStorage.getItem(LOCAL_STORAGE_USER_TOKEN)) {
      return true;
    }
    return false;
  },
  logout() {
    localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  },
};
