import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';
import schoolTemplate from '../template-code.mixin';

export default {
  mixins: [mapping, navigation, schoolTemplate],
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };

      this.$store.dispatch('getDistrictAssessmentsParticipation', params);
      this.$store.dispatch('getDistrictAssessmentsPerformance', params);
      this.$store.dispatch('getDistrictAPIB', params);
      this.$store.dispatch('getDistrictAPIBCoursesOffered', params);
      this.$store.dispatch('getDistrictDualEnrollment', params);
      this.$store.dispatch('getDistrictDualEnrollStdtGrp', params);
      this.$store.dispatch('getDistrictCTEParticipation', params);
      this.$store.dispatch('getDistrictCTEStdGroup', params);
      this.$store.dispatch('getDistrictCTEValuedCred', params);
      this.$store.dispatch('getDistrictCTEValuedCareer', params);
      this.$store.dispatch('getDistrictCourseParticipationMath', params);
      this.$store.dispatch('getDistrictCourseParticipationScience', params);
      this.$store.dispatch('getDistrictCourseParticipationHistory', params);
      this.$store.dispatch('getDistrictCourseParticipationLanguage', params);
      this.$store.dispatch('getDistrictCourseParticipationIT', params);
      this.$store.dispatch('getDistrictSealBiliteracy', params);
      this.$store.dispatch('getStructuredLearningExpParticipation', params);
      this.$store.dispatch('getWorkBasedLearning', params);
    },
  },
  computed: {
    AssessmentsSectionHeader() {
      return this.translationService.getCollegeReadinessTranslation(
        'Assessments-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AdvancedSectionHeader() {
      return this.translationService.getCollegeReadinessTranslation(
        'Advanced-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTESectionHeader() {
      return this.translationService.getCollegeReadinessTranslation(
        'CTE-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationSectionHeader() {
      return this.translationService.getCollegeReadinessTranslation(
        'CoursePart-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    AssessmentsHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AssessmentsHeader,
      );
    },
    AssessmentsIntro() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.AssessmentsIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AssessmentsParticipationId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AssessmentsParticipationTitle,
      );
    },
    AssessmentsParticipationTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.AssessmentsParticipationTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AssessmentsParticipationDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.AssessmentsParticipationText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AssessmentsParticipationData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_AssessmentsParticipation`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    AssessmentsPerformanceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AssessmentsPerformanceTitle,
      );
    },
    AssessmentsPerformanceTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.AssessmentsPerformanceTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AssessmentsPerformanceDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.AssessmentsPerformanceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AssessmentsPerformanceData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_AssessmentsPerformance`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    AdvancedHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AdvancedHeader,
      );
    },
    APIBIntro() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    APIBId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.APIBCourseworkTitle,
      );
    },
    APIBTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBCourseworkTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    APIBDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBCourseworkText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    APIBIData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_APIB`);
        let tmp;
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const dataArray = [];
        const storeObject = storeObjects[0].data;
        if (this.ReportType !== 'state') {
          for (let i = 0; i <= storeObject.length - 1; i += 1) {
            tmp = {
              id: `key${i}`,
              Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
              Values: [{
                backgroundColor: ['#5b9bd5', '#44546a'],
                pointBorderColor: '#249EBF',
                data: [+storeObject[i].data.data1.replace('%', '').replace('*', '').replace('N/A', '').replace('NA', '')
                  .replace('N', ''),
                +storeObject[i].data.data2.replace('%', '').replace('*', '').replace('N/A', '').replace('NA', '')
                  .replace('N', '')],
                dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
                label: storeObject[i].name,
              }],
              ChartSubTitle: storeObject[i].name,
            };
            dataArray.push(tmp);
          }
        } else {
          for (let i = 0; i <= storeObject.length - 1; i += 1) {
            tmp = {
              id: `key${i}`,
              Labels: [storeObject[i].data.header1],
              Values: [{
                backgroundColor: ['#44546a'],
                pointBorderColor: '#249EBF',
                data: [+storeObject[i].data.data1.replace('%', '').replace('*', '')
                  .replace('N/A', '').replace('NA', '')
                  .replace('N', '')],
                dataAsString: [storeObject[i].data.data1],
                label: storeObject[i].name,
              }],
              ChartSubTitle: storeObject[i].name,
            };
            dataArray.push(tmp);
          }
        }
        return dataArray;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    APIBCoursesOfferedId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.APIBCoursesOfferedTitle,
      );
    },
    APIBCoursesOfferedTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBCoursesOfferedTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    APIBCoursesOfferedDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBCoursesOfferedText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    APIBCoursesOfferedShading() {
      return [
        {
          cellValues: ['NA'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    APIBCoursesOfferedData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_APIBCoursesOffered`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    DualEnrollStdtGrpTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.DualEnrollStudentGroupTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DualEnrollStdtGrpId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DualEnrollStudentGroupTitle,
      );
    },
    DualEnrollStdtGrpDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.DualEnrollStudentGroupText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DualEnrollStdtGrpData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_DualEnrollStdtGrp`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    DualEnrollmentId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.APIBDualCourseworkTitle,
      );
    },
    DualEnrollmentTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBDualCourseworkTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DualEnrollmentDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.APIBDualCourseworkText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DualEnrollmentData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_DualEnrollment`);
        const chartObj = {};

        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        if (this.ReportType !== 'state') {
          chartObj.Labels = [storeObject.data.header1, storeObject.data.header2];
          chartObj.Values = [
            {
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', ''), +storeObject.data.data2.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', '')],
              dataAsString: [storeObject.data.data1, storeObject.data.data2],
              label: storeObject.name,
            },
          ];
        } else {
          chartObj.Labels = [storeObject.data.header1];
          chartObj.Values = [
            {
              backgroundColor: ['#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', '')],
              dataAsString: [storeObject.data.data1],
              label: storeObject.name,
            },
          ];
        }
        return chartObj;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CTEHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEHeader,
      );
    },
    CTEProgramsIntro() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEProgramsIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEParticipationId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEParticipationTitle,
      );
    },
    CTEParticipationTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEParticipationTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEParticipationDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEParticipationText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEParticipationData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEParticipation`);
        let tmp;
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const dataArray = [];
        const storeObject = storeObjects[0].data;
        if (this.ReportType !== 'state') {
          for (let i = 0; i <= storeObject.length - 1; i += 1) {
            tmp = {
              id: `key${i}`,
              description: storeObject[i].description,
              Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
              Values: [{
                backgroundColor: ['#5b9bd5', '#44546a'],
                pointBorderColor: '#249EBF',
                data: [+storeObject[i].data.data1.replace('%', '').replace('*', '')
                  .replace('N/A', '').replace('NA', '')
                  .replace('N', ''),
                +storeObject[i].data.data2.replace('%', '').replace('*', '')
                  .replace('N/A', '').replace('NA', '')
                  .replace('N', '')],
                dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
                label: storeObject[i].name,
              }],
              ChartSubTitle: storeObject[i].name,
            };
            dataArray.push(tmp);
          }
        } else {
          for (let i = 0; i <= storeObject.length - 1; i += 1) {
            tmp = {
              id: `key${i}`,
              description: storeObject[i].description,
              Labels: [storeObject[i].data.header1],
              Values: [{
                backgroundColor: ['#44546a'],
                pointBorderColor: '#249EBF',
                data: [+storeObject[i].data.data1.replace('%', '').replace('*', '')],
                dataAsString: [storeObject[i].data.data1],
                label: storeObject[i].name,
              }],
              ChartSubTitle: storeObject[i].name,
            };
            dataArray.push(tmp);
          }
        }
        return dataArray;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StructuredLearningExpParticipationId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEStructuredLearningTitle,
      );
    },
    StructuredLearningExpParticipationTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEStructuredLearningTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StructuredLearningExpParticipationDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEStructuredLearningText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StructuredLearningExpParticipationData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_StructuredLearningExpParticipation`);
        const chartObj = {};

        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;

        if (this.ReportType !== 'state') {
          chartObj.Labels = [storeObject.data.header1, storeObject.data.header2];
          chartObj.Values = [
            {
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', ''), +storeObject.data.data2.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', '')],
              dataAsString: [storeObject.data.data1, storeObject.data.data2],
              label: storeObject.name,
              ChartSubTitle: storeObject.name,
            },
          ];
        } else {
          chartObj.Labels = [storeObject.data.header1];
          chartObj.Values = [
            {
              backgroundColor: ['#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '').replace('NA', '').replace('N/A', '')
                .replace('N', '')],
              dataAsString: [storeObject.data.data1],
              label: storeObject.name,
              ChartSubTitle: storeObject.name,
            },
          ];
        }
        chartObj.ChartSubTitle = storeObject.name;

        return chartObj;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CTEStdGroupId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEStdGroupTitle,
      );
    },
    CTEStdGroupTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEStdGroupTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEStdGroupDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEStdGroupText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEStdGroupData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEStGroup`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    WorkBasedLearningId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.WorkBasedLearningTitle,
      );
    },
    WorkBasedLearningTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.WorkBasedLearningTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    WorkBasedLearningDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.WorkBasedLearningText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    WorkBasedLearningData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_WorkBasedLearning`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CTEValuedCredId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredTitle,
      );
    },
    CTEValuedCredTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEValuedCredDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEValuedCredData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEValuedCred`);
        const chartObj = {};

        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        if (this.ReportType !== 'state') {
          chartObj.Labels = [storeObject.data.header1, storeObject.data.header2];
          chartObj.Values = [
            {
              backgroundColor: ['#5b9bd5', '#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', ''),
              +storeObject.data.data2.replace('%', '').replace('*', '')
                .replace('N/A', '').replace('NA', '')
                .replace('N', '')],
              dataAsString: [storeObject.data.data1, storeObject.data.data2],
              label: storeObject.name,
            },
          ];
        } else {
          chartObj.Labels = [storeObject.data.header1];
          chartObj.Values = [
            {
              backgroundColor: ['#44546a'],
              pointBorderColor: '#249EBF',
              data: [+storeObject.data.data1.replace('%', '').replace('*', '')],
              dataAsString: [storeObject.data.data1],
              label: storeObject.name,
            },
          ];
        }

        chartObj.ChartSubTitle = storeObject.name;

        return chartObj;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CTEValuedCredCareerId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredCareerTitle,
      );
    },
    CTEValuedCredCareerTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredCareerTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEValuedCredCareerDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CTEValuedCredCareerText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CTEValuedCredCareerData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEValuedCareer`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CoursePartHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CoursePartHeader,
      );
    },
    CourseParticipationIntro() {
      if (this.institutionOffersGrade('9')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('12')) {
        return this.translationService.getCollegeReadinessTranslation(
          TranslationSvc.Constants.ComponentNames.CourseParticipationIntro912,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationIntroPK08,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationMathId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationMathTitle,
      );
    },
    CourseParticipationMathTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationMathTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationMathDescription() {
      if (this.institutionOffersGrade('9')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('12')) {
        return this.translationService.getCollegeReadinessTranslation(
          TranslationSvc.Constants.ComponentNames.CourseParticipationMath912Text,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationMathPK08Text,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationMathData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationMath`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    ParticipationShading() {
      return [
        {
          cellValues: ['NA', 'N/A'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    CourseParticipationScienceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationScienceTitle,
      );
    },
    CourseParticipationScienceTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationScienceTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationScienceDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationScienceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationScienceData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationScience`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CourseParticipationHistoryId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationHistoryTitle,
      );
    },
    CourseParticipationHistoryTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationHistoryTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationHistoryDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationHistoryText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationHistoryData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationHistory`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CourseParticipationLanguagesId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationLanguagesTitle,
      );
    },
    CourseParticipationLanguagesTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationLanguagesTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationLanguagesDescription() {
      if (this.institutionOffersGrade('9')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('12')
        || this.ReportType === 'state') {
        return this.translationService.getCollegeReadinessTranslation(
          TranslationSvc.Constants.ComponentNames.CourseParticipationLanguagesText912,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationLanguagesTextPK08,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationLanguagesData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationLanguage`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    CourseParticipationITId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.CourseParticipationITTitle,
      );
    },
    CourseParticipationITTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationITTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationITDescription() {
      if (this.$store.getters.SelectedSchoolYear.value <= '2122') {
        return this.translationService.getCollegeReadinessTranslation(
          TranslationSvc.Constants.ComponentNames.CourseParticipationITText,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      if (this.institutionOffersGrade('9')
        || this.institutionOffersGrade('10')
        || this.institutionOffersGrade('11')
        || this.institutionOffersGrade('12')
        || this.ReportType === 'state') {
        return this.translationService.getCollegeReadinessTranslation(
          TranslationSvc.Constants.ComponentNames.CourseParticipationITText912,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.CourseParticipationITTextPK08,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    CourseParticipationITData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationIT`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    SealBiliteracyId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SealBiliteracyTitle,
      );
    },
    SealBiliteracyTitle() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.SealBiliteracyTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SealBiliteracyDescription() {
      return this.translationService.getCollegeReadinessTranslation(
        TranslationSvc.Constants.ComponentNames.SealBiliteracyText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SealBiliteracyData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_SealBiliteracy`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    BiliteracyTableShading() {
      return [
        {
          cellValues: ['NA', 'N'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
