import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const userInfoUrl = `${rootUrl}api/cds/`;

export default {
  async getUserInfo() {
    const response = await axios.get(`${userInfoUrl}UserInfo`);

    return response.data;
  },
};
