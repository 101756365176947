/* eslint no-underscore-dangle: 0 */
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    DataLabels: {
      default: () => ['Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10'],
    },
    DataSets: {
      default: () => [{
        fill: false,
        label: '2019-20',
        data: [18, 20, 37, 47],
        backgroundColor: '#5997e3',
        borderColor: '#287AB8',
      }],
    },
  },
  data() {
    return {
      datacollection: {
        labels: this.$props.DataLabels,
        datasets: this.$props.DataSets,
      },
    };
  },
  computed: {
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
            },
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            offset: true,
            ticks: {
              fontSize: 16,
              fontColor: '#000',
              beginAtZero: false,
              min: 0,
              max: 100,
            },
            gridLines: {
              display: true,
              lineWidth: 1,
              color: '#FFFFFF',
              zeroLineWidth: 1,
            },
          }],
          yAxes: [{
            ticks: {
              fontSize: 16,
              beginAtZero: false,
              min: 0,
              max: 100,
              stepSize: 20,
            },
            gridLines: {
              display: true,
              lineWidth: 1,
              color: '#FFFFFF',
              zeroLineWidth: 1,
            },
          }],
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: this.isPrintView ? 0 : 1,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = '12px Roboto';
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const data = dataset.data[index];
                const dataStr = dataset.dataAsString[index];
                // const x = bar._model.x + 20;
                // eslint-disable-next-line prefer-destructuring
                const x = bar._model.x;
                const y = bar._model.y + (data > 90 ? 25 : -5);
                ctx.fillText(dataStr, x, y);
              });
            });
          },
        },
      };
    },
  },
  methods: {
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
  },
  mounted() {
    this.$refs.canvas.height = 250;
    this.$refs.canvas.width = 250;
    // renderChart function renders the chart with the datacollection and options object.
    this.renderChart(this.datacollection, this.options);
  },
};
