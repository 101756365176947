<template>
  <div class="page">
    <b-container fluid="sm">
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col offset="1" cols="5" offset-md="3" md="3" offset-lg="4" lg="2">
            Username:
          </b-col>
          <b-col cols="5" md="3" lg="2">
            <b-form-input id="user_name" v-model="userName" placeholder="Username" />
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1" cols="5" offset-md="3" md="3" offset-lg="4" lg="2">
            Password:
          </b-col>
          <b-col cols="5" md="3" lg="2">
            <b-form-input
              id="password"
              v-model="password"
              placeholder="Password"
              type="password"
              @keypress.enter="handleLogin"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1" cols="10" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-button
              id="login_button"
              class="login-btn button"
              variant="success"
              @click="handleLogin"
            >
              Login
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>

<script>
import LoginService from '../services/login.service';

export default {
  name: 'LoginView',
  data() {
    return {
      userName: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;
      LoginService.loginUser(this.userName, this.password)
        .then((response) => {
          this.loading = false;
          this.userName = '';
          this.password = '';
          if (response) {
            // Redirect to Home
            this.$store.dispatch('getCurrentSchoolYears', 'override');
            this.$store.dispatch('getUserInfo');
            this.$router.push({
              name: 'Home',
            });
          } else {
            this.$bvToast.toast('Invalid Username or Password', {
              title: 'Invalid Login',
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
            });
          }
        });
      return '';
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 15px;
}
.row {
  padding-top: 5px;
}
.button {
  width: 100px;
}
.login-btn {
}
</style>
