<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col col lg="8" md="8" sm="12">
          <!-- <b-row>
                <b-col col sm="12">
                  <h1>
                    {{ this.$route.params.schoolYear }} {{ NameOfSchool }} Performance Reports
                  </h1>
                </b-col>
            </b-row> -->
          <b-row class="">
            <b-col cols="12" md="2" class="pr-md-4">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="../assets/prlogo.png"
                  style="height:50px"
                  alt="NJ School Performance Reports Logo"
                  class="pt-2"
                >
              </router-link>
            </b-col>
            <b-col cols="12" md="10" class="pl-md-4 text-lg-left">
              <h1>
                {{ $route.params.schoolYear }} {{ NameOfSchool }} Performance Reports
              </h1>
            </b-col>
          </b-row>
          <b-row v-if="noReportsToDisplay">
            <b-col>
              <h2>No Historical Reports Found</h2>
            </b-col>
          </b-row>
          <b-row v-if="showEnglishDetailReport || showEnglishSummaryReport">
            <b-col col sm="12" lg="4" md="4">
              <h2>
                Reports In English
              </h2>
            </b-col>
            <b-col col sm="12" lg="2" md="2">
              <div class="btn-group" role="group">
                <a
                  :href="detailReportURL"
                  class="button"
                  target="_blank"
                  rel="noopener noreferrer">
                  <button
                    v-if="showEnglishDetailReport"
                    type="button">
                    Detailed Report
                  </button>
                </a>
                <a
                  :href="summaryReportURL"
                  class="button"
                  target="_blank"
                  rel="noopener noreferrer">
                  <button
                    v-if="showEnglishSummaryReport"
                    type="button">
                    Summary Report
                  </button>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="showSpanishDetailReport || showSpanishSummaryReport">
            <b-col col sm="12" lg="4" md="4">
              <h2>
                Translated Reports (in Spanish)
              </h2>
            </b-col>
            <b-col col sm="12" lg="2" md="2">
              <div class="btn-group" role="group">
                <a
                  :href="detailReportESPURL"
                  target="_blank"
                  rel="noopener noreferrer">
                  <button
                    v-if="showSpanishDetailReport"
                    type="button">
                    Informe Detallado
                  </button>
                </a>
                <a
                  :href="summaryReportESPURL"
                  target="_blank"
                  rel="noopener noreferrer">
                  <button
                    v-if="showSpanishSummaryReport"
                    type="button">
                    Informe Resumido
                  </button>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="false && (showSpanishDetailReport || showSpanishSummaryReport)" col sm="12">
              <h2>
                Translated Resources
              </h2>
              <ul>
                <li>
                  <!-- <a :href="`${documents}` + shortschoolYear +`/ReferenceGuideSpanish.pdf`"
                          target="_blank">
                          Reference Guide (Guía de Referencia: Detallado)</a>
                    -->
                  <a
                    href="/Documents/ReferenceGuideSpanish.pdf"
                    target="_blank"
                  >
                    Reference Guide (Guía de Referencia: Detallado)</a>
                </li>
                <li>
                  <!-- <a :href="`${documents}` + shortschoolYear +`/FAQsSpanish.pdf`"
                          target="_blank">
                          FAQ (Preguntas Frecuentes)</a> -->
                  <a
                    href="/Documents/FAQsSpanish.pdf"
                    target="_blank"
                  >
                    FAQ (Preguntas Frecuentes)</a>
                </li>
                <li v-if="!isStateReport">
                  <!-- <a :href="`${documents}` + shortschoolYear +`/SummaryReportSpanish.pdf`"
                          target="_blank">
                          Summary Report Guide (Guía de Referencia: Resumen)</a> -->
                  <a
                    href="/Documents/SummaryReportSpanish.pdf"
                    target="_blank"
                  >
                    Summary Report Guide (Guía de Referencia: Resumen)</a>
                </li>
                <li>
                  <!-- <a :href="`${documents}` + shortschoolYear +`/DataPrivacyRulesSpanish.pdf`"
                        target="_blank">
                      Privacy Rules (Reglas de Privacidad de Datos)
                  </a> -->
                  <a
                    href="/Documents/DataPrivacyRulesSpanish.pdf"
                    target="_blank"
                  >
                    Privacy Rules (Reglas de Privacidad de Datos)
                  </a>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row class="questions">
            <b-col>
              <p>
                <strong>Questions</strong> about reports? Contact
                <a href="mailto:reportcard@doe.nj.gov">reportcard@doe.nj.gov</a>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="4" md="4" sm="12">
          <b-row>
            <b-col>
              <omni-search :search-type="'short'" />
            </b-col>
          </b-row>
          <br />
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              County: {{ NameOfCounty }}
              <br />
              District: {{ NameOfDistrict }}
            </b-col>
          </b-row>
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              <b-row>
                <b-col cols="1">
                  <i class="fas fa-map-marker-alt" />
                </b-col>
                <b-col style="text-align: left;">
                  <span v-html="LocationAddress" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <br />
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              <span>
                {{ PRPrincipal }}: {{ SchoolPrincipal }}
              </span>
              <br />
              <a
                :href="SchoolWebsite"
                target="_blank"
                rel="noopener noreferrer">
                {{ OrganizationType }} Website</a>
              <br />
              <a :href="`tel:+${ SchoolPhone }`" class="phone">
                <b-row>
                  <b-col cols="1">
                    <i class="fas fa-phone-alt" />
                  </b-col>
                  <b-col>
                    {{ SchoolPhone }}
                  </b-col>
                </b-row>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <br />
              <div id="map" ref="map" style="width:100%;height:400px;">
                <iframe
                  height="270"
                  :src="MapUrl"
                  title="Map" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OmniSearch from '../components/OmniSearch.vue';
import TranslationService from '../services/translation.service';
import fileService from '../services/file.service';

export default {
  name: 'ReportSelection',
  components: {
    OmniSearch,
  },
  data() {
    return {
      showEnglishDetailReport: false,
      showEnglishSummaryReport: false,
      showSpanishDetailReport: false,
      showSpanishSummaryReport: false,
    };
  },
  computed: {
    noReportsToDisplay() {
      return !this.showEnglishDetailReport
        && !this.showEnglishSummaryReport
        && !this.showSpanishDetailReport
        && !this.showSpanishSummaryReport;
    },
    isStateReport() {
      return !this.$route.params.countyCd;
    },
    isDistrictReport() {
      return !this.$route.params.schoolCd;
    },
    isSchoolReport() {
      return this.$route.params.schoolCd;
    },
    NameOfCounty() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.countyName;
      }
      return this.$store.getters.CurrentDistrictInfo.countyName;
    },
    NameOfDistrict() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.districtName;
      }
      return this.$store.getters.CurrentDistrictInfo.districtName;
    },
    NameOfSchool() {
      if (this.$route.params.schoolCd) {
        return `${this.$store.getters.CurrentSchoolInfo.schoolName} (${this.$store.getters.CurrentSchoolInfo.countyCode}-${this.$store.getters.CurrentSchoolInfo.districtCode}-${this.$store.getters.CurrentSchoolInfo.schoolCode})`;
      }
      if (!this.$route.params.countyCd) {
        return 'New Jersey';
      }
      return `${this.$store.getters.CurrentDistrictInfo.districtName} (${this.$store.getters.CurrentDistrictInfo.countyCode}-${this.$store.getters.CurrentDistrictInfo.districtCode})`;
    },
    LocationAddress() {
      let address;
      if (this.$route.params.schoolCd) {
        const schoolInfo = this.$store.getters.CurrentSchoolInfo;
        if (!schoolInfo.address1) {
          return {};
        }
        address = schoolInfo.address1;
        if (schoolInfo.address2.trim().length > 0) {
          address = `${address}<br/>${schoolInfo.address2}`;
        }
        return `${address}<br/>${schoolInfo.city}, ${schoolInfo.state} ${schoolInfo.zip}`;
      }
      const districtInfo = this.$store.getters.CurrentDistrictInfo;
      if (!districtInfo.address1) {
        return {};
      }
      address = districtInfo.address1;
      if (districtInfo.address2.trim().length > 0) {
        address = `${address}<br/>${districtInfo.address2}`;
      }
      return `${address}<br/>${districtInfo.city}, ${districtInfo.state} ${districtInfo.zip}`;
    },
    SchoolPrincipal() {
      if (this.$route.params.schoolCd) {
        return `${this.$store.getters.CurrentSchoolInfo.principal}`;
      }
      return `${this.$store.getters.CurrentDistrictInfo.superintendent}`;
    },
    PRPrincipal() {
      if (this.$route.params.schoolCd) {
        return 'Principal';
      }
      return 'Superintendent';
    },
    SchoolWebsite() {
      if (this.$route.params.schoolCd) {
        return `${this.$store.getters.CurrentSchoolInfo.website}`;
      }
      return `${this.$store.getters.CurrentDistrictInfo.website}`;
    },
    SchoolPhone() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.phoneNumber;
      }
      return this.$store.getters.CurrentDistrictInfo.phoneNumber;
    },
    OrganizationType() {
      if (this.$route.params.schoolCd) {
        return 'School';
      }
      return 'District';
    },
    MapUrl() {
      let leo = {};

      if (this.$route.params.schoolCd) {
        leo = this.$store.getters.CurrentSchoolInfo;
      } else {
        leo = this.$store.getters.CurrentDistrictInfo;
      }

      if (!leo.address1) {
        return 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=New+Jersey';
      }

      const address = `${leo.address1.replace(' ', '+')},${leo.city.replace(' ', '+')},${leo.state.replace(' ', '+')}+${leo.zip.replace(' ', '+')}`;

      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=${address}`;
    },
    detailReportURL() {
      let urlToFile;
      if (this.isStateReport) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_STATE}${this.$route.params.schoolYear}/st.pdf`;
      } else if (!this.$route.params.schoolCd) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_DISTRICT}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}.pdf`;
      } else {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_SCHOOL}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-${this.$route.params.schoolCd}.pdf`;
      }
      return urlToFile;
    },
    detailReportESPURL() {
      let urlToFile;
      if (this.isStateReport) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_STATE}${this.$route.params.schoolYear}/st-esp.pdf`;
      } else if (!this.$route.params.schoolCd) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_DISTRICT}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-esp.pdf`;
      } else {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_SCHOOL}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-${this.$route.params.schoolCd}-esp.pdf`;
      }
      return urlToFile;
    },
    summaryReportURL() {
      let urlToFile;
      if (this.isStateReport) {
        urlToFile = '';
      } else if (!this.$route.params.schoolCd) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_DISTRICT_SUMMARY}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}.pdf`;
      } else {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_SCHOOL_SUMMARY}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-${this.$route.params.schoolCd}.pdf`;
      }
      return urlToFile;
    },
    summaryReportESPURL() {
      let urlToFile;
      if (this.isStateReport) {
        urlToFile = '';
      } else if (!this.$route.params.schoolCd) {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_DISTRICT_SUMMARY}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-esp.pdf`;
      } else {
        urlToFile = `${process.env.VUE_APP_HISTORICAL_SCHOOL_SUMMARY}${this.$route.params.schoolYear}/${this.$route.params.countyCd}-${this.$route.params.districtCd}-${this.$route.params.schoolCd}-esp.pdf`;
      }
      return urlToFile;
    },
    selectedSchoolYear() {
      return this.$store.getters.SelectedSchoolYear;
    },
    shortschoolYear() {
      return this.$store.getters.SelectedSchoolYear.value;
    },
    documents() {
      return process.env.VUE_APP_DOCUMENTS;
    },
    dataFiles() {
      return process.env.VUE_APP_DATA_FILES;
    },
    referenceGuide() {
      return process.env.VUE_APP_REFERENCE_GUIDE;
    },
  },
  watch: {
    selectedSchoolYear(newValue) {
      if (!newValue) {
        console.log('School Year set to undefined');
        return;
      }
      if (newValue.historical) {
        if (newValue.value <= 1415) {
          this.$router.push({
            name: 'Home',
          });
        } else {
          this.$router.push({
            name: this.$route.name,
            params: {
              schoolYear: newValue.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
            },
          });
          this.fetchData();
        }
      } else {
        this.$router.push({
          name: 'ReportSelect',
          params: {
            schoolYear: newValue.text,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            schoolCd: this.$route.params.schoolCd,
          },
        });
        this.fetchData();
      }
    },
  },
  mounted() {
    // const schoolYear = this.$store.getters.CurrentSchoolYears.filter(
    //   (sy) => sy.text === this.$route.params.schoolYear,
    // );
    // this.$store.dispatch('setSelectedSchoolYear', schoolYear.value);
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('flushReportData');
      if (!this.$route.params.countyCd) {
        this.$store.dispatch(
          'getStateDetailHeader',
          {
            schoolYear: this.$route.params.schoolYear,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
      } else if (!this.$route.params.schoolCd) {
        this.$store.dispatch(
          'getDistrictDetailHeader',
          {
            schoolYear: this.$route.params.schoolYear,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
      } else {
        this.$store.dispatch(
          'getSchoolDetailHeader',
          {
            schoolYear: this.$route.params.schoolYear,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            schoolCd: this.$route.params.schoolCd,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
      }
      this.showDetailReportURL();
      this.showDetailReportESPURL();
      this.showSummaryReportURL();
      this.showSummaryReportESPURL();
    },
    async showDetailReportURL() {
      const fileExists = await fileService.fileExists(this.detailReportURL);
      this.showEnglishDetailReport = fileExists;
    },
    async showDetailReportESPURL() {
      const fileExists = await fileService.fileExists(this.detailReportESPURL);
      this.showSpanishDetailReport = fileExists;
    },
    async showSummaryReportESPURL() {
      if (this.summaryReportESPURL.length === 0) {
        this.showSpanishSummaryReport = false;
        return;
      }
      const fileExists = await fileService.fileExists(this.summaryReportESPURL);
      this.showSpanishSummaryReport = fileExists;
    },
    async showSummaryReportURL() {
      if (this.summaryReportURL.length === 0) {
        this.showEnglishSummaryReport = false;
        return;
      }
      const fileExists = await fileService.fileExists(this.summaryReportURL);
      this.showEnglishSummaryReport = fileExists;
    },
    ShowPrincipal() {
      return this.OrganizationType === 'School';
    },
    navigate(routeName, params, language) {
      this.$store.dispatch('flushReportData');
      this.$store.dispatch('setCurrentLanguage', language);

      this.$router.push({
        name: routeName,
        params,
      });
    },
    EnglishDetailed() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      if (this.isStateReport) params.reportType = 'state';
      this.navigate('Overview', params, TranslationService.Constants.LanguageOptions.English);
    },
    EnglishSummary() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      this.navigate('SummaryReport', params, TranslationService.Constants.LanguageOptions.English);
    },
    SpanishDetailed() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      if (this.isStateReport) params.reportType = 'state';
      this.navigate('Overview', params, TranslationService.Constants.LanguageOptions.Spanish);
    },
    SpanishSummary() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      this.navigate('SummaryReport', params, TranslationService.Constants.LanguageOptions.Spanish);
    },
  },
};
</script>

<style scoped>
h1{
    text-align: left;
    padding: 20px;
    font-size: 22px;
}
h2{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding: 20px;
}
li{
    text-align: left;
}
button{
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    background-color: #008000;
    color: #FFF;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px rgba(0,0,0,0.5);
    width: auto;
    min-width: 170px;
    height: auto;
    margin: 5px;
}
button a{
  color: #fff;
}
button:hover{
    background-color: #A9D08E;
    color: #000;
    border: 1px solid #008000;
}
.search-box {
  font-size: 18px;
  color: #7B7474;
  font-weight: 600;
  height: auto;
}
.school-info{
    text-align: left;
    font-size: 14px;
    line-height: 22px;
}
.phone{
  color: black;
  text-decoration: none;
}
.map{
  height: 600px;
  background: gray;
}
.questions {
  padding-top: 35px;
  padding-left: 20px;
}
@media only screen and (max-width: 768px) {
  button{
    width: auto;
    min-width: 150px;
    height: auto;
  }
}
</style>
