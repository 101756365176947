import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    accountabilityReportData: [],
  },
  getters: {
    AccountabilityReportData: (state) => state.accountabilityReportData,
  },
  mutations: {
    SET_ACCOUNTABILITY_REPORT_DATA(state, payload) {
      const component = state.accountabilityReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.accountabilityReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getESSAAccountabilityStatus(
      { commit, state },
      payload,
    ) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_AccountabilityStatus`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Detail.Accountability
              .getESSAAccountabilityStatus(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            break;
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_AccountabilityStatus`, data: response });
      }
    },
    async getESSAAccountabilityProgress(
      { commit, state },
      payload,
    ) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_AccountabilityProgress`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Accountability
              .getESSAAccountabilityProgress(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Accountability
              .getESSAAccountabilityProgress(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Accountability
              .getESSAAccountabilityProgress(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_AccountabilityProgress`, data: response });
      }
    },
    async getComprehensiveOrTargettedSupport(
      { commit, state },
      payload,
    ) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_ComprehensiveOrTargettedSupport`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Accountability.getComprehensiveOrTargettedSupport(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
          // response=await schoolService.Detail.Accountability.getComprehensiveOrTargettedSupport(
          //   payload.schoolYear,
          //   payload.countyCd, payload.districtCd, payload.schoolCd,
          //   payload.langCd,
          // );
            response = {};
            break;
          default: // state
            response = await stateService.Detail.Accountability.getComprehensiveOrTargettedSupport(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_ComprehensiveOrTargettedSupport`, data: response });
      }
    },
    async getESSAAccountabilitySummative(
      { commit, state },
      payload,
    ) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_AccountabilitySummative`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Detail.Accountability
              .getESSAAccountabilitySummative(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
          // this is only available for school reports
          //   response = await schoolService.Detail.Accountability
          //     .getESSAAccountabilitySummative(
          //       payload.schoolYear,
          //       payload.countyCd, payload.districtCd, payload.schoolCd,
          //       payload.langCd,
          //     );
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_AccountabilitySummative`, data: response });
      }
    },
    async getSummativeFooter({ commit, state }, payload) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_SummativeFooter`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Detail.Accountability.getESSASummativeFooter(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default:
            break;
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_SummativeFooter`, data: response });
      }
    },
    async getESSAAccountabilitySummary(
      { commit, state },
      payload,
    ) {
      if (!state.accountabilityReportData.some((o) => o.Component === `${payload.reportType}_AccountabilitySummary`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Accountability
              .getESSAAccountabilitySummary(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Accountability
              .getESSAAccountabilitySummary(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            // this data is only available for school and district reports
            // response = await schoolService.Detail.Accountability
            //   .getESSAAccountabilitySummary(
            //     payload.schoolYear,
            //     payload.countyCd, payload.districtCd, payload.schoolCd,
            //     payload.langCd,
            //   );
            break;
        }
        commit('SET_ACCOUNTABILITY_REPORT_DATA', { Component: `${payload.reportType}_AccountabilitySummary`, data: response });
      }
    },
  },
};
