import { render, staticRenderFns } from "./Demographics.vue?vue&type=template&id=4b9b615e&scoped=true"
import script from "./Demographics.vue?vue&type=script&lang=js"
export * from "./Demographics.vue?vue&type=script&lang=js"
import style0 from "./Demographics.vue?vue&type=style&index=0&id=4b9b615e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9b615e",
  null
  
)

export default component.exports