import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const summaryUrl = `${rootUrl}api/districtsummary`;
const detailHeaderUrl = `${rootUrl}api/districtdetailed/header`;
const detailOverviewUrl = `${rootUrl}api/Districtdetailed/overview`;
const detailAcademicUrl = `${rootUrl}api/districtdetailed/AcademicAchievement`;
const detailAccountabilityUrl = `${rootUrl}api/districtdetailed/Accountability`;
const detailClimateUrl = `${rootUrl}api/districtdetailed/Climate`;
const detailCollegeAndCareerReadinessUrl = `${rootUrl}api/districtdetailed/CollegeAndCareerReadiness`;
const detailDemographicsUrl = `${rootUrl}api/districtdetailed/Demographics`;
const detailGradUrl = `${rootUrl}api/districtdetailed/Grad`;
const detailStaffUrl = `${rootUrl}api/districtdetailed/Staff`;
const detailStudentGrowthUrl = `${rootUrl}api/districtdetailed/StudentGrowth`;
const detailNarrativeUrl = `${rootUrl}api/districtdetailed/Narrative`;
const detailPerPupilUrl = `${rootUrl}api/districtdetailed/PerPupil`;

export default {
  Summary: {
    async getHeader(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/header/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getDistrictHighlights(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/districtHighlights/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getStudentGrowthCompareELA(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/StudentGrowthComparisonELA/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentGrowthCompareMath(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/StudentGrowthComparisonMath/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsGraduatingFourYear(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/AreStudentsGraduatingFourYear/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsGraduatingFiveYear(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/AreStudentsGraduatingFiveYear/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentsOnAssessmentsELA(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/StudentPerformanceELA/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getStudentsOnAssessmentsMath(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/StudentPerformanceMaths/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return {
        labels: response.data.data.map((o) => o.column1Data),
        values: [
          [response.data.data.map((o) => o.column2Data)],
        ],
        columnHeaders: [
          response.data.columns.column1Name,
          response.data.columns.column2Name,
        ],
      };
    },
    async getAreStudentsAtRisk(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/AreStudentsAtRisk/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
    async getAreStudentsCollegeAndCareerReady(schoolYear, countyCode, districtCode, languageCode) {
      const response = await axios.get(`${summaryUrl}/AreStudentsCollegeAndCareerReady/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
  },
  Detail: {
    Header: {
      async getHeader(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailHeaderUrl}/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return response.data;
      },
    },
    Overview: {
      async getOverviewTypeAndContactInformation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailOverviewUrl}/ContactInformation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getSchoolsInDistrict(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailOverviewUrl}/SchoolsinthisDistrict/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
          // Translate Response to DataSet objects for VisualComponent
          // create link to school details from the API response data
        return {
          labels: response.data.data.map((o) => `<a href="/${schoolYear}/school/detail/${o.column3Data}/${o.column4Data}/${o.column5Data}?lang=${languageCode}" target="_blank" title="Link to ${o.column1Data} detail report">${o.column1Data}</a>`),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getOverview(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailOverviewUrl}/Overview/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );

        return response.data;
      },
    },
    Academic: {
      // eslint-disable-next-line max-len
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsELA/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            [response.data.data.map((o) => o.column1Data)],
          ],
        };
      },
      // eslint-disable-next-line max-len
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsMath/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            [response.data.data.map((o) => o.column1Data)],
          ],
        };
      },
      async getEnglishLanguageArtsandMathematicsPerformanceTrend(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getELAParticipationPerformance(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentParticipationAndPerformance/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getEnglishLanguageArtsAssessmentPerformanceTrends(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsAssessmentPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getELAPerformanceByGradeX(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentPerformanceByGradeX/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathParticipationPerformance(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentParticipationAndPerformance/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getMathematicsAssessmentPerformanceTrends(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathematicsAssessmentPerformanceTrends/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getMathPerformanceByGradeX(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByGradeX/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathPerformanceByTestX(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByTestX/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((test) => {
          returnData.push({
            test: test.data[0].column1Data,
            labels: test.data.map((o) => o.column2Data),
            values: [
              [test.data.map((o) => o.column3Data)],
              [test.data.map((o) => o.column4Data)],
              [test.data.map((o) => o.column5Data)],
              [test.data.map((o) => o.column6Data)],
              [test.data.map((o) => o.column7Data)],
              [test.data.map((o) => o.column8Data)],
              [test.data.map((o) => o.column9Data)],
              [test.data.map((o) => o.column10Data)],
              [test.data.map((o) => o.column11Data)],
              [test.data.map((o) => o.column12Data)],
              [test.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              test.columns.column2Name,
              test.columns.column3Name,
              test.columns.column4Name,
              test.columns.column5Name,
              test.columns.column6Name,
              test.columns.column7Name,
              test.columns.column8Name,
              test.columns.column9Name,
              test.columns.column10Name,
              test.columns.column11Name,
              test.columns.column12Name,
              test.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAScienceAssessmentGradeXSummary(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentGradeXSummary/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        /* ******************************************
        * This is a stacked bar chart
        * 4 levels
        * y-axis %
        * x-axis chart label
        * legend
        ********************************************* */
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAPerformanceByGradeXTable(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentTableByGradeX/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNationalAssessmentEducationalProgressNAEP(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NationalAssessmentEducationalProgressNAEP/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getDLMAssessment(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/DLMAlternateAssessmentParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getEnglishProficiencyTest(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishProgressToProf(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELLProgressToProficiency/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishParticipationPerf(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getNJGPA11(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJGPAGrade11/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
    },
    Accountability: {
      async getESSAAccountabilityProgress(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        try {
          const response = await axios.get(`${detailAccountabilityUrl}/ESSAAccountabilityProgress/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
          return {
            labels: response.data.data.map((o) => o.column1Data),
            values: [
              [response.data.data.map((o) => o.column2Data)],
              [response.data.data.map((o) => o.column3Data)],
              [response.data.data.map((o) => o.column4Data)],
            ],
            columnHeaders: [
              response.data.columns.column1Name,
              response.data.columns.column2Name,
              response.data.columns.column3Name,
              response.data.columns.column4Name,
            ],
          };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
      async getComprehensiveOrTargettedSupport(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailAccountabilityUrl}/ComprehensiveOrTargettedSupport/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => `<a href="/${schoolYear}/school/detail/${o.column1Data}/${o.column3Data}/${o.column5Data}?lang=${languageCode}" target="_blank" title="Link to ${o.column6Data} detail report">${o.column6Data}</a>`)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
            [response.data.data.map((o) => o.column10Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
            response.data.columns.column10Name,
          ],
        };
      },
      async getESSAAccountabilitySummary(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        try {
          const response = await axios.get(`${detailAccountabilityUrl}/SummaryByStudentGroup/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
          return {
            labels: response.data.data.map((o) => o.column1Data),
            values: [
              [response.data.data.map((o) => o.column2Data)],
              [response.data.data.map((o) => o.column3Data)],
              [response.data.data.map((o) => o.column4Data)],
              [response.data.data.map((o) => o.column5Data)],
              [response.data.data.map((o) => o.column6Data)],
              [response.data.data.map((o) => o.column7Data)],
              [response.data.data.map((o) => o.column8Data)],
              [response.data.data.map((o) => o.column9Data)],
              [response.data.data.map((o) => o.column10Data)],
              [response.data.data.map((o) => o.column11Data)],
              [response.data.data.map((o) => o.column12Data)],
              [response.data.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              response.data.columns.column1Name,
              response.data.columns.column2Name,
              response.data.columns.column3Name,
              response.data.columns.column4Name,
              response.data.columns.column5Name,
              response.data.columns.column6Name,
              response.data.columns.column7Name,
              response.data.columns.column8Name,
              response.data.columns.column9Name,
              response.data.columns.column10Name,
              response.data.columns.column11Name,
              response.data.columns.column12Name,
              response.data.columns.column13Name,
            ],
          };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
    },
    Climate: {
      async getAbsentChronic(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/ChronicAbsenteeism/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getDaysAbsent(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/DaysAbsent/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getAbsentChronicGrade(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/ChronicAbsenteeismByGrade/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getViolenceVandalismHIBandSubstanceOffenses(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/ViolenceVandalismHIBandSubstanceOffenses/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getPoliceNotifications(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/PoliceNotifications/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getHarassmentIntimidationandBullyingHIBInvestigations(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/HarassmentIntimidationandBullyingHIBInvestigations/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovals(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/StudentDisciplinaryRemovals/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovalsSchoolDaysMissed(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/StudentDisciplinaryRemovalsSchoolDaysMissed/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
      async getDeviceRatios(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailClimateUrl}/DeviceRatios/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
    },
    College: {
      async getPSATSATACTParticipation(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/PSATSATACTParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getPSATSATACTPerformance(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/PSATSATACTPerformance/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
          ],
        };
      },
      async getAPIBCourseworkParticipationandPerformance(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/APIBCourseworkParticipationandPerformance/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getAPIBCoursesOffered(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/APIBCoursesOffered/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDualEnrollmentCourseworkParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/DualEnrollmentCourseworkParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getDualEnrollStdtGrp(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/ApIbDualEnrlPartByStudGroup/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getStructuredLearningExpParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/StructuredLearningExpParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/CareerandTechnicalEducationParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipationbyStudentGroup(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/CareerandTechnicalEducationParticipationbyStudentGroup/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getWorkBasedLearning(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/WorkBasedParticipationByCareerCluster/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getIndustryValuedCredentials(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/IndustryValuedCredentials/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationValuedCareer(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/IndustryValuedCredentialsbyCareerCluster/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getVisualandPerformingArtsCourseParticipation6to8(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation6To8/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation6to8Donut(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtSchool/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      } return {};
      },
      async getVisualandPerformingArtsCourseParticipation6to8DonutState(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtState/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      }
        return {};
      },
      async getVisualandPerformingArtsCourseParticipation9to12(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation9To12/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation9to12Donut(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtSchool/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
} return {};
      },
      async getVisualandPerformingArtsCourseParticipation9to12DonutState(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtState/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        if (response.data.columns.column1Name) {
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      } return {};
      },
      async getMathematicsCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/MathematicsCourseParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        if (response.data.data.length <= 0 || response.data.columns.length <= 0) {
          return {};
        }
        // eslint-disable-next-line no-unused-vars
        const retLabels = response.data.data.map((o) => o.column1Data);
        const retvalues = [];
        for (let i = 2; i <= 8; i += 1) {
          if (response.data.data[0][`column${i}Data`]) {
            retvalues.push(response.data.data.map((o) => o[`column${i}Data`]));
          }
        }
        const retHeaders = [];
        for (let j = 1; j <= 8; j += 1) {
          if (response.data.columns[`column${j}Name`]) {
            retHeaders.push(response.data.columns[`column${j}Name`]);
          }
        }
        return {
          labels: retLabels,
          values: retvalues,
          columnHeaders: retHeaders,
        };
      },
      async getScienceCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/ScienceCourseParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getSocialStudiesandHistoryCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/SocialStudiesandHistoryCourseParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getWorldLanguagesCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/WorldLanguagesCourseParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getComputerScienceandInformationTechnologyCourseParticipation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/ComputerScienceandInformationTechnologyCourseParticipation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getSealofBiliteracy(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailCollegeAndCareerReadinessUrl}/SealofBiliteracy/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
    },
    Demographics: {
      async getEnrollmentTrendsByGrade(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(
          `${detailDemographicsUrl}/EnrollmentTrendsByGrade/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByStudentGroup(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailDemographicsUrl}/EnrollmentTrendsByStudentGroup/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByRacialAndEthnicGroup(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailDemographicsUrl}/EnrollmentTrendsByRacialAndEthnicGroup/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullHalfDayPkAndKg(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailDemographicsUrl}/EnrollmentTrendsByFullHalfDayPkAndKg/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullAndSharedTimeStatus(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailDemographicsUrl}/EnrollmentTrendsByFullAndSharedTimeStatus/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          // "labels" are descriptors appearing first ie "English", "Spanish", etc.
          labels: response.data.data.map((o) => o.column1Data),
          // "values" for the percentages/numbers associated with the "labels" ie 50%, 60%, etc
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          // main table headers
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentByHomeLanguage(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailDemographicsUrl}/EnrollmentByHomeLanguage/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [response.data.data.map((o) => o.column2Data)],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Grad: {
      async getGraduationRateTrendsVisual4Year(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/GraduationRateTrendsVisual4Year/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrendsVisual5Year(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/GraduationRateTrendsVisual5Year/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrends(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/GraduationRateTrends/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort4YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/Cohort4YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort5YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/Cohort5YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort6YearGraduationCohortProfile(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/Cohort6YearGraduationCohortProfile/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getFederalGraduationRates(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/FederalGraduationRate/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getAccountabilityGraduationRates(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/AccountabilityGraduationRates/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getGraduationPathways(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/GraduationPathways/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDropoutRateTrends(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/DropoutRateTrends/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryVisual(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        if (response.data.columns.column5Name === undefined) {
          return {
            labels: [response.data.columns.column3Name,
              response.data.columns.column4Name],
            values: [
              [response.data.data[1].column3Data, response.data.data[1].column4Data],
              [response.data.data[0].column3Data, response.data.data[0].column4Data],
              [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
                `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`],
            ],
            columnHeaders: [
              response.data.columns.column2Name,
              response.data.data[1].column2Data,
              response.data.data[0].column2Data,
              response.data.data[2].column2Data,
            ],
          };
        }
        return {
          labels: [response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name],
          values: [
            [response.data.data[1].column3Data, response.data.data[1].column4Data,
              response.data.data[1].column5Data],
            [response.data.data[0].column3Data, response.data.data[0].column4Data,
              response.data.data[0].column5Data],
            [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column5Data}`],
          ],
          columnHeaders: [
            response.data.columns.column2Name,
            response.data.data[1].column2Data,
            response.data.data[0].column2Data,
            response.data.data[2].column2Data,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryTable(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRatesFall(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/PostsecondaryEnrollmentRatesFall/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRates16month(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/PostsecondaryEnrollmentRates16month/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getApprenticeship(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailGradUrl}/Apprenticeship/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Staff: {
      async getTeachersExperience(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStaffUrl}/TeachersExperience/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getAdministratorsExperienceDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/AdministratorsExperienceDistrictLevel/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStaffCountsDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/StaffCounts/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentandStaffRatios(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/StudentandStaffRatios/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getTeachersandAdministratorsDemographics(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/TeachersandAdministratorsDemographics/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getTeachersandAdministratorsLevelofEducation(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/TeachersandAdministratorsLevelofEducation/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        /* ********************************
        * This is a horizontal bar chart with a name,
        * headers, and data
        ********************************** */
        return response.data;
      },
      async getTeachersandAdministratorsOneYearRetentionDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/TeachersandAdministratorsOneYearRetentionDistrictLevel/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getTeacherSubjectAreaDistrictLevel(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailStaffUrl}/TeachersBySubjectArea/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
            [response.data.data.map((o) => o.column10Data)],
            [response.data.data.map((o) => o.column11Data)],
            [response.data.data.map((o) => o.column12Data)],
            [response.data.data.map((o) => o.column13Data)],
            [response.data.data.map((o) => o.column14Data)],
            [response.data.data.map((o) => o.column15Data)],
            [response.data.data.map((o) => o.column16Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
            response.data.columns.column10Name,
            response.data.columns.column11Name,
            response.data.columns.column12Name,
            response.data.columns.column13Name,
            response.data.columns.column14Name,
            response.data.columns.column15Name,
            response.data.columns.column16Name,
          ],
        };
      },
    },
    Narrative: {
      // async getNarrativeByCategory(schoolYear, countyCode, districtCode, category,
      //   languageCode) {
      // eslint-disable-next-line max-len
      //   const response = await axios.get(`${detailNarrativeUrl}/NarrativeByCategory/
      // ${schoolYear}/${countyCode}/${districtCode}/${category}?lang=${languageCode}`);
      //   return response.data;
      // },
      async getNarratives(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(`${detailNarrativeUrl}/Narrative/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return response.data;
      },
    },
    StudentGrowth: {
      async getStudentGrowthELATrendsMedian(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthTrendsandProgressELAMedian/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthMathTrendsMedian(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthTrendsandProgressMathMedian/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthTrendsMedian(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthTrendsandProgress/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);

        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getStudentGrowthTableData(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowth/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);

        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getStudentGrowthELAPerfLevel(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthByPerfLevelELA/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthMathPerfLevel(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthByPerfLevelMath/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthELAGrade(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthByGradeELA/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthMathGrade(schoolYear, countyCode, districtCode, languageCode) {
        const response = await axios.get(`${detailStudentGrowthUrl}/StudentGrowthByGradeMath/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`);
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      mergeColumnData(column2Data, column3Data) {
        const isNumber = column2Data && (!Number.isNaN(parseFloat(column2Data))
          || Number.isFinite(column2Data));
        if (isNumber) {
          if (column3Data) {
          return `${column3Data}<br/>${column2Data}`;
          }
        }
        return `${column2Data}`;
      },
    },
    PerPupil: {
      async getPerPupilExpenditure(
        schoolYear,
        countyCode,
        districtCode,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailPerPupilUrl}/PerPupilExpenditures/${schoolYear}/${countyCode}/${districtCode}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
    },
  },
};
