<template>
  <div>
    <div class="container-fluid w-100">
      <header class="row justify-content-center">
        <div id="logo" class="col-sm-8 col-lg-6 col-xl-5">
          <h1 class="my-2">
            <img
              src="https://www.nj.gov/nj/design/images/state_seal_white.png"
              alt="State of New Jersey Seal"
              title="Official State of New Jersey seal"
            />
            Official Site of The State of New Jersey
          </h1>
        </div>
        <div class="col-sm-4 col-lg-6 col-xl-5">
          <div class="text-right d-none d-lg-block">
            <a
              href="https://nj.gov/governor/"
              target="_blank"
              rel="noopener noreferrer">Governor Phil Murphy &bull; Lt. Governor Sheila Oliver</a>
          </div>
          <ul class="my-sm-2 my-lg-0 text-right nav-list">
            <li class="d-none d-lg-inline">
              <a href="https://nj.gov">NJ.gov</a>
            </li>
            <li class="d-none d-lg-inline">
              <a href="https://nj.gov/nj/gov/njgov/alphaserv.html">Services</a>
            </li>
            <li class="d-none d-lg-inline">
              <a href="https://nj.gov/nj/gov/deptserv/">Agencies</a>
            </li>
            <li class="d-none d-lg-inline">
              <a href="https://nj.gov/faqs/">FAQs</a>
            </li>
            <li class="popup-item">
              <span
                aria-label="translate.google.com"
                @click="toggleTranslate"
                @keydown="toggleTranslate">
                Translate</span>
              <div v-show="showTranslate" class="translate-popup px-4 pb-3">
                <button
                  type="button"
                  class="pl-0 btn btn-link float-left"
                  @click="toggleTranslate"
                  @keydown="toggleTranslate"
                >
                  <i class="fas fa-times-circle" /> close
                </button>
                <div id="google_translate_element" />
                <p class="pt-3">
                  The State of NJ site may contain optional links, information, services and/or
                  content from other websites operated by third parties that are provided as a
                  convenience, such as Google™ Translate. Google™ Translate is an online service
                  for which the user pays nothing to obtain a purported language translation. The
                  user is on notice that neither the State of NJ site nor its operators review any
                  of the services, information and/or content from anything that may be linked to
                  the State of NJ site for any reason. -
                  <a
                    href="https://nj.gov/nj/home/disclaimer.html"
                    target="_blank"
                    rel="noopener noreferrer">
                    <strong>
                      Read Full Disclaimer <i class="fas fa-link" />
                    </strong>
                  </a>
                </p>
              </div>
            </li>
            <li class="popup-item">
              <span
                @click="toggleSearch"
                @keydown="toggleSearch">
                Search <i class="fas fa-search" /></span>
              <div v-show="showSearch" class="search-popup px-4 pb-3">
                <button
                  type="button"
                  class="pl-0 btn btn-link float-left"
                  @click="toggleSearch"
                >
                  <i class="fas fa-times-circle" /> close
                </button>
                <div class="input-group">
                  <input
                    v-model="searchString"
                    type="text"
                    class="form-control p-1"
                    placeholder="Type Search Here"
                    aria-label="Search Query"
                    aria-describedby="search-label"
                    @keyup.enter="navigateSearch"
                  >
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="input-group-text"
                      @click="navigateSearch"
                    >
                      <i class="fas fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StateHeader',
  data() {
    return {
      showTranslate: false,
      showSearch: false,
      searchString: '',
    };
  },
  computed: {
  },
  mounted() {
    const googleCallBack = document.createElement('script');
    googleCallBack.setAttribute('type', 'text/javascript');
    googleCallBack.innerText = 'function googleTranslateElementInit() { console.log("Binding Translate"); new google.translate.TranslateElement({pageLanguage: "en", layout: google.translate.TranslateElement.InlineLayout.VERTICAL}, "google_translate_element");}';
    const googleScript = document.createElement('script');
    googleScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.head.appendChild(googleCallBack);
    document.head.appendChild(googleScript);
  },
  methods: {
    toggleTranslate() {
      this.showSearch = false;
      this.showTranslate = !this.showTranslate;
    },
    toggleSearch() {
      this.showTranslate = false;
      this.showSearch = !this.showSearch;
    },
    navigateSearch() {
      const searchURL = `https://www.nj.gov/search/?qt=${this.searchString}`;
      window.location = searchURL;
    },
  },
};
</script>

<style scoped>
@import "https://www.nj.gov/nj/design/css/bootstrap.min.css";
@import "https://www.nj.gov/nj/design/css/sonj-components.css";
@import "https://www.nj.gov/nj/design/css/site-base.css";
@import "https://www.nj.gov/nj/design/util/slick/slick.css";
@import "https://www.nj.gov/nj/design/util/slick/slick-theme.css";

.full-width {
  padding: 0px;
  margin: 0;
  width: 99%;
  background: #fff;
  box-sizing: border-box;
  display: block;
}
header, header a, header span {
  color: #fff;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  text-align: left;
}
header span {
  cursor: pointer;
}
header p, header p a {
  font-size: 12px;
}
header p {
  float: left;
  clear: left;
  width: 100%;
  text-align: justify;
  font-weight: 300;
}
header {
  background-color: #363636;
  padding: 4px 0;
  z-index: 1;
  margin-bottom: 0px;
}
#logo h1 {
  line-height: 1;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
}
#google_translate_element {
  float: left;
  clear: left;
  width: 100%;
}
img {
  margin: 0 5px 0 0px;
  max-height: 41px;
  display: inline-block;
}
ul {
  margin: 0px;
  position: relative;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0 1em;
}
li {
  border-right: 1px solid #fff;
  margin: 0;
  padding: 0 6px;
}
li:last-of-type {
  border-right: none;
}
ul.nav-list li a, ul.nav-list li span {
  font-size: 14px;
  font-weight: 300;
}
.translate-popup {
  position: absolute;
  right: 0px;
  background: #363636;
  width: 500px;
  z-index: 10;
  max-width: 100%;
}
.search-popup {
  position: absolute;
  right: 0px;
  background: #363636;
  width: 500px;
  z-index: 10;
  max-width: 100%;
}
.btn-link {
  color: #999;
}
.popup-item {
  display: inline-block;
}
</style>

<style>
.goog-te-combo {
    width: 100%;
    appearance: none;
    border-radius: 4px;
    font-size: 10pt;
    height: calc(2.25rem + 2px);
    padding: 4px;
    color: #495057;
}
</style>
