import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    gradReportData: [],
  },
  getters: {
    GradReportData: (state) => state.gradReportData,
  },
  mutations: {
    SET_GRAD_REPORT_DATA(state, payload) {
      const component = state.gradReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.gradReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getGraduationRateTrendsVisual4Year(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_GraduationRateTrendsVisual4Year`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Grad.getGraduationRateTrendsVisual4Year(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getGraduationRateTrendsVisual4Year(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getGraduationRateTrendsVisual4Year(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_GraduationRateTrendsVisual4Year`, data: response });
      }
    },
    async getGraduationRateTrendsVisual5Year(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_GraduationRateTrendsVisual5Year`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Grad.getGraduationRateTrendsVisual5Year(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getGraduationRateTrendsVisual5Year(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getGraduationRateTrendsVisual5Year(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_GraduationRateTrendsVisual5Year`, data: response });
      }
    },
    async getGraduationRateTrendsVisual(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_GraduationRateTrendsVisual`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getGraduationRateTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getGraduationRateTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getGraduationRateTrends(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_GraduationRateTrendsVisual`, data: response });
      }
    },
    async getCohort4YearGraduationCohortProfile(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_Cohort4YearGraduationCohortProfile`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Grad.getCohort4YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getCohort4YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getCohort4YearGraduationCohortProfile(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_Cohort4YearGraduationCohortProfile`, data: response });
      }
    },
    async getCohort5YearGraduationCohortProfile(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_Cohort5YearGraduationCohortProfile`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getCohort5YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getCohort5YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getCohort5YearGraduationCohortProfile(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_Cohort5YearGraduationCohortProfile`, data: response });
      }
    },
    async getCohort6YearGraduationCohortProfile(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_Cohort6YearGraduationCohortProfile`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getCohort6YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getCohort6YearGraduationCohortProfile(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getCohort6YearGraduationCohortProfile(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_Cohort6YearGraduationCohortProfile`, data: response });
      }
    },
    async getFederalGraduationRates(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_FederalGraduationRates`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getFederalGraduationRates(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getFederalGraduationRates(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getFederalGraduationRates(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_FederalGraduationRates`, data: response });
      }
    },
    async getAccountabilityGraduationRates(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_AccountabilityGraduationRates`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getAccountabilityGraduationRates(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getAccountabilityGraduationRates(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getAccountabilityGraduationRates(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_AccountabilityGraduationRates`, data: response });
      }
    },
    async getGraduationPathways(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_GraduationPathways`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getGraduationPathways(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getGraduationPathways(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getGraduationPathways(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_GraduationPathways`, data: response });
      }
    },
    async getDropoutRateTrends(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_DropoutRateTrends`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getDropoutRateTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getDropoutRateTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getDropoutRateTrends(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_DropoutRateTrends`, data: response });
      }
    },
    async getPostsecondaryEnrollmentRateSummaryVisual(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_PostsecondaryEnrollmentRateSummaryVisual`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryVisual(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryVisual(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryVisual(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        // now get the chart sub title and add the response to response.chatsubtitle property
        const text = await stateService.Detail.Grad.getText(
          payload.schoolYear,
          'PostsecondaryEnrollmentRateSummaryVisualChartSubTitle',
          payload.langCd,
        );
        response.ChartSubTitle = text;
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_PostsecondaryEnrollmentRateSummaryVisual`, data: response });
      }
    },
    async getPostsecondaryEnrollmentRateSummaryTable(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_PostsecondaryEnrollmentRateSummaryTable`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryTable(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryTable(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getPostsecondaryEnrollmentRateSummaryTable(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_PostsecondaryEnrollmentRateSummaryTable`, data: response });
      }
    },
    async getPostsecondaryEnrollmentRatesFall(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_PostsecondaryEnrollmentRatesFall`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getPostsecondaryEnrollmentRatesFall(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getPostsecondaryEnrollmentRatesFall(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getPostsecondaryEnrollmentRatesFall(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_PostsecondaryEnrollmentRatesFall`, data: response });
      }
    },
    async getPostsecondaryEnrollmentRates16month(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_PostsecondaryEnrollmentRates16month`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await
            districtService.Detail.Grad.getPostsecondaryEnrollmentRates16month(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getPostsecondaryEnrollmentRates16month(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await
            stateService.Detail.Grad.getPostsecondaryEnrollmentRates16month(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_PostsecondaryEnrollmentRates16month`, data: response });
      }
    },
    async getApprenticeship(
      { commit, state },
      payload,
    ) {
      if (!state.gradReportData.some((o) => o.Component === `${payload.reportType}_Apprenticeship`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Grad.getApprenticeship(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            response = await schoolService.Detail.Grad.getApprenticeship(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            response = await stateService.Detail.Grad.getApprenticeship(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_GRAD_REPORT_DATA', { Component: `${payload.reportType}_Apprenticeship`, data: response });
      }
    },
  },
};
