<template>
  <div>
    <b-navbar toggleable="xl" type="dark" class="navStyle">
      <b-navbar-toggle target="nav-collapse" />
      <div class="section-head d-block d-xl-none">
        <strong>{{ getSectionHeaderText($route.name) }}</strong>
      </div>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav justified class="pl-0">
          <b-nav-item-dropdown
            v-for="(navItem, index) in navItems"
            :key="index"
            :html="getNavText(navItem)"
            :class="{
              active: isActive(navItem),
              'spanish-font': isSpanishFont,
            }"
            class="px-2 pb-2 pb-xl-0"
          >
            <b-dropdown-item
              v-for="(item, index) in getSubItems(navItem)"
              :key="index"
              @click="doNav(navItem, item)"
            >
              <span v-if="item.IsHeader"><strong>{{ getSubNavText(item) }}</strong></span>
              <span v-else class="pl-xl-2">{{ getSubNavText(item) }}</span>
              <!-- <ul v-else class="pl-2">
                <li class="no-bullet">
                  {{ getSubNavText(item) }}</li>
              </ul> -->
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import NavigationSvc from '../services/navigation.service';
import templateMixin from '../mixins/template-code.mixin';
import translation from '../mixins/translation-service.mixin';

export default {
  mixins: [translation, templateMixin],
  data() {
    return {
      navExpansion: false,
      subNavItems: [],
      selectedMainNav: {},
    };
  },
  computed: {
    isSpanishFont() {
      return this.$store.getters.CurrentLanguage === 'ES';
    },
    apiNavItems() {
      switch (this.ReportType) {
        case 'state':
          return this.$store.getters.CurrentStateInfo.topT2NavigationItems || [];
        case 'district':
          return this.$store.getters.CurrentDistrictInfo.topT2NavigationItems || [];
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.topT2NavigationItems || [];
        default:
          return [];
      }
    },
    navItems() {
      const allNavItems = NavigationSvc.District.getNavigationItems(
        this.$store.getters.SelectedSchoolYear.value,
      );
      const allowedNavItems = this.apiNavItems;
      return allNavItems.filter((n) => allowedNavItems.some((v) => v.value === n.Component));
    },
  },
  mounted() {
  },
  methods: {
    getSectionHeaderText(section) {
      if (!this.translationService) return 'Translation Is Null';
      let routeName = this.$route.name;
      if (section === 'Default' || section === 'ReportDetail') {
        routeName = 'Overview';
      }
      return this.translationService.getNavigationTranslation(
        routeName,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      ).replace('<br/>', ' ').replace('<br />', ' ');
    },
    getSubItems(navItem) {
      const sub = NavigationSvc.District.getSubNavigationItems(
        navItem.NavSection,
        this.$store.getters.SelectedSchoolYear.value,
        // eslint-disable-next-line no-nested-ternary
        (this.ReportType === 'school'
          ? this.$store.getters.CurrentSchoolInfo
          : (this.ReportType === 'district'
            ? this.$store.getters.CurrentDistrictInfo : null)),
      ).filter((ni) => this.visualIsAvailableForInstitution(
        navItem.NavSection,
        ni.Component,
      ));
      return sub;
    },
    getSubNavText(subNavItem) {
      switch (this.$store.getters.CurrentLanguage) {
        case 'EN':
          return subNavItem.English;
        case 'ES':
          return (subNavItem.Spanish && subNavItem.Spanish.length > 0)
            ? subNavItem.Spanish : subNavItem.English;
        default:
          return subNavItem.English;
      }
    },
    getNavText(navItem) {
      switch (this.$store.getters.CurrentLanguage) {
        case 'EN':
          return navItem.English;
        case 'ES':
          return (navItem.Spanish && navItem.Spanish.length > 0)
            ? navItem.Spanish : navItem.English;
        default:
          return navItem.English;
      }
    },
    getNavLink(navItem) {
      const route = {
        name: navItem.Component,
        params: this.$route.params,
      };

      if (this.$route.query.lang) {
        route.query = {
          lang: this.$route.query.lang,
        };
      }

      if (this.$store.getters.CurrentLanguage === 'ES') {
        route.query = {
          lang: 'ES',
        };
      }
      return route;
    },
    doNav(navItem, subNav) {
      if (subNav) {
        if (this.isActive(navItem)) {
          this.$emit('inPageNav', subNav.VisualId);
          return;
        }

        this.$store.dispatch('setScrollToId', subNav.VisualId);
      }

      this.$router.push({
        name: navItem.Component,
        params: this.$route.params,
        query: {
          lang: this.$store.getters.CurrentLanguage,
        },
      });
    },
    isActive(navItem) {
      let routeName = this.$route.name;
      if (routeName === 'Default' || routeName === 'ReportDetail') {
        routeName = 'Overview';
      }
      return navItem.Component.toLowerCase() === this.$route.name.toLowerCase();
    },
  },
};
</script>

<style>
@media (max-width: 1199px){
  /* add scroll to dropdown menu on smaller devices */
  .navbar-collapse.collapse{
      max-height: 500px;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;
  }
  li.nav-item{
      text-align: left!important;;
  }
  .b-nav-dropdown.show{
    background: #009800!important;
  }
  .nav-item.active{
    /* text-decoration: underline; */
    background: #006c00;
  }
  li.nav-item br{
      content: '';
  }
  li.nav-item br{
      content: '';
  }
  li.nav-item br::after{
      content: ' ';
  }
}
@media (min-width: 1200px){
  /* add scroll to dropdown menu on smaller devices */
  a.dropdown-item{
      width: max-content;
      min-width: 300px;
  }
  .nav-item.active>a{
    border-bottom: 5px solid #fff;
  }
}
.nav-item.active{
    font-weight: bold;
  }
.navbar-nav {
    width:100%;
}
.navStyle{
  background-color: #008000;
}
.navbar-nav .nav-link{
  color:#fff!important;
}
a.dropdown-item{
  font-size: 14px;
  color: #426580;
  /* width: 500px; */
  white-space: normal;
}
a:hover.dropdown-item{
  background-color: #A9D08E;
  color: #000;
}
.spanish-font {
  font-size: 8.5pt;
}
/* .b-nav-dropdown{
  display: flex;
  align-items: center;
  justify-content: center;
} */
</style>
<style scoped>
.no-bullet{
  list-style-type: none;
  font-weight: normal;
}
.section-head {
  color: #FFF;
}
</style>
