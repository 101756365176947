import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';
import template from '../template-code.mixin';

export default {
  mixins: [mapping, navigation, template],
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };
      this.$store.dispatch('getGraduationRateTrendsVisual4Year', params);
      this.$store.dispatch('getGraduationRateTrendsVisual5Year', params);
      this.$store.dispatch('getGraduationRateTrendsVisual', params);
      this.$store.dispatch('getCohort4YearGraduationCohortProfile', params);
      this.$store.dispatch('getCohort5YearGraduationCohortProfile', params);
      this.$store.dispatch('getCohort6YearGraduationCohortProfile', params);
      this.$store.dispatch('getFederalGraduationRates', params);
      this.$store.dispatch('getAccountabilityGraduationRates', params);
      this.$store.dispatch('getGraduationPathways', params);
      this.$store.dispatch('getDropoutRateTrends', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRateSummaryVisual', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRateSummaryTable', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRatesFall', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRates16month', params);
      this.$store.dispatch('getApprenticeship', params);
    },
  },
  computed: {
    GradSectionHeader() {
      return this.translationService.getGradTranslation(
        'Grad-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecondarySectionHeader() {
      return this.translationService.getGradTranslation(
        'PostSec-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    GradId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.GradHeader,
      );
    },
    GraduationRateTrendsVisualIntro() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateProgressIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GraduationRateTrendsVisual4YearTitle() {
      // return '4-Year Graduation Rate Trends';
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateTrendsVisual4YearTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GraduationRateTrendsVisual4YearData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationRateTrendsVisual4Year`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };

        mappedObj.Labels = storeObject.columnHeaders;
        // .filter((o) => o.includes('AAA'))
        // .map((o) => o.replace(' AAA ', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '4Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    GraduationRateTrendsVisual5YearTitle() {
      // return '5-Year Graduation Rate Trends';
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateTrendsVisual5YearTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GraduationRateTrendsVisual5YearData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationRateTrendsVisual5Year`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };

        mappedObj.Labels = storeObject.columnHeaders;
        // .filter((o) => o.includes('Cohort '))
        // .map((o) => o.replace(' Cohort ', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '5Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    GraduationRateTrendsId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.GradRateProgressTitle,
      );
    },
    GraduationRateTrendsVisualTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateProgressTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GraduationRateTrendsvisualDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateProgressText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GraduationRateTrendsVisualShading() {
      return [
        {
          cellValues: ['Met Target', 'Objetivo cumplido', 'Met Goal', 'Objetivo Cumplido', 'Cumplir Estándar'],
          cellVariant: 'success',
        },
        {
          cellValues: ['Not Met', 'Objetivo no Cumplido', 'No se cumplió', 'No Cumplido'],
          cellVariant: 'danger',
        },
        {
          cellValues: ['NA', 'N/A'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    GraduationRateTrendsVisualData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationRateTrendsVisual`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    Cohort4YearId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.Cohort4YearTitle,
      );
    },
    Cohort4YearTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort4YearTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort4YearDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort4YearText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort4YearData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort4YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    Cohort5YearId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.Cohort5YearTitle,
      );
    },
    Cohort5YearTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort5YearTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort5YearDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort5YearText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort5YearData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort5YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    Cohort6YearId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.Cohort6YearTitle,
      );
    },
    Cohort6YearTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort6YearTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort6YearDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.Cohort6YearText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Cohort6YearData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort6YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    FederalGradRatesId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.FedGradRateTitle,
      );
    },
    FederalGradRatesTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.FedGradRateTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    FederalGradRatesDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.FedGradRateText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    FederalGradRatesData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_FederalGraduationRates`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        // storeObject.values = this.ReplaceChartIn3DArray(storeObject.values, 'NA', '');
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccountabilityGradRatesId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.GradRateTitle,
      );
    },
    AccountabilityGradRatesIntro() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityGradRatesTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityGradRatesDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradRateText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityGradRatesData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilityGraduationRates`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        // storeObject.values = this.ReplaceChartIn3DArray(storeObject.values, 'NA', '');
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccountabilityGradRatesShading() {
      return [
        {
          cellValues: ['N/A', 'NA', ''],
          cellVariant: 'secondary',
          suppressText: ['N/A', 'NA'],
        },
      ];
    },
    GradPathwaysId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.GradPathwaysTitle,
      );
    },
    GradPathwaysTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradPathwaysTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GradPathwaysDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.GradPathwaysText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    GradPathwaysData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationPathways`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HideGradPathwaysVisual() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationPathways`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    DropoutId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DropoutTitle,
      );
    },
    DropoutTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.DropoutTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DropoutDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.DropoutText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DropoutData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_DropoutRateTrends`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    PostSecEnrollId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.PostSecHeader,
      );
    },
    PostSecEnrollIntro() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnrollSummaryId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollSummaryTitle,
      );
    },
    PostSecEnrollSummaryTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollSummaryTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnrollSummaryDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollSummaryText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnrollSummaryVisualData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRateSummaryVisual`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        const txt = storeObject.ChartSubTitle;
        const ret = this.MapData(storeObject, this.ChartTypes);
        ret.Values[2].data = [0, 0, 0];
        ret.Values[2].backgroundColor = '#FFF';
        ret.ChartSubTitle = txt;

        return ret;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    PostSecEnrollSummaryTableData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRateSummaryTable`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const dataObject = this.MapData(storeObject, this.ChartTypes);

        return dataObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    PostSecEnrollFallId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollFallTitle,
      );
    },
    PostSecEnrollFallTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollFallTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnrollFallDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnrollFallText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnrollFallData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRatesFall`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    PostSecEnroll16MonthId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.PostSecEnroll16MonthTitle,
      );
    },
    PostSecEnroll16MonthTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnroll16MonthTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnroll16MonthDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.PostSecEnroll16MonthText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PostSecEnroll16MonthData() {
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRates16month`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    ApprenticeshipId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.ApprenticeshipTitle,
      );
    },
    ApprenticeshipTitle() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.ApprenticeshipTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ApprenticeshipDescription() {
      return this.translationService.getGradTranslation(
        TranslationSvc.Constants.ComponentNames.ApprenticeshipText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ApprenticeshipData() {
      try {
        const storeObjects = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Apprenticeship`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        // storeObject.values = this.ReplaceChartIn3DArray(storeObject.values, 'NA', '');
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    ApprenticeshipIsValid() {
      return true;
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
