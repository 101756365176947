<template>
  <div>
    <b-container v-if="hasAccess" fluid>
      <b-row style="">
        <b-col>
          <school-district-information />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="10" offset-md="1">
          <b-row v-if="isTarget" class="px-md-4 py-2 py-md-4 ">
            <b-col>
              <p>
                <b-link :to="accountabilityLink" target="_blank">
                  <font-awesome-icon :icon="['fa', 'info-circle']" class="info-icon" />
                </b-link>
                <span class="mr-1" v-html="TargetText" />
              </p>
            </b-col>
          </b-row>
          <b-row class="px-md-4 py-2 py-md-4 ">
            <b-col>
              <p v-html="SummaryIntro" />
            </b-col>
          </b-row>
          <b-row v-if="ShowHighlights" class="px-md-4 py-2 py-md-4 ">
            <b-col>
              <h2 class="pl-2" style="text-align: left; border-bottom: 2px solid #2C7FC2;">
                <i>{{ HighlightsTitle }}</i>
              </h2>
              <ul>
                <li v-for="(point, index) in HighlightsData" :key="index" class="text-left">
                  {{ point }}
                </li>
              </ul>
            </b-col>
          </b-row>
          <!-- Student Growth compared to other students-->
          <!-- TO-DO: Replace showPerformaAssessments -->
          <b-row
            v-if="SelectedSchoolYear.value >= 2223
              && ShowGrowthComparison()"
            class="px-md-4 py-2 py-md-4 "
          >
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StudentGrowthCompareTitle }}
                  <b-link
                    :to="studentGrowthLink"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fa', 'info-circle']" />
                  </b-link>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                    class="section-description">
                    <span v-html="StudentGrowthCompareDescription" />
                  </b-col>
                  <b-col cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points-label="[1, 40, 60, 99]"
                      :scale-data-points="[0, 40, 60, 100]"
                      :data-decoration="''"
                      :suppress-border="true"
                      :chart-data="StdGrowthELAData"
                    />
                  </b-col>
                  <b-col cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points-label="[1, 40, 60, 99]"
                      :scale-data-points="[0, 40, 60, 100]"
                      :data-decoration="''"
                      :suppress-border="true"
                      :chart-data="StdGrowthMathData"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <!-- How did students perform on assessments -->
          <b-row
            v-if="SelectedSchoolYear.value >= 2122
              && ShowPerformAssessments()"
            class="px-md-4 py-2 py-md-4 "
          >
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ HowAssessTitle }}
                  <b-link
                    :to="academicAchievementLink"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fa', 'info-circle']" />
                  </b-link>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                    class="section-description">
                    <span v-html="HowAssessDescription" />
                  </b-col>
                  <b-col cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points="[0, 51.3, 80, 100]"
                      :scale-data-points-label="[0, 51.3, 80, 100]"
                      :data-decoration="'%'"
                      :suppress-border="true"
                      :chart-data="HowAssessELAData"
                    />
                  </b-col>
                  <b-col cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points="[0, 38.2, 80, 100]"
                      :scale-data-points-label="[0, 38.2, 80, 100]"
                      :data-decoration="'%'"
                      :suppress-border="true"
                      :chart-data="HowAssessMathData"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <!-- Are students at risk -->
          <b-row v-if="SelectedSchoolYear.value >= 2122" class="px-md-4 py-2 py-md-4">
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StdRiskTitle }}
                  <b-link
                    :to="climateLink"
                    target="_blank"
                    @click="setStdRiskScroll"
                    @keydown="setStdRiskScroll"
                  >
                    <!-- replaced @click.native -->
                    <font-awesome-icon :icon="['fa', 'info-circle']">
                      <span class="sr-only">Students At Risk link</span>
                    </font-awesome-icon>
                  </b-link>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    lg="3"
                    class="section-description-inline">
                    <span v-html="StdRiskDescription" />
                  </b-col>
                  <b-col cols="9" md="9" lg="9">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="StdRiskData"
                      :chart-x-axis-title="StdRiskXAxisTitle"
                      :suppress-border="true"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <!-- Are students Graduating -->
          <b-row v-if="ShowGradRates()" class="px-md-4 py-2 py-md-4 ">
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StdGradTitle }}
                  <b-link
                    :to="postSecondaryLink"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fa', 'info-circle']" />
                  </b-link>
                </h2>
                <b-row>
                  <b-col cols="12" lg="4" class="section-description">
                    <span v-html="StdGradDescription" />
                  </b-col>
                  <b-col v-if="SelectedSchoolYear.value <= 1920" cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.DoubleDonutChart"
                      :suppress-border="true"
                      :data-decoration="'%'"
                      :chart-data="StdGrad4YearData"
                    />
                  </b-col>
                  <b-col v-if="SelectedSchoolYear.value <= 1920" cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.DoubleDonutChart"
                      :suppress-border="true"
                      :data-decoration="'%'"
                      :chart-data="StdGrad5YearData"
                    />
                  </b-col>
                  <b-col v-if="SelectedSchoolYear.value > 1920" cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points="[0, 91.1, 95, 100]"
                      :scale-data-points-label="[0, 91.1, 95, 100]"
                      :suppress-border="true"
                      :data-decoration="'%'"
                      :chart-data="StdGrad4YearData"
                    />
                  </b-col>
                  <b-col v-if="SelectedSchoolYear.value > 1920" cols="12" lg="4">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.GaugeChart"
                      :scale-data-points="[0, 92.7, 96, 100]"
                      :scale-data-points-label="[0, 92.7, 96, 100]"
                      :data-decoration="'%'"
                      :suppress-border="true"
                      :chart-data="StdGrad5YearData"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <!-- Are students at risk -->
          <b-row v-if="SelectedSchoolYear.value == 2021" class="px-md-4 py-2 py-md-4">
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StdRiskTitle }}
                  <b-link
                    :to="climateLink"
                    target="_blank"
                    @click="setStdRiskScroll"
                    @keydown="setStdRiskScroll"
                  >
                    <!-- replaced @click.native -->
                    <font-awesome-icon :icon="['fa', 'info-circle']">
                      <span class="sr-only">Students At Risk link</span>
                    </font-awesome-icon>
                  </b-link>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    lg="3"
                    class="section-description-inline">
                    <span v-html="StdRiskDescription" />
                  </b-col>
                  <b-col cols="9" md="9" lg="9">
                    <visual-component
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="StdRiskData"
                      :chart-x-axis-title="StdRiskXAxisTitle"
                      :suppress-border="true"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <!-- are students college and career ready -->
          <b-row v-if="ShowCollegeReady()" class="px-md-4 py-2 py-md-4 ">
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StdCollegeTitle }}
                  <b-link
                    :to="collegeReadyLink"
                    target="_blank"
                    @click="setCollegeReadyScroll"
                    @keydown="setCollegeReadyScroll"
                  >
                    <!-- Renamed @click.native -->
                    <font-awesome-icon :icon="['fa', 'info-circle']">
                      <span class="sr-only">Are Students College and Career Ready link</span>
                    </font-awesome-icon>
                  </b-link>
                </h2>
                <!-- <b-row>
                    <b-col v-html="StdCollegeDescription" class="section-description">
                    </b-col>
                  </b-row> -->
                <b-row v-for="(data, index) in StdCollegeData" :key="index">
                  <b-col v-if="ShowSubVisual(data)" cols="12">
                    <b-row>
                      <b-col cols="12" md="4" lg="3">
                        <div class="text-left pt-5">
                          <span class="chart-title">{{ data.lTitle }}</span><br />
                          <span>{{ data.lDescription }}</span>
                        </div>
                      </b-col>
                      <b-col cols="12" md="8" lg="9">
                        <visual-component
                          :visual-title="data.ChartTitle"
                          :chart-type="ChartTypes.HorizontalBarChart"
                          :suppress-border="true"
                          :chart-data="data"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <access-denied v-else />
  </div>
</template>

<script>
import SchoolDistrictInformation from '../components/SchoolDistrictInformation.vue';
import VisualComponent from '../components/VisualComponent.vue';
import AccessDenied from '../components/AccessDenied.vue';
import accessHandler from '../mixins/access-denied.mixin';
import summary from '../mixins/detailComponents/summary.mixin';

export default {
  emits: ['close'],
  components: {
    SchoolDistrictInformation,
    VisualComponent,
    AccessDenied,
  },
  mixins: [accessHandler, summary],
  watch: {
    CurrentInstitution(newValue) {
      if (newValue.template === 'NotTested') {
        this.$router.push({ name: 'ReportSelect', params: this.$route.params });
      }
    },
  },
  created() {
    this.$store.dispatch('setPrintView', false);
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
}
h2{
  color: #2C7FC2;
  font-size: 22px;
  font-weight: 600;
}
.info-icon, h2 a, h2 a:hover {
  color: #2C7FC2;
  text-decoration: none;
}
.info-icon {
  color: #000;
}
.section-description{
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-description-inline{
  font-size: 14px;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.chart-title {
  font-weight: bolder;
}
</style>
