import Vue from 'vue';
import Vuex from 'vuex';

import schoolYearService from '../services/schoolYear.service';
import translationService from '../services/translation.service';
import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';
import userService from '../services/user.service';

import demographics from './demographics.store';
import academic from './academic.store';
import staff from './staff.store';
import grad from './grad.store';
import climate from './climate.store';
import college from './college.store';
import accountability from './accountability.store';
import growth from './growth.store';
import narrative from './narrative.store';
import overview from './overview.store';
import summary from './summary.store';
import perpupil from './perpupil.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    initialLoad: true,
    currentSchoolYears: [],
    isPrintView: false,
    selectedSchoolYear: {
      text: '2022-2023',
      value: '2223',
      embargo: false,
      historical: false,
    },
    initialSchoolYear: '',
    currentLanguage: translationService.Constants.LanguageOptions.English,
    currentReportData: [],
    currentDistrictInfo: {},
    currentSchoolInfo: {},
    currentStateInfo: {},
    scrollToId: '',
    userInfo: {},
    requestCount: 0,
  },
  modules: {
    demographics,
    academic,
    staff,
    grad,
    climate,
    college,
    accountability,
    growth,
    narrative,
    overview,
    summary,
    perpupil,
  },
  getters: {
    InitialLoad: (state) => state.InitialLoad,
    CurrentSchoolYears: (state) => state.currentSchoolYears,
    IsPrintView: (state) => state.isPrintView,
    SelectedSchoolYear: (state) => state.selectedSchoolYear,
    CurrentLanguage: (state) => state.currentLanguage,
    CurrentReportData: (state) => state.currentReportData,
    CurrentDistrictInfo: (state) => state.currentDistrictInfo,
    CurrentSchoolInfo: (state) => state.currentSchoolInfo,
    CurrentStateInfo: (state) => state.currentStateInfo,
    ScrollToId: (state) => state.scrollToId,
    UserInfo: (state) => state.userInfo,
    RequestCount: (state) => state.requestCount,
  },
  mutations: {
    SET_SCHOOLYEARS(state, payload) {
      state.currentSchoolYears = payload;
      state.initialLoad = false;
    },
    SET_PRINTVIEW(state, payload) {
      state.isPrintView = payload;
    },
    SET_SELECTEDSCHOOLYEAR(state, payload) {
      state.selectedSchoolYear = payload;
    },
    SET_INITIALSCHOOLYEAR(state, payload) {
      state.initialSchoolYear = payload;
    },
    SET_CURRENT_LANGUAGE(state, payload) {
      state.currentLanguage = payload;
    },
    FLUSH_REPORT_DATA(state) {
      state.currentReportData.length = 0;
      state.demographics.demographicsReportData.length = 0;
      state.staff.staffReportData.length = 0;
      state.narrative.narrativeReportData.length = 0;
      state.grad.gradReportData.length = 0;
      state.college.collegeReportData.length = 0;
      state.climate.climateReportData.length = 0;
      state.academic.academicReportData.length = 0;
      state.accountability.accountabilityReportData.length = 0;
      state.growth.growthReportData.length = 0;
      state.narrative.narrativeReportData.length = 0;
      state.overview.overviewReportData.length = 0;
      state.summary.summaryReportData.length = 0;
    },
    CLEAR_SCHOOL_DISTRICT_INFO(state) {
      state.currentDistrictInfo = {};
      state.currentSchoolInfo = {};
      state.currentStateInfo = {};
    },
    SET_CURRENT_DISTRICT_INFO(state, payload) {
      state.currentDistrictInfo = payload;
    },
    SET_CURRENT_SCHOOL_INFO(state, payload) {
      state.currentSchoolInfo = payload;
    },
    SET_CURRENT_STATE_INFO(state, payload) {
      state.currentStateInfo = payload;
    },
    SET_REPORT_DATA(state, payload) {
      const component = state.currentReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.currentReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
    SET_SCROLL_TO_ID(state, payload) {
      state.scrollToId = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
    SET_REQUEST_COUNT(state, payload) {
      state.requestCount += payload;
    },
  },
  actions: {
    async setScrollToId({ commit }, value) {
      commit('SET_SCROLL_TO_ID', value);
    },
    async clearScrollToId({ commit }) {
      commit('SET_SCROLL_TO_ID', '');
    },
    async getCurrentSchoolYears({ commit, state }, value) {
      if (!state.currentSchoolYears
        || state.currentSchoolYears.length === 0
        || (value && value === 'override')) {
        const response = await schoolYearService.getCurrentSchoolYears();

        commit('SET_SCHOOLYEARS', response.data);
        if (state.initialSchoolYear.length > 0) {
          commit('SET_SELECTEDSCHOOLYEAR', response.data.filter((sy) => sy.value === state.initialSchoolYear)[0]);
        } else {
          commit('SET_SELECTEDSCHOOLYEAR', response.data[response.data.length - 1]);
        }
      }
    },
    async setPrintView({ commit }, value) {
      commit('SET_PRINTVIEW', value);
    },
    async setInitialSchoolYear({ commit }, value) {
      commit('SET_INITIALSCHOOLYEAR', value);
    },
    async setSelectedSchoolYear({ commit, state }, value) {
      const schoolYears = state.currentSchoolYears.filter((sy) => sy.value === value);
      commit('SET_SELECTEDSCHOOLYEAR', schoolYears[0]);
    },
    setCurrentLanguage({ commit }, value) {
      commit('SET_CURRENT_LANGUAGE', value);
    },
    flushReportData({ commit }) {
      commit('FLUSH_REPORT_DATA');
    },
    clearSchoolDistrictinfo({ commit }) {
      commit('CLEAR_SCHOOL_DISTRICT_INFO');
    },
    async getUserInfo({ commit }) {
      const response = await userService.getUserInfo();

      commit('SET_USER_INFO', response);
    },
    AddOutboundRequest({ commit }) {
      commit('SET_REQUEST_COUNT', 1);
    },
    RemoveOutboundRequest({ commit }) {
      commit('SET_REQUEST_COUNT', -1);
    },
    async getDistrictDetailHeader({ commit, state }, payload) {
      // If we don't have a District Info, or the current doesn't match
      // then fetch a new one.
      if (!state.currentDistrictInfo
        || !state.currentDistrictInfo.countyCode
        || state.currentDistrictInfo.countyCode !== payload.countyCd
        || state.currentDistrictInfo.districtCode !== payload.districtCd) {
        const response = await districtService.Detail.Header.getHeader(
          payload.schoolYear,
          payload.countyCd,
          payload.districtCd,
          payload.langCd,
        );

        commit('SET_CURRENT_DISTRICT_INFO', response);
      }
    },
    async getDistrictSummaryHeader({ commit, state }, payload) {
      // If we don't have a District Info, or the current doesn't match
      // then fetch a new one.
      if (!state.currentDistrictInfo
        || !state.currentDistrictInfo.countyCode
        || state.currentDistrictInfo.countyCode !== payload.countyCd
        || state.currentDistrictInfo.districtCode !== payload.districtCd) {
        const response = await districtService.Summary.getHeader(
          payload.schoolYear,
          payload.countyCd,
          payload.districtCd,
          payload.langCd,
        );

        commit('SET_CURRENT_DISTRICT_INFO', response);
      }
    },
    async getSchoolDetailHeader({ commit, state }, payload) {
      // If we don't have a School Info, or the current doesn't match
      // then fetch a new one.
      if (!state.currentSchoolInfo
        || !state.currentSchoolInfo.countyCode
        || state.currentSchoolInfo.countyCode !== payload.countyCd
        || state.currentSchoolInfo.districtCode !== payload.districtCd
        || state.currentSchoolInfo.schoolCode !== payload.schoolCd) {
        const response = await schoolService.Detail.Header.getHeader(
          payload.schoolYear,
          payload.countyCd,
          payload.districtCd,
          payload.schoolCd,
          payload.langCd,
        );

        commit('SET_CURRENT_SCHOOL_INFO', response);
      }
    },
    async getSchoolSummaryHeader({ commit, state }, payload) {
      // If we don't have a School Info, or the current doesn't match
      // then fetch a new one.
      if (!state.currentSchoolInfo
        || !state.currentSchoolInfo.countyCode
        || state.currentSchoolInfo.countyCode !== payload.countyCd
        || state.currentSchoolInfo.districtCode !== payload.districtCd
        || state.currentSchoolInfo.schoolCode !== payload.schoolCd) {
        const response = await schoolService.Summary.getHeader(
          payload.schoolYear,
          payload.countyCd,
          payload.districtCd,
          payload.schoolCd,
          payload.langCd,
        );

        commit('SET_CURRENT_SCHOOL_INFO', response);
      }
    },
    async getStateDetailHeader({ commit, state }, payload) {
      // If we don't have a State Info, or the current doesn't match
      // then fetch a new one.
      if (!state.currentStateInfo
        || !state.currentStateInfo.countyCode) {
        const response = await stateService.Header.getHeader(
          payload.schoolYear,
          payload.langCd,
        );

        commit('SET_CURRENT_STATE_INFO', response);
      }
    },
  },
});
