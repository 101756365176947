<template>
  <div>
    <b-container fluid>
      <b-row class="row flex-column-reverse flex-md-row">
        <b-col class="col-12 col-md-9 text-left">
          <b-row class="">
            <b-col cols="12" md="3" class="pr-md-4">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="../assets/prlogo.png"
                  style="height:50px"
                  alt="NJ School Performance Reports Logo"
                  class="pt-2"
                >
              </router-link>
            </b-col>
            <b-col cols="12" md="9" class="pl-md-4">
              <h1 class="text-left text-md-right text-lg-left text-print-left pt-2">
                {{ NameOfSchool }}
              </h1>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 col-md-3 pb-4 pb-md-2">
          <omni-search :search-type="'short'" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="9">
          <b-row>
            <b-col cols="12" md="4">
              <h2>
                Reports In English
              </h2>
            </b-col>
            <b-col cols="12" md="8">
              <b-row class="px-4">
                <b-col class="col-auto">
                  <button @click="EnglishDetailed()" type="button">
                    Detailed Report
                  </button>
                </b-col>
                <b-col class="col-auto">
                  <button
                    v-if="!isStateReport
                      && hasSummary"
                    @click="EnglishSummary()"
                    type="button">
                    Summary Report
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="showSpanishReports">
            <!-- Hidden for Embargo -->
            <b-col cols="12" md="4">
              <h2>
                Translated Reports (in Spanish)
              </h2>
            </b-col>
            <b-col cols="12" md="8">
              <b-row class="px-4">
                <b-col class="col-auto">
                  <button @click="SpanishDetailed()" type="button">
                    Informe Detallado
                  </button>
                </b-col>
                <b-col class="col-auto">
                  <button
                    v-if="!isStateReport
                      && hasSummary"
                    @click="SpanishSummary()"
                    type="button">
                    Informe Resumido
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="showSpanishReports">
            <!-- Hidden for Embargo -->
            <b-col cols="12">
              <h2>
                Translated Resources
              </h2>
              <ul>
                <li>
                  <!-- <a :href="`${documents}` + shortschoolYear +`/ReferenceGuideSpanish.pdf`"
                          target="_blank">
                          Reference Guide (Guía de Referencia: Detallado)</a> -->
                  <a
                    href="/Documents/ReferenceGuideSpanish.pdf"
                    target="_blank"
                  >
                    Reference Guide (Guía de Referencia: Detallado)</a>
                </li>
                <li>
                  <!-- <a :href="`${documents}` + shortschoolYear +`/FAQsSpanish.pdf`"
                          target="_blank">
                          FAQ (Preguntas Frecuentes)</a> -->
                  <a
                    href="/Documents/FAQsSpanish.pdf"
                    target="_blank"
                  >
                    FAQ (Preguntas Frecuentes)</a>
                </li>
                <li v-if="!isStateReport && false">
                  <!-- eslint-disable -->
                      <!-- <a :href="`${documents}` + shortschoolYear +`/SummaryReportSpanish.pdf`"
                          target="_blank">
                          Summary Report Guide (Guía de Referencia: Resumen)</a> -->
                      <a href="/Documents/SummaryReportSpanish.pdf"
                          target="_blank">
                          Summary Report Guide (Guía de Referencia: Resumen)</a>
                    </li>
                    <li>
                       <!-- <a :href="`${documents}` + shortschoolYear +`/DataPrivacyRulesSpanish.pdf`"
                          target="_blank">
                          Privacy Rules (Reglas de Privacidad de Datos)</a> -->
                      <a href="/Documents/DataPrivacyRulesSpanish.pdf"
                        target="_blank">
                        Privacy Rules (Reglas de Privacidad de Datos)</a>
                    </li>
                </ul>
            </b-col>
        </b-row>
        <b-row v-if="isDistrictReport" class="text-center">
          <b-col>
            <visual-component
            :VisualTitle="SchoolsInDistrictTitle"
            :visualDescription="SchoolsInDistrictDescription"
            :ChartType="ChartTypes.TableGrid"
            :chartData="SchoolsInDistrictData" />
          </b-col>
      </b-row>
      </b-col>
      <b-col cols="12" md="3">
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              County: {{ NameOfCounty }}
              <br />
              District: {{ NameOfDistrict }}
            </b-col>
          </b-row>
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              <b-row>
                <b-col cols="1">
                  <i class="fas fa-map-marker-alt"></i>
                </b-col>
                <b-col v-html="LocationAddress" style="text-align: left;" />
              </b-row>
            </b-col>
          </b-row>
          <br />
          <b-row v-if="!isStateReport">
            <b-col class="school-info">
              <span>
              {{ PRPrincipal }}: {{ SchoolPrincipal }}
              </span>
              <br />
              <a :href="SchoolWebsite" target="_blank">
                {{ OrganizationType }} Website</a>
              <br />
              <a :href="`tel:+${ SchoolPhone }`" class="phone">
                <b-row>
                  <b-col cols="1">
                    <i class="fas fa-phone-alt"></i>
                  </b-col>
                  <b-col>
                    {{ SchoolPhone }}
                  </b-col>
                  </b-row>
              </a>
            </b-col>
          </b-row>
          <b-row class="d-none d-lg-block text-left">
            <b-col>
              <br />
              <div id="map" ref="map" style="width:100%;height:400px;">
                <iframe height="270" :src="MapUrl">
                </iframe>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="questions">
            <b-col>
              <p><strong>Questions</strong> about reports? Contact
              <a href="mailto:reportcard@doe.nj.gov">reportcard@doe.nj.gov</a></p>
            </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OmniSearch from '../components/OmniSearch.vue';
import ChartTypes from '../components/ChartTypes';
import mapping from '../mixins/mapping.mixin';
import TranslationService from '../services/translation.service';
import translation from '../mixins/translation-service.mixin';
import VisualComponent from '../components/VisualComponent.vue';

export default {
  name: 'ReportSelection',
  components: {
    OmniSearch,
    VisualComponent,
  },
  mixins: [mapping, translation],
  data() {
    return {
      ChartTypes,
    };
  },
  computed: {
    isStateReport() {
      return !this.$route.params.countyCd;
    },
    isDistrictReport() {
      return !this.$route.params.schoolCd;
    },
    isSchoolReport() {
      return this.$route.params.schoolCd;
    },
    hasSummary() {
      if (!this.$store.getters.CurrentSchoolInfo.template
          && !this.$store.getters.CurrentDistrictInfo.template) {
        return false;
      }
      return (this.isSchoolReport && this.$store.getters.CurrentSchoolInfo.template !== 'NotTested')
          || (this.isDistrictReport && this.$store.getters.CurrentDistrictInfo.template !== 'NotTested');
    },
    NameOfCounty() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.countyName;
      }
      return this.$store.getters.CurrentDistrictInfo.countyName;
    },
    NameOfDistrict() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.districtName;
      }
      return this.$store.getters.CurrentDistrictInfo.districtName;
    },
    NameOfSchool() {
      if (this.$route.params.schoolCd) {
        if (!this.$store.getters.CurrentSchoolInfo.schoolName
        || !this.$store.getters.CurrentSchoolInfo.countyCode) {
          return '';
        }
        return `${this.$store.getters.CurrentSchoolInfo.schoolName} (${this.$store.getters.CurrentSchoolInfo.countyCode}-${this.$store.getters.CurrentSchoolInfo.districtCode}-${this.$store.getters.CurrentSchoolInfo.schoolCode})`;
      }
      if (!this.$route.params.countyCd) {
        return 'New Jersey';
      }
      if (!this.$store.getters.CurrentDistrictInfo.districtName
      || !this.$store.getters.CurrentDistrictInfo.countyCode) {
        return '';
      }
      return `${this.$store.getters.CurrentDistrictInfo.districtName} (${this.$store.getters.CurrentDistrictInfo.countyCode}-${this.$store.getters.CurrentDistrictInfo.districtCode})`;
    },
    LocationAddress() {
      let address;
      if (this.$route.params.schoolCd) {
        const schoolInfo = this.$store.getters.CurrentSchoolInfo;
        if (!schoolInfo.address1) {
          return {};
        }
        address = schoolInfo.address1;
        if (schoolInfo.address2.trim().length > 0) {
          address = `${address}<br/>${schoolInfo.address2}`;
        }
        return `${address}<br/>${schoolInfo.city}, ${schoolInfo.state} ${schoolInfo.zip}`;
      }
      const districtInfo = this.$store.getters.CurrentDistrictInfo;
      if (!districtInfo.address1) {
        return {};
      }
      address = districtInfo.address1;
      if (districtInfo.address2.trim().length > 0) {
        address = `${address}<br/>${districtInfo.address2}`;
      }
      return `${address}<br/>${districtInfo.city}, ${districtInfo.state} ${districtInfo.zip}`;
    },
    SchoolPrincipal() {
      if (this.$route.params.schoolCd) {
        return `${this.$store.getters.CurrentSchoolInfo.principal}`;
      }
      return `${this.$store.getters.CurrentDistrictInfo.superintendent}`;
    },
    PRPrincipal() {
      if (this.$route.params.schoolCd) {
        return 'Principal';
      }
      return 'Superintendent';
    },
    SchoolWebsite() {
      let website = '-';
      if (this.$route.params.schoolCd) {
        return `${this.$store.getters.CurrentSchoolInfo.website}`;
      }
      if (!this.$store.getters.CurrentDistrictInfo.website.startsWith('http')) {
        website = `//${this.$store.getters.CurrentDistrictInfo.website}`;
      } else {
        website = `${this.$store.getters.CurrentDistrictInfo.website}`;
      }
      return website;
    },
    SchoolPhone() {
      if (this.$route.params.schoolCd) {
        return this.$store.getters.CurrentSchoolInfo.phoneNumber;
      }
      return this.$store.getters.CurrentDistrictInfo.phoneNumber;
    },
    OrganizationType() {
      if (this.$route.params.schoolCd) {
        return 'School';
      }
      return 'District';
    },
    MapUrl() {
      let leo = {};

      if (this.$route.params.schoolCd) {
        leo = this.$store.getters.CurrentSchoolInfo;
      } else {
        leo = this.$store.getters.CurrentDistrictInfo;
      }

      if (!leo.address1) {
        return 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=New+Jersey';
      }

      const address = `${leo.address1.replace(' ', '+')},${leo.city.replace(' ', '+')},${leo.state.replace(' ', '+')}+${leo.zip.replace(' ', '+')}`;

      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=${address}`;
    },
    selectedSchoolYear() {
      return this.$store.getters.SelectedSchoolYear;
    },
    SchoolsInDistrictTitle() {
      return 'Schools in District';
    },
    SchoolsInDistrictDescription() {
      return 'Click on a school name below to access the detailed school-level report for each school.';
    },
    SchoolsInDistrictData() {
      try {
        const storeObjects = this.$store.getters.OverviewReportData.filter((o) => o.Component === 'district_SchoolsInDistrict');
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        // console.error(e);
      }
      return {};
    },
    shortschoolYear() {
      return this.$store.getters.SelectedSchoolYear.value;
    },
    documents() {
      return process.env.VUE_APP_DOCUMENTS;
    },
    dataFiles() {
      return process.env.VUE_APP_DATA_FILES;
    },
    referenceGuide() {
      return process.env.VUE_APP_REFERENCE_GUIDE;
    },
    showSpanishReports() {
      return (process.env.VUE_APP_SHOW_SPANISH_REPORTS === 'Yes'
        && this.$store.getters.SelectedSchoolYear.embargo)
        || !this.$store.getters.SelectedSchoolYear.embargo;
    },
  },
  watch: {
    selectedSchoolYear(newValue) {
      if (newValue.historical) {
        this.$router.push({
          name: 'PriorYearReport',
          params: {
            schoolYear: newValue.text,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            schoolCd: this.$route.params.schoolCd,
          },
        });
      }
    },
  },
  mounted() {
    // const { schoolYear } = this.$route.params;
    // this.$store.dispatch('setInitialSchoolYear', schoolYear);
    const lowYear = this.$route.params.schoolYear.substring(2, 4);
    const highYear = this.$route.params.schoolYear.substring(7, 9);
    this.$store.dispatch('setInitialSchoolYear', `${lowYear}${highYear}`);
    this.fetchData();
  },
  methods: {
    ShowPrincipal() {
      return this.OrganizationType === 'School';
    },
    navigate(routeName, params, language) {
      this.$store.dispatch('flushReportData');
      this.$store.dispatch('clearSchoolDistrictinfo');
      this.$store.dispatch('setCurrentLanguage', language);

      this.$router.push({
        name: routeName,
        params,
      });
    },
    EnglishDetailed() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      if (this.isStateReport) params.reportType = 'state';
      params.schoolYear = this.$store.getters.SelectedSchoolYear.text;
      this.navigate('Overview', params, TranslationService.Constants.LanguageOptions.English);
    },
    EnglishSummary() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      params.schoolYear = this.$store.getters.SelectedSchoolYear.text;
      this.navigate('SummaryReport', params, TranslationService.Constants.LanguageOptions.English);
    },
    SpanishDetailed() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      if (this.isStateReport) params.reportType = 'state';
      params.schoolYear = this.$store.getters.SelectedSchoolYear.text;
      this.navigate('Overview', params, TranslationService.Constants.LanguageOptions.Spanish);
    },
    SpanishSummary() {
      const { params } = this.$route;
      if (params.schoolCd) {
        params.reportType = 'school';
      } else {
        params.reportType = 'district';
      }
      params.schoolYear = this.$store.getters.SelectedSchoolYear.text;
      this.navigate('SummaryReport', params, TranslationService.Constants.LanguageOptions.Spanish);
    },
    fetchData() {
      this.$store.dispatch('flushReportData');
      if (this.isStateReport) return;
      if (!this.$route.params.schoolCd) {
        this.$store.dispatch(
          'getDistrictDetailHeader',
          {
            schoolYear: this.$route.params.schoolYear,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
        this.$store.dispatch(
          'getSchoolsInDistrict',
          {
            schoolYear: this.$route.params.schoolYear,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            langCd: this.$store.getters.CurrentLanguage,
            reportType: 'district',
          },
        );
      } else {
        this.$store.dispatch(
          'getSchoolDetailHeader',
          {
            schoolYear: this.$route.params.schoolYear,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            schoolCd: this.$route.params.schoolCd,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
      }
    },
    enableprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      visualComponents.forEach((comp) => {
        console.log(`Enabling Print View on ${comp}`);
        console.log(comp);
        comp.enablePrintView();
      });
    },
    disableprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      visualComponents.forEach((comp) => {
        comp.disablePrintView();
      });
    },
  },
};
</script>

<style scoped>
h1{
    padding: 20px;
    font-size: 22px;
}
h2{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding: 20px;
}
li{
    text-align: left;
}

button{
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    background-color: #008000;
    color: #FFF;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px rgba(0,0,0,0.5);
    width: auto;
    min-width: 170px;
    height: auto;
    margin: 5px;
}
button:hover{
    background-color: #A9D08E;
    color: #000;
    border: 1px solid #008000;
}
.search-box {
  font-size: 18px;
  color: #7B7474;
  font-weight: 600;
  height: auto;
}
.school-info{
    text-align: left;
    font-size: 14px;
    line-height: 22px;
}
.phone{
  color: black;
  text-decoration: none;
}
.map{
  height: 600px;
  background: gray;
}
.questions {
  padding-top: 35px;
  padding-left: 20px;
}
@media only screen and (max-width: 768px) {
  button{
    width: auto;
    min-width: 150px;
    height: auto;
  }
}
</style>
