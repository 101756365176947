<template>
  <div>
    <b-container fluid>
      <b-row class="">
        <b-col cols="12" md="3" class="pr-md-4">
          <router-link :to="{ name: 'Home' }">
            <img
              src="../assets/prlogo.png"
              style="height:70px"
              alt="NJ School Performance Reports Logo"
              class="pt-2"
            >
          </router-link>
        </b-col>
        <b-col cols="12" md="9" class="pl-md-4 text-lg-left">
          <h1>All Resources:</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <h2 style="font-size: 14pt;">
            General School Performance Report Resources:
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="text-left">
          <ul>
            <li>
              <a href="/Documents/ReferenceGuide.pdf" target="_blank">
                Reference Guide: Detailed Explanation of Reports</a>
              <a href="/Documents/ReferenceGuideSpanish.pdf" target="_blank">
                (Spanish Translated Reference Guide)</a>
            </li>
            <li>
              <a href="/Documents/SummaryGuide.pdf" target="_blank">
                Summary Reports Guide </a>
              <a href="/Documents/SummaryGuideSpanish.pdf" target="_blank">
                (Spanish Translated Summary Reports Guide)</a>
            </li>
            <li>
              <a href="/Documents/FAQs.pdf" target="_blank">
                School Performance Reports: Frequently Asked Questions</a>
              <a href="/Documents/FAQsSpanish.pdf" target="_blank">
                (Spanish Translated FAQs)</a>
            </li>
            <li>
              <a href="/Documents/DataPrivacyRules.pdf" target="_blank">
                Data Privacy Rules: FERPA and Student Confidentiality</a>
              <a href="/Documents/DataPrivacyRulesSpanish.pdf" target="_blank">
                (Spanish Translated Data Privacy Rules)</a>
            </li>
          </ul>
        </b-col>
      </b-row>
      <!-- 21-22 and prior: want generic links-->
      <!-- <a :href="`${documents}`
          + shortschoolYear +`/ReferenceGuide.pdf`" target="_blank">
            <strong>Reference Guide: Detailed Explanation of Reports</strong></a> -->
      <!-- <a :href="`${documents}`
          + shortschoolYear +`/FAQs.pdf`" target="_blank">
            <strong>FAQs</strong></a> -->
      <!-- <a :href="`${documents}`
          + shortschoolYear +`/DataPrivacyRules.pdf`" target="_blank">
            <strong>Data Privacy Rules: FERPA and Student Confidentiality
            </strong></a> -->
      <!-- <a :href="`${documents}`
          + shortschoolYear +`/SummaryGuide.pdf`" target="_blank">
            <strong>Summary Guide (English)</strong></a> -->
      <!-- <a :href="`${documents}`
          + shortschoolYear +`/SummaryGuideSpanish.pdf`" target="_blank">
            <strong>Summary Guide (Spanish)</strong></a> -->
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <h2 style="font-size: 14pt;">
            Toolkit Resources to help share and use School
            Performance Reports:
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="text-left">
          <ul>
            <li>
              One-page Guides to help start conversations:
              <ul>
                <li>
                  <a
                    href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OnePageGuide_BoardMembers.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >School Board Member Guide</a>
                </li>
                <li>
                  <a
                    href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OnePageGuides_Administrators.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Administrator Guide</a>
                </li>
                <li>
                  <a
                    href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OnePageGuides_Educators.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Educator Guide</a>
                </li>
                <li>
                  <a
                    href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OnePageGuides_Elementary_Families_Communities.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Elementary Families Guide</a>
                </li>
                <li>
                  <a
                    href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OnePageGuides_MS_HS_Families_Communities.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Middle and High School Families Guide</a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_OptionalPublicPresentationTemplate2 (002).pptx"
                target="_blank"
                rel="noopener noreferrer"
              >Public Presentation Template PPT</a>
            </li>
            <li>
              <a
                href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_SampleLetterParents.docx"
                target="_blank"
                rel="noopener noreferrer"
              >Sample Superintendent Letter</a>
            </li>
            <li>
              <a
                href="https://www.nj.gov/education/schoolperformance/resources/docs/SchoolPerformanceReports_DataSources.xlsx"
                target="_blank"
                rel="noopener noreferrer"
              >Data Sources Spreadsheet</a>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <h2 style="font-size: 14pt;">
            Graduation Rate and Median
            Student Growth Percentile Resources:
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="text-left">
          <ul>
            <li>
              <a
                href="https://www.njsmart.org/njr/ks/Key%20Documents/An%20Introduction%20to%20the%20Adjusted%20Cohort%20Graduation%20Rate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                An Introduction to the Adjusted Cohort Graduation
                Rate Calculation in New Jersey</a>
            </li>
            <li>
              <a
                href="https://www.nj.gov/education/schoolperformance/grad/understanding_acgr.shtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                Understanding Adjusted Cohort Graduation Rates</a>
            </li>
            <li>
              <a
                href="https://www.nj.gov/education/schoolperformance/growth/understanding_msgp.shtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                Understanding Median Student Growth Percentiles</a>
            </li>
            <li>
              <a
                href="https://www.njsmart.org/njr/ks/Key%20Documents/NJ%20SMART%20High%20School%20Graduation%20FAQs.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                NJ SMART High School Graduation FAQs</a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AdditionalView',
  computed: {
    schoolYear() {
      if (this.$store.getters.SelectedSchoolYear.text.substring(0, 4) < '2015') {
        return 'na';
      }
      return this.$store.getters.SelectedSchoolYear.text;
    },
    shortschoolYear() {
      return this.$store.getters.SelectedSchoolYear.value;
    },
    documents() {
      return process.env.VUE_APP_DOCUMENTS;
    },
    dataFiles() {
      return process.env.VUE_APP_DATA_FILES;
    },
    referenceGuide() {
      return process.env.VUE_APP_REFERENCE_GUIDE;
    },
  },
};
</script>
