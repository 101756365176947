<template>
  <div>
    <b-container class="pt-5">
      <b-card>
        <b-card-header>
          <h2>Maintenance Window</h2>
        </b-card-header>
        <b-card-body>
          <p>
            The School Performance Reports are temporarily
            unavailable while we make updates to the site.
            Please check back for an update.
          </p>
        </b-card-body>
        <b-card-footer />
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceView',
};
</script>

<style scoped>

</style>
