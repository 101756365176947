<template>
  <div>
    <h1>Please wait while we redirect you...</h1>
  </div>
</template>

<script>
import authFactory from '../services/auth.service';

export default {
  mounted() {
    localStorage.setItem('authOverride', 'handoff');

    const authService = authFactory.getAuthService();

    authService.logout();

    localStorage.setItem('access_code', this.$route.params.authToken);

    authService.getToken()
      .then(() => {
        this.$store.dispatch('getCurrentSchoolYears', 'override');
        this.$store.dispatch('getUserInfo');
        this.$router.push({ name: 'Home' });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>

</style>
