import axios from 'axios';

export default {
  async fileExists(fileUrl) {
    try {
      const response = await axios({
        method: 'HEAD',
        url: fileUrl,
        purpose: 'fileCheck',
        forceNoAuth: true,
      });
      return response.status !== 404;
    } catch {
      return false;
    }
  },
};
