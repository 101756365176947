import pattern from 'patternomaly';

export default {
  methods: {
    MapData(storeObject, chartTypes) {
      const dataObj = {
        Labels: [],
        ColumnHeaders: [],
        Values: [],
      };

      dataObj.Labels = storeObject.labels;
      // dataObj.ColumnHeaders = storeObject.columnHeaders;
      const nullColumnIndexes = [];
      let i = 0;
      for (i = 0; i < storeObject.columnHeaders.length; i += 1) {
        if (storeObject.columnHeaders[i] === undefined) {
          nullColumnIndexes.push(i); // skip columns that are undefined for the year
        } else {
          dataObj.ColumnHeaders.push(storeObject.columnHeaders[i]);
        }
      }
      let valArray = [];
      for (i = 0; i < storeObject.values.length; i++) { // eslint-disable-line no-plusplus
        if (nullColumnIndexes && nullColumnIndexes.includes(i + 1)) {
          // skip columns that are undefined for the year
          // eslint-disable-next-line no-continue
          continue;
        }
        valArray = storeObject.values[i].flat();
        dataObj.Values.push({
          fill: false,
          label: storeObject.columnHeaders[i + 1],
          data: valArray.map((x) => ((x || '')
          // growth->by grade viz has data in the format
          // 'Low<br/>40 ' 'Typical<br/>40 ' 'High<br/>40 '...
          // So separate data
            .replace('Low', '')
            .replace('Bajo', '')
            .replace('Typical', '')
            .replace('Típico', '')
            .replace('High', '')
            .replace('Alto', '')
            .replace('<br/>', '')
            .replace('%', '')
            .replace(',', '')
            .replace('<', '')
            .replace('>', '')
            .replace('*', '')
            .replace('NA', '')
            .replace('N/A', '')
            .replace('N', '')
          )),
          dataAsString: valArray,
          backgroundColor: chartTypes.BarChartColors[i],
          borderColor: chartTypes.BarChartColors[i],
        });
      }
      return dataObj;
    },
    ApplyStackedBarDisplayText(chartData) {
      const mappedData = chartData;

      // Apply specific colors for trends
      mappedData.Values[0].backgroundColor = [];
      mappedData.Values[0].borderColor = [];
      mappedData.Values[0].borderWidth = 0.5;
      mappedData.Values[1].backgroundColor = [];
      mappedData.Values[1].borderColor = [];
      mappedData.Values[1].borderWidth = 0.5;
      mappedData.Values[2].backgroundColor = [];
      mappedData.Values[2].borderColor = [];
      mappedData.Values[2].borderWidth = 0.5;

      let i;
      for (i = 0; i < mappedData.Values[0].data.length; i += 1) {
        if (mappedData.Values[0].dataAsString[i] === 'N') {
          mappedData.Values[0].data[i] = 0;
          mappedData.Values[0].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[0].borderColor.push('#7B7474');
        } else if (mappedData.Values[0].dataAsString[i] === 'NA') {
          mappedData.Values[0].data[i] = 0;
          mappedData.Values[0].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[0].borderColor.push('#7B7474');
        } else if (mappedData.Values[0].dataAsString[i] === 'N/A') {
          mappedData.Values[0].data[i] = 0;
          mappedData.Values[0].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[0].borderColor.push('#7B7474');
        } else {
          mappedData.Values[0].backgroundColor.push(pattern.draw('diagonal', '#c5dcf1'));
          mappedData.Values[0].borderColor.push('#7B7474');
        }
        if (mappedData.Values[1].dataAsString[i] === 'N') {
          mappedData.Values[1].data[i] = 0;
          mappedData.Values[1].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[1].borderColor.push('#7B7474');
        } else if (mappedData.Values[1].dataAsString[i] === 'NA') {
          mappedData.Values[1].data[i] = 0;
          mappedData.Values[1].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[1].borderColor.push('#7B7474');
        } else if (mappedData.Values[1].dataAsString[i] === 'N/A') {
          mappedData.Values[1].data[i] = 0;
          mappedData.Values[1].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[1].borderColor.push('#7B7474');
        } else {
          mappedData.Values[1].backgroundColor.push(pattern.draw('diagonal', '#327ec4'));
          mappedData.Values[1].borderColor.push('#7B7474');
        }
        if (mappedData.Values[2].dataAsString[i] === 'N') {
          mappedData.Values[2].data[i] = 0;
          mappedData.Values[2].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[2].borderColor.push('#7B7474');
        } else if (mappedData.Values[2].dataAsString[i] === 'NA') {
          mappedData.Values[2].data[i] = 0;
          mappedData.Values[2].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[2].borderColor.push('#7B7474');
        } else if (mappedData.Values[2].dataAsString[i] === 'N/A') {
          mappedData.Values[2].data[i] = 0;
          mappedData.Values[2].backgroundColor.push(pattern.draw('zigzag', '#000000'));
          mappedData.Values[2].borderColor.push('#7B7474');
        } else {
          mappedData.Values[2].backgroundColor.push('#1d4b75');
          mappedData.Values[2].borderColor.push('#7B7474');
        }
      }

      return mappedData;
    },
    ApplyTripleBarChartPatterns(chartData) {
      const mappedData = chartData;

      // Apply specific colors for trends
      mappedData.Values[0].backgroundColor = [];
      mappedData.Values[0].borderColor = [];
      mappedData.Values[0].borderWidth = 0.5;
      mappedData.Values[1].backgroundColor = [];
      mappedData.Values[1].borderColor = [];
      mappedData.Values[1].borderWidth = 0.5;
      mappedData.Values[2].backgroundColor = [];
      mappedData.Values[2].borderColor = [];
      mappedData.Values[2].borderWidth = 0.5;

      let i;
      for (i = 0; i < mappedData.Values[0].data.length; i += 1) {
        mappedData.Values[0].borderColor.push('#7B7474');
        mappedData.Values[1].borderColor.push('#7B7474');
        mappedData.Values[2].borderColor.push('#7B7474');
        // bar #0
        if (mappedData.Values[0].dataAsString[i] === 'N'
        || mappedData.Values[0].dataAsString[i] === 'NA'
        || mappedData.Values[0].dataAsString[i] === 'N/A'
        || mappedData.Values[0].dataAsString[i] === '*') {
          mappedData.Values[0].data[i] = 0;
          if (mappedData.Values[0].dataAsString.some((Q) => Q !== 'N' && Q !== 'NA' && Q !== 'N/A' && Q !== '*')) {
            // non zero values exist, so normal background color
            mappedData.Values[0].backgroundColor.push(pattern.draw('diagonal', '#c5dcf1'));
          } else {
            // all are zero values. So white background color
            mappedData.Values[0].backgroundColor.push('#FFFFFF');
          }
        } else {
          mappedData.Values[0].backgroundColor.push(pattern.draw('diagonal', '#c5dcf1'));
        }
        // bar #1
        if (mappedData.Values[1].dataAsString[i] === 'N'
        || mappedData.Values[1].dataAsString[i] === 'NA'
        || mappedData.Values[1].dataAsString[i] === 'N/A'
        || mappedData.Values[1].dataAsString[i] === '*') {
          mappedData.Values[1].data[i] = 0;
          if (mappedData.Values[1].dataAsString.some((Q) => Q !== 'N' && Q !== 'NA' && Q !== 'N/A' && Q !== '*')) {
            // non zero values exist, so normal background color
            mappedData.Values[1].backgroundColor.push(pattern.draw('diagonal', '#327ec4'));
          } else {
            // all are zero values. So white background color
            mappedData.Values[1].backgroundColor.push('#FFFFFF');
          }
        } else {
          mappedData.Values[1].backgroundColor.push(pattern.draw('diagonal', '#327ec4'));
        }
        // bar #2
        if (mappedData.Values[2].dataAsString[i] === 'N'
        || mappedData.Values[2].dataAsString[i] === 'NA'
        || mappedData.Values[2].dataAsString[i] === 'N/A'
        || mappedData.Values[2].dataAsString[i] === '*') {
          mappedData.Values[2].data[i] = 0;
          if (mappedData.Values[2].dataAsString.some((Q) => Q !== 'N' && Q !== 'NA' && Q !== 'N/A' && Q !== '*')) {
            // non zero values exist, so normal background color
            mappedData.Values[2].backgroundColor.push(pattern.draw('diagonal', '#1d4b75'));
          } else {
            // all are zero values. So white background color
            mappedData.Values[2].backgroundColor.push('#FFFFFF');
          }
        } else {
          mappedData.Values[2].backgroundColor.push('#1d4b75');
        }
      }

      return mappedData;
    },
    MapDataHBarChart(storeObject) {
      const dataArray = [];
      let tmp;
      for (let i = 0; i <= storeObject.length - 1; i += 1) {
        tmp = {
          Labels: [storeObject[i].data.header1, storeObject[i].data.header2],
          ChartTitle: storeObject[i].name,
          Values: [{
            backgroundColor: ['#5b9bd5', '#44546a'],
            pointBorderColor: '#249EBF',
            data: [+storeObject[i].data.data1.replace('%', '')
              .replace('N/A', '').replace('NA', '').replace('N', ''),
            +storeObject[i].data.data2.replace('%', '')
              .replace('N/A', '').replace('NA', '').replace('N', '')],
            dataAsString: [storeObject[i].data.data1, storeObject[i].data.data2],
            label: storeObject[i].name,
            // backgroundColor: chartTypes.BarChartColors[i],
            // borderColor: chartTypes.BarChartColors[i],
          }],
          ChartSubTitle: storeObject[i].name,
        };
        dataArray.push(tmp);
      }

      return dataArray;
    },
  },
};
