<template>
  <div>
    <b-container v-if="$store.getters.SelectedSchoolYear.text.substring(0, 4) >= '2015'" fluid>
      <b-row>
        <b-col cols="8" offset="1" class="text-left">
          <router-link :to="{ name: 'Home' }">
            <img src="../assets/prlogo.png" alt="NJ School Performance Reports Logo">
          </router-link>
          <!-- Replace this with actual logo and text -->
        </b-col>
      </b-row>
      <b-row class="pb-3">
        <b-col cols="8" offset="1" class="text-left">
          <h1>Download the Data:</h1>
        </b-col>
      </b-row>
      <b-row class="pb-3">
        <b-col cols="8" offset="1" class="text-left">
          <h2>{{ schoolYear }} Data Files</h2>
        </b-col>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="4" offset="1" class="text-left">
          {{ schoolYear }} Performance Reports Database - Excel
        </b-col>
        <b-col cols="1" class="text-left">
          <b-link
            :href="`${dataFiles}` + schoolYear
              + `/Database_SchoolDetail.xlsx`"
          >
            School
          </b-link>
        </b-col>
        <b-col cols="1">
          <b-link
            v-if="schoolYear !== '2015-2016'"
            :href="`${dataFiles}` + schoolYear
              + `/Database_DistrictStateDetail.xlsx`"
          >
            District/State
          </b-link>
        </b-col>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="4" offset="1" class="text-left">
          {{ schoolYear }} Performance Reports Database - Excel - zipped
        </b-col>
        <b-col cols="1" class="text-left">
          <b-link
            :href="`${dataFiles}` + schoolYear
              + `/Database_SchoolDetail.zip`"
          >
            School
          </b-link>
        </b-col>
        <b-col cols="1">
          <b-link
            v-if="schoolYear !== '2015-2016'"
            :href="`${dataFiles}` + schoolYear
              + `/Database_DistrictStateDetail.zip`"
          >
            District/State
          </b-link>
        </b-col>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="4" offset="1" class="text-left">
          {{ schoolYear }} Performance Reports Database - Access
        </b-col>
        <b-col cols="1" class="text-left">
          <b-link
            :href="`${dataFiles}` + schoolYear
              + `/Database_SchoolDetail.accdb`"
          >
            School
          </b-link>
        </b-col>
        <b-col cols="1">
          <b-link
            v-if="schoolYear !== '2015-2016'"
            :href="`${dataFiles}` + schoolYear
              + `/Database_DistrictStateDetail.accdb`"
          >
            District/State
          </b-link>
        </b-col>
      </b-row>
      <b-row class="py-4 footnote">
        <b-col cols="8" offset="1" class="text-left">
          It is recommended that you download these files to your
          local computer and not try to open them in your browser.
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" offset="1" class="pt-2 text-left">
          <strong>Database File Layouts: </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" offset="1" class="text-left">
          Performance Reports Database - Excel
        </b-col>
        <b-col cols="1" class="text-left">
          <b-link
            :href="`${dataFiles}` + schoolYear
              + `/Database_SchoolLayout.xlsx`"
          >
            School
          </b-link>
        </b-col>
        <b-col cols="1">
          <b-link
            v-if="schoolYear !== '2015-2016'"
            :href="`${dataFiles}` + schoolYear
              + `/Database_DistrictLayout.xlsx`"
          >
            District/State
          </b-link>
        </b-col>
      </b-row>
      <div
        v-if="schoolYear !== '2015-2016' && shortschoolYear < '1920'"
        class="pt-4 text-left"
      >
        <b-row>
          <b-col cols="8" offset="1">
            <h2>{{ schoolYear }} Resources:</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/ReferenceGuide.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reference Guide: Detailed Explanation of Reports</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/SummaryReportGuide.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              Summary Report Guide</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/DataPrivacyRules.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Privacy Rules: FERPA and Student Confidentiality</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear + `/FAQs.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQs</a>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="schoolYear === '2015-2016'"
        class="pt-4 text-left"
      >
        <b-row>
          <b-col cols="8" offset="1">
            <h2>{{ schoolYear }} Resources:</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/SuppressionRules.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ schoolYear }} Suppression Rules</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/ReferenceGuide.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ schoolYear }} Reference Guide</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              :href="`${documents}` + shortschoolYear
                + `/ClickByClick.pdf`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ schoolYear }} Navigation Guide</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              href="http://digitallearning.pcgus.com/NJSchoolPerformance/story.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ schoolYear }} Interpretive eLearning Video</a>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="shortschoolYear >= '1920'"
        class="pt-4 text-left"
      >
        <b-row>
          <b-col cols="8" offset="1">
            <h2>{{ schoolYear }} Resources:</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <!-- <a :href="`${referenceGuide}/ReferenceGuide.pdf`"
            target="_blank">
            Reference Guide: Detailed Explanation of Reports</a> -->
            <a
              href="/Documents/ReferenceGuide.pdf"
              target="_blank"
            >
              Reference Guide: Detailed Explanation of Reports</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <!-- <a :href="`${documents}/DataPrivacyRules.pdf`"
            target="_blank">
            Data Privacy Rules: FERPA and Student Confidentiality</a> -->
            <a
              href="/Documents/DataPrivacyRules.pdf"
              target="_blank"
            >
              Data Privacy Rules: FERPA and Student Confidentiality</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset="1">
            <a
              href="/Documents/FAQs.pdf"
              target="_blank"
            >
              FAQs</a>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <strong style="font-size: 12pt;"><b-link to="/">Return to Search</b-link></strong>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else fluid>
      <b-row>
        <b-col cols="8" offset="1" class="text-left">
          <router-link :to="{ name: 'Home' }">
            <img src="../assets/prlogo.png" alt="NJ School Performance Reports Logo">
          </router-link>
          <!-- Replace this with actual logo and text -->
        </b-col>
      </b-row>
      <b-row class="pb-3">
        <b-col cols="8" offset="1" class="text-left">
          <h1>Download the Data:</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <a
            href="https://nj.gov/education/schoolperformance/archive/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong style="font-size: 12pt;">Performance Reports Data Prior to 2015-16</strong>
          </a>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset="1" class="pt-4 text-left">
          <strong style="font-size: 12pt;"><b-link to="/">Return to Search</b-link></strong>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'DownloadView',
  computed: {
    schoolYear() {
      if (this.$store.getters.SelectedSchoolYear.text.substring(0, 4) < '2015') {
        return 'na';
      }
      return this.$store.getters.SelectedSchoolYear.text;
    },
    shortschoolYear() {
      return this.$store.getters.SelectedSchoolYear.value;
    },
    documents() {
      return process.env.VUE_APP_DOCUMENTS;
    },
    dataFiles() {
      return process.env.VUE_APP_DATA_FILES;
    },
    referenceGuide() {
      return process.env.VUE_APP_REFERENCE_GUIDE;
    },
  },
};
</script>

<style scoped>
.footnote {
  font-size: 10pt;
}
</style>
