<template>
  <b-row class="pb-3">
    <b-col col-12>
      <b-link v-scroll-to="`#${IconData.key}`" href="#">
        <div class="outside-border small-blue">
          <font-awesome-icon :icon="IconClass" :class="IconSize" />
          <!-- <span v-bind:class="`fas ${IconClass} fa-3x pr-4`"></span> -->
          <span>{{ IconData.rowHead }}</span>
          <!-- <ul>
                  <li v-for="bullet in IconData.bulletPoints" v-bind:key="bullet">
                    {{ bullet }}
                  </li>
                </ul> -->
        </div>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    // IconTarget: {
    //   type: String,
    //   default: '#highlightssssssss',
    //   required: false,
    // },
    IconClass: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
      IconSize: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    IconData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.small-blue{
  color: #287AB8;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 18pt;
}
.category-text{
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 0px;
  box-sizing: border-box;
  border: 2px solid #287AB8;
  width: 100%;
}
.outside-border {
  box-sizing: border-box;
  border: 2px solid #287AB8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}
</style>
