<template>
  <div>
    <b-container fluid class="header print-box">
      <b-row class="row flex-column-reverse flex-md-row">
        <b-col class="col-12 col-md-9 text-left col-print-12">
          <b-row class="">
            <b-col cols="12" md="3" class="pr-md-4 col-print-4">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="../assets/prlogo.png"
                  style="height:50px"
                  alt="NJ School Performance Reports Logo"
                  class="pt-2"
                >
              </router-link>
            </b-col>
            <b-col cols="12" md="9" class="pl-md-4 col-print-8">
              <div class="d-block">
                <h1 class="text-left text-md-right text-lg-left text-print-left mb-1 pt-2">
                  {{ NameOfLEO }}
                </h1>
                <span
                  style="position: relative; top: 0; left: 2in; font-weight: bold;"
                  class="d-none d-print-block"
                >
                  {{ SchoolYear }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 col-md-3 pb-4 pb-md-2 d-print-none">
          <b-row>
            <b-col cols="10">
              <omni-search :search-type="'short'" />
            </b-col>
            <b-col cols="1">
              <b-link
                v-if="showPrintLink"
                :href="PrintLink"
                title="print report"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon :icon="['fas', 'print']" class="small-icon" />
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="9" class="col-print-12">
          <b-row>
            <b-col cols="12" lg="8" class="school-info col-print-12">
              <b-row v-if="ReportType !== 'state'">
                <b-col cols="12" md="7" class="col-print-6">
                  <b-row>
                    <b-col>{{ RKCounty }}: {{ NameOfCounty }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col>{{ RKDistrict }}: {{ NameOfDistrict }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col class="school-info">
                      <b-row>
                        <b-col cols="1">
                          <b-icon-geo-alt-fill />
                        </b-col>
                        <b-col style="text-align: left;">
                          <span v-html="LocationAddress" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="school-info col-print-6" cols="12" md="5">
                  <b-row v-if="ReportType !== 'state'">
                    <b-col>{{ RKPrincipal }} {{ SchoolPrincipal }}</b-col>
                  </b-row>
                  <b-row v-if="ReportType !== 'state'">
                    <b-col>
                      <span v-html="RPSchoolWeb" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <a v-if="LEOPhone != '' && LEOPhone.length > 5" :href="`tel:+1-${ LEOPhone }`" class="phone">
                        <b-row>
                          <b-col cols="1">
                            <b-icon-telephone-fill />
                          </b-col>
                          <b-col>
                            {{ LEOPhone }}
                          </b-col>
                        </b-row>
                      </a>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="!HideMetrics" class="pt-4">
                <b-col>
                  <!--Row #1-->
                  <b-row
                    v-if="MetricKey('Total Students')"
                    class="pb-4"
                  >
                    <b-col
                      v-if="MetricKey('Total Students')"
                      :class="MetricKey('Economically Disadvantaged') ? `col-4` : `col-6 col-md-4`
                      "
                    >
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <i
                            :class="`fa-2x fa-print-2x ${MetricKey('Total Students').icon}`"
                          />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Total Students').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Total Students').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      v-if="MetricKey('Grades Offered')"
                      :class="MetricKey('Economically Disadvantaged') ? `col-4` : `col-6 col-md-4`"
                    >
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <i :class="`fa-2x fa-print-2x ${MetricKey('Grades Offered').icon}`" />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Grades Offered').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Grades Offered').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="MetricKey('English Learners')" cols="4">
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <i
                            :class="`fa-2x fa-print-2x ${MetricKey('English Learners').icon}`"
                          />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('English Learners').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('English Learners').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!--Row #2-->
                  <!--eslint-disable-next-line max-len-->
                  <b-row v-if="MetricKey('Economically Disadvantaged') || MetricKey('Students with Disabilities') || MetricKey('Avg Teacher Experience')" class="pb-4">
                    <b-col v-if="MetricKey('Students with Disabilities')" cols="4">
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <!--eslint-disable-next-line max-len-->
                          <i :class="`fa-2x fa-print-2x ${MetricKey('Students with Disabilities').icon}`" />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Students with Disabilities').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Students with Disabilities').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="MetricKey('Avg Teacher Experience')" cols="4">
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <!--eslint-disable-next-line max-len-->
                          <i :class="`fa-2x fa-print-2x ${MetricKey('Avg Teacher Experience').icon}`" />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Avg Teacher Experience').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Avg Teacher Experience').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="MetricKey('Economically Disadvantaged')" cols="4">
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <!--eslint-disable-next-line max-len-->
                          <i :class="`fa-2x fa-print-2x ${MetricKey('Economically Disadvantaged').icon}`" />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Economically Disadvantaged').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Economically Disadvantaged').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!--Row #3-->
                  <!--eslint-disable-next-line max-len-->
                  <b-row v-if="MetricKey('Student to Teacher Ratio')" class="pb-4">
                    <b-col v-if="MetricKey('Student to Teacher Ratio')" cols="4">
                      <b-row class="metrics">
                        <b-col cols="3" class="metrics-icon">
                          <!--eslint-disable-next-line max-len-->
                          <i :class="`fa-2x fa-print-2x ${MetricKey('Student to Teacher Ratio').icon}`" />
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12" class="metrics-number">
                              {{ MetricKey('Student to Teacher Ratio').value }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="metrics-text">
                              {{ MetricKey('Student to Teacher Ratio').text }}
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="4">
                      <b-row class="metrics">
                        <b-col cols="3">
                          <!-- -->
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12">
                              <!-- -->
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <!-- -->
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="4">
                      <b-row class="metrics">
                        <b-col cols="3">
                          <!-- -->
                        </b-col>
                        <b-col cols="9">
                          <b-row>
                            <b-col cols="12">
                              <!-- -->
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <!-- -->
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="ShowDoeNotes" class="doeNotes pb-4">
                <b-col>
                  <div class="notesContainer">
                    <div v-html="DoeNotesTitle" />
                    <p>{{ DoeNotesData }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="4" class="d-none d-lg-inline">
              <div id="map" ref="map" class="map-print d-print-none" style="height:100%;width:100%">
                <iframe class="map map-print" :src="MapUrl" title="Google Map" />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="3" class="d-print-none">
          <b-row />
          <b-row>
            <b-col>
              <h2>Jump to:</h2>
            </b-col>
          </b-row>
          <b-row class="text-left">
            <b-col>
              <b-link
                @click="handleHowToUse"
                @keydown="handleHowToUse"
              >
                <!-- Renamed @click.native -->
                How to use this report
              </b-link>
              <br v-if="ReportType !== 'state' && canSee" />
              <a
                v-if="ReportType !== 'state' && canSee"
                :href="stateRouterLink"
              >
                State Report
              </a>
              <br v-if="showSummaryLink" />
              <a
                v-if="showSummaryLink"
                :href="summaryRouterLink"
              >
                Summary Report
              </a>
              <br v-if="$route.name === 'SummaryReport'" />
              <a
                v-if="$route.name === 'SummaryReport'"
                :href="detailRouterLink"
              >
                Detailed Report
              </a>
              <br v-if="ReportType === 'school'" />
              <a
                v-if="ReportType === 'school'"
                :href="districtDetailRouterLink"
                class="link"
              >
                District Detailed Report
              </a>
              <br v-if="ReportType === 'school' && showDistrictSummaryLink" />
              <a
                v-if="ReportType === 'school' && showDistrictSummaryLink"
                :href="districtSummaryRouterLink"
                class="link"
              >
                District Summary Report
              </a>
              <br />
              <a
                href="/"
                class="link"
              >
                Search Another School/District
              </a>
            </b-col>
          </b-row>
          <!-- <b-row class="d-print-none">
            <b-col>
              <report-key></report-key>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="reportFooter" size="lg" :title="FooterTitle">
      <summary-footer />
    </b-modal>
  </div>
</template>

<script>
import TranslationSvc from '../services/translation.service';
import FileService from '../services/file.service';
import translation from '../mixins/translation-service.mixin';
import accessHandler from '../mixins/access-denied.mixin';
import OmniSearch from './OmniSearch.vue';
import SummaryFooter from './SummaryFooter.vue';

// import ReportKey from './ReportKey.vue';

export default {
  name: 'DistrictInformation',
  emits: ['close'],
  components: {
    OmniSearch,
    SummaryFooter,
    // ReportKey,
  },
  mixins: [translation, accessHandler],
  props: {
    HideMetrics: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      showPrintLink: false,
    };
  },
  computed: {
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    SchoolYear() {
      return `${this.$store.getters.SelectedSchoolYear.text}`;
    },
    currentReportType() {
      return this.$route.params.reportType.toLowerCase();
    },
    FooterTitle() {
      if (this.$route.name === 'ReportSummary') {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolSummary.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictSummary.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      } else {
        if (this.currentReportType === 'school') {
          return TranslationSvc.SchoolDetail.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd}`);
        }
        if (this.currentReportType === 'district') {
          return TranslationSvc.DistrictDetail.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
        if (this.currentReportType === 'state') {
          return TranslationSvc.State.getFooterTranslation(
            'Footer-Title',
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', `/${this.currentReportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}`);
        }
      }
      return '';
    },
    RKTitle() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.Title,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemStar() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemStar,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemStarStar() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemStarStar,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemN() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemN,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ItemPlus() {
      return this.translationService.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.ItemPlus,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DoeNotesTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NotesTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DoeNotesData() {
      try {
        const storeObjects = this.$store.getters.OverviewReportData.filter((o) => o.Component === `${this.ReportType}_OverviewData`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.doeNotes) {
          return '';
        }

        const storeObject = storeObjects[0].data.doeNotes;

        return storeObject;
      } catch (e) {
        console.error(e);
        return '';
      }
    },
    ShowDoeNotes() {
      try {
        const storeObjects = this.$store.getters.OverviewReportData.filter((o) => o.Component === `${this.ReportType}_OverviewData`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.doeNotes) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    showSummaryLink() {
      return this.$route.name !== 'SummaryReport'
        && this.ReportType !== 'state'
        && this.InstitutionTemplate !== 'NotTested';
    },
    showDistrictSummaryLink() {
      return this.ReportType !== 'state'
        && this.$store.getters.CurrentDistrictInfo.template
        && this.$store.getters.CurrentDistrictInfo.template !== 'NotTested';
    },
    InstitutionTemplate() {
      switch (this.ReportType) {
        case 'state':
          return '';
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.template;
        default:
          return this.$store.getters.CurrentDistrictInfo.template;
      }
    },
    isNotPrintView() {
      return !this.$store.getters.IsPrintView;
    },
    PrintLink() {
      let fileName;
      let rootPath;
      if (this.$route.params.schoolCd) {
        fileName = `${this.$route.params.countyCd}-${this.$route.params.districtCd}-${this.$route.params.schoolCd}${this.$store.getters.CurrentLanguage === 'ES' ? '-esp' : ''}.pdf`;
        rootPath = `${this.$route.name === 'SummaryReport' ? process.env.VUE_APP_HISTORICAL_SCHOOL_SUMMARY : process.env.VUE_APP_HISTORICAL_SCHOOL}`;
      } else if (this.$route.params.countyCd) {
        fileName = `${this.$route.params.countyCd}-${this.$route.params.districtCd}${this.$store.getters.CurrentLanguage === 'ES' ? '-esp' : ''}.pdf`;
        rootPath = `${this.$route.name === 'SummaryReport' ? process.env.VUE_APP_HISTORICAL_DISTRICT_SUMMARY : process.env.VUE_APP_HISTORICAL_DISTRICT}`;
      } else {
        fileName = `St${this.$store.getters.CurrentLanguage === 'ES' ? '-esp' : ''}.pdf`;
        rootPath = `${process.env.VUE_APP_HISTORICAL_STATE}`;
      }
      return `${rootPath}${this.$route.params.schoolYear}/${fileName}`;
    },
    NameOfCounty() {
      switch (this.ReportType) {
        case 'state':
          return '';
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.countyName;
        default:
          return this.$store.getters.CurrentDistrictInfo.countyName;
      }
    },
    RKCounty() {
      return TranslationSvc.SchoolSummary.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.RKCounty,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    RKDistrict() {
      return TranslationSvc.SchoolSummary.getReportKeyTranslation(
        TranslationSvc.Constants.ComponentNames.RKDistrict,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    RKPrincipal() {
      switch (this.ReportType) {
        case 'school':
          return TranslationSvc.SchoolSummary.getReportKeyTranslation(
            TranslationSvc.Constants.ComponentNames.RKPrincipal,
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          );
        case 'district':
          return TranslationSvc.DistrictSummary.getReportKeyTranslation(
            TranslationSvc.Constants.ComponentNames.RKSuper,
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          );
        default:
          return '-';
      }
    },
    RPSchoolWeb() {
      switch (this.ReportType) {
        case 'school':
          return TranslationSvc.SchoolSummary.getReportKeyTranslation(
            TranslationSvc.Constants.ComponentNames.RPSchoolWeb,
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', this.LEOWebsite);
        case 'district':
          return TranslationSvc.DistrictSummary.getReportKeyTranslation(
            TranslationSvc.Constants.ComponentNames.RPSchoolWeb,
            this.$store.getters.CurrentLanguage,
            this.$store.getters.SelectedSchoolYear.value,
          ).replace('{URL}', this.LEOWebsite);
        default:
          return '';
      }
    },
    NameOfDistrict() {
      switch (this.ReportType) {
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.districtName;
        case 'district':
          return this.$store.getters.CurrentDistrictInfo.districtName;
        default:
          return '-';
      }
    },
    NameOfLEO() {
      if (this.ReportType === 'school') {
        const school = this.$store.getters.CurrentSchoolInfo;
        if (!school.schoolName || !school.countyCode
        || !school.districtCode || !school.schoolCode) {
          return '';
        }
        return `${school.schoolName} (${school.countyCode}-${school.districtCode}-${school.schoolCode})`;
      }
      if (this.ReportType === 'district') {
        const district = this.$store.getters.CurrentDistrictInfo;
        if (!district || !district.districtName
        || !district.countyCode || !district.districtCode) {
          return '';
        }
        return `${district.districtName} (${district.countyCode}-${district.districtCode})`;
      }
      return 'New Jersey';
    },
    LocationAddress() {
      let address = '-';
      let district = {};
      let school = {};

      switch (this.ReportType) {
        case 'district':
          district = this.$store.getters.CurrentDistrictInfo;
          if (!district || !district.address1) { return address; }
          address = `${district.address1}<br class="keep-me"/>`;
          if (district && district.address2 && district.address2.trim().length > 0) {
            address = `${address + district.address2}<br class="keep-me"/>`;
          }
          address = `${address + district.city}, ${district.state} ${district.zip}`;
          break;
        case 'school':
          school = this.$store.getters.CurrentSchoolInfo;

          address = `${school.address1}<br class="keep-me"/>`;
          if (school && school.address2 && school.address2.trim().length > 0) {
            address = `${address + school.address2}<br class="keep-me"/>`;
          }
          address = `${address + school.city}, ${school.state} ${school.zip}`;
          break;
        default:
          address = '-';
          break;
      }

      return address;
    },
    MapUrl() {
      let leo = {};

      switch (this.ReportType) {
        case 'district':
          leo = this.$store.getters.CurrentDistrictInfo;
          break;
        case 'school':
          leo = this.$store.getters.CurrentSchoolInfo;
          break;
        default:
          break;
      }

      if (!leo.address1) {
        return 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=New+Jersey';
      }

      const address = `${leo.address1.replace(' ', '+')},${leo.city.replace(' ', '+')},${leo.state.replace(' ', '+')}+${leo.zip.replace(' ', '+')}`;

      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCqOosvffgGnFbYlTEd_JF9KZQONVcRR1I&q=${address}`;
    },
    SchoolPrincipal() {
      switch (this.ReportType) {
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.principal;
        case 'district':
          return this.$store.getters.CurrentDistrictInfo.superintendent;
        default:
          return '';
      }
    },
    LEOWebsite() {
      let website = '-';
      switch (this.ReportType) {
        case 'school':
          website = this.$store.getters.CurrentSchoolInfo.website;
          break;
        case 'district':
          website = this.$store.getters.CurrentDistrictInfo.website;
          break;
        default:
          return '-';
      }
      if (!website) return '';

      if (!website.startsWith('http')) {
        website = `//${website}`;
      }
      return website;
    },
    LEOPhone() {
      switch (this.ReportType) {
        // remove 1 from the start
        case 'school':
          return this.$store.getters.CurrentSchoolInfo.phoneNumber.trim().replace(/^(1\.)/, '');
        case 'district':
          return this.$store.getters.CurrentDistrictInfo.phoneNumber.trim().replace(/^(1\.)/, '');
        default:
          return '';
      }
    },
    summaryRouterLink() {
      const query = this.$route.query.lang || this.$store.getters.CurrentLanguage === 'ES' ? `?lang=${this.$store.getters.CurrentLanguage}` : '';
      return `/${this.$store.getters.SelectedSchoolYear.text}/${this.$route.params.reportType}/summary/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd || ''}${query}`;
    },
    detailRouterLink() {
      const query = this.$route.query.lang || this.$store.getters.CurrentLanguage === 'ES' ? `?lang=${this.$store.getters.CurrentLanguage}` : '';
      return `/${this.$store.getters.SelectedSchoolYear.text}/${this.$route.params.reportType}/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}/${this.$route.params.schoolCd || ''}${query}`;
    },
    districtDetailRouterLink() {
      const query = this.$route.query.lang || this.$store.getters.CurrentLanguage === 'ES' ? `?lang=${this.$store.getters.CurrentLanguage}` : '';
      return `/${this.$store.getters.SelectedSchoolYear.text}/district/detail/${this.$route.params.countyCd}/${this.$route.params.districtCd}${query}`;
    },
    districtSummaryRouterLink() {
      const query = this.$route.query.lang || this.$store.getters.CurrentLanguage === 'ES' ? `?lang=${this.$store.getters.CurrentLanguage}` : '';
      return `/${this.$store.getters.SelectedSchoolYear.text}/district/summary/${this.$route.params.countyCd}/${this.$route.params.districtCd}${query}`;
    },
    stateRouterLink() {
      const query = this.$route.query.lang || this.$store.getters.CurrentLanguage === 'ES' ? `?lang=${this.$store.getters.CurrentLanguage}` : '';
      return `/${this.$store.getters.SelectedSchoolYear.text}/state/detail/overview${query}`;
    },
  },
  watch: {
    ReportType() {
      this.fetchData();
    },
    Language() {
      // Clear the current School/District info so we can fetch
      // the data in the new language
      this.$store.dispatch('flushReportData');
      this.$store.dispatch('clearSchoolDistrictinfo');
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.getShowPrintLink();
  },
  methods: {
    handleHowToUse() {
      this.$bvModal.show('reportFooter');
    },
    async getShowPrintLink() {
      const printLink = this.PrintLink;
      const showLink = await FileService.fileExists(printLink);
      if (showLink) {
        console.log('File Exists returned TRUE');
      } else {
        console.log('File Exists returned FALSE');
      }
      this.showPrintLink = showLink;
    },
    // navigateDistrictDetail() {
    //   this.$store.dispatch('flushReportData');
    //   this.$store.dispatch('getDistrictDetailHeader',
    //     {
    //       schoolYear: this.$store.getters.SelectedSchoolYear.text,
    //       countyCd: this.$route.params.countyCd,
    //       districtCd: this.$route.params.districtCd,
    //       langCd: this.$store.getters.CurrentLanguage,
    //     });
    //   this.$router.push({
    //     name: 'Overview',
    //     params: {
    //       reportType: 'district',
    //       countyCd: this.$route.params.countyCd,
    //       districtCd: this.$route.params.districtCd,
    //     },
    //     query: {
    //       lang: this.$route.query.lang,
    //     },
    //   });
    // },
    // navigateDistrictSummary() {
    //   this.$store.dispatch('flushReportData');
    //   this.$store.dispatch('getDistrictDetailHeader',
    //     {
    //       schoolYear: this.$store.getters.SelectedSchoolYear.text,
    //       countyCd: this.$route.params.countyCd,
    //       districtCd: this.$route.params.districtCd,
    //       langCd: this.$store.getters.CurrentLanguage,
    //     });
    //   this.$router.push({
    //     name: 'SummaryReport',
    //     params: {
    //       reportType: 'district',
    //       countyCd: this.$route.params.countyCd,
    //       districtCd: this.$route.params.districtCd,
    //     },
    //     query: {
    //       lang: this.$route.query.lang,
    //     },
    //   });
    // },
    // MetricItem(index) {
    //   let items;
    //   switch (this.ReportType) {
    //     case 'school':
    //       if (this.$store.getters.CurrentSchoolInfo.schoolKeyData) {
    //         items = this.$store.getters.CurrentSchoolInfo.schoolKeyData;
    //       }
    //       break;
    //     case 'district':
    //       if (this.$store.getters.CurrentDistrictInfo.districtKeyData) {
    //         items = this.$store.getters.CurrentDistrictInfo.districtKeyData;
    //       }
    //       break;
    //     default:
    //       if (this.$store.getters.CurrentStateInfo.stateKeyData) {
    //         items = this.$store.getters.CurrentStateInfo.stateKeyData;
    //       }
    //       break;
    //   }
    //   if (!items || !items.length || items.length <= 0) {
    //     return undefined;
    //   }
    //   const item = items[index];
    //   if (!item) {
    //     return item;
    //   }
    //   item.icon = this.customIcon(item.key);
    //   return item;
    // },
    MetricKey(key) {
      let items;
      switch (this.ReportType) {
        case 'school':
          if (this.$store.getters.CurrentSchoolInfo.schoolKeyData) {
            items = this.$store.getters.CurrentSchoolInfo.schoolKeyData;
          }
          break;
        case 'district':
          if (this.$store.getters.CurrentDistrictInfo.districtKeyData) {
            items = this.$store.getters.CurrentDistrictInfo.districtKeyData;
          }
          break;
        default:
          if (this.$store.getters.CurrentStateInfo.stateKeyData) {
            items = this.$store.getters.CurrentStateInfo.stateKeyData;
          }
          break;
      }
      if (!items || !items.length || items.length <= 0) {
        return undefined;
      }
      const item = items.find((x) => x.key === key);
      if (!item) {
        return item;
      }
      item.icon = this.customIcon(item.key);
      return item;
    },
    customIcon(key) {
      let cssClass;
      switch (key) {
        case 'Total Students':
          cssClass = 'fas fa-users';
          break;
        case 'Grades Offered':
          cssClass = 'fas fa-school';
          break;
        case 'English Learners':
          cssClass = 'fas fa-book-reader';
          break;
        case 'Avg Teacher Experience':
          cssClass = 'fas fa-briefcase';
          break;
        case 'Students with Disabilities':
          cssClass = 'fab fa-accessible-icon';
          break;
        case 'Student to Teacher Ratio':
          cssClass = 'fas fa-user-circle';
          break;
        case 'Economically Disadvantaged':
          cssClass = 'fas fa-dollar-sign';
          break;
        default:
          cssClass = 'fas fa-users';
          break;
      }
      return cssClass;
    },
    fetchData() {
      if (this.$route.name === 'SummaryReport' || this.$route.name === 'PrintSummary') {
        if (this.$route.params.reportType.toLowerCase() === 'school') {
          this.$store.dispatch(
            'getSchoolSummaryHeader',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
        }
        this.$store.dispatch(
          'getDistrictSummaryHeader',
          {
            schoolYear: this.$store.getters.SelectedSchoolYear.text,
            countyCd: this.$route.params.countyCd,
            districtCd: this.$route.params.districtCd,
            langCd: this.$store.getters.CurrentLanguage,
          },
        );
      } else {
        if (this.ReportType === 'state') {
          this.$store.dispatch(
            'getStateDetailHeader',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
        }
        if (this.ReportType === 'district') {
          this.$store.dispatch(
            'getDistrictDetailHeader',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
          this.$store.dispatch(
            'getOverview',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              reportType: this.ReportType,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
        }
        if (this.ReportType === 'school') {
          this.$store.dispatch(
            'getSchoolDetailHeader',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
          this.$store.dispatch(
            'getDistrictDetailHeader',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
          this.$store.dispatch(
            'getOverview',
            {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              reportType: this.ReportType,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
              langCd: this.$store.getters.CurrentLanguage,
            },
          );
        }
      }
    },
  },
};
</script>

<style scoped>
h1{
    text-align: left;
    padding-top: 20px;
    font-size: 20px;
}
h2{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 0px;
    margin: 0px;
    margin-top: -8px;
}
@media (min-width: 768px) {
  h1{
    font-size: 22px;
  }
  h2{
      font-size: 18px;
  }
}

@media print {
  .doeNotes {
    font-size: 8pt;
  }
}

a{
    text-decoration: underline;
}
.search-box {
  font-size: 18px;
  color: #7B7474;
  font-weight: 600;
  height: auto;
}
.school-info{
    text-align: left;
    font-size: 14px;
    line-height: 22px;
}
.phone{
  color: black;
  text-decoration: none;
}
.map{
  width: 100%;
  height: 250px;
  background: gray;
}
.questions {
  padding-top: 35px;
  padding-left: 20px;
}
a, .link{
  color: #00289C;
  font-size: 14px;
  text-align: left;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.metrics{
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
}
.header {
  margin-bottom: 5px;
}
.metrics-icon {
  align-self: center;;
}
.metrics-number {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.metrics-text {
  text-align: center;
  font-size: 14px;
}
.notesContainer {
  border: 1px solid rgb(40, 122, 184);
  padding: .5em 1em;
  border-radius: 4px;
  line-height: 12px;
}
@media print {
  .notesContainer {
    line-height: 10px;
    font-size: 7.5pt;
  }
}
</style>
