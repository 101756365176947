<template>
  <div>
    <b-container class="pt-5">
      <b-card>
        <b-card-header>
          <h2>CDS Not Found</h2>
        </b-card-header>
        <b-card-body>
          <p>This School/District has not submitted data for the current selected school year.</p>
        </b-card-body>
        <b-card-footer>
          <b-link :to="{ name: 'Home' }">
            Return to Search Page
          </b-link>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    refreshLink() {
      return `${this.$route.query.url}`;
    },
    haveLink() {
      return this.$route.query.url && this.$route.query.url.length > 0;
    },
  },
};
</script>

<style scoped>

</style>
