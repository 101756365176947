<template>
  <div>
    <b-container class="pt-5">
      <b-card>
        <b-card-header>
          <h2>Connection Error</h2>
        </b-card-header>
        <b-card-body>
          <p>
            Looks like the page encountered an error while fetching your data.
            Please refresh your page and try again.
          </p>
          <b-link v-if="haveLink" :href="refreshLink">
            Refresh
          </b-link>
        </b-card-body>
        <b-card-footer>
          <b-link :to="{ name: 'Home' }">
            Return to Search Page
          </b-link>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ErrorView',
  computed: {
    refreshLink() {
      return `${this.$route.query.url}`;
    },
    haveLink() {
      return this.$route.query.url && this.$route.query.url.length > 0;
    },
  },
};
</script>

<style scoped>

</style>
