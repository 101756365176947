import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    academicReportData: [],
  },
  getters: {
    AcademicReportData: (state) => state.academicReportData,
  },
  mutations: {
    SET_ACADEMIC_REPORT_DATA(state, payload) {
      const component = state.academicReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.academicReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getDistrictEnglishMathELATrend(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishMathELATrend`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            /* eslint-disable */
            response = await
            districtService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            /* eslint-enable */
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService
              .Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await
            stateService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishMathELATrend`, data: response });
      }
    },
    async getDistrictEnglishMathMathTrend(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishMathMathTrend`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            /* eslint-disable */
            response = await
            districtService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            /* eslint-enable */
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService
              .Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await
            stateService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishMathMathTrend`, data: response });
      }
    },
    async getDistrictEnglishMathTrend(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishMathTrend`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrend(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService
              .Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrend(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await
            stateService.Detail.Academic.getEnglishLanguageArtsandMathematicsPerformanceTrend(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishMathTrend`, data: response });
      }
    },
    async getELAPartPerf(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_ELAPartPerf`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Academic.getELAParticipationPerformance(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getELAParticipationPerformance(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getELAParticipationPerformance(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_ELAPartPerf`, data: response });
      }
    },
    async getDistrictEnglishPerformance(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishPerformance`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await
            districtService.Detail.Academic.getEnglishLanguageArtsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            /* eslint-disable */
            response = await schoolService.Detail.Academic.getEnglishLanguageArtsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            /* eslint-enable */
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await
            stateService.Detail.Academic.getEnglishLanguageArtsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishPerformance`, data: response });
      }
    },
    async getELAPerfByGradeX(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_ELAPerfByGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getELAPerformanceByGradeX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getELAPerformanceByGradeX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getELAPerformanceByGradeX(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_ELAPerfByGrade`, data: response });
      }
    },
    async getMathPartPerf(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_MathPartPerf`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getMathParticipationPerformance(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getMathParticipationPerformance(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getMathParticipationPerformance(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_MathPartPerf`, data: response });
      }
    },
    async getDistrictMathPerformance(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_MathPerformance`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            /* eslint-disable */
            response = await districtService.Detail.Academic.getMathematicsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            /* eslint-enable */
            break;
          case 'school':
            /* eslint-disable */
            response = await schoolService.Detail.Academic.getMathematicsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            /* eslint-enable */
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getMathematicsAssessmentPerformanceTrends(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_MathPerformance`, data: response });
      }
    },
    async getMathPerfByGradeX(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_MathPerfByGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getMathPerformanceByGradeX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getMathPerformanceByGradeX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getMathPerformanceByGradeX(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_MathPerfByGrade`, data: response });
      }
    },
    async getMathPerfByTestX(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_MathPerfByTest`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getMathPerformanceByTestX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getMathPerformanceByTestX(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getMathPerformanceByTestX(payload.schoolYear, payload.langCd);
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_MathPerfByTest`, data: response });
      }
    },
    async getNJSLAPerformanceByGradeTable(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_NJSLAScienceAssessmentGradeXTable`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getNJSLAScienceAssessmentGradeXGrid(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getNJSLAPerformanceByGradeTable(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getNJSLAPerformanceByGradeTable(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_NJSLAScienceAssessmentGradeXTable`, data: response });
      }
    },
    async getDLMAssessmentParticipation(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_DLMAssessmentParticipation`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getDLMAssessment(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getDLMAssessment(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getDLMAssessment(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_DLMAssessmentParticipation`, data: response });
      }
    },
    async getEnglishProfTest(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishProgressProficiencyTest`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getEnglishProficiencyTest(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getEnglishProficiencyTest(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getEnglishProficiencyTest(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishProgressProficiencyTest`, data: response });
      }
    },
    async getEnglishProgressProf(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishProgressProficiency`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getEnglishProgressToProf(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getEnglishProgressToProf(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getEnglishProgressToProf(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishProgressProficiency`, data: response });
      }
    },
    async getNJGPA11(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_NJGPA11`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getNJGPA11(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getNJGPA11(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getNJGPA11(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_NJGPA11`, data: response });
      }
    },
    async getDistrictEnglishParticipationPerf(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_EnglishParticipationPerf`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Academic.getEnglishParticipationPerf(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService.Detail.Academic.getEnglishParticipationPerf(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.schoolCd,
              payload.langCd,
            );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic.getEnglishParticipationPerf(
              payload.schoolYear,
              payload.langCd,
            );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_EnglishParticipationPerf`, data: response });
      }
    },
    async getNJSLAScienceAssessmentGradeXSummary(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_NJSLAScienceAssessmentGradeXSummary`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getNJSLAScienceAssessmentGradeXSummary(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getNJSLAScienceAssessmentGradeXSummary(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getNJSLAScienceAssessmentGradeXSummary(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_NJSLAScienceAssessmentGradeXSummary`, data: response });
      }
    },
    async getNJSLAPerformanceByGradeXTable(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_NJSLAPerfByGradeTable`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail
              .Academic.getNJSLAPerformanceByGradeXTable(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail
              .Academic.getNJSLAPerformanceByGradeXTable(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            response = await stateService.Detail
              .Academic.getNJSLAPerformanceByGradeXTable(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_NJSLAPerfByGradeTable`, data: response });
      }
    },
    async getNationalAssessmentEducationalProgressNAEP(
      { commit, state },
      payload,
    ) {
      if (!state.academicReportData.some((o) => o.Component === `${payload.reportType}_NationalAssessmentEducationalProgressNAEP`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // response = await districtService.Detail.Academic
            //   .getNationalAssessmentEducationalProgressNAEP(
            //     payload.schoolYear, payload.countyCd, payload.districtCd, payload.langCd,
            //   );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            // response = await schoolService.Detail.Academic
            //   .getNationalAssessmentEducationalProgressNAEP(
            //     payload.schoolYear,
            //     payload.countyCd, payload.districtCd, payload.schoolCd,
            //     payload.langCd,
            //   );
            break;
          default: // state
            // eslint-disable-next-line max-len
            response = await stateService.Detail.Academic
              .getNationalAssessmentEducationalProgressNAEP(payload.schoolYear, payload.langCd);
            break;
        }
        commit('SET_ACADEMIC_REPORT_DATA', { Component: `${payload.reportType}_NationalAssessmentEducationalProgressNAEP`, data: response });
      }
    },
  },
};
