import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSidebarMenu from 'vue-sidebar-menu';

import VueDebounce from 'vue-debounce';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import iFrameResizer from 'iframe-resizer/js/iframeResizer';

import axios from 'axios';
import VueHtmlToPaper from 'vue-html-to-paper';
import App from './App.vue';
import router from './router';
import store from './store';
// eslint-disable-next-line import/order
import VueScrollTo from 'vue-scrollto';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import axiosHandlers from './axios';

library.add(fas);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.directive('resize', {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResizer(value, el));
  },
  unbind(el) {
    el.iFrameResizer.removeListeners();
  },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSidebarMenu);
Vue.use(VueScrollTo, {
  easing: 'ease-out',
  offset: -100,
});

Vue.use(VueDebounce, {
  lock: false,
  listenTo: 'keypress',
  defaultTime: '800ms',
  fireOnEmpty: false,
});

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    appName: process.env.VUE_APP_NAME,
    pageTrackerScreenviewEnabled: true,
  },
}, router);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue-icons.css',
    // 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css',
    // `${process.env.VUE_APP_ROOT}bootstrap-print.css`,
    `${process.env.VUE_APP_ROOT}print.css`,
  ],
};
Vue.use(VueHtmlToPaper, options);
const vm = new Vue({
  router,
  store,
  created() {
    // Axios Interceptors
    axios.interceptors.request.use(
      axiosHandlers.requestHandler,
      axiosHandlers.requestErrorHandler,
    );
    axios.interceptors.response.use(
      axiosHandlers.responseHandler,
      axiosHandlers.responseErrorHandler,
    );
  },
  render: (h) => h(App),
}).$mount('#app');

export default vm;
