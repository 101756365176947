import TranslationSvc from '../../services/translation.service';
import ChartTypes from '../../components/ChartTypes';
import navigation from '../navigation.mixin';
import mapping from '../mapping.mixin';

export default {
  mixins: [navigation, mapping],
  data() {
    return {
      ChartTypes,
    };
  },
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };
      this.$store.dispatch('getDistrictEnrollmentByGrade', params);
      this.$store.dispatch('getDistrictEnrollmentByDay', params);
      this.$store.dispatch('getDistrictEnrollmentByStudentGroup', params);
      this.$store.dispatch('getDistrictEnrollmentByTime', params);
      this.$store.dispatch('getDistrictEnrollmentByRace', params);
      this.$store.dispatch('getDistrictEnrollmentByHomeLanguage', params);
    },
  },
  computed: {
    EnrollmentSectionHeader() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.EnrollmentHeader,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    PageHeader() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.EnrollmentHeader);
    },
    // #region Enrollment by Grade
    EnrollmentByGradeId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByGradeTitle);
    },
    EnrollmentByGradeTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByGradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByGradeDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByGradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByGradeData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByGrade`);
      if (!storeData || storeData.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    // #endregion Enrollment by Grade
    // #region Enrollment by Student Group
    EnrollmentByStudentGroupId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByStdntTitle);
    },
    EnrollmentByStudentGroupTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByStdntTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByStudentGroupDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByStdntText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByStudentGroupTableShading() {
      return [
        {
          cellValues: ['N/A', 'NA'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    EnrollmentByStudentGroupData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByStudentGroup`);
      if (!storeData || storeData.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    // #endregion Enrollment by Student Group
    // #region Enrollment by Race
    EnrollmentByRaceId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByRaceTitle);
    },
    EnrollmentByRaceTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByRaceTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByRaceDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByRaceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByRaceData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByRace`);
      if (!storeData || storeData.length === 0) {
        return {};
      }

      const storeObject = storeData[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    // #endregion Enrollment by Race
    // #region Enrollment by Day
    EnrollmentByDayId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByDayTitle);
    },
    EnrollmentByDayTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByDayTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByDayDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByDayText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByDayData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByDay`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    // #endregion Enrollment by Day
    // #region Enrollment by Time
    EnrollmentByTimeId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByTimeTitle);
    },
    EnrollmentByTimeTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByTimeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByTimeDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByTimeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByTimeData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByTime`);
      if (!storeData || storeData.length === 0) {
        return {};
      }
      const storeObject = storeData[0].data;

      return this.MapData(storeObject, this.ChartTypes);
    },
    // #endregion Enrollment by Time
    // #region Enrollment by Home Language
    EnrollmentByLanguageId() {
      return this.GetElementIdByTitle(TranslationSvc.Constants.ComponentNames.DemogByLangTitle);
    },
    EnrollmentByLanguageTitle() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByLangTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByLanguageDescription() {
      return this.translationService.getDemographicTranslation(
        TranslationSvc.Constants.ComponentNames.DemogByLangText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnrollmentByLanguageData() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByHomeLanguage`);
      if (!storeData || storeData.length === 0) {
        return {};
      }

      const storeObject = storeData[0].data;

      const responseObject = this.MapData(storeObject, this.ChartTypes);

      let i;
      let j;

      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Values[j].data.length; i++) {
          responseObject.Values[j].backgroundColor.push(
            this.ChartTypes.BarChartColors[3],
          );
          responseObject.Values[j].borderColor.push(
            this.ChartTypes.BarChartColors[3],
          );
        }
      }

      if (responseObject.Values.length === 1 && responseObject.Values[0].dataAsString[0] === 'N') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.EnrollmentByLanguageTableToggle = this.ChartTypes.TableGrid;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.EnrollmentByLanguageTableToggle = this.ChartTypes.BarChart;
      }

      return responseObject;
    },
    EnrollmentByLanguageToggleText() {
      return 'Table View';
    },
    // #endregion Enrollment by Home Language
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
