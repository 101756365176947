var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"pt-4 d-print-none"},[_c('b-col',[_c('section-header',{attrs:{"id":_vm.AccountabilityHeaderId,"header-text":_vm.AccountabilitySectionHeader}})],1)],1),_c('b-row',{staticClass:"pb-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"intro",domProps:{"innerHTML":_vm._s(_vm.AccountabilityIntro)}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('visual-component',{attrs:{"id":_vm.ComprehensiveOrTargetedSupportId,"visual-title":_vm.ComprehensiveOrTargetedSupportTitle,"visual-description":_vm.ComprehensiveOrTargetedSupportDescription,"chart-type":!_vm.HideComprehensiveOrTargettedSupportData
                ? _vm.ChartTypes.None : _vm.ChartTypes.TableGrid,"chart-data":_vm.ComprehensiveOrTargettedSupportData,"visual-no-data-text":_vm.ComprehensiveOrTargettedSupportNoDataText}})],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.ReportType === 'school')?_c('visual-component',{attrs:{"id":_vm.AccountabilityStatusId,"visual-title":_vm.AccountabilityStatusTitle,"visual-description":_vm.AccountabilityStatusDescription,"visual-footer":_vm.AccountabilityStatusPlusFooter,"chart-type":_vm.ChartTypes.TableGrid,"chart-data":_vm.AccountabilityStatusData,"print-container-id":'ESSAAS'}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('visual-component',{attrs:{"id":_vm.AccountabilityProgressId,"visual-title":_vm.AccountabilityProgressTitle,"visual-description":_vm.AccountabilityProgressDescription,"visual-footer":_vm.AccountabilityProgressPlusFooter,"chart-type":_vm.ChartTypes.TableGrid,"table-shading":_vm.AccountabilityProgressTableShading,"chart-data":_vm.AccountabilityProgressData}})],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.$store.getters.SelectedSchoolYear.value != 2021
                && _vm.ReportType == 'school')?_c('visual-component',{attrs:{"id":_vm.AccountabilitySummativeId,"visual-title":_vm.AccountabilitySummativeTitle,"visual-description":_vm.AccountabilitySummativeDescription,"chart-type":_vm.ChartTypes.TableGrid,"chart-data":_vm.AccountabilitySummativeData,"table-shading":_vm.AccountabilitySummativeTableShading,"visual-footer":_vm.AccountabilitySummativeFooter,"horizontal-lines":['Summative Score', 'Puntaje sumativo']}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.$store.getters.SelectedSchoolYear.value != 2021
                && _vm.ReportType != 'state')?_c('visual-component',{attrs:{"id":_vm.AccountabilitySummaryId,"visual-title":_vm.AccountabilitySummaryTitle,"visual-description":_vm.AccountabilitySummaryDescription,"chart-type":_vm.ChartTypes.TableGrid,"chart-data":_vm.AccountabilitySummaryData,"table-shading":_vm.AccoutabilitySummaryShading,"visual-footer":_vm.AccountabilitySummaryGroupPlusFooter,"horizontal-lines":['Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja'],"vertical-lines":[
                'Requires Additional Targeted Support: Low Performing Student Group',
                'Requiere apoyo específico adicional: grupo de estudiantes de bajo rendimiento',
                'Chronic Absenteeism', 'Absentismo Crónico',
              ]}}):_vm._e()],1)],1)],1)],1)],1),_c('b-container',{staticClass:"pt-4",attrs:{"fluid":""}},[_c('b-row',{staticClass:"pt-4"},[_c('b-col',{staticClass:"pt-4"},[_c('br'),_c('br')])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }