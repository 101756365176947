<template>
  <div class="page" />
</template>

<script>
import LoginService from '../services/login.service';
import TokenService from '../services/token.service';

export default {
  name: 'LogoutService',
  created() {
    LoginService.logout();
    TokenService.logout();
    this.$router.push({ name: 'Login' });
  },
};
</script>

<style scoped>

</style>
