<template>
  <div>
    <b-container class="pt-5">
      <b-card>
        <b-card-header>
          <h2>Authentication Required</h2>
        </b-card-header>
        <b-card-body>
          <p>
            Please log into NJ SMART at
            <a :href="NJ_Url">{{ NJ_Url }}</a>
            and use the Performance Reports link in the reporting section to access
            these reports.
          </p>
        </b-card-body>
        <b-card-footer />
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    NJ_Url() {
      return process.env.VUE_APP_NJ_URL;
    },
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
