/* eslint no-underscore-dangle: 0 */
import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  props: {
    DataLabels: {
      default: () => ['School', 'State'], // Comes from Store Object : Labels
    },
    DataSets: {
      default: () => [
        {
          backgroundColor: ['#5b9bd5', '#44546a'],
          pointBorderColor: '#249EBF',
          data: [72.7, 35.2], // Comes from Store Object : Data[i]
          label: '{Column Header}', // Comes from Store Object : ColumnHeader[i]
        },
      ],
    },
    ShowXAxisLabels: {
      default: true,
    },
  },
  computed: {
    labels() {
      return this.$props.DataLabels;
    },
    datasets() {
      return this.$props.DataSets;
    },
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        layout: {
          padding: {
            right: 45,
          },
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
            },
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              callback: this.xAxisLabels,
              fontSize: '15',
              beginAtZero: true,
              min: 0,
              max: 100,
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            ticks: {
              fontStyle: 'bold',
              fontSize: '15',
              beginAtZero: true,
              display: true,
              min: 0,
              max: 100,
            },
            gridLines: {
              display: false,
            },
          }],
        },
        animation: {
          duration: this.isPrintView ? 0 : 1,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = '14px Roboto';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                // const data = dataset.data[index];
                // ctx.fillText(`${data}%`, bar._model.x + 25, bar._model.y + 5);
                const data = dataset.data[index];
                const dataStr = dataset.dataAsString[index];
                if (!dataStr) { return; }
                let { x } = bar._model;
                if (data && data >= 90) {
                  x += 22;
                } else if (data && data >= 80) {
                  x += 25;
                } else if (dataStr === '*' || dataStr === 'N/A' || dataStr === 'NA' || dataStr === 'N') {
                  x += 5;
                } else {
                  x += 25;
                }
                const y = bar._model.y + 7;
                // if (data >= 90) ctx.textAlign = 'right';
                // ctx.fillText(dataStr, x, y);
                ctx.fillText(dataStr, x, y);
              });
            });
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  methods: {
    xAxisLabels(value) {
      if (this.$props.ShowXAxisLabels) return value;
      return '';
    },
    render() {
      const dataCollection = {
        labels: this.labels,
        datasets: this.datasets,
      };
      this.renderChart(dataCollection, this.options);
    },
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
  },
  data() {
    return {
    };
  },
  watch: {
    labels() {
      this.render();
    },
    datasets() {
      this.render();
    },
  },
  mounted() {
    if (this.$store.getters.IsPrintView) {
      this.$refs.canvas.height = 75;
    }
    this.render();
  },
};
