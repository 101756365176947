<template>
  <div class="omniContainer">
    <div v-if="SearchType === 'long'" class="search underline">
      <input
        v-model="searchString"
        id="search"
        v-debounce:500ms="performSearch"
        type="text"
        class="search-input d-none d-sm-inline"
        placeholder="Search by County, School Name, District, or Zip Code"
        @keydown.up="scrollUp"
        @keydown.down="scrollDown"
        @keyup.delete="performSearch"
      />
      <input
        v-model="searchString"
        id="search"
        v-debounce:500ms="performSearch"
        type="text"
        class="search-input d-inline d-sm-none"
        placeholder="County, School Name, District, or Zip"
        @keydown.up="scrollUp"
        @keydown.down="scrollDown"
        @keyup.delete="performSearch"
      />
      <b-icon-search class="omni-icon" />
      <label for="search" class="sr-only">Search for School Or District</label>
    </div>
    <div v-else class="input-group underline">
      <input
        id="search short"
        v-model="searchString"
        v-debounce:500ms="performSearch"
        type="text"
        class="form-control search-input-short"
        placeholder="Search School/District"
        @keydown.up="scrollUp"
        @keydown.down="scrollDown"
      />
      <b-icon-search class="small-icon" />
      <label for="search short" class="sr-only">Search for School Or District</label>
    </div>
    <div>
      <label for="result list" ref="results-msg-lbl" class="" />
    </div>
    <div v-show="searchResults.length > 0" class="resultList">
      <b-list-group
        id="resultContainer"
        ref="result-container"
        style="overflow: auto; max-height: 300px;"
      >
        <!-- @click="handleClick(result)" -->
        <b-list-group-item
          v-for="(result, index) in searchResults"
          :id="`SI-${index}`"
          :key="index"
          :href="clickableLink(result)"
          :target="clickableLinkTarget(result)"
          :class="{ active: index === selectedIndex }"
          @mouseenter="setIndex(index)"
          @focusin="setIndex(index)"
        >
          <b-row>
            <b-col
              :class="{
                'col-12 col-md-10  text-left': SearchType === 'long',
                'col-12 text-left': SearchType !== 'long',
              }"
            >
              {{ displayString(result) }}
            </b-col>
            <b-col
              :class="{
                'col-12 col-md-2  text-right font-weight-light': SearchType === 'long',
                'col-12 text-right font-weight-light small': SearchType !== 'long',
              }"
              px-2
            >
              {{ result.entity }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import omniSearch from '../services/omni-search.service';

export default {
  props: {
    SearchType: {
      type: String,
      default: 'long',
      required: true,
    },
  },
  data() {
    return {
      searchString: '',
      searchResults: [],
      selectedIndex: 0,
    };
  },
  computed: {
  },
  watch: {
    selectedIndex(newValue, oldValue) {
      console.log(`Selected index changed from ${oldValue} to ${newValue}`);
    },
  },
  methods: {
    makeVisible() {
      this.$scrollTo(`#SI-${this.selectedIndex}`, {
        container: '#resultContainer',
        duration: 10,
        force: true,
        x: false,
        y: true,
      });
      // const yPos = (this.selectedIndex - 5) * 47;
      // this.$refs['result-container'].scrollTo(0, yPos);
    },
    scrollDown() {
      this.selectedIndex += 1;
      if (this.selectedIndex >= this.searchResults.length) {
        this.selectedIndex = this.searchResults.length - 1;
      }
      this.makeVisible();
    },
    scrollUp() {
      this.selectedIndex -= 1;
      if (this.selectedIndex < 0) {
        this.selectedIndex = 0;
      }
      this.makeVisible();
    },
    setIndex(index) {
      this.selectedIndex = index;
    },
    displayString(result) {
      switch (result.entity) {
        case 'School':
          return `${result.schoolName} - ${result.districtName} (${result.countyCode}-${result.districtCode}-${result.schoolCode})`;
        case 'District':
          return `${result.districtName} (${result.countyCode}-${result.districtCode})`;
        default:// county
          return `${result.countyName} (${result.countyCode})`;
      }
    },
    clickableLink(result) {
      if (result.entity === 'County') {
        return `/schoolbycounty/${result.countyCode}/list`;
      }
      if (this.$store.getters.SelectedSchoolYear.value <= 1415) {
        let schoolyear;
        schoolyear = this.$store.getters.SelectedSchoolYear.text.substring(0, 4);
        schoolyear += this.$store.getters.SelectedSchoolYear.text.substring(7, 9);
        // eslint-disable-next-line
        return 'https://nj.gov/education/schoolperformance/archive/' + schoolyear + '/reports/' + `${result.countyCode}` + '/' + `${result.countyCode}` + `${result.districtCode}` + `${result.schoolCode || ''}` + '.pdf';
        // new tab
      }
      if (this.$store.getters.SelectedSchoolYear.value <= 1516) {
        return `${process.env.VUE_APP_HISTORICAL_SCHOOL}${this.$store.getters.SelectedSchoolYear.text}/${result.countyCode}-${result.districtCode}-${result.schoolCode}.pdf`;
        // new tab
      }
      if (this.$store.getters.SelectedSchoolYear.historical) {
        return `/prioryearreport/${this.$store.getters.SelectedSchoolYear.text}/${result.countyCode}/${result.districtCode}/${result.schoolCode}`;
      }
      let path = `/selectreport/${this.$store.getters.SelectedSchoolYear.text}/${result.countyCode}/${result.districtCode}`;
      if (result.schoolCode && result.schoolCode.length > 0) {
        path += `/${result.schoolCode}`;
      }
      return path;
    },
    clickableLinkTarget(result) {
      if (result.entity === 'County') {
        return '_self';
      }
      if (this.$store.getters.SelectedSchoolYear.value <= 1415) {
        return '_blank';
      }
      if (this.$store.getters.SelectedSchoolYear.value <= 1516) {
        return '_blank';
      }
      if (this.$store.getters.SelectedSchoolYear.historical) {
        return '_self';
      }
      return '_self';
    },
    async performSearch(keyPressed) {
      this.$refs['results-msg-lbl'].innerHTML = '';
      if (keyPressed.key === 'ArrowDown') {
        // this.scrollDown();
        return;
      }
      if (keyPressed.key === 'ArrowUp') {
        // this.scrollUp();
        return;
      }
      if (keyPressed.key === 'Enter') {
        this.handleClick(this.searchResults[this.selectedIndex]);
        return;
      }
      if (this.searchString.length < 3) {
        this.searchResults = [];
        return;
      }

      this.searchResults = await omniSearch.performSearch(
        this.$store.getters.SelectedSchoolYear.text,
        encodeURIComponent(this.searchString),
      );
      if (this.searchResults && this.searchResults.length <= 0) {
        const str = this.searchString.replace(/-/g, '').replace(/\//g, '').replace(/ /g, '');
        if (!Number.isNaN(Number(str))
        && (str.length === 6 || str.length === 9)) {
          this.$refs['results-msg-lbl'].innerHTML = 'This CDS Code either does not exist or did not submit data for the current school year';
        }
      }
      this.selectedIndex = 0;
    },
  },
};
</script>

<style scoped>
.omniContainer {
  display: block;
}
.resultList {
  position: absolute;
  width: 96%;
  z-index: 1;
}
.search {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  text-align: left;
  font-weight: 600;
}
.search-input {
  border: 0;
  width: 93%;
  font-size: 18px;
  font-family: Roboto;
  padding-top: 20px;
}
/* .omniContainer::after {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f007";
} */
.underline{
  border-bottom: 2px solid #7B7474;
}
::placeholder{
  font-size: 14px;
}
.search-input-short {
  border: 0;
  font-size: 12px;
  font-family: Roboto;
  padding: 0px;
  padding-top: 15px;
  text-align: left;
  margin: 0px;
  font-weight: 600;
}
.small-icon{
  padding: 0px;
  margin: 0px;
  margin-top: 11px;
  font-size: 16px;
}
.list-group-item:hover {
  background-color: #426580;
  color: #F3EEEE;
  cursor: pointer;
}
.list-group-item.active {
  background-color: #426580;
  color: #F3EEEE;
  cursor: pointer;
}
</style>
