<template>
  <div>
    <b-container fluid>
      <b-row class="pt-4 d-print-none">
        <b-col>
          <section-header :id="AccountabilityHeaderId" :header-text="AccountabilitySectionHeader" />
        </b-col>
      </b-row>
      <b-row class="pb-4">
        <b-col cols="12">
          <div class="intro" v-html="AccountabilityIntro" />
          <b-row>
            <b-col cols="12">
              <!--  -->
              <visual-component
                :id="ComprehensiveOrTargetedSupportId"
                :visual-title="ComprehensiveOrTargetedSupportTitle"
                :visual-description="ComprehensiveOrTargetedSupportDescription"
                :chart-type="!HideComprehensiveOrTargettedSupportData
                  ? ChartTypes.None : ChartTypes.TableGrid"
                :chart-data="ComprehensiveOrTargettedSupportData"
                :visual-no-data-text="ComprehensiveOrTargettedSupportNoDataText"
              />
            </b-col>

            <b-col cols="12">
              <!--  -->
              <visual-component
                v-if="ReportType === 'school'"
                :id="AccountabilityStatusId"
                :visual-title="AccountabilityStatusTitle"
                :visual-description="AccountabilityStatusDescription"
                :visual-footer="AccountabilityStatusPlusFooter"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="AccountabilityStatusData"
                :print-container-id="'ESSAAS'"
              />
            </b-col>
            <b-col cols="12">
              <!--  -->
              <visual-component
                :id="AccountabilityProgressId"
                :visual-title="AccountabilityProgressTitle"
                :visual-description="AccountabilityProgressDescription"
                :visual-footer="AccountabilityProgressPlusFooter"
                :chart-type="ChartTypes.TableGrid"
                :table-shading="AccountabilityProgressTableShading"
                :chart-data="AccountabilityProgressData"
              />
            </b-col>
            <!-- hidden for 2019-2020 -->
            <!-- <div class="outside-border p-3">
              <h3 v-html="AccountabilitySummativeTitle"></h3>
              <p class="p-1" v-html="AccountabilitySummativeDescription" />
            </div>
            <visual-component
                :VisualTitle="AccountabilitySummaryTitle"
                :visualDescription="AccountabilitySummaryDescription"
                :visualFooter="AccountabilitySummaryPlusFooter"
                :ChartType="ChartTypes.TableGrid"
                :chartData="AccountabilitySummaryData" /> -->
            <b-col cols="12">
              <!--  -->
              <visual-component
                v-if="$store.getters.SelectedSchoolYear.value != 2021
                  && ReportType == 'school'"
                :id="AccountabilitySummativeId"
                :visual-title="AccountabilitySummativeTitle"
                :visual-description="AccountabilitySummativeDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="AccountabilitySummativeData"
                :table-shading="AccountabilitySummativeTableShading"
                :visual-footer="AccountabilitySummativeFooter"
                :horizontal-lines="['Summative Score', 'Puntaje sumativo']"
              />
            </b-col>
            <b-col cols="12">
              <visual-component
                v-if="$store.getters.SelectedSchoolYear.value != 2021
                  && ReportType != 'state'"
                :id="AccountabilitySummaryId"
                :visual-title="AccountabilitySummaryTitle"
                :visual-description="AccountabilitySummaryDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="AccountabilitySummaryData"
                :table-shading="AccoutabilitySummaryShading"
                :visual-footer="AccountabilitySummaryGroupPlusFooter"
                :horizontal-lines="['Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja']"
                :vertical-lines="[
                  'Requires Additional Targeted Support: Low Performing Student Group',
                  'Requiere apoyo específico adicional: grupo de estudiantes de bajo rendimiento',
                  'Chronic Absenteeism', 'Absentismo Crónico',
                ]"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
// eslint-disable-next-line import/no-cycle
import Accountability from '../../mixins/detailComponents/accountability.mixin';
import translation from '../../mixins/translation-service.mixin';
import hidden from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolAccountability',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [Accountability, translation, hidden, inPageNav],
  data() {
    return {
      ChartTypes,
    };
  },
  methods: {

  },
};
</script>
<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8!important;
}
</style>
<style>
  @media  print, screen {
    div[id='ESSA Accountability Status'] table th:nth-child(2),
    div[id='Estado de Responsabilidad de la ESSA'] table th:nth-child(2) {
      color: black;
      background-color: white!important;
      font-weight: normal;
      -webkit-print-color-adjust: exact;
      }
    div[id='ESSA Accountability Status'] table tr:nth-child(1) th:first-child,
    div[id='Estado de Responsabilidad de la ESSA'] table tr:nth-child(1) th:first-child {
      font-weight: normal;
      }
    div[id='ESSA Accountability Status'] table tr:nth-child(1) th:nth-child(1),
    div[id='Estado de Responsabilidad de la ESSA'] table tr:nth-child(1) th:nth-child(1),
    div[id='ESSA Accountability Status'] table tr:nth-child(1) td:nth-child(1),
    div[id='Estado de Responsabilidad de la ESSA'] table tr:nth-child(1) td:nth-child(1),
    div[id='ESSA Accountability Status'] table tr:nth-child(2) td:nth-child(1),
    div[id='Estado de Responsabilidad de la ESSA'] table tr:nth-child(2) td:nth-child(1) {
      color: white;
      background-color: #287AB8!important;
      -webkit-print-color-adjust: exact;
      }
    div[id='ESSA Accountability Status'] table tr:nth-child(1n+3) td:nth-child(1),
    div[id='Estado de Responsabilidad de la ESSA'] table tr:nth-child(1n+3) td:nth-child(1) {
      color: white;
      background-color:#008000!important;
      -webkit-print-color-adjust: exact;
      }
      div[id='Accountability Indicator Scores and Summative Ratings - 2021-22 School Year']
      table tr:nth-child(1n+9) td:nth-child(1),
      div[id=
    'Puntuaciones del indicador de responsabilidad y calificaciones sumativas - Año escolar 2021-22'
      ]
      table tr:nth-child(1n+9) td:nth-child(1)
    {
      font-weight: bold;
      }
      div[id='Accountability Indicator Scores and Summative Ratings - 2021-22 School Year']
      table tr:nth-child(1n+13) td:nth-child(1n),
      div[id=
    'Puntuaciones del indicador de responsabilidad y calificaciones sumativas - Año escolar 2021-22'
      ]
      table tr:nth-child(1n+13) td:nth-child(1n)
    {
      display: none;
      }
      div[id='Accountability Indicator Scores and Summative Ratings - 2022-23 School Year']
      table tr:nth-child(1n+9) td:nth-child(1),
      div[id=
    'Puntuaciones del indicador de responsabilidad y calificaciones sumativas - Año escolar 2022-23'
      ]
      table tr:nth-child(1n+9) td:nth-child(1)
    {
      font-weight: bold;
      }
      div[id='Accountability Indicator Scores and Summative Ratings - 2022-23 School Year']
      table tr:nth-child(1n+13) td:nth-child(1n),
      div[id=
    'Puntuaciones del indicador de responsabilidad y calificaciones sumativas - Año escolar 2022-23'
      ]
      table tr:nth-child(1n+13) td:nth-child(1n)
    {
      display: none;
      }
  }
</style>
