import Vue from 'vue';
import VueRouter from 'vue-router';

import authService from '../services/auth.service';
import loginService from '../services/login.service';

import Home from '../views/Home.vue';
import PrintDetail from '../views/PrintDetail.vue';
import PrintSummary from '../views/PrintSummary.vue';
import ReportTypeSelection from '../views/ReportTypeSelection.vue';
import PriorYearReport from '../views/PriorYearReport.vue';
import Maintenance from '../views/Maintenance.vue';
import NJMFA from '../views/NJMFA.vue';

import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import AuthHandoff from '../views/AuthHandoff.vue';
import Download from '../views/Download.vue';
import Additional from '../views/Additional.vue';
import ErrorView from '../views/Error.vue';
import CDSNotFoundView from '../views/CDSNotFound.vue';

// Main Report Pages
import DetailReport from '../views/DetailReport.vue';
import SummaryReport from '../views/SummaryReport.vue';

// Report Sections
import Overview from '../views/detailComponents/Overview.vue';
import Demographics from '../views/detailComponents/Demographics.vue';
import StudentGrowth from '../views/detailComponents/Growth.vue';
import AcademicAchievement from '../views/detailComponents/Academic.vue';
import CollegeReadiness from '../views/detailComponents/College.vue';
import PostSecondary from '../views/detailComponents/Grad.vue';
import Climate from '../views/detailComponents/Climate.vue';
import Staff from '../views/detailComponents/Staff.vue';
import PerPupil from '../views/detailComponents/PerPupil.vue';
import Accountability from '../views/detailComponents/Accountability.vue';
import Narrative from '../views/detailComponents/Narrative.vue';

Vue.use(VueRouter);

function doAuth(to, from, next) {
  const auth = authService.getAuthService();
  if (process.env.VUE_APP_MAINTENANCE === 'Yes') {
    if (!loginService.isAuthenticated()) {
      next({ name: 'Maintenance' });
    } else {
      next();
    }
  } else if (process.env.VUE_APP_USE_NJ_MFA === 'Yes') {
    const authOverride = localStorage.getItem('authOverride');
    const expireStr = localStorage.getItem('token_expire');
    if (authOverride !== 'handoff') {
      console.log('Override not set; Going to NJMFA Page');
      next({ name: 'NJMFA' });
    }
    if (!expireStr || expireStr === '') {
      console.log('Token Expire not set; Going to NJMFA Page');
      next({ name: 'NJMFA' });
    }
    const dt = new Date();
    const expireDt = new Date(expireStr);
    if (expireDt && expireDt < dt) {
      console.log('Token Expired; Going to NJMFA Page');
      localStorage.removeItem('authOverride');
      auth.logout();
      next({ name: 'NJMFA' });
    }
    next();
  } else if (process.env.VUE_APP_USE_AUTH === 'Yes') {
    if (!auth.isAuthenticated()) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
}

function reRouteToYearPrint(to, summaryDetail) {
  const { params, query } = to;
  let newUrl = `/2019-2020/print/${params.reportType}/${summaryDetail}`;
  if (params.countyCd) {
    newUrl = `${newUrl}/${params.countyCd}/${params.districtCd}`;
  }
  if (params.schoolCd) {
    newUrl = `${newUrl}/${params.schoolCd}`;
  }
  if (query) {
    const queryString = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');

    newUrl = `${newUrl}?${queryString}`;
  }
  return newUrl;
}

function reRouteToYear(to, summaryDetail) {
  const { params, query } = to;
  let newUrl = `/2019-2020/${params.reportType}/${summaryDetail}`;
  if (params.countyCd) {
    newUrl = `${newUrl}/${params.countyCd}/${params.districtCd}`;
  }
  if (params.schoolCd) {
    newUrl = `${newUrl}/${params.schoolCd}`;
  }
  if (query) {
    const queryString = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');

    newUrl = `${newUrl}?${queryString}`;
  }
  return newUrl;
}

function reRouteToDefaultYear(to) {
  const { params, query } = to;
  let newUrl = '/selectreport/';
  const schoolYear = params.countyCd?.includes('-') ? params.countyCd : '2022-2023';
  const countyCd = params.countyCd?.includes('-') ? params.districtCd : params.countyCd;
  const districtCd = params.countyCd?.includes('-') ? params.schoolCd : params.districtCd;
  const { schoolCd } = params;

  newUrl = `${newUrl}${schoolYear}/`;

  if (countyCd) {
    newUrl = `${newUrl}${countyCd}/${districtCd}`;
  }
  if (schoolCd) {
    newUrl = `${newUrl}/${schoolCd}`;
  }
  if (query) {
    const queryString = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');

    if (queryString.length > 0) {
      newUrl = `${newUrl}?${queryString}`;
    }
  }
  return newUrl;
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      if (process.env.VUE_APP_USE_NJ_MFA === 'Yes') {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/njmfa',
    name: 'NJMFA',
    component: NJMFA,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: doAuth,
  },
  {
    path: '/PerformanceReports.aspx',
    beforeEnter(to, from, next) {
      next({ name: 'Home' });
    },
  },
  {
    path: '/report.aspx',
    beforeEnter(to, from, next) {
      const qry = {};
      const param = {};

      if (to.query.schoolyear && to.query.county && to.query.district) {
        param.schoolYear = to.query.schoolyear;
        param.countyCd = to.query.county;
        param.districtCd = to.query.district;
        param.schoolCd = to.query.school ? to.query.school : '';
        qry.lang = to.query.lang && to.query.lang === 'english' ? 'EN' : 'ES';

        if (to.query.SY <= 1819) {
          next({
            name: 'PriorYearReport',
            params: param,
            query: qry,
          });
        } else {
          next({
            name: 'ReportSelect',
            params: param,
            query: qry,
          });
        }
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  // Print Routes
  {
    path: '/print/:reportType/summary/:countyCd?/:districtCd?/:schoolCd?',
    redirect: (to) => reRouteToYearPrint(to, 'summary'),
  },
  {
    path: '/print/:reportType/detail/:countyCd?/:districtCd?/:schoolCd?',
    redirect: (to) => reRouteToYearPrint(to, 'detail'),
  },
  {
    path: '/:schoolYear?/print/:reportType/summary/:countyCd?/:districtCd?/:schoolCd?',
    name: 'PrintSummary',
    component: PrintSummary,
    beforeEnter: doAuth,
  },
  {
    path: '/:schoolYear?/print/:reportType/detail/:countyCd?/:districtCd?/:schoolCd?',
    name: 'PrintDetail',
    component: PrintDetail,
    beforeEnter: doAuth,
  },
  // Detail Report Route
  {
    path: '/:reportType?/detail/:countyCd?/:districtCd?/:schoolCd?',
    redirect: (to) => reRouteToYear(to, 'detail'),
  },
  {
    path: '/:schoolYear?/:reportType/detail/:countyCd?/:districtCd?/:schoolCd?',
    name: 'DetailReport',
    component: DetailReport,
    beforeEnter: doAuth,
    children: [
      {
        name: 'Overview',
        path: 'overview',
        component: Overview,
      },
      {
        name: 'Demographics',
        path: 'demographics',
        component: Demographics,
      },
      {
        name: 'StudentGrowth',
        path: 'growth',
        component: StudentGrowth,
      },
      {
        name: 'AcademicAchievement',
        path: 'academic',
        component: AcademicAchievement,
      },
      {
        name: 'CollegeCareer',
        path: 'college',
        component: CollegeReadiness,
      },
      {
        name: 'PostSecondary',
        path: 'postsecondary',
        component: PostSecondary,
      },
      {
        name: 'Climate',
        path: 'climate',
        component: Climate,
      },
      {
        name: 'Staff',
        path: 'staff',
        component: Staff,
      },
      {
        name: 'PerPupil',
        path: 'perpupil',
        component: PerPupil,
      },
      {
        name: 'Accountability',
        path: 'accountability',
        component: Accountability,
      },
      {
        name: 'Narrative',
        path: 'narrative',
        component: Narrative,
      },
      {
        name: 'Default',
        path: '/',
        component: Overview,
      },
    ],
  },
  // Summary Report Route
  {
    path: '/:reportType?/summary/:countyCd?/:districtCd?/:schoolCd?',
    redirect: (to) => reRouteToYear(to, 'summary'),
  },
  {
    path: '/:schoolYear?/:reportType/summary/:countyCd/:districtCd/:schoolCd?',
    name: 'SummaryReport',
    component: SummaryReport,
    beforeEnter: doAuth,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/:type/list',
    name: 'List',
    component: () => import(/* webpackChunkName: "List" */ '../views/List.vue'),
    beforeEnter: doAuth,
  },
  {
    path: '/:type/:filter/list',
    name: 'CountyList',
    component: () => import(/* webpackChunkName: "List" */ '../views/List.vue'),
    beforeEnter: doAuth,
  },
  {
    path: '/defaultreport/:countyCd?/:districtCd?/:schoolCd?',
    redirect: (to) => reRouteToDefaultYear(to),
  },
  {
    path: '/selectreport/:schoolYear/:countyCd?/:districtCd?/:schoolCd?',
    name: 'ReportSelect',
    component: ReportTypeSelection,
    beforeEnter: doAuth,
  },
  {
    path: '/prioryearreport/:schoolYear/:countyCd?/:districtCd?/:schoolCd?',
    name: 'PriorYearReport',
    component: PriorYearReport,
    beforeEnter: doAuth,
  },
  {
    path: '/auth/:authToken',
    name: 'NJSmartHandoff',
    component: AuthHandoff,
  },
  {
    path: '/download',
    name: 'DownloadData',
    component: Download,
    beforeEnter: doAuth,
  },
  {
    path: '/additional',
    name: 'Additional',
    component: Additional,
    beforeEnter: doAuth,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorView,
  },
  {
    path: '/cdsnotfound',
    name: 'CDSNotFound',
    component: CDSNotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
