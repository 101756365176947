<template>
  <div>
    <b-container fluid>
      <b-row class="pt-4 d-print-none">
        <b-col>
          <section-header :id="NarrativeId" :header-text="NarrativeSectionHeader" />
        </b-col>
      </b-row>
      <b-row class="pt-4 pb-4">
        <b-col cols="12" style="width: 100%;">
          <div class="intro" v-html="Narrativetext" />
        </b-col>
      </b-row>
      <b-row class=" pt-3">
        <b-col
          v-for="item in narrativeItems"
          :key="item.key"
          col
          md="6"
          lg="3"
          sm="12"
        >
          <narrative-icon
            :icon-class="narrativeIcon(item)"
            :icon-size="narrativeIconSize(item)"
            :icon-data="item"
          />
        </b-col>
      </b-row>
      <!--end of icons -->
      <b-row
        v-for="item in narrativeItems"
        :key="item.key"
        class="category pt-4"
      >
        <b-col col-12>
          <narrative-description
            :icon-data="item"
            :show-bullet-points="narrativeShowBullet(item)"
            :icon-class="narrativeIcon(item)"
          />
        </b-col>
      </b-row>
      <b-row v-if="!narrativeItems || narrativeItems.length <= 0 ">
        <b-col col>
          <p class="lead">
            <strong>No narrative was submitted.</strong>
          </p>
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <p class="pt-4" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import NarrativeIcon from '../../components/NarrativeIcon.vue';
import NarrativeDescription from '../../components/NarrativeDescription.vue';
import SectionHeader from '../../components/SectionHeader.vue';
import Narrative from '../../mixins/detailComponents/narrative.mixin';
import translation from '../../mixins/translation-service.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolNarrative',
  components: {
    NarrativeIcon,
    NarrativeDescription,
    SectionHeader,
  },
  mixins: [translation, Narrative, inPageNav],
  data() {
    return {
      busy: true,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.small-blue{
  color: #287AB8;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 18pt;
}
.category-text{
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.category{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 0px;
  box-sizing: border-box;
  border: 2px solid #287AB8;
  width: 100%;
}
.outside-border {
  box-sizing: border-box;
  border: 2px solid #287AB8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}
</style>
