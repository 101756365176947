export default
{
	"LanguageOptions": {
		"English": "EN",
		"Spanish": "ES"
	},
	"ComponentNames": {
		"Title": "Title",
		"ItemStar": "Item-Star",
		"ItemStarStar": "Item-Star-Star",
		"ItemN": "Item-N",
		"ItemPlus": "Item-Plus",
		"TitleGrades": "Title-Grades",
		"HowToTitle": "How-To-Title",
		"HowToItem1": "How-To-Item-1",
		"HowToItem2": "How-To-Item-2",
		"HowToItem3": "How-To-Item-3",
		"ImportantNotesTitle": "Important-Notes-Title",
		"ImportantNotesText": "Important-Notes-Text",
		"NavTitle": "Nav-Title",
		"NavItem1": "Nav-Item-1",
		"NavItem2": "Nav-Item-2",
		"NavItem3": "Nav-Item-3",
		"NavItem4": "Nav-Item-4",
		"ImpactCovid": "Impact-Covid",
		"OtherTitle": "Other-Title",
		"OtherText": "Other-Text",
		"OtherItem1": "Other-Item-1",
		"OtherItem2": "Other-Item-2",
		"OtherItem3": "Other-Item-3",
		"OtherItem4": "Other-Item-4",
		"OtherItem5": "Other-Item-5",
		"OtherItem6": "Other-Item-6",
		"NotesTitle": "Notes-Title",
		"SchoolContactTitle": "School-Contact-Title",
		"SchoolContactText": "School-Contact-Text",
		"DistrictContactTitle": "District-Contact-Title",
		"DistrictContactText": "District-Contact-Text",
		"ContactText": "Contact-Text",
		"EnrollmentHeader": "Enrollment-Header",
		"DemogIntro": "Demog-Intro",
		"DemogByGradeTitle": "Demog-By-Grade-Title",
		"DemogByGradeText": "Demog-By-Grade-Text",
		"DemogByStdntTitle": "Demog-By-Stdnt-Title",
		"DemogByStdntText": "Demog-By-Stdnt-Text",
		"DemogByRaceTitle": "Demog-By-Race-Title",
		"DemogByRaceText": "Demog-By-Race-Text",
		"DemogByDayTitle": "Demog-By-Day-Title",
		"DemogByDayText": "Demog-By-Day-Text",
		"DemogByTimeTitle": "Demog-By-Time-Title",
		"DemogByTimeText": "Demog-By-Time-Text",
		"DemogByLangTitle": "Demog-By-Lang-Title",
		"DemogByLangText": "Demog-By-Lang-Text",
		"StdGrowthTrendTitle": "StdGrowthTrend-Title",
		"StdGrowthTrendText": "StdGrowthTrend-Text",
		"StdGrowthIntro": "StdGrowth-Intro",
		"StdGrowthTitle": "StdGrowth-Title",
		"StdGrowthText": "StdGrowth-Text",
		"StdGrowthPerfTitle": "StdGrowthPerf-Title",
		"StdGrowthPerfText": "StdGrowthPerf-Text",
		"StdGrowthgradeTitle": "StdGrowthgrade-Title",
		"StdGrowthgradeText": "StdGrowthgrade-Text",
		"AcadAchievementIntro": "Acad-Achievement-Intro",
		"EnglishMathTrendTitle": "EnglishMathTrend-Title",
		"EnglishMathTrendText": "EnglishMathTrend-Text",
		"EnglishMathMathTrendChartTitle": "EnglishMathMathTrend-ChartTitle",
		"EnglishMathELATrendChartTitle": "EnglishMathELATrend-ChartTitle",
		"EnglishMathTrendPlusFooter": "EnglishMathTrend-PlusFooter",
		"EnglishParticipationTitle": "EnglishParticipation-Title",
		"EnglishParticipationText": "EnglishParicipationPerformance-Text",
		"EnglishParticipationPlusFooter": "EnglishParticipation-PlusFooter",
		"EnglishPerformanceTitle": "EnglishPerformance-Title",
		"EnglishPerformanceChartSubTitle": "EnglishPerformance-ChartSubTitle",
		"EnglishPerformanceText": "EnglishPerformance-Text",
		"EnglishParicipationPerformanceText": "EnglishParicipationPerformance-Text",
		"EnglishGradesTitle": "EnglishGrades-Title",
		"EnglishGradesText": "EnglishGrades-Text",
		"ELAPerfByGradeVisualTitleTemplate": "ELA-PerfByGrade-Visual-Title-Template",
		"MathPerfByGradeVisualTitleTemplate": "Math-PerfByGrade-Visual-Title-Template",
		"MathPerfByTestVisualTitleTemplate": "Math-PerfByTest-Visual-Title-Template",
		"NJSLAPerfByGradeVisualTitleTemplate": "NJSLA-PerfByGrade-Visual-Title-Template",
		"ELAPerformanceByGradeXText": "ELAPerformanceByGradeX-Text",
		"MathPerformanceByGradeX35Text": "MathPerformanceByGradeX35-Text",
		"MathParticipationTitle": "MathParticipation-Title",
		"MathParicipationPerformanceText": "MathParicipationPerformance-Text",
		"MathParticipationText": "MathParticipation-Text",
		"MathParticipationPlusFooter": "MathParticipation-PlusFooter",
		"MathPerformanceTitle": "MathPerformance-Title",
		"MathPerformanceChartSubTitle": "MathPerformance-ChartSubTitle",
		"MathPerformanceText": "MathPerformance-Text",
		"MathPerformancePlusFooter": "MathPerformance-PlusFooter",
		"MathGrades35Title": "MathGrades3-5-Title",
		"MathGrades35Text": "MathPerformanceByGradeX35-Text",
		"MathGrades68Title": "MathGrades6-8-Title",
		"MathGrades6Text": "MathPerformanceByGradeX6-Text",
		"MathGrades78Text": "MathPerformanceByGradeX78-Text",
		"MathTestTitle": "MathTest-Title",
		"MathTestText": "MathTest-Text",
		"MathTestAlgText": "MathPerformanceByGradeXAlg-Text",
		"MathTestGeoText": "MathPerformanceByGradeXGeo-Text",
		"MathTestAlg2Text": "MathPerformanceByGradeXAlg2-Text",
		"NJSLAScienceGradeSummaryTableText": "NJSLA-Science-Grade-Summary-Table-Text",
		"NJSLAScienceGradeTitleTable": "NJSLAScience-Grade-Title-Table",
		"DLMTitle": "DLM-Title",
		"DLMText": "DLM-Text",
		"EngProficiencyTestTitle": "EngProficiencyTest-Title",
		"EngProficiencyTestText": "EngProficiencyTest-Text",
		"EngProficiencyTitle": "EngProficiency-Title",
		"EngProficiencyText": "EngProficiency-Text",
		"EngProficiencyPlussFooter": "EngProficiency-PlussFooter",
		"EngProficiencyTestTitle": "EngProficiencyTest-Title",
		"EngProficiencyTestText": "EngProficiencyTest-Text",
		"NJGPATitle": "NJGPA-Title",
		"NJGPAText": "NJGPA-Text",
		"NJSLAScienceGradeIntro": "NJSLA-Science-Grade-Intro",
		"NJSLAScienceGradeSummaryTitle": "NJSLA-Science-Grade-Summary-Title",
		"NJSLAScienceGradeSummaryText": "NJSLA-Science-Grade-Summary-Text",
		"NJSLAScienceGradeTitle": "NJSLA-Science-Grade-Title",
		"NJSLAScienceGradeText": "NJSLA-Science-Grade-Text",
		"AssessmentsIntro": "Assessments-Intro",
		"AssessmentsParticipationTitle": "AssessmentsParticipation-Title",
		"AssessmentsParticipationText": "AssessmentsParticipation-Text",
		"AssessmentsPerformanceTitle": "AssessmentsPerformance-Title",
		"AssessmentsPerformanceText": "AssessmentsPerformance-Text",
		"APIBIntro": "APIB-Intro",
		"APIBCourseworkTitle": "APIB-Coursework-Title",
		"APIBCourseworkText": "APIB-Coursework-Text",
		"APIBCoursesOfferedTitle": "APIB-CoursesOffered-Title",
		"APIBCoursesOfferedText": "APIB-CoursesOffered-Text",
		"APIBDualCourseworkTitle": "APIB-DualCoursework-Title",
		"APIBDualCourseworkText": "APIB-DualCoursework-Text",
		"CTEProgramsIntro": "CTE-Programs-Intro",
		"CTEParticipationTitle": "CTE-Participation-Title",
		"CTEParticipationText": "CTE-Participation-Text",
		"CTEStructuredLearningTitle": "CTE-StructuredLearning-Title",
		"CTEStructuredLearningText": "CTE-StructuredLearning-Text",
		"CTEStdGroupTitle": "CTE-StdGroup-Title",
		"CTEStdGroupText": "CTE-StdGroup-Text",
		"CTEValuedCredTitle": "CTE-ValuedCred-Title",
		"CTEValuedCredText": "CTE-ValuedCred-Text",
		"CTEValuedCredCareerTitle": "CTE-ValuedCred-Career-Title",
		"CTEValuedCredCareerText": "CTE-ValuedCred-Career-Text",
		"CourseParticipationIntro": "CourseParticipation-Intro",
		"CourseParticipationIntro912": "CourseParticipation-Intro-9-12",
		"CourseParticipationIntroPK08": "CourseParticipation-Intro-PK-08",
		"CourseParticipationMathTitle": "CourseParticipation-Math-Title",
		"CourseParticipationMathText": "CourseParticipation-Math-Text",
		"CourseParticipationMath912Text": "CourseParticipation-Math-9-12-Text",
		"CourseParticipationMathPK08Text": "CourseParticipation-Math-PK-08-Text",
		"CourseParticipationScienceTitle": "CourseParticipation-Science-Title",
		"CourseParticipationScienceText": "CourseParticipation-Science-Text",
		"CourseParticipationHistoryTitle": "CourseParticipation-History-Title",
		"CourseParticipationHistoryText": "CourseParticipation-History-Text",
		"CourseParticipationLanguagesTitle": "CourseParticipation-Languages-Title",
		"CourseParticipationLanguagesText": "CourseParticipation-Languages-Text",
		"CourseParticipationLanguagesText912": "CourseParticipation-Languages-Text-9-12",
		"CourseParticipationLanguagesTextPK08": "CourseParticipation-Languages-Text-PK-08",
		"CourseParticipationITText912": "CourseParticipation-IT-Text-9-12",
		"CourseParticipationITTextPK08": "CourseParticipation-IT-Text-PK-08",
		"VisPerfArts68Title": "Vis-Perf-Arts-6-8-Title",
		"VisPerfArts912Title": "Vis-Perf-Arts-9-12-Title",
		"VisArtsDesc": "Vis-Arts-Desc",
		"CourseParticipationITTitle": "CourseParticipation-IT-Title",
		"CourseParticipationITText": "CourseParticipation-IT-Text",
		"SealBiliteracyTitle": "SealBiliteracy-Title",
		"SealBiliteracyText": "SealBiliteracy-Text",
		"CourseParticipationArtsTitle": "CourseParticipation-Arts-Title",
		"CourseParticipationArtsText": "CourseParticipation-Arts-Text",
		"GradRateProgressIntro": "GradRateProgress-Intro",
		"GradRateProgressTitle": "GradRateProgress-Title",
		"GradRateProgressText": "GradRateProgress-Text",
		"GradRateTrendsVisual4YearTitle": "Grad-Rate-Trends-Visual-4Year-Title",
		"GradRateTrendsVisual5YearTitle": "Grad-Rate-Trends-Visual-5Year-Title",
		"Cohort2021Title": "Cohort2021-Title",
		"Cohort2021Text": "Cohort2021-Text",
		"Cohort2020Title": "Cohort2020-Title",
		"Cohort2020Text": "Cohort2020-Text",
		"Cohort2019Title": "Cohort2019-Title",
		"Cohort2019Text": "Cohort2019-Text",
		"Cohort4YearTitle": "Cohort4Year-Title",
		"Cohort5YearTitle": "Cohort5Year-Title",
		"Cohort6YearTitle": "Cohort6Year-Title",
		"Cohort4YearText": "Cohort4Year-Text",
		"Cohort5YearText": "Cohort5Year-Text",
		"Cohort6YearText": "Cohort6Year-Text",
		"GradRateIntro": "GradRate-Intro",
		"GradRateTitle": "GradRate-Title",
		"GradRateText": "GradRate-Text",
		"GradPathwaysTitle": "GradPathways-Title",
		"GradPathwaysText": "GradPathways-Text",
		"DropoutTitle": "Dropout-Title",
		"DropoutText": "Dropout-Text",
		"PostSecEnrollIntro": "PostSecEnroll-Intro",
		"PostSecEnrollSummaryTitle": "PostSecEnrollSummary-Title",
		"PostSecEnrollSummaryText": "PostSecEnrollSummary-Text",
		"PostSecEnrollFallTitle": "PostSecEnrollFall-Title",
		"PostSecEnrollFallText": "PostSecEnrollFall-Text",
		"PostSecEnroll16MonthTitle": "PostSecEnroll16Month-Title",
		"PostSecEnroll16MonthText": "PostSecEnroll16Month-Text",
		"AbsentHeader": "Absent-Header",
		"AbsentIntro": "Absent-Intro",
		"AbsentChronicTitle": "AbsentChronic-Title",
		"AbsentChronicText": "AbsentChronic-Text",
		"AbsentDaysTitle": "AbsentDays-Title",
		"AbsentDaysText": "AbsentDays-Text",
		"AbsentDaysChartSubTitle": "AbsentDays-ChartSubTitle",
		"AbsentGradeIntro": "AbsentGrade-Intro",
		"AbsentGradeTitle": "AbsentGrade-Title",
		"AbsentGradeText": "AbsentGrade-Text",
		"AbsentGradeChartSubTitle": "AbsentGrade-ChartSubTitle",
		"Violenceintro": "Violence-intro",
		"ViolenceOffensesTitle": "ViolenceOffenses-Title",
		"ViolenceOffensesText": "ViolenceOffenses-Text",
		"PoliceNotifTitle": "PoliceNotif-Title",
		"PoliceNotifText": "PoliceNotif-Text",
		"HarrassInvestTitle": "HarrassInvest-Title",
		"HarrassInvestText": "HarrassInvest-Text",
		"StdDisciplinTitle": "StdDisciplin-Title",
		"SchoolDaysMissedTitle": "School-Days-Missed-Title",
		"SchoolDaysMissedText": "School-Days-Missed-Text",
		"SchoolDayTitle": "StdDisciplin-Title",
		"StdDisciplinText": "StdDisciplin-Text",
		"SchoolDayText": "SchoolDay-Text",
		"SchoolDayTitle": "SchoolDay-Title",
		"DeviceRatiosTitle": "DeviceRatios-Title",
		"DeviceRatiosText": "DeviceRatios-Text",
		"StaffHeader": "Staff-Header",
		"StaffIntro": "Staff-Intro",
		"TeacherExpTitle": "TeacherExp-Title",
		"TeacherExpText": "TeacherExp-Text",
		"AdmExpTitle": "AdmExp-Title",
		"AdmExpText": "AdmExp-Text",
		"StdStaffRatiosTitle": "StdStaffRatios-Title",
		"StdStaffRatiosText": "StdStaffRatios-Text",
		"StdStaffRatiosPlusFooter": "StdStaffRatios-PlusFooter",
		"TeacherDemogTitle": "TeacherDemog-Title",
		"TeacherDemogText": "TeacherDemog-Text",
		"TeacherLevelEducTitle": "TeacherLevelEduc-Title",
		"TeacherLevelEducText": "TeacherLevelEduc-Text",
		"Teacher1YearRetentionTitle": "Teacher1YearRetention-Title",
		"Teacher1YearRetentionText": "Teacher1YearRetention-Text",
		"FacultyAttendaceTitle": "FacultyAttendace-Title",
		"FacultyAttendaceText": "FacultyAttendace-Text",
		"ExpenditureTitle": "Expenditure-Title",
		"ExpenditureText": "Expenditure-Text",
		"ExpenditureFooter": "Expenditure-Footer",
		"ExpenditureUrl": "Expenditure-URL",
		"AccountabilityIntro": "Accountability-Intro",
		"AccountabilityStatusTitle": "AccountabilityStatus-Title",
		"AccountabilityStatusText": "AccountabilityStatus-Text",
		"AccountabilityStatusPlusFooter": "AccountabilityStatus-PlusFooter",
		"AccountabilityProgressTitle": "AccountabilityProgress-Title",
		"AccountabilityProgressText": "AccountabilityProgress-Text",
		"AccountabilityProgressPlusFooter": "AccountabilityProgress-PlusFooter",
		"AccountabilitySummativeTitle": "AccountabilitySummative-Title",
		"AccountabilitySummativeText": "AccountabilitySummative-Text",
		"AccountabilitySummativePlusFooter": "AccountabilitySummative-PlusFooter",
		"AccountabilitySummaryGroupPlusFooter": "AccountabilitySummaryGroup-PlusFooter",
		"AccountabilitySummaryTitle": "AccountabilitySummary-Title",
		"AccountabilitySummaryText": "AccountabilitySummary-Text",
		"AccountabilitySummaryPlusFooter": "AccountabilitySummary-PlusFooter",
		"NarrativeHeader": "Narrative-Header",
		"Narrativetitle": "Narrative-title",
		"Narrativetext": "Narrative-text",
		"NarrativeBoxHighlightsTitle": "NarrativeBox-Highlights-Title",
		"NarrativeBoxHighlightsText": "NarrativeBox-Highlights-Text",
		"NarrativeBoxMissionTitle": "NarrativeBox-Mission-Title",
		"NarrativeBoxMissionText": "NarrativeBox-Mission-Text",
		"NarrativeBoxAwardsTitle": "NarrativeBox-Awards-Title",
		"NarrativeBoxAwardsText": "NarrativeBox-Awards-Text",
		"NarrativeBoxSportsTitle": "NarrativeBox-Sports-Title",
		"NarrativeBoxSportsText": "NarrativeBox-Sports-Text",
		"NarrativeBoxSchoolProgramTitle": "NarrativeBox-SchoolProgram-Title",
		"NarrativeBoxSchoolProgramText": "NarrativeBox-SchoolProgram-Text",
		"NarrativeBoxPostSecTitle": "NarrativeBox-PostSec-Title",
		"NarrativeBoxPostSecText": "NarrativeBox-PostSec-Text",
		"NarrativeBoxParentTitle": "NarrativeBox-Parent-Title",
		"NarrativeBoxParentText": "NarrativeBox-Parent-Text",
		"NarrativeBoxSurveyTitle": "NarrativeBox-Survey-Title",
		"NarrativeBoxSurveyText": "NarrativeBox-Survey-Text",
		"NarrativeBoxFacilitiesTitle": "NarrativeBox-Facilities-Title",
		"NarrativeBoxFacilitiesText": "NarrativeBox-Facilities-Text",
		"NarrativeBoxSafetyTitle": "NarrativeBox-Safety-Title",
		"NarrativeBoxSafetyText": "NarrativeBox-Safety-Text",
		"NarrativeBoxTecSTEMTitle": "NarrativeBox-TecSTEM-Title",
		"NarrativeBoxTecSTEMText": "NarrativeBox-TecSTEM-Text",
		"RKCounty": "RK-County",
		"RKDistrict": "RK-District",
		"RKPrincipal": "RK-Principal",
		"RPSchoolWeb": "RP-SchoolWeb",
		"RKSuper": "RK-Super",
		"RPPhone": "RP-Phone",
		"HeaderStdTotal": "Header-StdTotal",
		"HeaderStdDisadvantage": "Header-StdDisadvantage",
		"HeaderStdDisability": "Header-StdDisability",
		"HeaderEnglishLearner": "Header-EnglishLearner",
		"HeaderGradeoffer": "Header-Gradeoffer",
		"HeaderStdRatio": "Header-StdRatio",
		"HeaderTeacherExperience": "Header-TeacherExperience",
		"FooterTitle": "Footer-Title",
		"StdGrowthCompareTitle": "StdGrowthCompare-Title",
		"StdGrowthCompareText": "StdGrowthCompare-Text",
		"HowAssessTitle": "HowAssess-Title",
		"HowAssessText": "HowAssess-Text",
		"HowAssessELADataChartSubTitle": "HowAssessELAData-Chart-Sub-Title",
		"HowAssessMathDataChartSubTitle": "HowAssessMathData-Chart-Sub-Title",
		"StdRiskTitle": "StdRisk-Title",
		"StdRiskText": "StdRisk-Text",
		"StdGrowthTitle": "StdGrowth-Title",
		"StdGrowthText": "StdGrowth-Text",
		"StdGradTitle": "StdGrad-Title",
		"StdGradText": "StdGrad-text",
		"StdCollegeTitle": "StdCollege-Title",
		"StdCollegeText": "StdCollege-Text",
		"StdGrad4YearDataChartSubTitle": "StdGrad4YearData-Chart-Sub-Title",
		"StdGrad5YearDataChartSubTitle": "StdGrad5YearData-Chart-Sub-Title",
		"BelowState": "Below-State",
		"MetState": "Met-State",
		"MetGoal": "Met-Goal",
		"BelowStandard": "Below-Standards",
		"MetStandard": "Met-Standards",
		"ExceedsStandard": "Exceeds-Standards",
		"NAEPTitle": "NAEP-Title",
		"NAEPText": "NAEP-Text",
		"ImportantNotesTitle": "Important-Notes-Title",
		"ImportantNotesText": "Important-Notes-Text",
		"EducatorEquityDataTitle": "EducatorEquityData-Title",
		"EducatorEquityDataText": "EducatorEquityData-Text",
		"SchoolsTargetSupportText": "SchoolsTargetSupport-Text",
		"SchoolsTargetSupportNoDataText": "SchoolsTargetSupport-NoDataText",
		"AlternateMethodologyNoDataText": "AlternateMethodology-NoDataText",
		"SchoolsTargetSupportTitle": "SchoolsTargetSupport-Title",
		"SchoolsInDistrictTitle": "Schools-In-District-Title",
		"SchoolsInDistrictText": "Schools-In-District-Text",
		"OverviewIntro": "Overview-Intro",
		"OverviewHeader": "Overview-Header",
		"SummaryIntro": "Summary-Intro",
		"AcadAchievementHeader": "Acad-Achievement-Header",
		"ScienceHeader": "Science-Header",
		"AccountabilityHeader": "Accountability-Header",
		"DisciplineHeader": "Discipline-Header",
		"SchoolDayHeader": "SchoolDay-Header",
		"DeviceRatiosHeader": "DeviceRatios-Header",
		"AssessmentsHeader": "Assessments-Header",
		"AdvancedHeader": "Advanced-Header",
		"CTEHeader": "CTE-Header",
		"CoursePartHeader": "CoursePart-Header",
		"GradHeader": "Grad-Header",
		"PostSecHeader": "PostSec-Header",
		"StdHeader": "Std-Header",
		"NJSLAScienceVisualTitleTemplate": "NJSLA-Science-Visual-Title-Template",
		"ELAMedianStudentGrowthChartTitle": "ELA-Median-Student-Growth-Chart-Title",
		"MathMedianStudentGrowthChartTitle": "Math-Median-Student-Growth-Chart-Title",
		"MedianSubTitle": "Median-Sub-Title",
		"StdGrowthPerfChartTitleEla": "StdGrowthPerf-Chart-Title-Ela",
    "StdGrowthPerfChartTitleMath": "StdGrowthPerf-Chart-Title-Math",
    "StdGrowthgradeChartTitleEla": "StdGrowthgrade-Chart-Title-Ela",
    "StdGrowthgradeChartTitleMath": "StdGrowthgrade-Chart-Title-Math",
		"TeacherSubjectAreaTitle": "TeacherSubjectArea-Title",
		"TeacherSubjectAreaText": "TeacherSubjectArea-Text",
		"WorkBasedLearningText": "WorkBasedLearning-Text",
		"WorkBasedLearningTitle": "WorkBasedLearning-Title",
		"Cohort2021Text": "Cohort2021-Text",
		"FedGradRateText": "FedGradRate-Text",
		"FedGradRateTitle": "FedGradRate-Title",
		"ApprenticeshipTitle": "Apprenticeship-Title",
		"ApprenticeshipText": "Apprenticeship-Text",
		"TechnologyInternetAccessTitle": "TechnologyInternetAccess-Title",
		"TechnologyInternetAccessText": "TechnologyInternetAccess-Text",
		"DualEnrollStudentGroupText": "DualEnrollStudentGroup-Text",
		"DualEnrollStudentGroupTitle": "DualEnrollStudentGroup-Title",
		"StaffCountsTitle": "StaffCounts-Title",
		"StaffCountsText": "StaffCounts-Text",
		"StdRiskXAxisTitle": "StdRiskXAxis-Title"
	}
}
