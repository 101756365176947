import authService from './services/auth.service';
import router from './router';
import store from './store';

function redirectToError(rtr) {
  if (window.location.pathname.indexOf('/error') !== 0) {
    rtr.push({ name: 'Error', query: { url: window.location.pathname } });
  }
}
function redirectToCDSNotFound(rtr) {
  if (window.location.pathname.indexOf('/cdsnotfound') !== 0) {
    rtr.push({ name: 'CDSNotFound', query: { url: window.location.pathname } });
  }
}

export default {
  async requestHandler(config) {
    const auth = authService.getAuthService();

    if (auth.authUrl() !== config.url && !config.forceNoAuth) {
      const token = await auth.getToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (!config.headers['content-type']) {
      // eslint-disable-next-line no-param-reassign
      config.headers['content-type'] = 'application/json';
    }
    store.dispatch('AddOutboundRequest');
    return config;
  },
  requestErrorHandler(err) {
    Promise.reject(err);
  },
  responseHandler(response) {
    store.dispatch('RemoveOutboundRequest');
    return response;
  },
  responseErrorHandler(error) {
    const auth = authService.getAuthService();

    store.dispatch('RemoveOutboundRequest');
    if (error.response.data && error.response.data.message
      && error.response.data.message.indexOf('Invalid CDS combination') >= 0) {
      console.log(error);
      redirectToCDSNotFound(router);
      return Promise.reject(error);
    }
    if (!error.response) {
      console.log(error);
      redirectToError(router);
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (originalRequest && originalRequest.purpose && originalRequest.purpose === 'fileCheck') {
      return Promise.reject(error);
    }

    if ((error.response.status === 401 || error.response.status === 400)
      && originalRequest.url === auth.refreshUrl()) {
      router.push({ name: 'Login' });
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      return auth.refreshToken(originalRequest);
    }

    redirectToError(router);

    console.log(error);
    return Promise.reject(error);
  },
};
