<template>
  <div>
    <b-container fluid>
      <b-row
        v-if="!HideAssessmentsParticipationData && !HideAssessmentsPerformanceData"
        class="mt-4 d-print-none"
      >
        <b-col>
          <section-header :id="AssessmentsHeaderId" :header-text="AssessmentsSectionHeader" />
        </b-col>
      </b-row>
      <b-row
        v-if="!(HideAssessmentsParticipationData
          && HideAssessmentsPerformanceData)"
        class="pb-4"
      >
        <b-col cols="12">
          <div
            v-if="!(HideAssessmentsParticipationData
              && HideAssessmentsPerformanceData)"
            class="intro"
            v-html="AssessmentsIntro"
          />
          <visual-component
            v-if="!HideAssessmentsParticipationData"
            :id="AssessmentsParticipationId"
            :visual-title="AssessmentsParticipationTitle"
            :visual-description="AssessmentsParticipationDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AssessmentsParticipationData"
          />
          <visual-component
            v-if="!HideAssessmentsPerformanceData"
            :id="AssessmentsPerformanceId"
            :visual-title="AssessmentsPerformanceTitle"
            :visual-description="AssessmentsPerformanceDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AssessmentsPerformanceData"
            :horizontal-lines="['SAT - Reading and Writing', 'SAT - Lectura y Escritura',
                                'ACT - Reading', 'ACT - Lectura']"
            :vertical-lines="['State Average Score', 'Puntaje promedio estatal']"
            :equal-width-data-columns="true"
          />
          <!-- v-if="HideAPIBData && HideAPIBCoursesOfferedData && HideDualEnrollmentData" -->
        </b-col>
      </b-row>
      <b-row
        v-if="!(HideAPIBData
          && HideAPIBCoursesOfferedData
          && HideDualEnrollmentData)"
        class="pt-4 pb-4"
      >
        <b-col cols="12">
          <b-row
            v-if="!HideAPIBData
              || !HideAPIBCoursesOfferedData
              || !HideDualEnrollmentData"
            class="d-print-none"
          >
            <b-col>
              <section-header :id="AdvancedHeaderId" :header-text="AdvancedSectionHeader" />
            </b-col>
          </b-row>
          <div
            v-if="!HideAPIBData
              || !HideAPIBCoursesOfferedData
              || !HideDualEnrollmentData"
            class="pt-4 intro"
            v-html="APIBIntro"
          />
          <b-card
            v-if="!HideAPIBData"
            :id="`${APIBId}-print`"
            ref="APIBVisual"
            class="outside-border"
          >
            <b-card-header class="d-none d-print-block">
              {{ APIBPrintDisplay }}
            </b-card-header>
            <b-row :id="APIBId" class="pt-4">
              <b-col>
                <h3>{{ APIBTitle }} </h3>
              </b-col>
              <b-col cols="1" class="d-none d-lg-block">
                <a
                  ref="APIBPrintButton"
                  class="small-icon d-print-none"
                  :title="APIBTitle"
                  @click="printAPIBCourseWork"
                  @keydown="printAPIBCourseWork"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    :alt="APIBIntro + `Print Data`"
                  >
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <p v-html="APIBDescription" />
            <b-row
              v-for="(fullAPIBIDataSet, index) in APIBIData"
              :key="`fullAPIBIDataSet-${index}`"
              ref="APIBChartContainer"
            >
              <b-col>
                <visual-component
                  :ref="`APIB-Chart-${index}`"
                  class="keep-break"
                  :visual-title="''"
                  :visual-description="''"
                  :chart-type="ChartTypes.HorizontalBarChart"
                  :show-x-axis-labels="false"
                  :chart-data="fullAPIBIDataSet"
                  :suppress-border="true"
                />
                <visual-component
                  :ref="`APIB-Chart-${index}`"
                  class="keep-break sr-only"
                  :visual-title="''"
                  :visual-description="''"
                  :chart-type="ChartTypes.TableGrid"
                  :show-x-axis-labels="false"
                  :chart-data="fullAPIBIDataSet"
                  :suppress-border="true"
                />
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col>
              <visual-component
                v-if="!HideDualEnrollmentData"
                :id="DualEnrollmentId"
                :visual-title="DualEnrollmentTitle"
                :visual-description="DualEnrollmentDescription"
                :chart-type="ChartTypes.HorizontalBarChart"
                :chart-data="DualEnrollmentData"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <visual-component
                v-if="!HideDualEnrollStdtGrpData"
                :id="DualEnrollStdtGrpId"
                :visual-title="DualEnrollStdtGrpTitle"
                :visual-description="DualEnrollStdtGrpDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="DualEnrollStdtGrpData"
                :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                    'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja']"
                :vertical-lines="['% Enrolled in one or more Dual Enrollment course',
                                  '% Inscriptos en uno o más cursos de inscripción doble']"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <visual-component
                v-if="!HideAPIBCoursesOfferedData"
                :id="APIBCoursesOfferedId"
                :visual-title="APIBCoursesOfferedTitle"
                :visual-description="APIBCoursesOfferedDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="APIBCoursesOfferedData"
                :table-shading="APIBCoursesOfferedShading"
                :horizontal-lines="['Total Exams taken', 'Total de exámenes realizados']"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="!(HideCTEParticipationData
          && HideCTEStdGroupData
          && HideCTEValuedCredCareerData)"
        class="pt-4 pb-4"
      >
        <b-col cols="12">
          <b-row
            v-if="!HideCTEParticipationData
              && !HideCTEStdGroupData
              && !HideCTEValuedCredCareerData"
            class="d-print-none"
          >
            <b-col>
              <section-header :id="CTEHeaderId" :header-text="CTESectionHeader" />
            </b-col>
          </b-row>
          <div
            v-if="!HideCTEParticipationData
              && !HideCTEStdGroupData
              && !HideCTEValuedCredCareerData"
            class="pt-4 intro"
            v-html="CTEProgramsIntro"
          />
          <br />
          <b-card
            v-if="!HideCTEParticipationData"
            :id="`${CTEParticipationId}-print`"
            ref="CTEParticipationVisual"
            class="outside-border"
          >
            <b-card-header class="d-none d-print-block">
              {{ CTEPartPrintDisplay }}
            </b-card-header>
            <b-row>
              <b-col :id="CTEParticipationId">
                <b-row>
                  <b-col>
                    <h3>{{ CTEParticipationTitle }} </h3>
                  </b-col>
                  <b-col cols="1" class="d-none d-lg-block">
                    <a
                      class="small-icon d-print-none"
                      :title="CTEParticipationTitle"
                      @click="printCTEParticipation"
                      @keydown="printCTEParticipation"
                    >
                      <font-awesome-icon :icon="['fas', 'download']">
                        <span class="sr-only">Download Icon</span>
                      </font-awesome-icon>
                    </a>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-left">
                    <span>{{ CTEParticipationDescription }}</span>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(fullCTEParticipationDataSet, index)
                    in CTEParticipationData"
                  :key="`fullCTEParticipationDataSet-${index}`"
                  ref="CTEPartChartContainer"
                >
                  <b-col>
                    <visual-component
                      :ref="`CTEPart-Chart-${index}`"
                      class="keep-break"
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="fullCTEParticipationDataSet"
                      :show-x-axis-labels="false"
                      :suppress-border="true"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
          <visual-component
            v-if="!HideStructuredLearningExpParticipationData"
            :id="StructuredLearningExpParticipationId"
            :visual-title="StructuredLearningExpParticipationTitle"
            :visual-description="StructuredLearningExpParticipationDescription"
            :chart-type="ChartTypes.HorizontalBarChart"
            :show-x-axis-labels="false"
            :chart-data="StructuredLearningExpParticipationData"
          />
          <visual-component
            v-if="!HideCTEStdGroupData"
            :id="CTEStdGroupId"
            :visual-title="CTEStdGroupTitle"
            :visual-description="CTEStdGroupDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CTEStdGroupData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
          />
          <visual-component
            v-if="!HideWorkBasedLearningData"
            :id="WorkBasedLearningId"
            :visual-title="WorkBasedLearningTitle"
            :visual-description="WorkBasedLearningDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="WorkBasedLearningData"
            :horizontal-lines="['Total']"
          />
          <visual-component
            v-if="!HideCTEValuedCredData"
            :id="CTEValuedCredId"
            :visual-title="CTEValuedCredTitle"
            :visual-description="CTEValuedCredDescription"
            :chart-type="ChartTypes.HorizontalBarChart"
            :show-x-axis-labels="false"
            :chart-data="CTEValuedCredData"
          />
          <visual-component
            v-if="!HideCTEValuedCredCareerData"
            :id="CTEValuedCredCareerId"
            :visual-title="CTEValuedCredCareerTitle"
            :visual-description="CTEValuedCredCareerDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CTEValuedCredCareerData"
            :horizontal-lines="['Total']"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="!(HideCourseParticipationMathData
          && HideCourseParticipationScienceData
          && HideCourseParticipationHistoryData
          && HideCourseParticipationLanguagesData
          && HideCourseParticipationITData)"
        class="pt-4 pb-4"
      >
        <b-col cols="12">
          <b-row
            v-if="!(HideCourseParticipationMathData
              && HideCourseParticipationScienceData
              && HideCourseParticipationHistoryData
              && HideCourseParticipationLanguagesData
              && HideCourseParticipationITData)"
            class="d-print-none"
          >
            <b-col>
              <section-header
                :id="CoursePartHeaderId"
                :header-text="CourseParticipationSectionHeader"
              />
            </b-col>
          </b-row>
          <div
            v-if="!(HideCourseParticipationMathData
              && HideCourseParticipationScienceData
              && HideCourseParticipationHistoryData
              && HideCourseParticipationLanguagesData
              && HideCourseParticipationITData)"
            class="pt-4 intro"
            v-html="CourseParticipationIntro"
          />
          <visual-component
            v-if="!HideCourseParticipationMathData"
            :id="CourseParticipationMathId"
            :visual-title="CourseParticipationMathTitle"
            :visual-description="CourseParticipationMathDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CourseParticipationMathData"
            :table-shading="ParticipationShading"
            :horizontal-lines="['Total']"
            :equal-width-data-columns="true"
          />
          <visual-component
            v-if="!HideCourseParticipationScienceData"
            :id="CourseParticipationScienceId"
            :visual-title="CourseParticipationScienceTitle"
            :visual-description="CourseParticipationScienceDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CourseParticipationScienceData"
            :table-shading="ParticipationShading"
            :horizontal-lines="['Total']"
            :equal-width-data-columns="true"
          />
          <visual-component
            v-if="!HideCourseParticipationHistoryData"
            :id="CourseParticipationHistoryId"
            :visual-title="CourseParticipationHistoryTitle"
            :visual-description="CourseParticipationHistoryDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CourseParticipationHistoryData"
            :table-shading="ParticipationShading"
            :horizontal-lines="['Total']"
            :equal-width-data-columns="true"
          />
          <visual-component
            v-if="!HideCourseParticipationLanguagesData"
            :id="CourseParticipationLanguagesId"
            :visual-title="CourseParticipationLanguagesTitle"
            :visual-description="CourseParticipationLanguagesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CourseParticipationLanguagesData"
            :horizontal-lines="['Total']"
            :equal-width-data-columns="true"
          />
          <visual-component
            v-if="!HideCourseParticipationITData"
            :id="CourseParticipationITId"
            :visual-title="CourseParticipationITTitle"
            :visual-description="CourseParticipationITDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="CourseParticipationITData"
            :table-shading="ParticipationShading"
            :horizontal-lines="['Total']"
            :equal-width-data-columns="true"
          />
          <visual-component
            v-if="!HideSealBiliteracyData"
            :id="SealBiliteracyId"
            :visual-title="SealBiliteracyTitle"
            :visual-description="SealBiliteracyDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="SealBiliteracyData"
            :horizontal-lines="['Total']"
            :table-shading="BiliteracyTableShading"
          />
          <visual-and-performing-arts />
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
// import VisualComponentDoubleHorizontal from '../components/VisualComponentDoubleHorizontal.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
// eslint-disable-next-line import/no-cycle
import college from '../../mixins/detailComponents/college.mixin';
import translation from '../../mixins/translation-service.mixin';
import hidden from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';
import VisualAndPerformingArts from './VisualAndPerformingArts.vue';

export default {
  name: 'SchoolCollege',
  components: {
    VisualComponent,
    VisualAndPerformingArts,
    SectionHeader,
  },
  mixins: [translation, hidden, college, inPageNav],
  data() {
    return {
      ChartTypes,
      APIBPrintDisplay: '',
      APIBprintMode: false,
      CTEPartPrintDisplay: '',
      CTEPartprintMode: false,
    };
  },
  methods: {
    print() {
      // eslint-disable-next-line
      const printButton = document.getElementById(this.$props.VisualTitle + 'no-print');
      printButton.style.visibility = 'hidden';
      this.$htmlToPaper(this.$props.VisualTitle);
      printButton.style.visibility = 'visible';
    },
    printAPIBCourseWork() {
      this.$refs.APIBVisual.style.width = '1008px';
      setTimeout(() => {
        this.APIBprintMode = true;
        let i;
        for (i = 0; i < this.$refs.APIBChartContainer.length; i += 1) {
          console.log(`Enabling Pring View for APIB-Chart-${i}`);
          this.$refs[`APIB-Chart-${i}`][0].enablePrintView();
          this.APIBPrintDisplay = this.$refs[`APIB-Chart-${i}`][0].printDisplay;
        }

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.APIBId}-print`);

          for (i = 0; i < this.$refs.APIBChartContainer.length; i += 1) {
            this.$refs[`APIB-Chart-${i}`][0].disablePrintView();
          }
          this.APIBprintMode = false;
          this.$refs.APIBVisual.style.width = '';
        });
      }, 100);
    },
    printCTEParticipation() {
      this.$refs.CTEParticipationVisual.style.width = '1008px';
      setTimeout(() => {
        this.CTEPartprintMode = true;
        let i;
        for (i = 0; i < this.$refs.CTEPartChartContainer.length; i += 1) {
          console.log(`Enabling Pring View for CTEPart-Chart-${i}`);
          this.$refs[`CTEPart-Chart-${i}`][0].enablePrintView();
          this.CTEPartPrintDisplay = this.$refs[`CTEPart-Chart-${i}`][0].printDisplay;
        }

        this.$nextTick(() => {
          this.$htmlToPaper(`${this.CTEParticipationId}-print`);

          for (i = 0; i < this.$refs.CTEPartChartContainer.length; i += 1) {
            this.$refs[`CTEPart-Chart-${i}`][0].disablePrintView();
          }
          this.CTEPartprintMode = false;
          this.$refs.CTEParticipationVisual.style.width = '';
        });
      }, 100);
    },
  },
};
</script>

<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
  border-radius: 4px;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
</style>
