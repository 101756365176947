import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_API_ROOT}`;
const detailAcademicUrl = `${rootUrl}api/statedetailed/AcademicAchievement`;
const detailedAccountabilityUrl = `${rootUrl}api/statedetailed/Accountability`;
const detailedClimateUrl = `${rootUrl}api/statedetailed/Climate`;
const detailedCollegeUrl = `${rootUrl}api/statedetailed/CollegeAndCareerReadiness`;
const detailedDemographicsUrl = `${rootUrl}api/statedetailed/demographics`;
const detailGradUrl = `${rootUrl}api/statedetailed/Grad`;
const detailStaffUrl = `${rootUrl}api/statedetailed/Staff`;
const detailedStudentGrowthUrl = `${rootUrl}api/statedetailed/StudentGrowth`;
const detailedHeaderUrl = `${rootUrl}api/statedetailed/header`;
const cdsUrl = `${rootUrl}api/cds`;

export default {
  Detail: {
    Academic: {
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsELA(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsELA/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            [response.data.data.map((o) => o.column1Data)],
          ],
        };
      },
      // eslint-disable-next-line max-len
      async getEnglishLanguageArtsandMathematicsPerformanceTrendsMath(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrendsMath/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            [response.data.data.map((o) => o.column1Data)],
          ],
        };
      },
      async getEnglishLanguageArtsandMathematicsPerformanceTrend(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsandMathematicsPerformanceTrends/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getELAParticipationPerformance(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentParticipationAndPerformance/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getEnglishLanguageArtsAssessmentPerformanceTrends(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EnglishLanguageArtsAssessmentPerformanceTrends/${schoolYear}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getELAPerformanceByGradeX(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELAAssessmentPerformanceByGradeX/${schoolYear}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathParticipationPerformance(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentParticipationAndPerformance/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
      async getMathematicsAssessmentPerformanceTrends(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathematicsAssessmentPerformanceTrends/${schoolYear}?lang=${languageCode}`,
        );
        return {
          /* *******************************************
          * This is a triple bar chart with y-axis label,
          * y-axis Label %
          * multiple grade on x-axis
          * 3 data points per x-axis
          * legend
          ******************************************* */
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getMathPerformanceByGradeX(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByGradeX/${schoolYear}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
              [grade.data.map((o) => o.column7Data)],
              [grade.data.map((o) => o.column8Data)],
              [grade.data.map((o) => o.column9Data)],
              [grade.data.map((o) => o.column10Data)],
              [grade.data.map((o) => o.column11Data)],
              [grade.data.map((o) => o.column12Data)],
              [grade.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
              grade.columns.column7Name,
              grade.columns.column8Name,
              grade.columns.column9Name,
              grade.columns.column10Name,
              grade.columns.column11Name,
              grade.columns.column12Name,
              grade.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getMathPerformanceByTestX(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/MathAssessmentPerformanceByTestX/${schoolYear}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((test) => {
          returnData.push({
            test: test.data[0].column1Data,
            labels: test.data.map((o) => o.column2Data),
            values: [
              [test.data.map((o) => o.column3Data)],
              [test.data.map((o) => o.column4Data)],
              [test.data.map((o) => o.column5Data)],
              [test.data.map((o) => o.column6Data)],
              [test.data.map((o) => o.column7Data)],
              [test.data.map((o) => o.column8Data)],
              [test.data.map((o) => o.column9Data)],
              [test.data.map((o) => o.column10Data)],
              [test.data.map((o) => o.column11Data)],
              [test.data.map((o) => o.column12Data)],
              [test.data.map((o) => o.column13Data)],
            ],
            columnHeaders: [
              test.columns.column2Name,
              test.columns.column3Name,
              test.columns.column4Name,
              test.columns.column5Name,
              test.columns.column6Name,
              test.columns.column7Name,
              test.columns.column8Name,
              test.columns.column9Name,
              test.columns.column10Name,
              test.columns.column11Name,
              test.columns.column12Name,
              test.columns.column13Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAScienceAssessmentGradeXSummary(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentGradeXSummary/${schoolYear}?lang=${languageCode}`,
        );
        /* ******************************************
        * This is a stacked bar chart
        * 4 levels
        * y-axis %
        * x-axis chart label
        * legend
        ********************************************* */
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNJSLAPerformanceByGradeXTable(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJSLAScienceAssessmentTableByGradeX/${schoolYear}?lang=${languageCode}`,
        );
        const apiData = response.data;
        const returnData = [];

        apiData.forEach((grade) => {
          returnData.push({
            grade: grade.data[0].column1Data,
            labels: grade.data.map((o) => o.column2Data),
            values: [
              [grade.data.map((o) => o.column3Data)],
              [grade.data.map((o) => o.column4Data)],
              [grade.data.map((o) => o.column5Data)],
              [grade.data.map((o) => o.column6Data)],
            ],
            columnHeaders: [
              grade.columns.column2Name,
              grade.columns.column3Name,
              grade.columns.column4Name,
              grade.columns.column5Name,
              grade.columns.column6Name,
            ],
          });
        });
        return returnData;
      },
      async getNationalAssessmentEducationalProgressNAEP(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NationalAssessmentEducationalProgressNAEP/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getDLMAssessment(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/DLMAlternateAssessmentParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getEnglishProficiencyTest(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishProgressToProf(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/ELLProgressToProficiency/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnglishParticipationPerf(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/EngLangProfTestPartAndPerf/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getNJGPA11(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailAcademicUrl}/NJGPAGrade11/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
          ],
        };
      },
    },
    Accountability: {
      async getESSAAccountabilityProgress(schoolYear, languageCode) {
        try {
          console.log(`${detailedAccountabilityUrl}/ESSAAccountabilityProgress/${schoolYear}?lang=${languageCode}`);
          const response = await axios.get(`${detailedAccountabilityUrl}/ESSAAccountabilityProgress/${schoolYear}?lang=${languageCode}`);
          // Translate Response to DataSet objects for VisualComponent
          return {
            labels: response.data.data.map((o) => o.column1Data),
            values: [
              [response.data.data.map((o) => o.column2Data)],
              [response.data.data.map((o) => o.column3Data)],
              [response.data.data.map((o) => o.column4Data)],
            ],
            columnHeaders: [
              response.data.columns.column1Name,
              response.data.columns.column2Name,
              response.data.columns.column3Name,
              response.data.columns.column4Name,
            ],
          };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
      async getComprehensiveOrTargettedSupport(schoolYear, languageCode) {
        try {
          console.log(`${detailedAccountabilityUrl}/ComprehensiveOrTargettedSupport/${schoolYear}?lang=${languageCode}`);
          const response = await axios.get(`${detailedAccountabilityUrl}/ComprehensiveOrTargettedSupport/${schoolYear}?lang=${languageCode}`);
          // Translate Response to DataSet objects for VisualComponent
          return {
            labels: response.data.data.map((o) => o.column1Data),
            values: [
              [response.data.data.map((o) => o.column2Data)],
              [response.data.data.map((o) => o.column3Data)],
              [response.data.data.map((o) => o.column4Data)],
              [response.data.data.map((o) => o.column5Data)],
              [response.data.data.map((o) => `<a href="/${schoolYear}/school/detail/${o.column1Data}/${o.column3Data}/${o.column5Data}?lang=${languageCode}" target="_blank" title="Link to ${o.column6Data} detail report">${o.column6Data}</a>`)],
              [response.data.data.map((o) => o.column7Data)],
              [response.data.data.map((o) => o.column8Data)],
              [response.data.data.map((o) => o.column9Data)],
              [response.data.data.map((o) => o.column10Data)],
            ],
            columnHeaders: [
              response.data.columns.column1Name,
              response.data.columns.column2Name,
              response.data.columns.column3Name,
              response.data.columns.column4Name,
              response.data.columns.column5Name,
              response.data.columns.column6Name,
              response.data.columns.column7Name,
              response.data.columns.column8Name,
              response.data.columns.column9Name,
              response.data.columns.column10Name,
            ],
          };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
    },
    Climate: {
      async getAbsentChronic(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/ChronicAbsenteeism/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getDaysAbsent(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/DaysAbsent/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getAbsentChronicGrade(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/ChronicAbsenteeismByGrade/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getViolenceVandalismHIBandSubstanceOffenses(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/ViolenceVandalismHIBandSubstanceOffenses/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getPoliceNotifications(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/PoliceNotifications/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getHarassmentIntimidationandBullyingHIBInvestigations(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/HarassmentIntimidationandBullyingHIBInvestigations/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovals(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/StudentDisciplinaryRemovals/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentDisciplinaryRemovalsSchoolDaysMissed(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/StudentDisciplinaryRemovalsSchoolDaysMissed/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
      /* WHERE DOES THIS GO? */
      async getSchoolDay(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/SchoolDay/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
      /* END POINT DOES NOT EXIST FOR THIS; USING SCHOOLDAYS INSTEAD FOR NOW (DeviceRatios) */
      async getDeviceRatios(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedClimateUrl}/SchoolDay/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
          ],
          columnHeaders: [
            response.data.columns.column1Name,
          ],
        };
      },
    },
    College: {
      async getPSATSATACTParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/PSATSATACTParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getPSATSATACTPerformance(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/PSATSATACTPerformance/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getAPIBCourseworkParticipationandPerformance(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/APIBCourseworkParticipationandPerformance/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getAPIBCoursesOffered(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/APIBCoursesOffered/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDualEnrollmentCourseworkParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/DualEnrollmentCourseworkParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getDualEnrollStdtGrp(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ApIbDualEnrlPartByStudGroup/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getStructuredLearningExpParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/StructuredLearningExpParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/CareerandTechnicalEducationParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationParticipationbyStudentGroup(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/CareerandTechnicalEducationParticipationbyStudentGroup/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getWorkBasedLearning(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/WorkBasedParticipationByCareerCluster/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getIndustryValuedCredentials(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/IndustryValuedCredentials/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getCareerandTechnicalEducationValuedCareer(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/IndustryValuedCredentialsbyCareerCluster/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getVisualandPerformingArtsCourseParticipation6to8(schoolYear, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8/${schoolYear}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation6to8Donut(schoolYear, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtState/${schoolYear}?lang=${languageCode}`);
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      },
      async getVisualandPerformingArtsCourseParticipation6to8DonutState(schoolYear, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation6To8AnyVisualPerfArtState/${schoolYear}?lang=${languageCode}`);
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      },
      async getVisualandPerformingArtsCourseParticipation9to12(schoolYear, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12/${schoolYear}?lang=${languageCode}`);
        return response.data;
      },
      async getVisualandPerformingArtsCourseParticipation9to12Donut(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtState/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      },
      async getVisualandPerformingArtsCourseParticipation9to12DonutState(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/VisualandPerformingArtsCourseParticipation9To12AnyVisualPerfArtState/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [`${response.data.columns.column1Name}`, undefined],
          values: [
            [response.data.data[0].column1Data,
              `${100 - parseInt(response.data.data[0].column1Data, 10)}%`],
          ],
          columnHeaders: ['',
            response.data.columns.column1Name, ''],
        };
      },
      async getMathematicsCourseParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/MathematicsCourseParticipation/${schoolYear}?lang=${languageCode}`,
        );
        if (response.data.data.length <= 0 || response.data.columns.length <= 0) {
          return {};
        }
        // eslint-disable-next-line no-unused-vars
        const retLabels = response.data.data.map((o) => o.column1Data);
        const retvalues = [];
        for (let i = 2; i <= 8; i += 1) {
          if (response.data.data[0][`column${i}Data`]) {
            retvalues.push(response.data.data.map((o) => o[`column${i}Data`]));
          }
        }
        const retHeaders = [];
        for (let j = 1; j <= 8; j += 1) {
          if (response.data.columns[`column${j}Name`]) {
            retHeaders.push(response.data.columns[`column${j}Name`]);
          }
        }
        return {
          labels: retLabels,
          values: retvalues,
          columnHeaders: retHeaders,
        };
      },
      async getScienceCourseParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ScienceCourseParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getSocialStudiesandHistoryCourseParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/SocialStudiesandHistoryCourseParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getWorldLanguagesCourseParticipation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/WorldLanguagesCourseParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getComputerScienceandInformationTechnologyCourseParticipation(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailedCollegeUrl}/ComputerScienceandInformationTechnologyCourseParticipation/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getSealofBiliteracy(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedCollegeUrl}/SealofBiliteracy/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      /* async getSealofBiliteracy(schoolYear, languageCode) {
        const response = await axios.get(`${detailedCollegeUrl}/
        SealofBiliteracy/${schoolYear}?lang=${languageCode}`, {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${await tokenService.getToken()}`,
          },
        });
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.Column1Data),
          values: [
            [response.data.data.map((o) => o.Column2Data)],
          ],
          columnHeaders: [
            response.data.columnHeaders.column1Name,
            response.data.columnHeaders.column2Name,
          ],
        };
      }, */
    },
    Demographics: {
      async getEnrollmentTrendsByGrade(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByGrade/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByStudentGroup(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByStudentGroup/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByRacialAndEthnicGroup(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByRacialAndEthnicGroup/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullHalfDayPkAndKg(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByFullHalfDayPkAndKg/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentTrendsByFullAndSharedTimeStatus(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentTrendsByFullAndSharedTimeStatus/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getEnrollmentByHomeLanguage(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedDemographicsUrl}/EnrollmentByHomeLanguage/${schoolYear}?lang=${languageCode}`,
        );
        // Translate Response to DataSet objects for VisualComponent
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Grad: {
      async getText(schoolYear, dataKey, languageCode) {
        const response = await axios.get(
          `${cdsUrl}/text/${schoolYear}/${dataKey}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getGraduationRateTrendsVisual4Year(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrendsVisual4Year/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrendsVisual5Year(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrendsVisual5Year/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
          values: [
            [response.data.data.map((o) => o.column1Data)],
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getGraduationRateTrends(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationRateTrends/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getCohort4YearGraduationCohortProfile(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort4YearGraduationCohortProfile/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getCohort5YearGraduationCohortProfile(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort5YearGraduationCohortProfile/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getCohort6YearGraduationCohortProfile(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Cohort6YearGraduationCohortProfile/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getFederalGraduationRates(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/FederalGraduationRate/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getAccountabilityGraduationRates(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/AccountabilityGraduationRates/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getGraduationPathways(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/GraduationPathways/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getDropoutRateTrends(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/DropoutRateTrends/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryVisual(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}?lang=${languageCode}`,
        );
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        if (response.data.columns.column5Name === undefined) {
          return {
            labels: [response.data.columns.column3Name,
              response.data.columns.column4Name],
            values: [
              [response.data.data[1].column3Data, response.data.data[1].column4Data],
              [response.data.data[0].column3Data, response.data.data[0].column4Data],
              [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
                `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`],
            ],
            columnHeaders: [
              response.data.columns.column2Name,
              response.data.data[1].column2Data,
              response.data.data[0].column2Data,
              response.data.data[2].column2Data,
            ],
          };
        }
        return {
          labels: [response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name],
          values: [
            [response.data.data[1].column3Data, response.data.data[1].column4Data,
              response.data.data[1].column5Data],
            [response.data.data[0].column3Data, response.data.data[0].column4Data,
              response.data.data[0].column5Data],
            [`txt::${response.data.data[2].column2Data} = ${response.data.data[2].column3Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column4Data}`,
              `txt::${response.data.data[2].column2Data} = ${response.data.data[2].column5Data}`],
          ],
          columnHeaders: [
            response.data.columns.column2Name,
            response.data.data[1].column2Data,
            response.data.data[0].column2Data,
            response.data.data[2].column2Data,
          ],
        };
      },
      async getPostsecondaryEnrollmentRateSummaryTable(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRateSummary/${schoolYear}?lang=${languageCode}`,
        );
        // column heads are -
        // "Performance Measure, Performance Measure Short, Class of 2018, class of 2019
        // for the visual , we need columns 2,3,and 4
        // for the table, we need columns 1,3,4
        // next year we will have one more column class of 2020 as column 5
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRatesFall(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRatesFall/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getPostsecondaryEnrollmentRates16month(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/PostsecondaryEnrollmentRates16month/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
          ],
        };
      },
      async getApprenticeship(
        schoolYear,
        languageCode,
      ) {
        const response = await axios.get(
          `${detailGradUrl}/Apprenticeship/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
    },
    Staff: {
      async getTeachersExperience(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersExperience/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            // [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            // response.data.columns.column3Name,
          ],
        };
      },
      async getAdministratorsExperienceDistrictLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/AdministratorsExperienceDistrictLevel/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            // [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            // response.data.columns.column3Name,
          ],
        };
      },
      async getStaffCountsStateLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/StaffCounts/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
          ],
        };
      },
      async getStudentandStaffRatios(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/StudentandStaffRatios/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            // [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            // response.data.columns.column3Name,
          ],
        };
      },
      async getTeachersandAdministratorsDemographics(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsDemographics/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            // [response.data.data.map((o) => o.column5Data)],
            // [response.data.data.map((o) => o.column6Data)],
            // [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            // response.data.columns.column5Name,
            // response.data.columns.column6Name,
            // response.data.columns.column7Name,
          ],
        };
      },
      async getTeachersandAdministratorsLevelofEducation(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsLevelofEducation/${schoolYear}?lang=${languageCode}`,
        );
        return response.data;
      },
      async getTeachersandAdministratorsOneYearRetentionStateLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersandAdministratorsOneYearRetentionStateLevel/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            // [response.data.data.map((o) => o.column3Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            // response.data.columns.column3Name,
          ],
        };
      },
      async getTeacherSubjectAreaStateLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/TeachersBySubjectArea/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
            [response.data.data.map((o) => o.column8Data)],
            [response.data.data.map((o) => o.column9Data)],
            [response.data.data.map((o) => o.column10Data)],
            [response.data.data.map((o) => o.column11Data)],
            [response.data.data.map((o) => o.column12Data)],
            [response.data.data.map((o) => o.column13Data)],
            [response.data.data.map((o) => o.column14Data)],
            [response.data.data.map((o) => o.column15Data)],
            [response.data.data.map((o) => o.column16Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
            response.data.columns.column8Name,
            response.data.columns.column9Name,
            response.data.columns.column10Name,
            response.data.columns.column11Name,
            response.data.columns.column12Name,
            response.data.columns.column13Name,
            response.data.columns.column14Name,
            response.data.columns.column15Name,
            response.data.columns.column16Name,
          ],
        };
      },
      // async getFacultyAttendance(schoolYear, languageCode) {
      //   const response = await axios.get(
      //     `${detailStaffUrl}/FacultyAttendance/${schoolYear}?lang=${languageCode}`,
      //     {
      //       headers: {
      //         'content-type': 'application/json',
      //         Authorization: `Bearer ${await tokenService.getToken()}`,
      //       },
      //     },
      //   );
      //   return {
      //     labels: response.data.data.map((o) => o.column1Data),
      //     values: [
      //       [response.data.data.map((o) => o.column2Data)],
      //     // [response.data.data.map((o) => o.column3Data)],
      //     ],
      //     columnHeaders: [
      //       response.data.columns.column1Name,
      //       response.data.columns.column2Name,
      //     // response.data.columns.column3Name,
      //     ],
      //   };
      // },
      async getStatewideEducatorEquityData(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailStaffUrl}/StatewideEducatorEquityData/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            // [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            // response.data.columns.column7Name,
          ],
        };
      },
    },
    StudentGrowth: {
      async getStudentGrowthELATrendsMedian(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgressELAMedian/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthMathTrendsMedian(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgressMathMedian/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthTrendsMedian(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthTrendsandProgress/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column3Data)],
            [response.data.data.map((o) => o.column4Data)],
            [response.data.data.map((o) => o.column5Data)],
            [response.data.data.map((o) => o.column6Data)],
            [response.data.data.map((o) => o.column7Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column3Name,
            response.data.columns.column4Name,
            response.data.columns.column5Name,
            response.data.columns.column6Name,
            response.data.columns.column7Name,
          ],
        };
      },
      async getStudentGrowthTableData(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowth/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => o.column2Data)],
            [response.data.data.map((o) => o.column4Data)],
          ],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column2Name,
            response.data.columns.column4Name,
          ],
        };
      },
      async getStudentGrowthELAPerfLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByPerfLevelELA/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthMathPerfLevel(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByPerfLevelMath/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthELAGrade(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByGradeELA/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      async getStudentGrowthMathGrade(schoolYear, languageCode) {
        const response = await axios.get(
          `${detailedStudentGrowthUrl}/StudentGrowthByGradeMath/${schoolYear}?lang=${languageCode}`,
        );
        return {
          labels: response.data.data.map((o) => o.column1Data),
          values: [
            [response.data.data.map((o) => this.mergeColumnData(o.column2Data, o.column3Data))]],
          columnHeaders: [
            response.data.columns.column1Name,
            response.data.columns.column3Name,
          ],
        };
      },
      mergeColumnData(column2Data, column3Data) {
        const isNumber = column2Data && (!Number.isNaN(parseFloat(column2Data))
          || Number.isFinite(column2Data));
        if (isNumber) {
          if (column3Data) {
          return `${column3Data}<br/>${column2Data}`;
          }
        }
        return `${column2Data}`;
      },
    },
  },
  Header: {
    async getHeader(schoolYear, languageCode) {
      const response = await axios.get(`${detailedHeaderUrl}/${schoolYear}?lang=${languageCode}`);
      // Translate Response to DataSet objects for VisualComponent
      return response.data;
    },
  },
};
