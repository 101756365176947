import TranslationSvc from '../../services/translation.service';
import ChartTypes from '../../components/ChartTypes';
import navigation from '../navigation.mixin';
import mapping from '../mapping.mixin';

export default {
  mixins: [navigation, mapping],
  data() {
    return {
      ChartTypes,
    };
  },
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };
      this.$store.dispatch('getDistrictEnrollmentByGrade', params);
      this.$store.dispatch('getDistrictEnrollmentByDay', params);
      this.$store.dispatch('getDistrictEnrollmentByStudentGroup', params);
      this.$store.dispatch('getDistrictEnrollmentByTime', params);
      this.$store.dispatch('getDistrictEnrollmentByRace', params);
      this.$store.dispatch('getDistrictEnrollmentByHomeLanguage', params);
    },
  },
  computed: {
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    OverviewSectionHeader() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OverviewHeader,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    overviewHeaderText() {
      return this.translationService.getNavigationTranslation(
        'Overview',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      ).replace('<br/>', ' ');
    },
    OverviewIntro() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OverviewIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OverviewId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.OverviewHeader,
      );
    },
    ImportantTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.ImportantNotesTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ImportantText() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.ImportantNotesText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ImpactCovid() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.ImpactCovid,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NavTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NavTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NavItem1() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NavItem1,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NavItem2() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NavItem2,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NavItem3() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NavItem3,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NavItem4() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NavItem4,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherText() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem1() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem1,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem2() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem2,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem3() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem3,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem4() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem4,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem5() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem5,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    OtherItem6() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.OtherItem6,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NotesTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.NotesTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolContact() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolContactText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DistrictContact() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.DistrictContactText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StateContact() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.ContactText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TypeAndContactId() {
      if (this.ReportType === 'district') {
        return this.GetElementIdByTitle(
          TranslationSvc.Constants.ComponentNames.DistrictContactTitle,
        );
      }
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolContactTitle,
      );
    },
    TypeAndContactTitle() {
      if (this.ReportType === 'district') {
        return this.translationService.getOverviewTranslation(
          TranslationSvc.Constants.ComponentNames.DistrictContactTitle,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolContactTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TypeAndContactDescription() {
      if (this.ReportType === 'district') {
        return this.translationService.getOverviewTranslation(
          TranslationSvc.Constants.ComponentNames.DistrictContactText,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolContactText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TypeAndContactData() {
      try {
        const storeObjects = this.$store.getters.OverviewReportData.filter((o) => o.Component === `${this.ReportType}_TypeAndContactInformation`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        // console.error(e);
      }
      return {};
    },
    HideTypeAndContactData() {
      const storeData = this.$store.getters.OverviewReportData.filter((o) => o.Component === `${this.ReportType}_TypeAndContactInformation`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return false;
      }
      return true;
    },
    SchoolsInDistrictId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolsInDistrictTitle,
      );
    },
    SchoolsInDistrictTitle() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolsInDistrictTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolsInDistrictDescription() {
      return this.translationService.getOverviewTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolsInDistrictText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolsInDistrictData() {
      try {
        const storeObjects = this.$store.getters.OverviewReportData.filter((o) => o.Component === `${this.ReportType}_SchoolsInDistrict`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        // console.error(e);
      }
      return {};
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
