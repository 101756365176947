import districtService from '../services/district.service';
import schoolService from '../services/school.service';
// import stateService from '../services/state.service';

export default {
  state: {
    narrativeReportData: [],
  },
  getters: {
    NarrativeReportData: (state) => state.narrativeReportData,
  },
  mutations: {
    SET_NARRATIVE_REPORT_DATA(state, payload) {
      const component = state.narrativeReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.narrativeReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    // async getNarrative(
    //   { commit, state },
    //   payload,
    // ) {
    // eslint-disable-next-line max-len
    //   if (!state.narrativeReportData.some((o) => o.Component
    // === `${payload.reportType}_Narrative_${payload.category}`)) {
    //     let response;
    //     switch (payload.reportType) {
    //       case 'district':
    //         // eslint-disable-next-line max-len
    //         response = await districtService.Detail.Narrative.getNarrativeByCategory(
    //           payload.schoolYear, payload.countyCd, payload.districtCd,
    //           payload.category, payload.langCd,
    //         );
    //         break;
    //       case 'school':
    //         // eslint-disable-next-line max-len
    //         response = await schoolService
    //           .Detail.Narrative.getNarrativeByCategory(
    //             payload.schoolYear,
    //             payload.countyCd, payload.districtCd, payload.schoolCd, payload.category,
    //             payload.langCd,
    //           );
    //         break;
    //       default: // state
    //         // eslint-disable-next-line max-len
    //         response = await stateService.Detail.Narrative.getNarrativeByCategory(
    //           payload.schoolYear, payload.category, payload.langCd,
    //         );
    //         break;
    //     }
    // eslint-disable-next-line max-len
    //     commit('SET_NARRATIVE_REPORT_DATA', { Component:
    // `${payload.reportType}_Narrative_${payload.category}`, data: response });
    //   }
    // },
    async getAllNarrativeData(
      { commit, state },
      payload,
    ) {
      if (!state.narrativeReportData.some((o) => o.Component === `${payload.reportType}_Narrative`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // eslint-disable-next-line max-len
            response = await districtService.Detail.Narrative.getNarratives(
              payload.schoolYear,
              payload.countyCd,
              payload.districtCd,
              payload.langCd,
            );
            break;
          case 'school':
            // eslint-disable-next-line max-len
            response = await schoolService
              .Detail.Narrative.getNarratives(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default: // state
            // This shouldn't exist
            // Then why are returning anything?
            response = [];
            break;
        }
        commit('SET_NARRATIVE_REPORT_DATA', { Component: `${payload.reportType}_Narrative`, data: response });
      }
    },
  },
};
