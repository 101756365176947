import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [mapping, navigation],
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };
      this.$store.dispatch('getComprehensiveOrTargettedSupport', params);
      this.$store.dispatch('getESSAAccountabilityStatus', params);
      this.$store.dispatch('getESSAAccountabilityProgress', params);
      this.$store.dispatch('getESSAAccountabilitySummative', params);
      this.$store.dispatch('getESSAAccountabilitySummary', params);
      this.$store.dispatch('getSummativeFooter', params);
    },
  },
  computed: {
    AccountabilitySectionHeader() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityHeader,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    AccountabilityHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AccountabilityHeader,
      );
    },
    AccountabilityIntro() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityStatusId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AccountabilityStatusTitle,
      );
    },
    AccountabilityStatusTitle() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityStatusTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityStatusDescription() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityStatusText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityStatusPlusFooter() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityStatusPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityStatusData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilityStatus`);
        if (!storeData || storeData.length === 0 || !storeData[0].data) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccountabilityProgressId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AccountabilityProgressTitle,
      );
    },
    AccountabilityProgressTitle() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityProgressTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityProgressDescription() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityProgressText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityProgressPlusFooter() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilityProgressPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilityProgressTableShading() {
      if (this.$store.getters.SelectedSchoolYear.value >= 2223) {
        return [
          {
            cellValues: ['NA'],
            cellVariant: 'secondary',
            suppressText: true,
          },
        ];
      }
      return [
        {
          cellValues: ['NA', 'N'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    AccountabilityProgressData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilityProgress`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccountabilitySummativeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummativeTitle,
      );
    },
    AccountabilitySummativeTitle() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummativeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilitySummativeDescription() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummativeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilitySummativeData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilitySummative`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccountabilitySummativeTableShading() {
      return [
        {
          cellValues: ['0%'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    AccountabilitySummativeFooter() {
      const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_SummativeFooter`);
      const additionalFooter = this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummativePlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      let defaultText = this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummativePlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );

      if (!storeData || storeData.length === 0 || !storeData[0].data
        || storeData[0].data.length === 0) {
        return defaultText;
      }
      if (this.$store.getters.CurrentLanguage === 'ES') {
        defaultText = `<p style="mb-0"><strong>Nota de Metodología Alternativa:</strong>
        ${storeData[0].data}</p><p style="mb-0">${additionalFooter}</p>`;
      } else {
        defaultText = `<p style="mb-0"><strong>Alternate Methodology Note:</strong>
        ${storeData[0].data}</p><p style="mb-0">${additionalFooter}</p>`;
      }
      return defaultText;
    },
    AccountabilitySummaryId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummaryTitle,
      );
    },
    AccountabilitySummaryTitle() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummaryTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilitySummaryDescription() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummaryText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AccountabilitySummaryData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilitySummary`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AccoutabilitySummaryShading() {
      return [
        {
          cellValues: ['Met', 'Cumplió', 'Met Standard', 'Cumplió el Estándar', 'Cumplió el Estándar',
            'Met Goal', 'Objetivo Cumplido', 'Cumplir Estándar', 'Met Target†', 'Objetivo cumplido†',
            'Met Target', 'Objetivo cumplido'],
          cellVariant: 'success',
        },
        {
          cellValues: ['Not Met', 'Objetivo no Cumplido', 'No se cumplió', 'No Cumplido'],
          cellVariant: 'danger',
        },
        {
          cellValues: ['Exceeds Standard', 'Exceder el Estándar', 'Excede el Estándar'],
          cellVariant: 'info',
        },
        {
          cellValues: ['Met Goal', 'Meta Cumplida', 'Objetivo Cumplido'],
          cellVariant: 'info',
        },
        {
          cellValues: ['NA', 'N/A'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    AccountabilitySummaryGroupPlusFooter() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AccountabilitySummaryGroupPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ComprehensiveOrTargetedSupportId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolsTargetSupportTitle,
      );
    },
    ComprehensiveOrTargetedSupportTitle() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolsTargetSupportTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ComprehensiveOrTargetedSupportDescription() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolsTargetSupportText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ComprehensiveOrTargettedSupportData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_ComprehensiveOrTargettedSupport`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.labels || storeData[0].data.labels.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    ComprehensiveOrTargettedSupportNoDataText() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolsTargetSupportNoDataText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AlternateMethodologyNoDataText() {
      return this.translationService.getAccountabilityTranslation(
        TranslationSvc.Constants.ComponentNames.AlternateMethodologyNoDataText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
  },
  watch: {
    ReportType() {
      this.fetchData();
    },
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
