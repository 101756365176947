import templateCode from './template-code.mixin';
import TranslationSvc from '../services/translation.service';

export default {
  mixins: [templateCode],
  computed: {
    // #region Overview

    // #endregion Overview
    // #region Demographics
    HideEnrollmentByGradeVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByGrade`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    HideEnrollmentByStudentGroupVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByStudentGroup`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    HideEnrollmentByRaceVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByRace`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    HideEnrollmentByDayVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByDay`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    HideEnrollmentByTimeVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByTime`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    HideEnrollmentByHomeLanguageVisual() {
      const storeData = this.$store.getters.DemographicsReportData.filter((o) => o.Component === `${this.ReportType}_EnrollmentByHomeLanguage`);
      if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        return true;
      }
      return false;
    },
    // #endregion Demographics
    // #region StudentGrowth
    HideStdGrowthTrendsAndProgress() {
      return false;
    },
    HideStdGrowth() {
      return false;
    },
    HideStdGrowthByPerformanceLevel() {
      return false;
    },
    HideStdGrowthByGrade() {
      return false;
    },
    // #endregion Student Growth
    // #region CollegeCareer
    HideAssessmentsParticipationData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_AssessmentsParticipation`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideAssessmentsPerformanceData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_AssessmentsPerformance`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideAPIBData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_APIB`);
        if (!storeObjects || !storeObjects[0]
        || !storeObjects[0].data
        || storeObjects[0].data.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideAPIBCoursesOfferedData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_APIBCoursesOffered`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideDualEnrollStdtGrpData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_DualEnrollStdtGrp`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideDualEnrollmentData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_DualEnrollment`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || storeObjects[0].data.length === 0
          || (storeObjects[0].data.data.data1 === '' && storeObjects[0].data.data.data2 === '')
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCTEParticipationData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEParticipation`);
        if (!storeObjects || !storeObjects[0] || !storeObjects[0].data
        || storeObjects[0].data.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCTEStdGroupData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEStGroup`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideWorkBasedLearningData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_WorkBasedLearning`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCTEValuedCredData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEValuedCred`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.data
          || (storeData[0].data.data.data1 === '' && storeData[0].data.data.data2 === '')
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCTEValuedCredCareerData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CTEValuedCareer`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideStructuredLearningExpParticipationData() {
      try {
        const storeObjects = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_StructuredLearningExpParticipation`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || storeObjects[0].data.length === 0
          || (storeObjects[0].data.data.data1 === '' && storeObjects[0].data.data.data2 === '')
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCourseParticipationMathData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationMath`);
        if (!storeData
          || storeData.length === 0
          || !storeData[0].data
          || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCourseParticipationScienceData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationScience`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCourseParticipationHistoryData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationHistory`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCourseParticipationLanguagesData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationLanguage`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideCourseParticipationITData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_CourseParticipationIT`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideSealBiliteracyData() {
      try {
        const storeData = this.$store.getters.CollegeReportData.filter((o) => o.Component === `${this.ReportType}_SealBiliteracy`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    // #endregion CollegeCareer
    // #region Climate
    HideViolenceOffensesData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.ViolenceOffensesTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_ViolenceOffense`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HidePoliceNotifData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.PoliceNotifTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_PoliceNotif`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideHarrassInvestData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.HarrassInvestTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_HarrassInvest`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideStdDisciplinData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.StdDisciplinTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdDisciplin`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideSchoolDayData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.SchoolDayTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_SchoolDay`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || !storeData[0].data.labels.length === 0) {
          return true;
        }
        return this.ReportType !== 'school';
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideSchoolDaysMissedData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.SchoolDaysMissedTitle,
      )) {
        return true;
      }
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_SchoolDaysMissed`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.labels
          || !storeObjects[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    HideDeviceRatiosData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.DeviceRatiosTitle,
      )) {
        return true;
      }
      return false;
    },
    HideAbsentChronicData() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.AbsentChronicTitle,
      )) {
        return true;
      }
      return false;
    },
    HideTechnologyInternetAccessTitle() {
      if (!this.visualIsAvailableForInstitution(
        'ClimateEnv',
        TranslationSvc.Constants.ComponentNames.TechnologyInternetAccessTitle,
      )) {
        return true;
      }
      return false;
    },
    // #endregion Climate
    // #region PostSecondary
    HideGradHeader() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'Grad-Header',
      )) {
        return true;
        // else check for data existence.
      }
      return false;
    },
    HideGraduationRateTrends() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'GradRateProgress-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationRateTrendsVisual`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideCohort4Year() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'Cohort4Year-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort4YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideCohort5Year() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'Cohort5Year-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort5YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideCohort6Year() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'Cohort6Year-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Cohort6YearGraduationCohortProfile`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideFederalGradRates() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'FedGradRate-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_FederalGraduationRates`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideAccountabilityGradRates() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'GradRate-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        return false;
        // eslint-disable-next-line max-len
        // const storeData = this.$store.getters.GradReportData.filter((o) => o.Component
        // === `${this.ReportType}_AccountabilityGraduationRates`);
        // if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
        //   return false;
        // }
        // return true;
      // eslint-disable-next-line no-unreachable
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideGradPathways() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'GradPathways-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_GraduationPathways`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideDropout() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'Dropout-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_DropoutRateTrends`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HidePostSecEnrollSummaryTable() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'PostSecEnrollSummary-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRateSummaryTable`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HidePostSecEnrollSummaryVisual() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'PostSecEnrollSummary-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRateSummaryVisual`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HidePostSecEnrollFallVisual() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'PostSecEnrollFall-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRatesFall`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HidePostSecEnroll16MonthVisual() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        'PostSecEnroll16Month-Title',
      )) {
        return true;
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_PostsecondaryEnrollmentRates16month`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideApprenticeship() {
      if (!this.visualIsAvailableForInstitution(
        'Grad',
        TranslationSvc.Constants.ComponentNames.ApprenticeshipTitle,
      )) {
        const institution = this.CurrentInstitution;
        if (institution && institution.collegeAndCareerReadiness && institution.collegeAndCareerReadiness !== 'Y') {
          return true;
        }
        // else check for data existence.
      }
      try {
        const storeData = this.$store.getters.GradReportData.filter((o) => o.Component === `${this.ReportType}_Apprenticeship`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    // #endregion PostSecondary
    // #region Staff
    HideTeacherExpVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherExp`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideAdmExpVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_AdmExp`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideStaffCountsVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StaffCounts`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideStdStaffRatiosVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StdStaffRatios`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideTeacherDemogVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherDemog`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideTeacherLevelEducVisual() {
      const storeObjects = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherLevelEdu`);
      if (!storeObjects || storeObjects.length === 0
        || !storeObjects[0].data || !storeObjects[0].data.length
        || storeObjects[0].data.length === 0) {
        return true;
      }

      return false;
    },
    HideTeacher1YearRetentionVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_Teacher1YearRetention`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideTeacherSubjectAreaVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherSubjectArea`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideStatewideEducatorEquityVisual() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StatewideEducatorEquityData`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    // #endregion Staff
    // #region PerPupil
    HidePerPupil() {
      if (!this.visualIsAvailableForInstitution(
        'PerPupilExp',
        'Expenditure-Title',
      )) {
        return true;
        // else check for data existence.
      }
      return false;
    },
    // #endregion PerPupil
    // #region Accountability
    HideAccountabilityStatus() {
      return this.ReportType !== 'school';
    },
    HideAccountabilityStatusData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilityStatus`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    HideAccountabilityProgressData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilityProgress`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    HideAccountabilitySummativeData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilitySummative`);

        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    HideAccountabilitySummaryData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_AccountabilitySummary`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    HideComprehensiveOrTargettedSupportData() {
      try {
        const storeData = this.$store.getters.AccountabilityReportData.filter((o) => o.Component === `${this.ReportType}_ComprehensiveOrTargettedSupport`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.labels || storeData[0].data.labels.length === 0) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    // #endregion Accountability
    // #region Narrative

    // #endregion Narrative
  },
};
