/* eslint no-underscore-dangle: 0 */
import { Bar } from 'vue-chartjs';
/* eslint no-unused-vars: 0 */
import pattern from 'patternomaly';

export default {
  extends: Bar,
  props: {
    DataLabels: {
      // default: () => ['Apple', 'Orange', 'Banana'],
    },
    DataSets: {
      // default: () => [{
      //   fill: false,
      //   label: '2017-18',
      //   data: [15, 25, 35],
      //   backgroundColor: [
      //     pattern.draw('diagonal', '#c5dcf1'),
      //     pattern.draw('diagonal', '#c5dcf1'),
      //     pattern.draw('diagonal', '#c5dcf1'),
      //   ],
      //   borderColor: [
      //     '#7B7474',
      //     '#7B7474',
      //     '#7B7474',
      //   ],
      //   borderWidth: 0.5,
      // },
      // {
      //   fill: false,
      //   label: '2018-19',
      //   data: [16, 26, 36],
      //   backgroundColor: [
      //     pattern.draw('diagonal', '#327ec4'),
      //     pattern.draw('diagonal', '#327ec4'),
      //     pattern.draw('diagonal', '#327ec4'),
      //   ],
      //   borderColor: [
      //     '#7B7474',
      //     '#7B7474',
      //     '#7B7474',
      //   ],
      //   borderWidth: 0.5,
      // },
      // {
      //   label: '2019-20',
      //   data: [17, 27, 37],
      //   fill: false,
      //   backgroundColor: '#1d4b75',
      // },
      // ],
    },
  },
  computed: {
    labels() {
      return this.$props.DataLabels;
    },
    datasets() {
      return this.$props.DataSets;
    },
    isPrintView() {
      return this.$store.getters.IsPrintView;
    },
    options() {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 100,
              fontSize: 10,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 10,
            },
          }],
        },
        tooltips: {
          mode: 'index',
          titleFontSize: 8,
          titleFontStyle: 'normal',
          titleFontFamily: 'Arial',
          bodyFontSize: 8,
          bodyFontStyle: 'normal',
          bodyFontFamily: 'Arial',
          callbacks: {
            label: (tooltipItem, data) => {
              const dataSet = data.datasets[tooltipItem.datasetIndex];
              if (dataSet.label === 'CHART_KEY') {
                return data.labels[tooltipItem.index];
              }
              return `${dataSet.label} - ${dataSet.dataAsString[tooltipItem.index]}`;
            },
          },
        },
        legend: {
          display: true,
          fullWidth: true,
          labels: {
            boxWidth: 15,
            fontSize: 10,
            font: {
              family: 'Helvetica',
              style: 'normal',
              size: 10,
            },
          },
          onClick: (e) => e.stopPropagation(),
        },
        barThickness: '150',
        categoryPercentage: 0.9,
        maintainAspectRatio: false,
        responsive: () => {
          if (window.matchMedia('(max-width: 767px)').matches) {
            /* The viewport is less than, or equal to, 700 pixels wide */
            return false;
          }
          /* The viewport is greater than 700 pixels wide */
          return true;
        },
        animation: {
          duration: this.isPrintView ? 0 : 0.5,
          onComplete() {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = '12px Roboto';
            ctx.fillStyle = '#000';
            let offset = 0;
            this.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const dataStr = dataset.dataAsString[index];
                if (i % 2 !== 0 && dataStr === '100%') {
                  offset = -8;
                } else {
                  offset = 0;
                }
                if (ctx.canvas.width < 768) {
                  ctx.fillText(`${dataStr}`, bar._model.x, bar._model.y + offset, 5);
                } else {
                  ctx.fillText(`${dataStr}`, bar._model.x, bar._model.y + offset);
                }
              });
            });
          },
        },
      };
    },
  },
  methods: {
    render() {
      const dataCollection = {
        labels: this.labels,
        datasets: this.datasets,
      };
      this.renderChart(dataCollection, this.options);
    },
    getContext() {
      return this.$refs.canvas.getContext('2d');
    },
  },
  data() {
    return {
    };
  },
  watch: {
    labels() {
      this.render();
    },
    datasets() {
      this.render();
    },
  },
  mounted() {
    if (this.$store.getters.IsPrintView) {
      this.$refs.canvas.height = 250;
    }
    // renderChart function renders the chart with the datacollection and options object.
    this.render();
  },
};
