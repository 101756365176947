<template>
  <div>
    <b-row
      v-if="!HideVisualPerformingArts6to8 || !HideVisualPerformingArts9to12"
      id="CourseParticipationArtsTitle"
      class="pt-4"
    >
      <b-col>
        <b-card
          :id="CourseParticipationArtsId"
          ref="VisualPerformingArts"
          class="outside-border"
        >
          <b-card-header v-if="PrintDisplay" class="d-none d-print-block">
            {{ PrintDisplay }}
          </b-card-header>
          <b-row>
            <b-col>
              <h3>{{ CourseParticipationArtsTitle }}</h3>
            </b-col>
            <b-col cols="1" class="d-none d-lg-block">
              <a
                :id="'CourseParticipationArtsTitle-no-print'"
                class="small-icon d-print-none"
                :title="CourseParticipationArtsTitle"
                :alt="CourseParticipationArtsTitle + ` Download Chart Icon`"
                @click="print"
                @keydown="print"
              >
                <font-awesome-icon :icon="['fas', 'download']">
                  <span class="sr-only">Download Chart</span>
                </font-awesome-icon>
              </a>
            </b-col>
          </b-row>
          <p v-html="CourseParticipationArtsDescription" />
          <b-row>
            <b-col
              v-if="!HideVisualPerformingArts6to8"
              cols="12"
              md="6"
              class="bottom-right-border"
            >
              <b-row>
                <b-col class="descriptive-text">
                  <span v-html="Grade68Title" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  col
                  :md="ReportType === 'school'
                    || ReportType === 'district' ? `6` : `12`"
                >
                  <visual-component
                    ref="VisualPerformingDonut6to8"
                    :chart-type="ChartTypes.DonutChart"
                    :chart-data="VisualPerformingDonut6to8"
                    :suppress-border="true"
                  />
                </b-col>
                <b-col
                  v-if="ReportType === 'school' || ReportType === 'district'"
                  col
                  md="6"
                >
                  <visual-component
                    ref="VisualPerformingDonut6to8State"
                    :chart-type="ChartTypes.DonutChart"
                    :chart-data="VisualPerformingDonut6to8State"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="!HideVisualPerformingArts9to12"
              cols="12"
              md="6"
              class="border-bottom-only"
            >
              <b-row>
                <b-col class="descriptive-text">
                  <span v-html="Grade912Title" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  col
                  :md="ReportType === 'school'
                    || ReportType === 'district' ? `6` : `12`"
                >
                  <visual-component
                    ref="VisualPerformingDonut9to12"
                    :chart-type="ChartTypes.DonutChart"
                    :chart-data="VisualPerformingDonut9to12"
                    :suppress-border="true"
                  />
                </b-col>
                <b-col
                  v-if="ReportType === 'school' || ReportType === 'district'"
                  col
                  md="6"
                >
                  <visual-component
                    ref="VisualPerformingDonut9to12State"
                    :chart-type="ChartTypes.DonutChart"
                    :chart-data="VisualPerformingDonut9to12State"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="page-break" />
          <b-row>
            <b-col>
              <b-row class="pt-0">
                <b-col
                  v-if="!HideVisualPerformingArts6to8"
                  cols="12"
                  md="6"
                  class="border-right-only pt-4"
                >
                  <span v-html="VisArtsDesc" />
                  <b-row
                    v-for="(fullHorizontal1DataSet, index) in VisualPerformingArts6to8"
                    ref="VPA68Container"
                    :key="`fullHorizontal1DataSet-${index}`"
                  >
                    <b-col style="text-align: left; border: 0px;">
                      <visual-component
                        :ref="`VPA68-Chart-${index}`"
                        :suppress-border="true"
                        :visual-description="''"
                        :chart-type="ChartTypes.HorizontalBarChart"
                        :chart-data="fullHorizontal1DataSet"
                        :show-x-axis-labels="false"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="!HideVisualPerformingArts9to12" cols="12" md="6" class="pt-4">
                  <span v-html="VisArtsDesc" />
                  <b-row
                    v-for="(fullHorizontal2DataSet, index)
                      in VisualPerformingArts9to12"
                    ref="VPA912Container"
                    :key="`fullHorizontal2DataSet-${index}`"
                  >
                    <b-col style="text-align: left; border: 0px;">
                      <visual-component
                        :ref="`VPA912-Chart-${index}`"
                        :suppress-border="true"
                        :visual-description="''"
                        :chart-type="ChartTypes.HorizontalBarChart"
                        :show-x-axis-labels="false"
                        :chart-data="fullHorizontal2DataSet"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import translation from '../../mixins/translation-service.mixin';
import hidden from '../../mixins/hidden.mixin';
import VisualComponent from '../../components/VisualComponent.vue';
import visualPerformingArts from '../../mixins/detailComponents/visual-performing-arts.mixin';

export default {
  components: {
    VisualComponent,
  },
  mixins: [translation, hidden, visualPerformingArts],
  props: {
    chartData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    visualDescription: {
      type: String,
      default: '',
      required: false,
    },
    visualFooter: {
      type: String,
      default: '',
      required: false,
    },
    ChartSubTitle: {
      type: String,
      default: '',
      required: false,
    },
    suppressBorder: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      PrintDisplay: '',
    };
  },
  mounted() {
    const params = {
      schoolYear: this.$store.getters.SelectedSchoolYear.text,
      countyCd: this.$route.params.countyCd,
      districtCd: this.$route.params.districtCd,
      schoolCd: this.$route.params.schoolCd,
      langCd: this.$store.getters.CurrentLanguage,
      reportType: this.ReportType,
    };

    this.$store.dispatch('getDistrictCourseParticipationArts6to8', params);
    this.$store.dispatch('getDistrictCourseParticipationArts9to12', params);
    this.$store.dispatch('getDistrictCourseParticipationArts6to8Donut', params);
    this.$store.dispatch('getDistrictCourseParticipationArts6to8DonutState', params);
    this.$store.dispatch('getDistrictCourseParticipationArts9to12Donut', params);
    this.$store.dispatch('getDistrictCourseParticipationArts9to12DonutState', params);
  },
};
</script>

<style scoped>
.outside-border{
  border: 2px solid #287AB8;
}
.bottom-right-border{
  border-bottom: 2px solid #287AB8;
}
.border-bottom-only{
  border-bottom: 2px solid #287AB8;
}

@media (min-width: 768px) {
  /*show right border only on medium and up */
  .bottom-right-border{
  border-right: 2px solid #287AB8;
  }
  .border-right-only{
    border-right: 2px solid #287AB8;
  }
}
.descriptive-text{
  text-align: left;
  padding: 20px;
}
.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
</style>
