<template>
  <div id="app">
    <span id="pageTop" />
    <state-header v-if="showPrintView" />
    <resource-bar v-if="showPrintView" @school-year-changed="changeSchoolYear" />
    <b-overlay
      :show="showLoading"
      variant="transparent"
      :opacity="0.9"
      :blur="'1em'"
    >
      <router-view :key="$route.fullPath" />
      <div v-show="scrollPosition >= 500" class="scrollToTopBox d-print-none">
        <a v-scroll-to="'#pageTop'" href="#">
          <b-icon-arrow-up-circle-fill class="upArrowIcon" />To Top
        </a>
      </div>
    </b-overlay>
    <div v-show="false" id="u-detail">
      <div>
        Username: {{ currentUser.username }}<br />
        Institution Code: {{ currentUser.institutionCode }}<br />
      </div>
    </div>
  </div>
</template>

<script>
import StateHeader from './components/StateHeader.vue';
import ResourceBar from './components/ResourceBar.vue';
import storeLoader from './mixins/store-loader.mixin';
import TranslationService from './services/translation.service';

export default {
  components: {
    StateHeader,
    ResourceBar,
  },
  mixins: [storeLoader],
  data() {
    return {
      scrollPosition: 0,
      currentUser: {
        username: 'UNSET',
        institutionCode: 'UNSET',
      },
      loading: true,
      reportRouteNames: [
        'DetailReport',
        'Overview',
        'Demographics',
        'StudentGrowth',
        'AcademicAchievement',
        'CollegeCareer',
        'PostSecondary',
        'Climate',
        'Staff',
        'PerPupil',
        'Narrative',
        'Accountability',
        'Default',
        'SummaryReport',
      ],
    };
  },
  computed: {
    showPrintView() {
      return !this.$store.getters.IsPrintView
        && this.$route.name !== 'Login'
        && this.$route.name !== 'NJSmartHandoff';
    },
    showLoading() {
      if (this.$route.name === 'Error' || this.$route.name === 'NJMFA') {
        return false;
      }
      return this.loading;
    },
    storeUser() {
      return this.$store.getters.UserInfo;
    },
    requestCount() {
      return this.$store.getters.RequestCount;
    },
    selectedSchoolYear() {
      return this.$store.getters.SelectedSchoolYear;
    },
  },
  watch: {
    requestCount(newValue) {
      if (this.$route.name === 'Maintenance') {
        this.loading = false;
        return;
      }
      if (newValue === 0) {
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
    storeUser(newValue) {
      if (!newValue || !newValue.username) { return; }
      this.currentUser.username = newValue.username;
      this.currentUser.institutionCode = newValue.institutionCode;
    },
    selectedSchoolYear() {
      if (this.selectedSchoolYear.embargo
        && this.$store.getters.CurrentLanguage === 'ES'
        && process.env.VUE_APP_SHOW_SPANISH_REPORTS) {
        this.$router.push({
          name: 'Error',
        });
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);

    if (this.$route.params.schoolYear) {
      const lowYear = this.$route.params.schoolYear.substring(2, 4);
      const highYear = this.$route.params.schoolYear.substring(7, 9);
      this.$store.dispatch('setInitialSchoolYear', `${lowYear}${highYear}`);
    }
    if (this.$route.query && this.$route.query.lang) {
      switch (this.$route.query.lang.toUpperCase()) {
        case TranslationService.Constants.LanguageOptions.Spanish:
          this.$store.dispatch(
            'setCurrentLanguage',
            TranslationService.Constants.LanguageOptions.Spanish,
          );
          break;
        default:
          this.$store.dispatch(
            'setCurrentLanguage',
            TranslationService.Constants.LanguageOptions.English,
          );
          break;
      }
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    const title = document.createElement('title');
    title.innerText = process.env.VUE_APP_NAME;
    document.head.appendChild(title);

    const favIcon = document.createElement('link');
    favIcon.setAttribute('rel', 'icon');
    favIcon.setAttribute('type', 'image/png');
    favIcon.setAttribute('href', `${process.env.VUE_APP_ROOT}${process.env.VUE_APP_ICON}`);
    document.head.appendChild(favIcon);

    const baseRef = document.createElement('base');
    baseRef.setAttribute('href', `${process.env.VUE_APP_ROOT}`);
    document.head.appendChild(baseRef);

    if (this.$route.name === 'Login' || this.$route.name === 'Maintenance') {
      this.loading = false;
    }
  },
  methods: {
    changeSchoolYear() {
      if (this.reportRouteNames.some((o) => o === this.$route.name)) {
        if (this.$store.getters.SelectedSchoolYear.historical) {
          this.$router.push({
            name: 'PriorYearReport',
            params: {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
            },
            query: {},
          });
        }
        this.$store.dispatch('clearSchoolDistrictinfo');
        this.$store.dispatch('flushReportData');

        if (this.$route.name === 'SummaryReport') {
          if (this.$route.params.schoolCd) {
            this.$store.dispatch('getSchoolSummaryHeader', {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
              langCd: this.$store.getters.CurrentLanguage,
            });
          } else if (this.$route.params.districtCd) {
            this.$store.dispatch('getDistrictSummaryHeader', {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              langCd: this.$store.getters.CurrentLanguage,
            });
          } else {
            // This would call State Summary, but that doesn't exist
          }
        } else {
          console.log('Detail Report');
          if (this.$route.params.schoolCd) {
            this.$store.dispatch('getSchoolDetailHeader', {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              schoolCd: this.$route.params.schoolCd,
              langCd: this.$store.getters.CurrentLanguage,
            });
          } else if (this.$route.params.districtCd) {
            this.$store.dispatch('getDistrictDetailHeader', {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              countyCd: this.$route.params.countyCd,
              districtCd: this.$route.params.districtCd,
              langCd: this.$store.getters.CurrentLanguage,
            });
          } else {
            this.$store.dispatch('getStateDetailHeader', {
              schoolYear: this.$store.getters.SelectedSchoolYear.text,
              langCd: this.$store.getters.CurrentLanguage,
            });
          }
        }

        const newParams = {
          schoolYear: this.$store.getters.SelectedSchoolYear.text,
          reportType: this.$route.params.reportType,
        };

        if (this.$route.params.reportType.toLowerCase() !== 'state') {
          newParams.countyCd = this.$route.params.countyCd;
          newParams.districtCd = this.$route.params.districtCd;
          newParams.schoolCd = this.$route.params.schoolCd;
        }

        this.$router.push({
          name: this.$route.name === 'SummaryReport' ? 'SummaryReport' : 'Overview',
          params: newParams,
          query: {
            lang: this.$store.getters.CurrentLanguage,
            yc: 't',
          },
        }).catch(() => {});
      }
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>
<style>
#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 10.5pt;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.sub-heading {
    font-size: 1.125em;
    color: #287AB8;
    font-weight: bold;
    display: block;
    margin-bottom: 50px;
}
.x-axis-title {
    font-size: 1.125em;
    color: #287AB8;
    font-weight: bold;
    display: block;
}
p{
    font-size: 10.5pt;
    text-align: left;
}
h3{
    font-size: 1.125em;
    color: #287AB8;
    font-weight: bold;
}
h4{
  font-size: 0.875em;
  color: #287AB8;
  font-weight: bold;
}
.v-sidebar-menu {
  margin-top: 148px;
  position: fixed;
}
</style>
<style scoped>
.scrollToTopBox {
  position: fixed;
  bottom: 15px;
  right: 50px;
}
</style>
